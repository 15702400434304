export const enum TYPEKIT {
  D1 = 'd1',
  D2 = 'd2',
  D3 = 'd3',
  D4 = 'd4',
  D5 = 'd5',
  D6 = 'd6',
  P1 = 'p1',
  P2 = 'p2',
  P3 = 'p3',
  P4 = 'p4',
  S2 = 's2',
}

export const enum TITLE_TAG {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export const enum ICON_VARIANT {
  MINOR = 'minor',
  MAJOR = 'major',
  SPOT = 'spot',
}

export const enum CHIP_VARIANT {
  LARGE = 'large',
  SMALL = 'small',
}

export const SPAN_TAG = 'span';
export const PARAGRAPH_TAG = 'p';
export enum PROGRESS_BAR_VARIANTS {
  KG_SIMPLE = 'kg-simple',
  ROUNDED = 'rounded',
}

export const DATE_LOCALE = 'en-US';

// END DATE COMPONENT TYPE
export enum EndDateType {
  CARD,
  BANNER,
}

export const enum CardType {
  CAMPAIGN = 'campaign',
  AUCTION_ITEM = 'auction-item',
  AUCTION_DETAILS = 'auction-item-details',
  NOTIFICATION = 'notification',
}

export const enum BUTTON_LINK_TAG {
  BUTTON = 'button',
  LINK = 'link',
  ANCHOR = 'a',
}

export const enum BREAKPOINT {
  SMALL_MOBILE = 'smallMobile',
  LARGE_MOBILE = 'largeMobile',
  SMALL_TABLET = 'smallTablet',
  SMALL_DESKTOP = 'smallDesktop',
  MEDIUM_DESKTOP = 'mediumDesktop',
  LARGE_DESKTOP = 'largeDesktop',
}

export const enum COLORS {
  INTERACTIVE_KG_BLUE = 'interactive-kg-blue',
  INTERACTIVE_BLACK = 'interactive-black',
  INTERACTIVE_WHITE = 'interactive-white',
  INTERACTIVE_LIGHT_GREY = 'interactive-light-grey',
  INTERACTIVE_DISABLED_GREY = 'interactive-disabled-grey',
  INTERACTIVE_SOFT_BLUE = 'interactive-soft-blue',
  TEXT_BLACK = 'text-black',
  TEXT_MEDIUM_GREY = 'text-medium-grey',
  TEXT_WHITE = 'text-white',
  TEXT_DISABLED_TEXT = 'text-disabled-text',
  BACKGROUND_WHITE = 'background-white',
  BACKGROUND_TINT_GREY = 'background-tint-grey',
  BORDERS_STROKES_BORDER_GREY = 'borders-strokes-border-grey',
  SYSTEM_ERROR_RED = 'system-error-red',
  SYSTEM_ERROR_TINT_RED = 'system-error-tint-red',
  SYSTEM_SUCCESS_GREEN = 'system-success-green',
  SYSTEM_SUCCESS_TINT_GREEN = 'system-success-tint-green',
  SYSTEM_CAUTION_YELLOW = 'system-caution-yellow',
  SYSTEM_CAUTION_TINT_YELLOW = 'system-caution-tint-yellow',
  SYSTEM_ORANGE = 'system-orange',
}

export const DEFAULT_ROLES_OPTIONS = [
  { id: '1', name: 'Admin' },
  { id: '2', name: 'User' },
];

export enum ROLES {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum MASKTYPES {
  CURRENCY = 'currency',
}

export enum GRIDTYPES {
  CAMPAIGN = 'campaign',
  ITEM = 'item',
}

export const MOUSEDOWN_EVENT_LISTENER = 'mousedown';
export const KEYDOWN_EVENT_LISTENER = 'keydown';
export const CLICK_EVENT_LISTENER = 'click';

export const ESCAPE_KEY = 'Escape';
export const ENTER_KEY = 'Enter';

export enum OVERFLOW {
  HIDDEN = 'hidden',
  UNSET = 'unset',
}

export enum STATUS {
  ACTIVE = 'active',
  PENDING = 'pending',
  DECLINED = 'declined',
  DELETED = 'deleted',
  COMPLETED = 'completed',
}

export const HOME_PAGE_ROUTE = '/';
export const SETTINGS_PAGE_ROUTE = '/settings';
export const NOT_FOUND_ERROR_PAGE_ROUTE = '/not-found';
export const SERVER_ERROR_PAGE_ROUTE = '/server-error';
export const UNAUTHORIZED_ERROR_PAGE_ROUTE = '/unauthorized';
export const ITEM_DETAILS_PAGE_ROUTE = '/:campaignId/:itemId';
export const CAMPAIGN_DETAILS_PAGE_ROUTE = '/:campaignId';
export const LOGIN_PAGE_ROUTE = '/login';

export const DEFAULT_AUTH_TOKEN_KEY = 'authenticationToken';
export const DEFAULT_CURRENT_USER_KEY = 'currentUser';
export const DEFAULT_NO_TOKEN_FALLBACK = 'no-token';
export const DEFAULT_NO_USER_FALLBACK = 'no-user';
export const AUTH_HEADER_BEARER = 'Bearer';
export const TOKEN_REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes/hour * 60 seconds/minute * 1000 milliseconds/second = 30 min in ms

export enum INFO_MESSAGE_TYPES {
  DEFAULT = 'default',
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export const ITEM_ID_MODIFIER = 'item-id';
export const EMPTY_COLUMN_HEADER = '';
export const APPROVAL_HEADER_EXCEPTION = 'Approval';

export enum CURRENCY_TYPES {
  US = 'USD ',
  CANADIAN = 'CAD ',
}

export const UPLOAD_ITEM_IMAGE_URL = '/api/auction_items/upload';
export const DELETE_ITEM_IMAGE_URL = '/api/delete';
export const DELETE_ITEM_IMAGES_URL = '/api/deleteMultiple';
export const FIND_FILE_BY_KEY_URL = '/api/find';
export const CAMPAIGN_IMAGES_ENDPOINT = '/api/campaigns/upload';

export const FILE_MANAGER_DOMAIN = 'amazonaws.com/';
export const URL_VARIABLE_OPERATOR = '?';
export const URL_OPERATOR = '/';
export const CAMPAIGN_CARD_FALLBACK_IMAGE_URL =
  'https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8189.jpg';

export const TOAST_OFFICES_FETCH_ERROR = 'Could not fetch offices';

export const TOAST_ADDED = 'was added.';
export const TOAST_UPDATED = 'was updated.';
export const FILE_MANAGER_ERROR =
  'An error occured while trying to save images.';

export const EMPTY_STRING = '';

export const OFFICES_ERROR = 'Failed to load the offices.';
export const CATEGORIES_ERROR = 'Failed to load the categories.';
export const TOP_BID_ERROR = 'Failed to fetch top bid.';

export enum ASPECT_RATIO {
  ONE_BY_ONE = '1-1',
}

export const SOCKET_BROKER_URL = process.env
  .REACT_APP_SOCKET_BROKER_URL as string;

export enum NOTIFICATION_TYPE {
  ITEM_APPROVE = 'ITEM_APPROVE',
  ITEM_DECLINE = 'ITEM_DECLINE',
  ITEM_UPDATE = 'ITEM_UPDATE',
  ITEM_DELETE = 'ITEM_DELETE',
  ITEM_BID_DELETE = 'ITEM_BID_DELETE',
}
export const DESTINATION_ADD = '/add';
export const DESTINATION_ABORT = '/delete/abort';
export const TOPIC_FLAG_DELETE = '/flag/delete';
export const TOPIC_DELETE = '/delete';
export const TOPIC_PREFIX = '/topic/bids/';
export const DESTINATION_PREFIX = '/app/bids/';
export const NOTIFICATIONS_PREFIX = '/topic/notifications/';

export const SLASH_SYMBOL = '/';
export const SPACE_CHAR = ' ';

export const TWO_FLOAT_PRECISION_DECIMAL = 2;

export const TIME_SECOND = 'second';
export const ONE_SECOND = 1000;

export enum POLICY {
  NETWORK_ONLY = 'network-only',
}

export const FRACTION_DIGITS = 2;
export const STRING_STYLE = 'currency';
export const DOLLAR_SYMBOL = '$';
export const SEARCH_BAR_PLACEHOLDER = 'Search for...';
