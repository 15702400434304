import {
  type ChangeEvent,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from 'react';
import Checkbox from 'components/molecules/Checkbox';
import {
  type RequiredCheckboxOption,
  type CheckboxGroupProps,
  type CheckboxOption,
  type SelectedCheckboxOption,
} from './CheckboxGroup.types';
import Text from 'components/atoms/Text';
import styles from './checkboxGroup.module.scss';
import { TYPEKIT } from 'utils/constants';
import ErrorMessage from '../ErrorMessage';
import { v4 as uuidv4 } from 'uuid';
import { AS_LEGEND, MASTER_CHECKBOX_INDEX } from './CheckboxGroup.constants';

const getMappedOptions = (
  options: RequiredCheckboxOption[],
  defaultValues: string[],
  masterCheckboxOption: string | undefined,
  masterCheckboxId: React.MutableRefObject<string>
): CheckboxOption[] => {
  const mappedOptions = options.map((option) => ({
    ...option,
    isDisabled: false,
    isIndeterminate: false,
    isChecked: defaultValues?.includes(option.id),
  }));

  if (masterCheckboxOption !== undefined) {
    const isChecked = mappedOptions.every((option) => option.isChecked);
    const isIndeterminate =
      !isChecked && mappedOptions.some((option) => option.isChecked);
    return [
      {
        id: masterCheckboxId.current,
        name: masterCheckboxOption,
        isChecked,
        isIndeterminate,
      },
      ...mappedOptions,
    ];
  } else {
    return mappedOptions;
  }
};

const getCheckedIds = (
  options: CheckboxOption[],
  startIndex = 0
): SelectedCheckboxOption[] => {
  return options
    .slice(startIndex)
    .filter((opt) => opt.isChecked)
    .map((opt) => {
      return { id: opt.id };
    });
};

/**
 * CheckboxGroup is a React component that represents a group of checkboxes.
 * It allows users to select multiple options from a list of checkboxes and provides
 * callbacks for handling checkbox state changes. The group has a master checkbox,
 * which is the first element on the options array, to toggle the state of all other checkboxes.
 *
 * @param {string} ariaLabel - ARIA label for the entire checkbox group.
 * @param {CheckboxOption[]} options - An array of CheckboxOption objects representing the individual checkboxes.
 * @param {string} [label] - Label for the CheckboxGroup component.
 * @param {string[]} defaultValues - String array containing the ids of the checkboxes that should be selected.
 * @param {string} [errorMessage] - Error message to be displayed.
 * @param {function} onCheckChange - A callback function invoked when checkbox states change.
 * @returns {React.FC} The rendered CheckboxGroup component.
 *
 * @example
 * // Example usage:
 * import CheckboxGroup from './CheckboxGroup';
 * import { CHECKED, UNCHECKED } from 'utils/constants';
 *
 * function MyComponent() {
 *   const handleCheckboxChange = (selectedCheckboxes) => {
 *     // Handle checkbox selection changes here
 *   };
 *
 *   const checkboxOptions = [
 *     { id: 'checkbox1', name: 'Option 1', state: CHECKED },
 *     { id: 'checkbox2', name: 'Option 2', state: UNCHECKED },
 *     // ...more checkbox options
 *   ];
 *
 *   return (
 *     <CheckboxGroup
 *       ariaLabel="Select Options"
 *       options={checkboxOptions}
 *       onCheckChange={handleCheckboxChange}
 *     />
 *   );
 * }
 */

const CheckboxGroup = forwardRef<HTMLFieldSetElement, CheckboxGroupProps>(
  (
    {
      ariaLabel,
      options,
      label,
      errorMessage,
      defaultValues = [],
      masterCheckboxOption,
      onCheckChange,
    },
    ref
  ) => {
    const masterCheckboxId = useRef(uuidv4());
    const masterCheckboxRef = useRef<HTMLInputElement>(null);

    const [checkboxOptions, setCheckboxOptions] = useState<CheckboxOption[]>(
      getMappedOptions(
        options,
        defaultValues,
        masterCheckboxOption,
        masterCheckboxId
      )
    );

    const handleMasterChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const isChecked = event.target.checked;
      const updatedOptions: CheckboxOption[] = checkboxOptions.map((option) => {
        if (option.isDisabled !== undefined && option.isDisabled) {
          return option;
        } else {
          return { ...option, isChecked };
        }
      });

      updatedOptions[MASTER_CHECKBOX_INDEX].isIndeterminate = false;

      setCheckboxOptions(updatedOptions);

      onCheckChange(getCheckedIds(updatedOptions, 1));
    };

    const handleCheckboxChange = (
      index: number,
      event: ChangeEvent<HTMLInputElement>
    ): void => {
      const isChecked = event.target.checked;
      const updatedOptions = [...checkboxOptions];
      updatedOptions[index].isChecked = isChecked;

      if (masterCheckboxOption !== undefined) {
        const allChecked = updatedOptions
          .slice(1)
          .every((option) => option.isChecked);
        const someChecked = updatedOptions
          .slice(1)
          .some((option) => option.isChecked);
        updatedOptions[MASTER_CHECKBOX_INDEX].isChecked = allChecked;
        updatedOptions[MASTER_CHECKBOX_INDEX].isIndeterminate =
          !allChecked && someChecked;
      }

      setCheckboxOptions(updatedOptions);
      onCheckChange(
        getCheckedIds(
          updatedOptions,
          masterCheckboxOption !== undefined ? 1 : 0
        )
      );
    };

    useEffect(() => {
      if (masterCheckboxOption !== undefined) {
        const masterCheckbox = masterCheckboxRef.current;
        if (masterCheckbox !== null) {
          masterCheckbox.indeterminate =
            checkboxOptions[MASTER_CHECKBOX_INDEX].isIndeterminate;
        }
      }

      onCheckChange(
        getCheckedIds(
          checkboxOptions,
          masterCheckboxOption !== undefined ? 1 : 0
        )
      );
    }, [checkboxOptions, masterCheckboxOption, onCheckChange]);

    return (
      <fieldset
        ref={ref}
        aria-label={ariaLabel}
        className={styles['checkbox-group']}>
        {label !== null && (
          <Text
            as={AS_LEGEND}
            variant={TYPEKIT.D5}
            className={styles['checkbox-group__label']}>
            {label}
          </Text>
        )}
        <ul className={styles['checkbox-group__container']}>
          {masterCheckboxOption !== undefined && (
            <li className={styles['checkbox-group__singular-container']}>
              <Checkbox
                aria-controls={checkboxOptions
                  .slice(1)
                  .map((option) => option.id)
                  .join(' ')}
                name={checkboxOptions[MASTER_CHECKBOX_INDEX].name}
                checked={checkboxOptions[MASTER_CHECKBOX_INDEX].isChecked}
                onChange={handleMasterChange}
                id={checkboxOptions[MASTER_CHECKBOX_INDEX].id}
                ref={masterCheckboxRef}
                value={checkboxOptions[MASTER_CHECKBOX_INDEX].id}
              />
            </li>
          )}
          {checkboxOptions
            .slice(masterCheckboxOption !== undefined ? 1 : 0)
            .map((option, index) => (
              <li
                key={option.id}
                className={styles['checkbox-group__singular-container']}>
                <Checkbox
                  name={option.name}
                  onChange={(event) => {
                    handleCheckboxChange(
                      masterCheckboxOption !== undefined ? index + 1 : index,
                      event
                    );
                  }}
                  id={option.id}
                  disabled={option.isDisabled === true}
                  checked={option.isChecked}
                  value={option.id}
                />
              </li>
            ))}
        </ul>
        {Boolean(errorMessage) && <ErrorMessage error={errorMessage} />}
      </fieldset>
    );
  }
);

CheckboxGroup.displayName = 'CheckboxGroup';
export default CheckboxGroup;
