import { maskCurrency } from 'utils/masks';

export const maskFormatters: Record<
  string,
  (text: string, currencySymbol: string) => string
> = {
  currency: maskCurrency,
};

export function maskText(
  text: string,
  mask: string,
  currencySymbol: string
): string {
  const formatter = maskFormatters[mask];
  return formatter(text, currencySymbol);
}

export default maskText;
