import React from 'react';
import { type WinnerBannerProps } from './WinnerBanner.types';
import Image from 'components/atoms/Image';
import styles from './winnerBanner.module.scss';
import winningBackground from 'assets/images/winners-header.jpeg';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import {
  TOP_BID_SUBTITLE,
  WINNER_BANNER_DEFAULT_ASPECT_RATIO,
  WINNER_BANNER_IMAGE_ALT,
  WINNER_PICTURE_ALT,
  WINNER_SUBTITLE,
  WINNER_SUBTTITLE_TEXT_TYPE,
  WINNER_TITLE,
  WINNER_TITLE_TEXT_TYPE,
} from './WinnerBanner.constants';
// import { maskCurrency } from 'utils/masks';

/**
 * Banner is displayed when a auction item ended to declare the winner of the bid.
 *
 * @param {string} winnerPicture - Required. Profile picture of the user winner.
 * @param {string} winnerName - Required. Full name of the user winner.
 * @param {number} winningBid - Required. The top bid.
 */
const WinnerBanner: React.FC<WinnerBannerProps> = ({
  winnerPicture,
  winnerName,
  winningBid,
}) => {
  return (
    <div className={styles.banner}>
      <Image
        src={winningBackground}
        alt={WINNER_BANNER_IMAGE_ALT}
        aspectRatio={WINNER_BANNER_DEFAULT_ASPECT_RATIO}
        className={styles.banner__image}
      />
      <div className={styles.winner__container}>
        <Text
          as={WINNER_TITLE_TEXT_TYPE}
          variant={TYPEKIT.D3}>
          {WINNER_TITLE}
        </Text>
        <div className={styles.winner__information}>
          <div className={styles.winner__profile}>
            <div className={styles['profile-picture__container']}>
              <Image
                src={winnerPicture}
                alt={`${WINNER_TITLE} ${winnerName}${WINNER_PICTURE_ALT}`}
                className={styles['profile-picture']}
              />
            </div>

            <div className={styles.winner__text}>
              <Text
                as={WINNER_SUBTTITLE_TEXT_TYPE}
                variant={TYPEKIT.P3}>
                {WINNER_SUBTITLE}
              </Text>
              <Text variant={TYPEKIT.D4}>{winnerName}</Text>
            </div>
          </div>
          <div className={styles.winner__text}>
            <Text
              as={WINNER_SUBTTITLE_TEXT_TYPE}
              variant={TYPEKIT.P3}>
              {TOP_BID_SUBTITLE}
            </Text>
            <Text
              displayCurrency
              variant={TYPEKIT.D4}>
              {winningBid}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WinnerBanner;
