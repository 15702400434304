import React from 'react';
import { useCountdown } from 'hooks/useCountdown';
import { ExpiredMessage } from '../ExpiredMessage/ExpiredMessage';
import { ShowCountdown } from '../ShowCountdown/ShowCountdown';
import { type CountdownProps } from './Countdown.types';

/**
 * A generic countdown component that show the remaining time from a target date given
 *
 * @component
 * @example
 * <Countdown
 * targetDate='2020-10-02'
 * variant={TYPEKIT.D1}
 * className={styles.countdown}
 * descriptive/>
 *
 * <Countdown
 * targetDate='2020-10-02'
 * expirationMessage='Auction closed'
 * variant={TYPEKIT.D1}
 * className={styles.countdown}
 * descriptive/>
 *
 *
 * @param {string} targetDate - Target date given for the countdown to close
 * @param {string} expirationMessage - Text to be displayed when out of time
 * @param {boolean} descriptive - Boolean property that enables each number title as HOURS, MINUTES, SECONDS, etc
 * @param {boolean} spaced - Boolean property that enables spacing between date elements
 * @param {string} className - Given class for styling properties
 * @param {TYPEKIT} variant - Typography size
 */

const Countdown: React.FC<CountdownProps> = ({
  targetDate,
  expirationMessage,
  descriptive,
  spaced,
  className,
  variant,
}) => {
  const { months, days, hours, minutes, seconds, counterOff } =
    useCountdown(targetDate);

  return (
    <div className={className}>
      {counterOff ? (
        expirationMessage !== null && expirationMessage !== undefined ? (
          <ExpiredMessage message={expirationMessage} />
        ) : null
      ) : (
        <ShowCountdown
          months={months}
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          spaced={spaced ?? false}
          descriptive={descriptive ?? false}
          variant={variant}
        />
      )}
    </div>
  );
};

export default Countdown;
