/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { type FC } from 'react';
import styles from './locations.module.scss';
import { type LocationsProps } from './Locations.types';
import locationIcon from 'assets/svgs/location-icon.svg';
import Text from '../../atoms/Text/Text';
import { TYPEKIT } from 'utils/constants';
import Icon from 'components/atoms/Icon';
import cx from 'classnames';

const Locations: FC<LocationsProps> = ({
  className,
  locations,
  showLocationIcon = true,
}) => {
  const sortAscendent = (location1: string, location2: string): number => {
    return location1 < location2 ? -1 : 1;
  };

  return (
    <div className={cx(styles.locations, className)}>
      {((showLocationIcon as boolean) ?? false) && (
        <Icon
          variant="minor"
          src={locationIcon}
          color="interactive-black"
          ariaLabel="Locations icon"
        />
      )}
      <Text variant={TYPEKIT.P3}>
        {locations.sort(sortAscendent).join(' / ')}
      </Text>
    </div>
  );
};

export default Locations;
