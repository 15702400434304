import React, { useCallback, useRef, useState } from 'react';
import ModalOverlay from 'components/atoms/ModalOverlay';
import { type FormModalProps } from './FormModal.types';
import styles from './formModal.module.scss';
import cx from 'classnames';
import Icon from 'components/atoms/Icon';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import ButtonLink from 'components/molecules/ButtonLink';
import Modal from '../Modal';
import {
  ACCEPT_BUTTON_VARIANT,
  ARIA_MODAL,
  BUTTONS_SIZE_VARIANT,
  BUTTON_AS,
  DEFAULT_EXIT_ICON_ARIA_LABEL,
  DISMISS_BUTTON_VARIANT,
  FORM_ID,
  MODAL_ACCEPT_TEXT,
  MODAL_CANCEL_TEXT,
  MODAL_DESCRIBED_BY,
  MODAL_DESCRIPTION,
  MODAL_LABELLED_BY,
  MODAL_OVERLAY_ROLE,
  MODAL_TITLE,
  SUBMIT_TYPE,
  TEXT_AS,
  TITLE_ID,
} from './FormModal.constants';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';

/**
 * FormModal is a React component designed to display a form within a modal overlay.
 * This modal also contains an additional confirmation modal that asks users if they are
 * sure they want to leave, thus providing an opportunity to prevent accidental loss of changes.
 *
 * @param {boolean} isOpen Determines if the modal is visible.
 * @param {function} hide Handler function to hide the modal.
 * @param {string} title Text for the modal's title.
 * @param {function} onSubmit Function to handle the form's submission.
 * @param {string} cancelButtonText Text for the cancel button.
 * @param {string} acceptButtonText Text for the accept button.
 * @param {React.ReactNode} children The content of the form.
 *
 *
 * @component
 * @returns {React.FC | null} The `FormModal` component rendered with its content, or `null` if it's not open.
 *
 * @example
 * const handleHideModal = () => {
 *   // Handle modal hide logic here
 * };
 *
 * const handleFormSubmit = (formData) => {
 *   // Handle form data submission here
 * };
 *
 * <FormModal
 *   isOpen={true}
 *   hide={handleHideModal}
 *   title="Create Campaign"
 *   onSubmit={handleFormSubmit}
 *   cancelButtonText="Cancel"
 *   acceptButtonText="Submit"
 * >
 *   // Form fields go here...
 * </FormModal>
 */

const FormModal: React.FC<FormModalProps<any>> = ({
  isOpen,
  hide,
  title,
  onSubmit,
  cancelButtonText,
  acceptButtonText,
  children,
  disabled,
  submitting,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { isVisible, setIsVisible } = useHandleExitAnimation(
    styles.slideOutFromBottomFade,
    () => {
      hide();
      setIsVisible(true);
    },
    modalRef.current as HTMLElement,
    true
  );

  const hideModal = useCallback((): void => {
    setIsModalOpen(true);
  }, []);

  const stayAction = (): void => {
    setIsModalOpen(false);
  };

  const leaveAction = (): void => {
    setIsModalOpen(false);
    setIsVisible(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    onSubmit(formData);
  };

  return isOpen ? (
    <>
      <Modal
        title={MODAL_TITLE}
        ariaLabelledBy={MODAL_LABELLED_BY}
        ariaDescribedBy={MODAL_DESCRIBED_BY}
        cancelButtonText={MODAL_CANCEL_TEXT}
        acceptButtonText={MODAL_ACCEPT_TEXT}
        isOpen={isModalOpen}
        hide={leaveAction}
        action={stayAction}>
        {MODAL_DESCRIPTION}
      </Modal>
      <ModalOverlay
        className={cx(styles.modal__overlay, {
          [styles['modal__overlay--closing']]: !isVisible,
        })}
        isOpen={isOpen}
        hideModal={hideModal}>
        <div
          className={cx(styles.modal, {
            [styles['modal--closing']]: !isVisible,
          })}
          ref={modalRef}
          role={MODAL_OVERLAY_ROLE}
          aria-modal={ARIA_MODAL}
          aria-labelledby={TITLE_ID}>
          <div className={styles.modal__container}>
            <div className={styles.modal__header}>
              <Text
                as={TEXT_AS}
                variant={TYPEKIT.D3}
                id={TITLE_ID}>
                {title}
              </Text>
            </div>
          </div>
          <form
            id={FORM_ID}
            onSubmit={handleSubmit}
            className={styles.modal__form}>
            {children}
          </form>
          <div
            className={cx(
              styles.modal__container,
              styles['modal__container--wider'],
              styles.modal__buttons
            )}>
            <ButtonLink
              as={BUTTON_AS}
              form={FORM_ID}
              isLoading={submitting}
              type={SUBMIT_TYPE}
              disabled={disabled}
              size={BUTTONS_SIZE_VARIANT}
              variant={ACCEPT_BUTTON_VARIANT}>
              {acceptButtonText}
            </ButtonLink>
            <ButtonLink
              as={BUTTON_AS}
              size={BUTTONS_SIZE_VARIANT}
              variant={DISMISS_BUTTON_VARIANT}
              ariaLabel={cancelButtonText}
              onClick={hideModal}
              disabled={submitting}>
              {cancelButtonText}
            </ButtonLink>
          </div>
          <Icon
            ariaLabel={DEFAULT_EXIT_ICON_ARIA_LABEL}
            onClick={hideModal}
            className={cx(styles['modal__exit-icon'])}
            src={faXmark}
          />
        </div>
      </ModalOverlay>
    </>
  ) : null;
};

export default FormModal;
