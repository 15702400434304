import {
  type Dispatch,
  type SetStateAction,
  useState,
  useEffect,
  useCallback,
} from 'react';

interface exitAnimation {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const useHandleExitAnimation = (
  exitAnimationName: string,
  hidingFunction: () => void,
  element: HTMLElement | null,
  defaultVisible?: boolean
): exitAnimation => {
  const [isVisible, setIsVisible] = useState(defaultVisible ?? false);
  const handleExitAnimation = useCallback(
    (e: globalThis.AnimationEvent): any => {
      if (e.animationName === exitAnimationName) hidingFunction();
    },
    [exitAnimationName, hidingFunction]
  );

  useEffect(() => {
    // There needs to be 2 ifs here,
    // because the linter won't allow to use an AND conditional in the same if
    if (element !== undefined)
      if (element !== null) element.onanimationend = handleExitAnimation;
  }, [element, handleExitAnimation]);

  return { isVisible, setIsVisible };
};

export default useHandleExitAnimation;
