import React from 'react';
import { type ImageSliderProps } from './ImageSlider.types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Keyboard, Navigation } from 'swiper';
import Image from 'components/atoms/Image';
import {
  IMG_ALT_PREFIX,
  DIVIDER,
  SLIDER_LABEL,
  SLIDER_PREVIOUS_BUTTON_LABEL,
  SLIDER_NEXT_BUTTON_LABEL,
  SLIDES_PER_VIEW,
  IMAGES_SPACING,
  IMAGE_CLASSNAME,
} from './ImageSlider.constants';
import cx from 'classnames';
import styles from './imageSlider.module.scss';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/a11y';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './swiper.scss';

const ImageSlider: React.FC<ImageSliderProps> = ({ images, className }) => {
  return (
    <>
      <Swiper
        slidesPerView={SLIDES_PER_VIEW}
        spaceBetween={IMAGES_SPACING}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        a11y={{
          enabled: true,
          itemRoleDescriptionMessage: SLIDER_LABEL,
          nextSlideMessage: SLIDER_NEXT_BUTTON_LABEL,
          prevSlideMessage: SLIDER_PREVIOUS_BUTTON_LABEL,
        }}
        navigation={true}
        modules={[Keyboard, Pagination, Navigation]}
        className={styles['image-carousel']}>
        {images.map((image, id) => (
          <SwiperSlide key={`${id}`}>
            <div className={className}>
              <div className={styles['img-cover']}>
                <Image
                  src={image}
                  alt={`${IMG_ALT_PREFIX} ${id} ${DIVIDER} ${images.length}`}
                  className={cx(styles.img, IMAGE_CLASSNAME)}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ImageSlider;
