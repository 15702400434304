import React from 'react';
import { type NotificationProps } from './Notification.types';

import { CardType, TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import warning_icon from 'assets/svgs/warning-circle-logo.svg';
import close_icon from 'assets/svgs/close-icon.svg';
import Icon from 'components/atoms/Icon';
import { useTimeout } from 'hooks/useTimeout';
import {
  TIMEOUT,
  CLOSE_BUTTON_LABEL,
  CLOSE_ICON_COLOR,
  WARNING_ICON_COLOR,
} from './Notification.constants';
import Card from 'components/atoms/Card';
import styles from './notification.module.scss';
import cx from 'classnames';

const Notification: React.FC<NotificationProps> = ({
  title,
  relatedURL,
  urlTitle,
  close,
}) => {
  useTimeout(() => {
    close();
  }, TIMEOUT);

  return (
    <div
      className={cx(
        styles['open-animation'],
        styles['notification-container']
      )}>
      <Card
        type={CardType.NOTIFICATION}
        redirectTo={relatedURL}
        className={styles.notification}>
        <Icon
          src={warning_icon}
          color={WARNING_ICON_COLOR}
          className={styles['notification__warning-icon']}
          aria-hidden="true"
          disabled
        />
        <div className={styles.notification__content}>
          <Text
            as="span"
            variant={TYPEKIT.D5}
            className={styles.notification__title}>
            {title}
          </Text>
          <Text
            variant={TYPEKIT.P2}
            as="a"
            href={relatedURL}
            className={styles.notification__url}>
            {urlTitle}
          </Text>
        </div>
      </Card>
      <Icon
        src={close_icon}
        color={CLOSE_ICON_COLOR}
        ariaLabel={CLOSE_BUTTON_LABEL}
        onClick={close}
        className={styles['notification__close-icon']}
      />
    </div>
  );
};

export default Notification;
