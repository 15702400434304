import React, { useCallback, useEffect } from 'react';
import styles from './notificationPageWrapper.module.scss';
import { Outlet } from 'react-router-dom';
import {
  AUTH_HEADER_BEARER,
  DEFAULT_AUTH_TOKEN_KEY,
  NOTIFICATIONS_PREFIX,
  type NOTIFICATION_TYPE,
  SOCKET_BROKER_URL,
} from 'utils/constants';
import { useStomp } from 'hooks/useStomp';
import useLocalStorage from 'hooks/useLocalStorage';
import { useNotification } from '../Notification/Notification';
import { getNotificationMessage, showToast } from 'utils/utilities';
import {
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_LINK_TITLE,
} from './NotificationPageWrapper.constants';
import { type IMessage, StompHeaders } from '@stomp/stompjs';
import { type Notification } from 'utils/dataTypes';
import { useAuth } from 'context/Authentication/AuthContext';
import { useQuery } from '@apollo/client';
import { type GetNotificationsByUserIdQuery } from 'graphql/generated-types/graphql';
import { GET_NOTIFICATIONS_BY_USER_ID } from 'graphql/notification/queries';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { useToast } from 'hooks/useToast';

const NotificationPageWrapper: React.FC = () => {
  const { connect, disconnect, subscribe, unsubscribeAll } = useStomp({
    brokerURL: SOCKET_BROKER_URL,
  });
  const [token] = useLocalStorage(DEFAULT_AUTH_TOKEN_KEY, null);
  const notification = useNotification();
  const { currentUser } = useAuth();
  const toast = useToast();

  const showNotification = useCallback(
    (
      itemId: string,
      itemName: string,
      type: NOTIFICATION_TYPE,
      campaignId: string,
      notificationId: string
    ): void => {
      if (notification !== undefined)
        notification.open(
          getNotificationMessage(itemName, type),
          `${campaignId}/${itemId}`,
          NOTIFICATION_LINK_TITLE,
          notificationId
        );
    },
    [notification]
  );

  const onNotificationArrival = useCallback(
    (response: IMessage): void => {
      const notif: Notification = JSON.parse(response.body);
      showNotification(
        notif.itemId,
        notif.itemName,
        notif.notificationType,
        notif.itemCampaignId,
        notif.id
      );
    },
    [showNotification]
  );

  useEffect(() => {
    const headers = Object.assign(new StompHeaders(), {
      Authorization: `${AUTH_HEADER_BEARER} ${token}`,
    });
    connect({
      onConnect: () => {
        subscribe(
          `${NOTIFICATIONS_PREFIX}${currentUser?.id}`,
          onNotificationArrival
        );
      },
      connectHeaders: headers,
      onDisconnect: () => {
        unsubscribeAll();
      },
    });
  }, [
    connect,
    subscribe,
    disconnect,
    unsubscribeAll,
    token,
    onNotificationArrival,
    currentUser?.id,
  ]);

  useQuery<GetNotificationsByUserIdQuery>(GET_NOTIFICATIONS_BY_USER_ID, {
    variables: { id: currentUser?.id },
    onCompleted: (dataNotifications) => {
      const notifications = dataNotifications.getNotificationsByUserId;
      notifications?.forEach((notification) => {
        showNotification(
          notification.itemId,
          notification.itemName,
          notification.notificationType as NOTIFICATION_TYPE,
          notification.itemCampaignId,
          notification.id
        );
      });
    },
    onError: () => {
      showToast(NOTIFICATION_ERROR_MESSAGE, toast, TOAST_TYPE.ERROR);
    },
  });

  return (
    <>
      <div className={styles['notification-wrapper']}></div>
      <Outlet />
    </>
  );
};

export default NotificationPageWrapper;
