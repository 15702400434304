import {
  FILTER_CATEGORY_ID,
  FILTER_CATEGORY_LABEL,
  FILTER_CATEGORY_DEFAULT_OPTION,
  FILTER_OFFICE_ID,
  FILTER_OFFICE_LABEL,
  FILTER_OFFICE_DEFAULT_OPTION,
  FILTER_RESET_OPTION_ID,
} from './CampaignDetailsPage.constants';
import { type ItemFilters } from './CampaignDetailsPage.types';
import { type Filter } from 'components/molecules/FilterBar/FilterBar.types';
import styles from './campaignDetailsPage.module.scss';
import { createCustomFilterHandler } from 'utils/utilities';
import { type DropdownOption } from 'components/molecules/Dropdown/Dropdown.types';

/**
 * Creates filters for CampaignDetailsPage with setAppliedFilters to change state in page
 * @param setAppliedFilters Function to set applied filters state
 * @returns An array of Filter objects
 */
export const getItemFilters = (
  setAppliedFilters: React.Dispatch<React.SetStateAction<ItemFilters>>,
  defaultOption: DropdownOption
): Filter[] => {
  return [
    {
      id: FILTER_OFFICE_ID,
      label: FILTER_OFFICE_LABEL,
      options: [],
      multiple: true,
      resetOption: {
        id: FILTER_OFFICE_ID,
        name: FILTER_OFFICE_DEFAULT_OPTION,
      },
      defaultOption,
      masterCheckboxOption: FILTER_OFFICE_DEFAULT_OPTION,
      onChange: createCustomFilterHandler('office', setAppliedFilters),
    },
    {
      id: FILTER_CATEGORY_ID,
      label: FILTER_CATEGORY_LABEL,
      options: [],
      multiple: true,
      masterCheckboxOption: FILTER_CATEGORY_DEFAULT_OPTION,
      resetOption: {
        id: FILTER_RESET_OPTION_ID,
        name: FILTER_CATEGORY_DEFAULT_OPTION,
      },
      className: styles['campaign-details__filters'],
      onChange: createCustomFilterHandler('category', setAppliedFilters),
    },
  ];
};
