import React from 'react';
import { type ContentLoaderProps } from './ContentLoader.types';
import styles from './contentLoader.module.scss';
import Lottie from 'lottie-react';
import cx from 'classnames';
import loadingAnimation from 'assets/lottie/KG_Logo_Animation.json';
import {
  LOADING_ARIA_BUSY,
  LOADING_ARIA_LABEL,
  LOADING_ARIA_LIVE,
  LOADING_ROLE,
} from './ContentLoader.constants';

const ContentLoader: React.FC<ContentLoaderProps> = ({
  isLoading,
  children,
  className,
}) => {
  return children === undefined || (isLoading ?? false) ? (
    <div
      aria-live={LOADING_ARIA_LIVE}
      aria-busy={LOADING_ARIA_BUSY}
      role={LOADING_ROLE}
      aria-label={LOADING_ARIA_LABEL}
      className={cx(styles.loading__container, className)}>
      <Lottie
        animationData={loadingAnimation}
        loop
        autoplay
        className={styles.loading__lottie}
      />
    </div>
  ) : (
    <>{children}</>
  );
};

export default ContentLoader;
