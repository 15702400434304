import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import { TYPEKIT } from 'utils/constants';
import styles from './biddingTable.module.scss';
import trashIcon from 'assets/svgs/trash-icon.svg';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  BID_HEADER,
  DECIMAL_PLACES_FOR_BID,
  DEFAULT_CURRENCY_SIGN,
  DELETE_BUTTON_TEXT,
  OWNER_NAME_IDENTIFIER,
  RELATIVE_TIME_DAY,
  RELATIVE_TIME_DAYS,
  RELATIVE_TIME_HOUR,
  RELATIVE_TIME_HOURS,
  RELATIVE_TIME_MINUTE,
  RELATIVE_TIME_MINUTES,
  RELATIVE_TIME_MONTH,
  RELATIVE_TIME_MONTHS,
  RELATIVE_TIME_PAST_SUFFIX,
  RELATIVE_TIME_SECONDS,
  RELATIVE_TIME_YEAR,
  RELATIVE_TIME_YEARS,
  UPDATE_LOCALE_LANG,
} from './BiddingTable.constants';

export interface BidTable extends Record<string, unknown> {
  BidId: string;
  UserId: string;
  FirstName: string;
  LastName: string;
  Bid: string;
  ElapsedTime: string;
}

const bidColumnHelper = createColumnHelper<BidTable>();

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.updateLocale(UPDATE_LOCALE_LANG, {
  relativeTime: {
    past: RELATIVE_TIME_PAST_SUFFIX,
    s: RELATIVE_TIME_SECONDS,
    m: RELATIVE_TIME_MINUTE,
    mm: RELATIVE_TIME_MINUTES,
    h: RELATIVE_TIME_HOUR,
    hh: RELATIVE_TIME_HOURS,
    d: RELATIVE_TIME_DAY,
    dd: RELATIVE_TIME_DAYS,
    M: RELATIVE_TIME_MONTH,
    MM: RELATIVE_TIME_MONTHS,
    y: RELATIVE_TIME_YEAR,
    yy: RELATIVE_TIME_YEARS,
  },
});

export const updateBidColumns = (
  deleteFunction: (bidId: string) => void,
  isActive: boolean,
  currentUserId: string
): Array<ColumnDef<BidTable, string>> => {
  return [
    bidColumnHelper.accessor((row) => row.Bid, {
      id: BID_HEADER,
      header: BID_HEADER,
      cell: (info) => {
        return (
          <div
            className={
              info.row.original.UserId === currentUserId
                ? styles['row--owner']
                : styles.row
            }>
            <div className={styles.row__bidder}>
              <Text variant={TYPEKIT.D5}>{`${info.row.original.FirstName} ${
                info.row.original.LastName
              } ${
                info.row.original.UserId === currentUserId
                  ? OWNER_NAME_IDENTIFIER
                  : ''
              }`}</Text>
              <Text
                variant={TYPEKIT.P3}
                className={styles.row__time}>
                {dayjs(info.row.original.ElapsedTime).fromNow()}
              </Text>
            </div>
            <div className={styles.row__bid}>
              <Text
                displayCurrency
                variant={TYPEKIT.P2}>{`${DEFAULT_CURRENCY_SIGN}${parseFloat(
                info.getValue()
              ).toFixed(DECIMAL_PLACES_FOR_BID)}`}</Text>
              {isActive &&
                info.row.index === 0 &&
                info.row.original.UserId === currentUserId && (
                  <div className={styles.delete__container}>
                    <Icon
                      ariaLabel={DELETE_BUTTON_TEXT}
                      onClick={() => {
                        deleteFunction(info.row.original.BidId);
                      }}
                      src={trashIcon}
                      className={styles.delete}
                    />
                  </div>
                )}
            </div>
          </div>
        );
      },
    }),
  ];
};
