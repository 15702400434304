import { createContext } from 'react';

export interface NotificationContextType {
  open: (
    title: string,
    relatedURL: string,
    urlTitle: string,
    notificationId: string
  ) => void;
}

export const NotificationContext = createContext<
  NotificationContextType | undefined
>(undefined);
