export const FILTER_OFFICE_DEFAULT_OPTION = 'All Offices';
export const FILTER_CATEGORY_DEFAULT_OPTION = 'All Categories';
export const FILTER_OFFICE_LABEL = 'Offices';
export const FILTER_CATEGORY_LABEL = 'Categories';
export const FILTER_RESET_OPTION_ID = '0';
export const FILTER_OFFICE_ID = '1';
export const FILTER_CATEGORY_ID = '2';
export const EDIT_CAMPAIGN_LABEL = 'Edit Campaign';
export const CREATE_CAMPAIGN_TITLE = 'Create a Campaign';
export const CREATE_CAMPAIGN_BUTTON_LABEL = 'Create';
export const CANCEL_LABEL = 'Cancel';
export const UPDATE_LABEL = 'Update';
export const DELETE_ITEM_LABEL = 'Delete item';
export const MODAL_LABELLED_BY = 'confirmationModalTitle';
export const MODAL_DESCRIBED_BY = 'confirmationModalDescription';
export const ADD_ITEM_LABEL = 'Add item';
export const EDIT_ITEM_LABEL = 'Edit auction item';
export const ADD_LABEL = 'Add';
export const TAB_ITEMS_LABEL = 'Auction Items';
export const TAB_MANAGE_LABEL = 'Manage';
export const TAB_ITEMS_ID = 'auction-items';
export const TAB_MANAGE_ID = 'manage-items';
export const CAMPAIGN_IMAGE_DEFAULT_NAME = 'campaign_image.jpg';
export const ITEM_IMAGE_DEFAULT_NAME = 'item_image';
export const IMAGE_DEFAULT_TYPE = 'image/jpeg';
export const IMAGE_DEFAULT_EXTENSION = '.jpg';
export const DELETE_DESCRIPTION_CONFIRM = 'Are you sure you want to delete';
export const QUESTION_MARK = '?';
export const DELETE_DESCRIPTION_CONDITIONS =
  'This item will be deleted immediately and all bidders will be notified of this action. You cannot undo this action.';
export const PAGE_CONTAINER_MARGIN = '1rem';
export const DELETE_MESSAGE_SUCCESS = 'was successfully deleted.';
export const DELETE_MESSAGE_FAIL = 'could not be deleted.';
export const ITEM_TRENDING_MARK = 3;
export const BIDS_IN_THE_PAST = 'bids in the past';
export const MINUTES = 'minutes';
export const BUTTON_ACTION_EDIT_LABEL = 'Edit';
export const BUTTON_ACTION_DUPLICATE_LABEL = 'Duplicate';
export const ACTION_EDIT_ID = '1';
export const ACTION_DUPLICATE_ID = '2';
export const CAMPAIGN_NOT_FOUND_MESSAGE = 'NOT_FOUND';
