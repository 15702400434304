import React from 'react';
import { type ManageItemsProps } from './ManageItems.types';
import styles from './manageItems.module.scss';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import {
  NO_PENDING_ITEMS_MESSAGE,
  PENDING_ITEMS_TITLE,
  REVIEWED_ITEMS_TITLE,
  REVIEWED_ITEMS_TITLE_ADMIN,
} from './ManageItems.constants';
import AuctionItemTable from 'components/organisms/AuctionItemTable';
import { NoItemsMessage } from '../NoItemsMessage/NoItemsMessage';

const ManageItems: React.FC<ManageItemsProps> = ({
  pendingItems,
  reviewedItems,
  onAdminAction,
  isActive,
  isAdmin,
  onEdit,
  onDelete,
  onAddItem,
  isListEmpty,
  filterFn,
  filterKeyword,
}) => {
  const getItemsLength = (): number => {
    return pendingItems.length + reviewedItems.length;
  };

  return (
    <div className={styles['manage-items']}>
      {isAdmin && getItemsLength() > 0 && (
        <div className={styles['manage-items__table-container']}>
          <Text
            variant={TYPEKIT.D4}
            as="h1">
            {PENDING_ITEMS_TITLE}
          </Text>
          {pendingItems.length > 0 ? (
            <AuctionItemTable
              items={
                filterFn !== undefined && filterKeyword !== undefined
                  ? filterFn(pendingItems, filterKeyword)
                  : pendingItems
              }
              isActive={isActive}
              isAdmin={isAdmin}
              onAdminAction={onAdminAction}
              onDelete={onDelete}
              onEdit={onEdit}
              approval
            />
          ) : (
            <Text variant={TYPEKIT.D5}>{NO_PENDING_ITEMS_MESSAGE}</Text>
          )}
        </div>
      )}
      {reviewedItems.length > 0 ? (
        <div className={styles['manage-items__table-container']}>
          <Text
            variant={TYPEKIT.D4}
            as="h1">
            {isAdmin ? REVIEWED_ITEMS_TITLE_ADMIN : REVIEWED_ITEMS_TITLE}
          </Text>
          <AuctionItemTable
            className={styles['manage-items__table']}
            items={
              filterFn !== undefined && filterKeyword !== undefined
                ? filterFn(reviewedItems, filterKeyword)
                : reviewedItems
            }
            isActive={isActive}
            isAdmin={isAdmin}
            onAdminAction={onAdminAction}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </div>
      ) : (
        <NoItemsMessage
          isListEmpty={isListEmpty}
          onAddItem={onAddItem}
        />
      )}
    </div>
  );
};

export default ManageItems;
