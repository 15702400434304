import React, {
  type KeyboardEventHandler,
  useRef,
  useState,
  useId,
} from 'react';
import { type TooltipProps } from './Tooltip.types';
import popperArrow from 'assets/svgs/popper-arrow.svg';

import Text from 'components/atoms/Text';
import { ICON_VARIANT, TYPEKIT } from 'utils/constants';
import {
  POPPER_ARROW_TAB_INDEX,
  TOOLTIP_DEFAULT_CONTENT,
  TOOLTIP_DEFAULT_DELAY,
  TOOLTIP_DEFAULT_POSITION,
  TOOLTIP_DEFAULT_SHOW_POPPER_ARROW,
  TOOLTIP_ROLE,
} from './Tooltip.constants';
import Icon from 'components/atoms/Icon';
import styles from './tooltip.module.scss';
import cx from 'classnames';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';

/**
 * Tooltip component for displaying additional information on hover.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {string} props.classname - Additional css properties.
 * @param {boolean} props.showPopperArrow - Whether to show the popper arrow (default: true).
 * @param {string} props.position - The position of the tooltip ('bottom' by default).
 * @param {string} props.ariaDescribedBy - The aria-describedBy for the tooltip.
 * @param {string} props.content - The content to display within the tooltip.
 * @param {React.ReactNode} props.children - The elements that will display the tooltip on hover
 * @returns {JSX.Element} The rendered Tooltip component.
 */
const Tooltip: React.FC<TooltipProps> = ({
  className,
  showPopperArrow = TOOLTIP_DEFAULT_SHOW_POPPER_ARROW,
  position = TOOLTIP_DEFAULT_POSITION,
  content = TOOLTIP_DEFAULT_CONTENT,
  delay = TOOLTIP_DEFAULT_DELAY,
  children,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { isVisible, setIsVisible } = useHandleExitAnimation(
    styles[`slideOutFrom${position.charAt(0).toUpperCase()}Fade`],
    () => {
      clearInterval(timeout.current);
      setActive(false);
    },
    tooltipRef.current as HTMLElement
  );
  const timeout = useRef<NodeJS.Timeout>();
  const tooltipContentId = useId();

  const showTooltip = (): void => {
    setIsVisible(true);
    timeout.current = setTimeout(() => {
      setActive(true);
    }, delay ?? delay);
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setActive(false);
    }
  };

  return (
    <button
      className={cx(styles.tooltip__wrapper, className)}
      onMouseEnter={showTooltip}
      onMouseLeave={() => {
        setIsVisible(false);
      }}
      aria-describedby={tooltipContentId}
      onFocus={showTooltip}
      onBlur={() => {
        setIsVisible(false);
      }}
      onKeyDown={
        handleKeyDown as unknown as KeyboardEventHandler<HTMLButtonElement>
      }>
      {children}
      <div className={cx(styles.tooltip, styles[`tooltip--${position}`])}>
        {!!active && (
          <div
            aria-hidden={!active}
            role={TOOLTIP_ROLE}
            id={tooltipContentId}
            ref={tooltipRef}
            onMouseEnter={() => {
              setIsVisible(false);
            }}
            className={cx(styles.tooltip__container, {
              [styles[`tooltip__container--${position}`]]: isVisible,
              [styles[`tooltip__container--${position}-close`]]: !isVisible,
            })}>
            <div className={styles['tooltip__container-info']}>
              <Text
                className={styles['tooltip__info-text']}
                variant={TYPEKIT.P3}>
                {content}
              </Text>
            </div>
            {!!showPopperArrow && (
              <Icon
                tabIndex={POPPER_ARROW_TAB_INDEX}
                src={popperArrow}
                className={cx(
                  styles['tooltip-popper-arrow-icon'],
                  styles[`tooltip-popper-arrow-icon--${position}`]
                )}
                variant={ICON_VARIANT.MINOR}
              />
            )}
          </div>
        )}
      </div>
    </button>
  );
};

export default Tooltip;
