import React, { useState, useMemo, type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { ToastContext } from '../ToastContext';
import { type TOAST_TYPE } from '../Toast/toast.constants';
import { type ToastProviderProps } from './ToastProvider.types';
import styles from './toastprovider.module.scss';
import Toast from '../Toast/Toast';

export const ToastProvider: React.FC<ToastProviderProps> = (props) => {
  const [toasts, setToasts] = useState<
    Array<{ id: string; content: ReactNode; type: TOAST_TYPE }>
  >([]);

  const GenerateUUID = (): string => {
    return crypto.randomUUID();
  };

  const open = (content: ReactNode, type: TOAST_TYPE): void => {
    setToasts((currentToasts) => [
      { id: GenerateUUID(), content, type },
      ...currentToasts,
    ]);
  };

  const close = (id: string): void => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  };

  const contextValue = useMemo(() => ({ open }), []);

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <div className={styles['toasts-container']}>
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              type={toast.type}
              close={() => {
                close(toast.id);
              }}>
              {toast.content}
            </Toast>
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};
