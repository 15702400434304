import { type FC } from 'react';
import { type CampaignCardProps } from './CampaignCard.types';
import Text from 'components/atoms/Text';
import DonationProgress from 'components/molecules/DonationProgress';
import EndDate from 'components/atoms/EndDate';
import Card from 'components/atoms/Card';
import Image from 'components/atoms/Image';
import {
  BREAKPOINT,
  CAMPAIGN_CARD_FALLBACK_IMAGE_URL,
  CardType,
  EndDateType,
  TYPEKIT,
} from 'utils/constants';
import useBreakpoint from 'hooks/useBreakpoint';
import styles from './campaignCard.module.scss';
import cx from 'classnames';
import Locations from 'components/molecules/Locations/Locations';

/**
 * CampaignCard is a React component that displays information about a campaign.
 * @param {string[]} locations - An array of location strings associated with the campaign.
 * @param {string} title - The title of the campaign.
 * @param {number} raisedAmount - The current amount of donations for the campaign.
 * @param {number} donationGoal - The total donation goal for the campaign.
 * @param {string} endDate - The end date of the campaign.
 * @param {string} imgUrl - The URL of the campaign's image.
 * @param {boolean} isFinished - A boolean indicating if the campaign is finished.
 * @param {string} startDate - The start date of the campaign.
 * @param {string} redirectTo - The URL to redirect to when the card is clicked.
 */
const CampaignCard: FC<CampaignCardProps> = ({
  locations,
  title,
  raisedAmount,
  donationGoal,
  endDate,
  imgUrl,
  isFinished,
  redirectTo,
}) => {
  const { isBreakpoint } = useBreakpoint();
  return (
    <Card
      type={CardType.CAMPAIGN}
      redirectTo={redirectTo}>
      <div className={styles['card-campaign']}>
        <div className={styles['card-campaign__content']}>
          <div className={styles['card-campaign__header']}>
            <Locations locations={locations} />
            <div className={styles['card-campaign__text']}>
              <Text
                className={styles['card-campaign__title']}
                as="h3"
                variant={TYPEKIT.D2}>
                {title}
              </Text>
              <EndDate
                endDatetime={endDate}
                type={EndDateType.CARD}
              />
            </div>
          </div>
          {isBreakpoint(BREAKPOINT.SMALL_TABLET) && (
            <div className={styles['card-campaign__donations']}>
              <DonationProgress
                donationGoal={donationGoal}
                raisedAmount={raisedAmount}
                typekit={TYPEKIT.D3}
                isFinished={isFinished}
                useKFormat
              />
            </div>
          )}
        </div>
        <div className={styles['card-campaign__img-container']}>
          <Image
            src={imgUrl ?? CAMPAIGN_CARD_FALLBACK_IMAGE_URL}
            alt={`${title} campaign`}
            className={styles['card-campaign__img']}></Image>
        </div>
      </div>
      {!isBreakpoint(BREAKPOINT.SMALL_TABLET) && (
        <div
          className={cx(styles['card-campaign__donations'], {
            [styles['card-campaign__donations-ended']]: isFinished,
          })}>
          <DonationProgress
            isFinished={isFinished}
            donationGoal={donationGoal}
            raisedAmount={raisedAmount}
            typekit={TYPEKIT.D3}
            useKFormat
          />
        </div>
      )}
    </Card>
  );
};

export default CampaignCard;
