import { createColumnHelper } from '@tanstack/react-table';
import mockProfilePicture from 'assets/images/profile-picture.png';
import {
  type User,
  type AuctionItem,
  type Option,
  type Campaign,
  type Office,
  type Role,
} from './dataTypes';
import dayjs from 'dayjs';

export interface Bid extends Record<string, unknown> {
  BidId: string;
  UserId: string;
  FirstName: string;
  LastName: string;
  Bid: string;
  ElapsedTime: string;
}
export interface Employee {
  Name: string;
  Age: number;
  Email: string;
  Position: string;
  Location: string;
  Hobbies: string[];
  Status: string;
}

export const mockOffices: Office[] = [
  { id: '1', name: 'San Jose' },
  { id: '2', name: 'Toronto' },
  { id: '3', name: 'New York' },
  { id: '4', name: 'London' },
  { id: '5', name: 'Miami' },
  { id: '6', name: 'Vancouver' },
];

export const mockRoles: Role[] = [
  { id: '1', name: 'Admin', description: 'Admin' },
  { id: '2', name: 'User', description: 'User' },
];
export const mockCategories: Option[] = [
  { id: '1', name: 'Miscellaneous' },
  { id: '2', name: 'Food and Drink' },
  { id: '3', name: 'Service' },
  { id: '4', name: 'Experience' },
];
export const mockUsers: User[] = [
  {
    id: '1',
    firstName: 'Eric',
    lastName: 'Alpizar',
    email: 'ericalpizar@gmail.com',
    office: { id: mockOffices[0].id, name: mockOffices[0].name },
    role: {
      id: mockRoles[1].id,
      name: mockRoles[1].name,
      description: mockRoles[1].name,
    },
    dateJoined: '2023-07-27',
    imageUrl: mockProfilePicture,
  },
  {
    id: '2',
    firstName: 'Jacobo',
    lastName: 'Lopera',
    email: 'jlopera@gmail.com',
    office: { id: mockOffices[0].id, name: mockOffices[0].name },
    role: {
      id: mockRoles[1].id,
      name: mockRoles[1].name,
      description: mockRoles[1].name,
    },
    dateJoined: '2018-05-02',
    imageUrl: mockProfilePicture,
  },
  {
    id: '3',
    firstName: 'Andres',
    lastName: 'Benavides',
    email: 'abenavides@gmail.com',
    office: { id: mockOffices[0].id, name: mockOffices[0].name },
    role: {
      id: mockRoles[1].id,
      name: mockRoles[1].name,
      description: mockRoles[1].name,
    },
    dateJoined: '2020-01-03',
    imageUrl: mockProfilePicture,
  },
  {
    id: '4',
    firstName: 'Alonso',
    lastName: 'Gonzalez',
    email: 'agonzalez@gmail.com',
    office: { id: mockOffices[0].id, name: mockOffices[0].name },
    role: {
      id: mockRoles[1].id,
      name: mockRoles[1].name,
      description: mockRoles[1].name,
    },
    dateJoined: '2012-12-27',
    imageUrl: mockProfilePicture,
  },
  {
    id: '5',
    firstName: 'Juan',
    lastName: 'Carvajal',
    email: 'jcarvajal@gmail.com',
    office: { id: mockOffices[0].id, name: mockOffices[0].name },
    role: {
      id: mockRoles[1].id,
      name: mockRoles[1].name,
      description: mockRoles[1].name,
    },
    dateJoined: '2021-05-15',
    imageUrl: mockProfilePicture,
  },
  {
    id: '6',
    firstName: 'Esteban',
    lastName: 'Aleman',
    email: 'ealeman@gmail.com',
    office: { id: mockOffices[0].id, name: mockOffices[0].name },
    role: {
      id: mockRoles[1].id,
      name: mockRoles[1].name,
      description: mockRoles[1].name,
    },
    dateJoined: '2018-01-03',
    imageUrl: mockProfilePicture,
  },
  {
    id: '8',
    firstName: 'Valeria',
    lastName: 'Montoya',
    email: 'vmontoya@gmail.com',
    office: { id: mockOffices[0].id, name: mockOffices[0].name },
    role: {
      id: mockRoles[1].id,
      name: mockRoles[1].name,
      description: mockRoles[1].name,
    },
    dateJoined: '2021-02-25',
    imageUrl: mockProfilePicture,
  },
];
export const mockEmployees: Employee[] = [
  {
    Name: 'Alice Smith',
    Age: 28,
    Email: 'asmith@gmail.com',
    Position: 'Web Developer',
    Location: 'Wonderland',
    Hobbies: ['Tea Parties', 'Chasing White Rabbits'],
    Status: 'Active',
  },
  {
    Name: 'Bob Johnson',
    Age: 32,
    Email: 'bjohn@gmail.com',
    Position: 'Software Engineer',
    Location: 'Techtopia',
    Hobbies: ['Coding', 'Sci-Fi Novels'],
    Status: 'Active',
  },
  {
    Name: 'Charlie Brown',
    Age: 25,
    Email: 'cbrown@gmail.com',
    Position: 'Graphic Designer',
    Location: 'Artlandia',
    Hobbies: ['Drawing Comics', 'Flying Kites'],
    Status: 'Inactive',
  },
  {
    Name: 'David Davis',
    Age: 34,
    Email: 'ddavis@gmail.com',
    Position: 'Marketing Manager',
    Location: 'Advertopia',
    Hobbies: ['Ad Campaigns', 'Branding'],
    Status: 'Active',
  },
  {
    Name: 'Eva White',
    Age: 27,
    Email: 'ewhite@gmail.com',
    Position: 'Product Manager',
    Location: 'Innovationville',
    Hobbies: ['Product Design', 'Brainstorming'],
    Status: 'Active',
  },
  {
    Name: 'Frankie Green',
    Age: 30,
    Email: 'fgreen@gmail.com',
    Position: 'Data Analyst',
    Location: 'Datatown',
    Hobbies: ['Data Crunching', 'Solving Puzzles'],
    Status: 'Active',
  },
  {
    Name: 'Grace Lee',
    Age: 29,
    Email: 'glee@gmail.com',
    Position: 'UX Designer',
    Location: 'Designland',
    Hobbies: ['User Testing', 'Sketching Wireframes'],
    Status: 'Active',
  },
];

const EmployeeColumnHelper = createColumnHelper<Employee>();

export const EmployeeColumns = [
  EmployeeColumnHelper.accessor((row) => row.Name, {
    id: 'Name',
    header: 'Name',
    cell: (info) => info.getValue(),
  }),
  EmployeeColumnHelper.accessor((row) => row.Age, {
    id: 'Age',
    header: 'Age',
    cell: (info) => info.getValue(),
  }),
  EmployeeColumnHelper.accessor((row) => row.Email, {
    id: 'Email',
    header: 'Email',
    cell: (info) => info.getValue(),
  }),
  EmployeeColumnHelper.accessor((row) => row.Position, {
    id: 'Position',
    header: 'Position',
    cell: (info) => info.getValue(),
  }),
  EmployeeColumnHelper.accessor((row) => row.Location, {
    id: 'Location',
    header: 'Location',
    cell: (info) => info.getValue(),
  }),
  EmployeeColumnHelper.accessor((row) => row.Hobbies, {
    id: 'Hobbies',
    header: 'Hobbies',
    cell: (info) => info.getValue().join(', '),
  }),
  EmployeeColumnHelper.accessor((row) => row.Status, {
    id: 'Status',
    header: 'Status',
    cell: (info) => info.getValue(),
  }),
];

// does not render an actual image, just for testing
export const mockImage = new File([], 'mockImage.jpg', {
  type: 'image/jpeg',
});

export const campaigns: Campaign[] = [
  {
    id: '1',
    name: `Movember 2023`,
    offices: mockOffices.slice(0, 3),
    goal: 25000,
    endDate: '2023-11-30',
    startDate: '2023-11-01',
    imageKey:
      'https://www.pittsburghpenguinsfoundation.org/wp-content/uploads/2017/11/movember.jpg',
    isActive: false,
    isDeleted: false,
    updatedAt: '2023-11-28',
    raisedAmount: 23500,
    createdAt: '2023-11-28',
    updatedBy: mockUsers[0],
  },
  {
    id: '2',
    name: `Childhood Cancer Awareness Month Campaign 2023`,
    offices: mockOffices.slice(0, 2),
    goal: 10000,
    endDate: '2023-09-01',
    startDate: '2023-10-01',
    imageKey:
      'https://healthwatchwiganandleigh.co.uk/sites/healthwatchwiganandleigh.co.uk/files/styles/article_main_image/public/f9625a74c8ebb1efcc50355fd81a5f625e8dab6f-660x363.jpg?itok=thCkBojm',
    isActive: false,
    isDeleted: false,
    updatedAt: '2023-11-28',
    raisedAmount: 23500,
    createdAt: '2023-11-28',
    updatedBy: mockUsers[0],
  },
  {
    id: '3',
    name: `Christmas Food Drive`,
    offices: mockOffices.slice(1, 5),
    goal: 10000,
    endDate: '2023-11-27',
    startDate: '2023-12-15',
    imageKey:
      'https://t4.ftcdn.net/jpg/05/39/70/95/360_F_539709579_4czwytKDv2RQ12TkCLJQ4ABhQASSD3nq.jpg',
    isActive: false,
    isDeleted: false,
    updatedAt: '2023-11-28',
    raisedAmount: 23500,
    createdAt: '2023-11-28',
    updatedBy: mockUsers[0],
  },
  {
    id: '4',
    name: `Community Food Drive`,
    offices: mockOffices.slice(2, 6),
    goal: 2500,
    endDate: '2022-03-01',
    startDate: '2022-03-25',
    imageKey:
      'https://ntfb.org/wp-content/uploads/2021/10/Cans-and-whole-grains-scaled.jpeg',
    isActive: true,
    isDeleted: false,
    updatedAt: '2023-11-28',
    raisedAmount: 23500,
    createdAt: '2023-11-28',
    updatedBy: mockUsers[0],
  },
  {
    id: '5',
    name: `International Women's Day`,
    offices: mockOffices.slice(3, 6),
    goal: 10000,
    endDate: '2023-03-8',
    startDate: '2023-02-15',
    imageKey:
      'https://internationalwomensday.s3-us-west-2.amazonaws.com/images/2022/IWD-relationships.png',
    isActive: true,
    isDeleted: false,
    updatedAt: '2023-11-28',
    raisedAmount: 23500,
    createdAt: '2023-11-28',
    updatedBy: mockUsers[0],
  },
];

export const mockAuctionItems: AuctionItem[] = [
  {
    itemId: '1',
    itemImages: [
      'https://images.pexels.com/photos/6119145/pexels-photo-6119145.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/6107466/pexels-photo-6107466.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/752499/pexels-photo-752499.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    ],
    itemName: 'Cookies',
    itemDescription:
      'Cookies description lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis. lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis.',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$25',
    itemStartPrice: 25,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[1],
    itemStatus: 'active',
    itemOffices: mockOffices.slice(0, 3),
    itemOwnerImage:
      'https://www.perfocal.com/blog/content/images/size/w960/2021/01/Perfocal_17-11-2019_TYWFAQ_100_standard-3.jpg',
    itemTopBid: 50,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: true,
    itemTrendMessage: '9 bids in the past 12 minutes',
    itemIsReviewed: true,
    itemIsApproved: true,
    itemIsDeleted: false,
    itemCampaignId: '1',
  },
  {
    itemId: '2',
    itemImages: [
      'https://images.pexels.com/photos/771502/pexels-photo-771502.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/2362002/pexels-photo-2362002.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    ],
    itemName: 'Disneyland Tickets',
    itemDescription:
      'Disneyland tickets description lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis.',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$100',
    itemStartPrice: 100,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[3],
    itemStatus: 'pending',
    itemOffices: mockOffices.slice(0, 2),
    itemOwnerImage:
      'https://media.istockphoto.com/id/1303206644/photo/portrait-of-smiling-caucasian-man-pose-in-office.jpg?s=612x612&w=0&k=20&c=7lpbx5jEVQkdG0iA9UvsEUmeu7oed2A3suvMwMPAeIs=',
    itemTopBid: 150,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: true,
    itemTrendMessage: '9 bids in the past 12 minutes',
    itemIsReviewed: false,
    itemIsApproved: false,
    itemIsDeleted: false,
    itemCampaignId: '1',
  },
  {
    itemId: '3',
    itemImages: [
      'https://images.pexels.com/photos/4938450/pexels-photo-4938450.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/5928039/pexels-photo-5928039.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/3321416/pexels-photo-3321416.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    ],
    itemName: 'Facial Set',
    itemDescription:
      'Facial set description lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis.',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$10',
    itemStartPrice: 10,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[0],
    itemStatus: 'deleted',
    itemOffices: mockOffices.slice(0, 4),
    itemOwnerImage:
      'https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=',
    itemTopBid: 35,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: false,
    itemTrendMessage: '',
    itemIsReviewed: true,
    itemIsApproved: true,
    itemIsDeleted: true,
    itemCampaignId: '1',
  },
  {
    itemId: '4',
    itemImages: [
      'https://images.pexels.com/photos/8271926/pexels-photo-8271926.jpeg',
    ],
    itemName: 'Nespresso Machine',
    itemDescription: 'Nespresso machine description',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$200',
    itemStartPrice: 200,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[1],
    itemStatus: 'declined',
    itemOffices: mockOffices.slice(0, 2),
    itemOwnerImage:
      'https://sb.kaleidousercontent.com/67418/1672x1018/6463a5af0d/screenshot-2022-05-24-at-15-22-28.png',
    itemTopBid: 210,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: false,
    itemTrendMessage: '',
    itemIsReviewed: true,
    itemIsApproved: false,
    itemIsDeleted: false,
    itemCampaignId: '1',
  },
  {
    itemId: '5',
    itemImages: [
      'https://images.pexels.com/photos/6119145/pexels-photo-6119145.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/6107466/pexels-photo-6107466.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/752499/pexels-photo-752499.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    ],
    itemName: 'Cookies',
    itemDescription:
      'Cookies description lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis. lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis.',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$25',
    itemStartPrice: 25,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[1],
    itemStatus: 'active',
    itemOffices: mockOffices.slice(0, 3),
    itemOwnerImage:
      'https://www.perfocal.com/blog/content/images/size/w960/2021/01/Perfocal_17-11-2019_TYWFAQ_100_standard-3.jpg',
    itemTopBid: 50,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: true,
    itemTrendMessage: '9 bids in the past 12 minutes',
    itemIsReviewed: true,
    itemIsApproved: true,
    itemIsDeleted: false,
    itemCampaignId: '1',
  },
  {
    itemId: '6',
    itemImages: [
      'https://images.pexels.com/photos/771502/pexels-photo-771502.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/2362002/pexels-photo-2362002.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    ],
    itemName: 'Disneyland Tickets',
    itemDescription:
      'Disneyland tickets description lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis.',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$100',
    itemStartPrice: 100,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[3],
    itemStatus: 'pending',
    itemOffices: mockOffices.slice(0, 2),
    itemOwnerImage:
      'https://media.istockphoto.com/id/1303206644/photo/portrait-of-smiling-caucasian-man-pose-in-office.jpg?s=612x612&w=0&k=20&c=7lpbx5jEVQkdG0iA9UvsEUmeu7oed2A3suvMwMPAeIs=',
    itemTopBid: 150,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: true,
    itemTrendMessage: '9 bids in the past 12 minutes',
    itemIsReviewed: false,
    itemIsApproved: true,
    itemIsDeleted: false,
    itemCampaignId: '1',
  },
  {
    itemId: '7',
    itemImages: [
      'https://images.pexels.com/photos/4938450/pexels-photo-4938450.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/5928039/pexels-photo-5928039.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      'https://images.pexels.com/photos/3321416/pexels-photo-3321416.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    ],
    itemName: 'Facial Set',
    itemDescription:
      'Facial set description lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque quis duis.',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$10',
    itemStartPrice: 10,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[0],
    itemStatus: 'deleted',
    itemOffices: mockOffices.slice(0, 4),
    itemOwnerImage:
      'https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=',
    itemTopBid: 35,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: false,
    itemTrendMessage: '',
    itemIsReviewed: true,
    itemIsApproved: true,
    itemIsDeleted: true,
    itemCampaignId: '1',
  },
  {
    itemId: '8',
    itemImages: [
      'https://images.pexels.com/photos/8271926/pexels-photo-8271926.jpeg',
    ],
    itemName: 'Nespresso Machine',
    itemDescription: 'Nespresso machine description',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$200',
    itemStartPrice: 200,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[1],
    itemStatus: 'declined',
    itemOffices: mockOffices.slice(0, 2),
    itemOwnerImage:
      'https://sb.kaleidousercontent.com/67418/1672x1018/6463a5af0d/screenshot-2022-05-24-at-15-22-28.png',
    itemTopBid: 210,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: false,
    itemTrendMessage: '',
    itemIsReviewed: true,
    itemIsApproved: false,
    itemIsDeleted: false,
    itemCampaignId: '1',
  },
  {
    itemId: '9',
    itemImages: [
      'https://images.pexels.com/photos/8271926/pexels-photo-8271926.jpeg',
    ],
    itemName: 'Nespresso Machine',
    itemDescription: 'Nespresso machine description',
    itemOwner: { id: '1', name: 'Item owner' },
    itemStartingBid: '$200',
    itemStartPrice: 200,
    itemDateCreated: '28/10/2023',
    itemCategory: mockCategories[1],
    itemStatus: 'declined',
    itemOffices: mockOffices.slice(0, 2),
    itemOwnerImage:
      'https://sb.kaleidousercontent.com/67418/1672x1018/6463a5af0d/screenshot-2022-05-24-at-15-22-28.png',
    itemTopBid: 210,
    itemTopBidderName: 'Alonso Gonzalez',
    itemIsTrending: false,
    itemTrendMessage: '',
    itemIsReviewed: true,
    itemIsApproved: false,
    itemIsDeleted: false,
    itemCampaignId: '1',
  },
];

export const mockOnEditItem = (itemId: string): void => {
  window.alert(`Edit item ${itemId}`);
};

export const mockOnDeleteItem = (itemId: string): void => {
  window.alert(`Delete item ${itemId}`);
};

export const mockOnAdminAction = (approved: boolean, itemId: string): void => {
  window.alert(`Item ${itemId} was ${approved ? 'approved' : 'declined'}`);
};

export const MOCK_CURRENT_USER_ID = 'f50d5c30-3cbc-4758-8a79-216c4fbe1d9e';

export const mockBids: Bid[] = [
  {
    BidId: 'afed24fa-9b81-42ae-a8d8-737fd4895145',
    UserId: 'f50d5c30-3cbc-4758-8a79-216c4fbe1d9e',
    FirstName: 'Alonso',
    LastName: 'González',
    Bid: '40',
    ElapsedTime: dayjs().toString(),
  },
  {
    BidId: '6fdbe4cf-bcda-48bc-b756-cf64499dbf55',
    UserId: '2ee53d87-21e4-4208-88e3-edf6c43728b4',
    FirstName: 'Tom',
    LastName: 'Machado',
    Bid: '30',
    ElapsedTime: dayjs().subtract(1, 'minute').toString(),
  },
  {
    BidId: '1ac2967d-5c47-4559-ac93-c5fa4a1ae408',
    UserId: '2ee53d87-21e4-4208-88e3-edf6c43728b4',
    FirstName: 'Tom',
    LastName: 'Machado',
    Bid: '5',
    ElapsedTime: dayjs().subtract(1, 'month').toString(),
  },
  {
    BidId: 'f566719f-d5c5-4fa4-93bf-e3f32ddc63a2',
    UserId: '2ee53d87-21e4-4208-88e3-edf6c43728b4',
    FirstName: 'Tom',
    LastName: 'Machado',
    Bid: '20',
    ElapsedTime: dayjs().subtract(3, 'minutes').toString(),
  },
  {
    BidId: '581fb15b-db4a-4e7c-bb51-518b2252858f',
    UserId: '2ee53d87-21e4-4208-88e3-edf6c43728b4',
    FirstName: 'Tom',
    LastName: 'Machado',
    Bid: '19',
    ElapsedTime: dayjs().subtract(1, 'hours').toString(),
  },
  {
    BidId: '8a9b92a7-6b78-4c20-ae12-06614b54e3cf',
    UserId: '2ee53d87-21e4-4208-88e3-edf6c43728b4',
    FirstName: 'Tom',
    LastName: 'Machado',
    Bid: '18',
    ElapsedTime: dayjs()
      .subtract(23, 'hours')
      .subtract(59, 'minutes')
      .toString(),
  },
  {
    BidId: '2aaea669-4f4c-4db2-b7bb-d2aad3639097',
    UserId: '2ee53d87-21e4-4208-88e3-edf6c43728b4',
    FirstName: 'Tom',
    LastName: 'Machado',
    Bid: '15',
    ElapsedTime: dayjs().subtract(1, 'days').toString(),
  },
  {
    BidId: 'cdaff5dd-1173-4ba2-aa1f-34892d0417ba',
    UserId: 'f50d5c30-3cbc-4758-8a79-216c4fbe1d9e',
    FirstName: 'Alonso',
    LastName: 'González',
    Bid: '10',
    ElapsedTime: dayjs()
      .subtract(29, 'day')
      .subtract(23, 'hours')
      .subtract(59, 'minutes')
      .toString(),
  },
];
