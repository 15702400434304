import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Banner from 'components/organisms/Banner';
import PageContainer from 'components/atoms/PageContainer';
import FilterBar from 'components/molecules/FilterBar';
import {
  ADD_ITEM_LABEL,
  ADD_LABEL,
  CANCEL_LABEL,
  DELETE_DESCRIPTION_CONDITIONS,
  DELETE_DESCRIPTION_CONFIRM,
  DELETE_ITEM_LABEL,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS,
  EDIT_CAMPAIGN_LABEL,
  CREATE_CAMPAIGN_TITLE,
  CREATE_CAMPAIGN_BUTTON_LABEL,
  EDIT_ITEM_LABEL,
  FILTER_CATEGORY_DEFAULT_OPTION,
  FILTER_OFFICE_DEFAULT_OPTION,
  MODAL_DESCRIBED_BY,
  MODAL_LABELLED_BY,
  PAGE_CONTAINER_MARGIN,
  QUESTION_MARK,
  TAB_ITEMS_ID,
  TAB_ITEMS_LABEL,
  TAB_MANAGE_ID,
  TAB_MANAGE_LABEL,
  UPDATE_LABEL,
  BUTTON_ACTION_EDIT_LABEL,
  BUTTON_ACTION_DUPLICATE_LABEL,
  ACTION_EDIT_ID,
  ACTION_DUPLICATE_ID,
  CAMPAIGN_NOT_FOUND_MESSAGE,
} from './CampaignDetailsPage.constants';
import Text from 'components/atoms/Text';
import {
  NOT_FOUND_ERROR_PAGE_ROUTE,
  ROLES,
  SEARCH_BAR_PLACEHOLDER,
  SERVER_ERROR_PAGE_ROUTE,
  TOAST_UPDATED,
  TYPEKIT,
} from 'utils/constants';
import useBreakpoint from 'hooks/useBreakpoint';
import ManageItems from './ManageItems';
import PageHeader from 'components/molecules/PageHeader';
import { getItemFilters } from './CampaignDetailsPage.filters';
import CampaignForm from 'components/organisms/CampaignForm';
import Modal from 'components/organisms/Modal';
import { useToast } from 'hooks/useToast';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import AuctionItemDrawer from 'components/organisms/AuctionItemDrawer';
import { type AuctionItemFormData } from 'components/organisms/AuctionItemDrawer/AuctionItemDrawer.types';
import ButtonAddItem from './ButtonAddItem/ButtonAddItem';
import { type Campaign, type AuctionItem } from 'utils/dataTypes';
import Tab from 'components/molecules/Tab';
import AuctionItems from './AuctionItems/AuctionItems';
import { useNavigate, useParams } from 'react-router-dom';
import { type CampaignFormData } from 'components/organisms/CampaignForm/CampaignForm.types';
import { type ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_CAMPAIGN_BY_ID } from 'graphql/campaign/queries.gql';
import { GET_ALL_OFFICES } from 'graphql/office/queries.gql';
import { GET_ALL_CATEGORIES } from 'graphql/category/queries.gql';
import { GET_ALL_ITEMS_FOR_CAMPAIGN } from 'graphql/item/queries.gql';
import {
  findItemById,
  getPendingItems,
  getAllItems,
  mapItemDBsToAuctionItems,
} from './CampaignDetailsPage.helpers';
import { DELETE_ITEM, REVIEW_ITEM } from 'graphql/item/mutations.gql';
import {
  type GetCampaignByIdQuery,
  type GetAllCategoriesQuery,
  type GetAllOfficesQuery,
  type DeleteItemMutation,
  type ReviewItemMutation,
  type GetAllItemsForCampaignQuery,
  type ItemTrend,
} from 'graphql/generated-types/graphql';
import { useAuth } from 'context/Authentication/AuthContext';
import { type ButtonAction } from 'components/organisms/AuctionItemTable/ActionsMenu/ActionsMenu.types';
import ActionsMenu from 'components/organisms/AuctionItemTable/ActionsMenu/ActionsMenu';
import styles from './campaignDetailsPage.module.scss';
import ContentLoader from 'components/atoms/ContentLoader';
import { type AuctionItemForTable } from 'components/organisms/AuctionItemTable/AuctionItemTable.types';
import { type DropdownOption } from 'components/molecules/Dropdown/Dropdown.types';
import fileManagerClient from 'api/fileManagerClient';

const CampaignDetailsPage: React.FC = () => {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState<Campaign>();
  const [allItems, setAllItems] = useState<AuctionItem[]>([]);
  const [currentItems, setCurrentItems] = useState<AuctionItem[]>(allItems);
  const [appliedFilters, setAppliedFilters] = useState({
    office: [FILTER_OFFICE_DEFAULT_OPTION],
    category: [FILTER_CATEGORY_DEFAULT_OPTION],
  });
  const { currentUser } = useAuth();
  const [filters, setFilters] = useState(
    getItemFilters(setAppliedFilters, currentUser?.office as DropdownOption)
  );
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDuplicateFormOpen, setIsDuplicateFormOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isItemDrawerOpen, setIsItemDrawerOpen] = useState(false);
  const [isForEdit, setIsForEdit] = useState(false);
  const [actionItem, setActionItem] = useState<Partial<AuctionItemFormData>>();
  const [editCampaignFormData, setEditCampaignFormData] =
    useState<Partial<CampaignFormData>>();
  const [duplicateCampaignFormData, setDuplicateCampaignFormData] =
    useState<Partial<CampaignFormData>>();
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const toast = useToast();
  const { isDesktop } = useBreakpoint();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(TAB_ITEMS_ID);

  const {
    loading: loadingCampaign,
    error: errorCampaign,
    data: dataCampaign,
  } = useQuery<GetCampaignByIdQuery>(GET_CAMPAIGN_BY_ID, {
    variables: { campaignId },
  });

  useQuery<GetAllOfficesQuery>(GET_ALL_OFFICES, {
    onCompleted: (dataOffices) => {
      setFilters((oldFilters) => {
        const newFilters = [...oldFilters];
        newFilters[0].options = dataOffices.getAllOffices;
        return newFilters;
      });
    },
    onError: () => {
      goToErrorPage();
    },
  });

  useQuery<GetAllCategoriesQuery>(GET_ALL_CATEGORIES, {
    onCompleted: (dataCategories) => {
      setFilters((oldFilters) => {
        const newFilters = [...oldFilters];
        newFilters[1].options = dataCategories.getAllCategories;
        return newFilters;
      });
    },
    onError: () => {
      goToErrorPage();
    },
  });

  const onSearch = useCallback((searchTerm: string): void => {
    const cleanSearchTerm = searchTerm.trim().replace(/\s\s+/g, ' ');
    setGlobalFilter(cleanSearchTerm);
  }, []);

  const {
    loading: loadingItems,
    error: errorItems,
    data: dataItems,
  } = useQuery<GetAllItemsForCampaignQuery>(GET_ALL_ITEMS_FOR_CAMPAIGN, {
    variables: { campaignId },
  });

  const [deleteItemMutation] = useMutation<DeleteItemMutation>(DELETE_ITEM, {
    onCompleted: (data) => {
      if (actionItem !== undefined) {
        const success = data.flagItemAsDeleted.success;
        if (success) {
          showToastSuccess(`${actionItem.name} ${DELETE_MESSAGE_SUCCESS}`);
          hideDeleteModal();
        } else {
          showToastError(`${actionItem.name} ${DELETE_MESSAGE_FAIL}`);
        }
      }
    },
    onError: (error: ApolloError) => {
      showToastError(error.message);
    },
    refetchQueries: [
      { query: GET_ALL_ITEMS_FOR_CAMPAIGN, variables: { campaignId } },
    ],
  });

  const [reviewItemMutation] = useMutation<ReviewItemMutation>(REVIEW_ITEM, {
    onCompleted: (response) => {
      showToastSuccess(`${response.reviewItem?.name} ${TOAST_UPDATED}`);
    },
    onError: (error: ApolloError) => {
      showToastError(error.message);
    },
    refetchQueries: [
      { query: GET_ALL_ITEMS_FOR_CAMPAIGN, variables: { campaignId } },
    ],
  });

  const goToErrorPage = useCallback((): void => {
    navigate(SERVER_ERROR_PAGE_ROUTE);
  }, [navigate]);

  const goToNotFoundPage = useCallback((): void => {
    navigate(NOT_FOUND_ERROR_PAGE_ROUTE);
  }, [navigate]);

  const showToastSuccess = useCallback(
    (message: string): void => {
      if (toast !== undefined) toast.open(message, TOAST_TYPE.SUCCESS);
    },
    [toast]
  );

  const showToastError = useCallback(
    (message: string): void => {
      if (toast !== undefined) toast.open(message, TOAST_TYPE.ERROR);
    },
    [toast]
  );

  const hideCampaignForm = useCallback((): void => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const hideDuplicateCampaignForm = useCallback((): void => {
    setIsDuplicateFormOpen(false);
  }, [setIsDuplicateFormOpen]);

  const showEditCampaignForm = useCallback((): void => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const showDuplicateCampaignForm = useCallback((): void => {
    setIsDuplicateFormOpen(true);
  }, [setIsDuplicateFormOpen]);

  const hideDeleteModal = useCallback((): void => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  const showDeleteModal = useCallback((): void => {
    setIsDeleteModalOpen(true);
  }, [setIsDeleteModalOpen]);

  const hideItemDrawer = useCallback((): void => {
    setIsItemDrawerOpen(false);
  }, [setIsItemDrawerOpen]);

  const showItemDrawer = useCallback((): void => {
    setIsItemDrawerOpen(true);
  }, [setIsItemDrawerOpen]);

  const handleTabChange = (newActiveTab: string): void => {
    setActiveTab(newActiveTab);
  };

  const handleSearch = (searchTerm: string): void => {
    setGlobalFilter(searchTerm);
  };

  useEffect(() => {
    if (errorCampaign === undefined) {
      if (!loadingCampaign && dataCampaign !== undefined) {
        setCampaign(dataCampaign.getCampaignById as Campaign);
      }
    } else if (
      errorCampaign.graphQLErrors.length > 0 &&
      errorCampaign.graphQLErrors[0].extensions.classification ===
        CAMPAIGN_NOT_FOUND_MESSAGE
    ) {
      goToNotFoundPage();
    } else {
      goToErrorPage();
    }
  }, [
    loadingCampaign,
    errorCampaign,
    dataCampaign,
    goToErrorPage,
    goToNotFoundPage,
  ]);

  useEffect(() => {
    if (errorItems === undefined) {
      if (!loadingItems && dataItems !== undefined) {
        setAllItems(
          mapItemDBsToAuctionItems(
            dataItems.getAllItemsForCampaign as ItemTrend[]
          )
        );
      }
    } else {
      goToErrorPage();
    }
  }, [loadingItems, errorItems, dataItems, goToErrorPage]);

  useEffect(() => {
    let filteredItems = allItems.filter((item) =>
      item.itemName.toLowerCase().includes(globalFilter.toLowerCase())
    );

    filteredItems =
      appliedFilters.category[0] !== FILTER_CATEGORY_DEFAULT_OPTION
        ? filteredItems.filter((item) => {
            return appliedFilters.category.includes(item.itemCategory.name);
          })
        : filteredItems;

    filteredItems =
      appliedFilters.office[0] !== FILTER_OFFICE_DEFAULT_OPTION
        ? filteredItems.filter((item) => {
            return item.itemOffices
              .map((office) => office.name)
              .some((office) => appliedFilters.office.includes(office));
          })
        : filteredItems;
    setCurrentItems(filteredItems);
  }, [appliedFilters, allItems, globalFilter]);

  const setActionItemFormData = useCallback(
    async (itemId: string): Promise<void> => {
      const item = findItemById(itemId, allItems);

      if (item !== undefined) {
        try {
          const imageFilesURL = item.itemImages.map((image, i) => {
            return { url: image, key: fileManagerClient.getIdFromURL(image) };
          });
          setActionItem({
            id: item.itemId,
            name: item.itemName,
            startPrice: item.itemStartingBid,
            description: item.itemDescription,
            category: [item.itemCategory],
            locations: item.itemOffices.map((loc) => loc.id),
            images: { localFiles: [], remoteFiles: imageFilesURL },
          });
        } catch (error) {
          goToErrorPage();
        }
      }
    },
    [allItems, goToErrorPage, setActionItem]
  );

  const onAdminAction = useCallback(
    (approved: boolean, itemId: string): void => {
      const item = findItemById(itemId, allItems);
      if (item !== undefined) {
        void reviewItemMutation({
          variables: {
            id: item.itemId,
            approved,
          },
        });
      }
    },
    [allItems, reviewItemMutation]
  );

  const onAddItem = useCallback((): void => {
    setIsForEdit(false);
    showItemDrawer();
  }, [showItemDrawer]);

  const onEditItem = useCallback(
    (itemId: string): void => {
      void setActionItemFormData(itemId).then(() => {
        setIsForEdit(true);
        showItemDrawer();
      });
    },
    [setActionItemFormData, showItemDrawer]
  );

  const onEditCampaign = useCallback((): void => {
    setIsForEdit(true);
    if (campaign !== undefined) {
      setEditCampaignFormData({
        name: campaign.name,
        goal: campaign.goal.toString(),
        raisedAmount: campaign.raisedAmount.toString(),
        locations: campaign.offices.map((office) => office.id),
        dates: [new Date(campaign.startDate), new Date(campaign.endDate)],
        image: {
          localFiles: [],
          remoteFiles: [
            {
              url: campaign.imageKey,
              key: fileManagerClient.getIdFromURL(campaign.imageKey),
            },
          ],
        },
        defaultImages: campaign.imageKey,
      });

      showEditCampaignForm();
    }
  }, [campaign, showEditCampaignForm]);

  const onDuplicateCampaign = useCallback((): void => {
    setIsForEdit(false);
    if (campaign !== undefined) {
      try {
        setDuplicateCampaignFormData({
          name: campaign.name,
          goal: campaign.goal.toString(),
          raisedAmount: campaign.raisedAmount.toString(),
          locations: campaign.offices.map((office) => office.id),
          dates: undefined,
          image: {
            localFiles: [],
            remoteFiles: [
              {
                url: campaign.imageKey,
                key: fileManagerClient.getIdFromURL(campaign.imageKey),
              },
            ],
          },
        });

        showDuplicateCampaignForm();
      } catch (e) {
        goToErrorPage();
      }
    }
  }, [campaign, goToErrorPage, showDuplicateCampaignForm]);

  const onDeleteItem = useCallback(
    (itemId: string): void => {
      void setActionItemFormData(itemId).then(() => {
        showDeleteModal();
      });
    },
    [setActionItemFormData, showDeleteModal]
  );

  const deleteModalAction = useCallback((): void => {
    if (actionItem !== undefined) {
      void deleteItemMutation({
        variables: {
          id: actionItem.id,
        },
      });
    }
  }, [actionItem, deleteItemMutation]);

  const isAdmin = useMemo(() => {
    return currentUser?.role.name === ROLES.ADMIN;
  }, [currentUser]);

  const editCampaignAction = (onEdit: () => void): ButtonAction => {
    return {
      id: ACTION_EDIT_ID,
      label: BUTTON_ACTION_EDIT_LABEL,
      onClick: () => {
        onEdit();
      },
    };
  };
  const duplicateCampaignAction = (onDuplicate: () => void): ButtonAction => {
    return {
      id: ACTION_DUPLICATE_ID,
      label: BUTTON_ACTION_DUPLICATE_LABEL,
      onClick: () => {
        onDuplicate();
      },
    };
  };

  const filterItems: (
    items: AuctionItemForTable[],
    keyword: string
  ) => AuctionItemForTable[] = (items, keyword) => {
    const filteredItems: AuctionItemForTable[] = items.filter((item) => {
      const lowerKeyword = keyword.toLocaleLowerCase();
      return (
        item.itemName.toLocaleLowerCase().includes(lowerKeyword) ||
        item.itemDescription.toLocaleLowerCase().includes(lowerKeyword)
      );
    });

    return filteredItems;
  };

  return campaign !== undefined && !loadingCampaign && !loadingItems ? (
    <div className={styles['campaign-details']}>
      <PageHeader campaignName={campaign.name} />
      <div className={styles['campaign-details__banner-border']}>
        <div className={styles['campaign-details__banner-container']}>
          {isAdmin && (
            <div className={styles['campaign-details__actions-menu-container']}>
              <ActionsMenu
                className={styles['campaign-details__actions-menu']}
                small
                actions={[
                  editCampaignAction(onEditCampaign),
                  duplicateCampaignAction(onDuplicateCampaign),
                ]}
              />
            </div>
          )}
          <Banner
            name={campaign.name}
            locations={campaign.offices.map((office) => office.name)}
            raisedAmount={campaign.raisedAmount}
            goal={campaign.goal}
            endDatetime={campaign.endDate}
            imageURL={campaign.imageKey}
            lastUpdate={campaign.updatedAt ?? campaign.createdAt}
          />
        </div>
      </div>
      <PageContainer
        spacingTop={PAGE_CONTAINER_MARGIN}
        spacingBottom={PAGE_CONTAINER_MARGIN}>
        <div className={styles['campaign-details__tabs-container']}>
          <Tab
            tabs={[
              {
                id: TAB_ITEMS_ID,
                label: TAB_ITEMS_LABEL,
                content: (
                  <>
                    <FilterBar
                      className={styles['campaign-details__filter-bar']}
                      searchboxClassNames={{
                        searchBox:
                          styles['campaign-details__filter-bar__searchbox'],
                        container:
                          styles[
                            'campaign-details__filter-bar__searchbox-container'
                          ],
                      }}
                      filters={filters}
                      searchBarPlaceholder={SEARCH_BAR_PLACEHOLDER}
                      onSearch={handleSearch}
                      smallerIcon
                    />
                    <AuctionItems
                      currentItems={currentItems}
                      isActive={campaign.isActive}
                      onAddItem={onAddItem}
                      isListEmpty={allItems.length === 0}
                    />
                  </>
                ),
                onTabSelected: () => {
                  handleTabChange(TAB_ITEMS_ID);
                },
              },
              {
                id: TAB_MANAGE_ID,
                label: TAB_MANAGE_LABEL,
                content: (
                  <>
                    {!isDesktop && campaign.isActive && (
                      <FilterBar
                        filters={filters}
                        className={styles['campaign-details__actions--mobile']}
                        onSearch={onSearch}
                      />
                    )}
                    <ManageItems
                      pendingItems={getPendingItems(currentItems)}
                      reviewedItems={getAllItems(
                        isAdmin
                          ? currentItems
                          : currentItems.filter(
                              (item) => item.itemOwner.id === currentUser?.id
                            )
                      )}
                      isAdmin={isAdmin}
                      onAdminAction={onAdminAction}
                      isActive={campaign.isActive}
                      onEdit={onEditItem}
                      onDelete={onDeleteItem}
                      onAddItem={onAddItem}
                      isListEmpty={allItems.length === 0}
                      filterFn={filterItems}
                      filterKeyword={globalFilter}
                    />
                  </>
                ),
                onTabSelected: () => {
                  handleTabChange(TAB_MANAGE_ID);
                },
              },
            ]}
          />
          <div className={styles['campaign-details__actions']}>
            {!isDesktop && campaign.isActive && (
              <ButtonAddItem onAddItem={onAddItem} />
            )}
            {isDesktop && activeTab !== TAB_ITEMS_ID && (
              <FilterBar
                filters={filters}
                hideSearchBar
              />
            )}
            {isDesktop && campaign.isActive && (
              <ButtonAddItem onAddItem={onAddItem} />
            )}
          </div>
        </div>
      </PageContainer>
      {/* POP UPS */}
      <CampaignForm
        title={EDIT_CAMPAIGN_LABEL}
        cancelButtonText={CANCEL_LABEL}
        acceptButtonText={UPDATE_LABEL}
        hide={hideCampaignForm}
        isOpen={isEditFormOpen}
        edit={isForEdit}
        defaultValues={editCampaignFormData}
        campaign={campaign}
      />
      <CampaignForm
        title={CREATE_CAMPAIGN_TITLE}
        cancelButtonText={CANCEL_LABEL}
        acceptButtonText={CREATE_CAMPAIGN_BUTTON_LABEL}
        hide={hideDuplicateCampaignForm}
        isOpen={isDuplicateFormOpen}
        edit={isForEdit}
        defaultValues={duplicateCampaignFormData}
        campaign={campaign}
      />
      <Modal
        title={DELETE_ITEM_LABEL}
        ariaLabelledBy={MODAL_LABELLED_BY}
        ariaDescribedBy={MODAL_DESCRIBED_BY}
        cancelButtonText={CANCEL_LABEL}
        acceptButtonText={DELETE_ITEM_LABEL}
        isOpen={isDeleteModalOpen}
        hide={hideDeleteModal}
        action={deleteModalAction}>
        <Text
          variant={TYPEKIT.P2}
          className={styles['campaign-details__message']}>
          {DELETE_DESCRIPTION_CONFIRM}
          <span className={styles['campaign-details__message--highlight']}>
            {' '}
            {actionItem?.name}
          </span>
          {QUESTION_MARK}
          <br />
          <br />
          {DELETE_DESCRIPTION_CONDITIONS}
        </Text>
      </Modal>
      <AuctionItemDrawer
        isOpen={isItemDrawerOpen}
        hide={hideItemDrawer}
        title={isForEdit ? EDIT_ITEM_LABEL : ADD_ITEM_LABEL}
        cancelButtonText={CANCEL_LABEL}
        acceptButtonText={isForEdit ? UPDATE_LABEL : ADD_LABEL}
        edit={isForEdit}
        availableOffices={campaign.offices}
        defaultValues={isForEdit ? actionItem : undefined}
      />
    </div>
  ) : (
    <ContentLoader />
  );
};

export default CampaignDetailsPage;
