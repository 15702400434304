import { type Filter } from 'components/molecules/FilterBar/FilterBar.types';
import {
  FILTER_OFFICE_ID,
  FILTER_OFFICE_LABEL,
  FILTER_OFFICE_RESET_OPTION,
} from './CampaignLandingPage.constants';
import { FILTER_RESET_OPTION_ID } from 'pages/SettingsPage/SettingsPage.constants';
import { type DropdownOption } from 'components/molecules/Dropdown/Dropdown.types';
import { type CampaignFilters } from './CampaignLandingPage.types';
import { createCustomFilterHandler } from 'utils/utilities';

/**
 * Creates filters for CampaignLandinPage with setAppliedFilters to change state in page
 * @param setAppliedFilters Function to set applied filters state
 * @returns An array of Filter objects
 */
export const getCampaignFilters = (
  setAppliedFilters: React.Dispatch<React.SetStateAction<CampaignFilters>>,
  defaultOption: DropdownOption
): Filter[] => {
  return [
    {
      id: FILTER_OFFICE_ID,
      label: FILTER_OFFICE_LABEL,
      options: [],
      multiple: true,
      resetOption: {
        id: FILTER_RESET_OPTION_ID,
        name: FILTER_OFFICE_RESET_OPTION,
      },
      defaultOption,
      masterCheckboxOption: FILTER_OFFICE_RESET_OPTION,
      onChange: createCustomFilterHandler('office', setAppliedFilters),
    },
  ];
};
