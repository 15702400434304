import { type FC } from 'react';
import { type CardProps } from './Card.types';
import styles from './card.module.scss';
import cx from 'classnames';

/**
 * A card component used for displaying content.
 *
 * @component
 * @param {ReactNode} children - The content to be displayed inside the card.
 * @param {string} className - Additional CSS classes to apply to the card.
 * @param {boolean} pressed - Whether the card should appear as pressed (change background color to grey).
 * @param {CardType} type - The type or style of the card ('CardType.CAMPAIGN', 'CardType.AUCTION_ITEM', 'CardType.AUCTION_ITEM_DETAILS').
 * @param {string|null} redirectTo - The URL to redirect to when the card is clicked.
 */
const Card: FC<CardProps> = ({ children, className, type, redirectTo }) => {
  const cardClasses = cx(className, styles.card, {
    [styles['card--pressed']]: redirectTo,
    [styles[`card-${type}`]]: type,
  });

  return (
    <>
      {redirectTo === undefined || redirectTo === null ? (
        <section className={cardClasses}>{children}</section>
      ) : (
        <a
          href={redirectTo}
          className={styles.card__link}>
          <section className={cardClasses}>{children}</section>
        </a>
      )}
    </>
  );
};

export default Card;
