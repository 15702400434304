import React from 'react';
import Text from 'components/atoms/Text';
import { type DisplayTimeProps } from './DisplayTime.types';
import styles from './displaytime.module.scss';
import cx from 'classnames';
import { TYPEKIT } from 'utils/constants';

const DisplayTime: React.FC<DisplayTimeProps> = ({
  value,
  type,
  descriptive,
  spaced,
  variant,
}) => {
  return (
    <div
      className={cx(styles.displaytime, {
        [styles['displaytime--spaced']]: spaced,
      })}>
      <div
        className={cx(styles.displaytime__number, {
          [styles['displaytime__number--spaced']]: spaced,
        })}>
        <Text
          as="p"
          variant={variant}>
          {value}
        </Text>
        {descriptive && (
          <Text
            as="span"
            variant={variant === TYPEKIT.D2 ? TYPEKIT.P4 : TYPEKIT.P3}
            className={styles.displaytime__type}>
            {type}
          </Text>
        )}
      </div>
    </div>
  );
};

export default DisplayTime;
