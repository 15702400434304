import React from 'react';
import styles from './errorpage.module.scss';
import ErrorPageInfo from 'components/organisms/ErrorPageInfo';
import { type ErrorPageProps } from './ErrorPage.types';
import {
  BUTTON_DEFAFULT_TITLE,
  BUTTON_DEFAULT_LINK,
  errorTypeMap,
} from './ErrorPage.constants';

/**
 * A generic error page that can be displayed to indicate the user an unexpected behaviour when navigating the web page
 *
 * @page
 * @example
 * <ErrorPage type='not-found'/>
 *
 * @example
 * <ErrorPage type='server-error' buttonTitle='Go back' redirectTo='/landingPage'/>
 *
 *
 * @param {"not-found" | "server-error" | "unauthorized"} type - Type of error page to render. It can be "not-found", "server-error" or "unauthorized"
 * @param {string} buttonTitle -Button title to show, default should be displayed if not provided
 * @param {string} redirectTo -URL to redirect when button clicked, if not provided, it would redirect to home page
 * @returns
 */

const ErrorPage: React.FC<ErrorPageProps> = ({
  type,
  buttonTitle = BUTTON_DEFAFULT_TITLE,
  redirectTo = BUTTON_DEFAULT_LINK,
}) => {
  const { icon, title, description, iconSize, descriptionSize } =
    errorTypeMap[type];

  return (
    <div className={styles['page-container']}>
      <ErrorPageInfo
        icon={icon}
        title={title}
        description={description}
        buttonTitle={buttonTitle}
        iconSize={iconSize}
        descriptionSize={descriptionSize}
        redirectTo={redirectTo}></ErrorPageInfo>
    </div>
  );
};

export default ErrorPage;
