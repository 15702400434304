import { useContext, type ElementType, type ReactElement } from 'react';
import { type TextProps } from './Text.types';
import styles from './text.module.scss';
import cx from 'classnames';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';
import { DOLLAR_SYMBOL } from 'utils/constants';
import { formatCurrencyInText } from 'utils/utilities';

const Text = <C extends ElementType = 'p'>({
  as,
  children,
  className,
  variant,
  displayCurrency = false,
  useKFormat = false,
  ...restProps
}: TextProps<C>): ReactElement => {
  const Tag = as ?? 'p';

  const { selectedCurrency, conversionRate } = useContext(CurrencyContext);

  const formattedChildren =
    Boolean(displayCurrency) &&
    typeof children === 'string' &&
    children.includes(DOLLAR_SYMBOL)
      ? formatCurrencyInText(
          children,
          selectedCurrency,
          conversionRate,
          useKFormat
        )
      : children;

  return (
    <Tag
      className={cx(className, styles[variant])}
      {...restProps}>
      {formattedChildren}
    </Tag>
  );
};

export default Text;
