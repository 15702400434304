import { gql } from '@apollo/client';

export const GET_ALL_CURRENCIES = gql`
  query {
    getAllCurrencies {
      id
      code
      name
      symbol
    }
  }
`;

export const GET_RATES = gql`
  query {
    getRates {
      rate
      currency {
        code
      }
    }
  }
`;
