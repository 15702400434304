import React, { createContext, useEffect, useCallback, useMemo } from 'react';
import {
  type CurrencyContextProps,
  type CurrencyContextType,
} from './CurrencyContext.types';
import { type Currency } from 'graphql/generated-types/graphql';
import useLocalStorage from 'hooks/useLocalStorage';
import { GET_RATES } from 'graphql/currency/queries';
import { type OperationVariables, useMutation, useQuery } from '@apollo/client';
import {
  CONVERSION_RATES_KEY,
  CURRENCY_DEFAULT_CODE,
  CURRENCY_DEFAULT_NAME,
  CURRENCY_DEFAULT_SYMBOL,
  DEFAULT_CONVERSION_RATE,
  NOON_HOUR,
  RATES_REQUEST_ERROR,
  RATES_UPDATE_ERROR,
  SELECTED_CURRENCY_KEY,
  START_OF_HOUR,
} from './CurrencyContext.constants';
import { UPDATE_RATES } from 'graphql/currency/mutations';
import { v4 as uuidv4 } from 'uuid';
import useToastError from 'hooks/useToastError';

const getExpirationDate = (): number => {
  const now = new Date();
  const nextNoonUTC = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      NOON_HOUR,
      START_OF_HOUR,
      START_OF_HOUR
    )
  );

  if (now.getTime() >= nextNoonUTC.getTime()) {
    nextNoonUTC.setUTCDate(nextNoonUTC.getUTCDate() + 1);
  }

  return nextNoonUTC.getTime();
};

const defaultCurrency: Currency = {
  id: uuidv4(),
  code: CURRENCY_DEFAULT_CODE,
  name: CURRENCY_DEFAULT_NAME,
  symbol: CURRENCY_DEFAULT_SYMBOL,
};

export const CurrencyContext = createContext<CurrencyContextType>({
  selectedCurrency: defaultCurrency,
  conversionRate: DEFAULT_CONVERSION_RATE,
  setSelectedCurrency: () => {},
});

export const CurrencyProvider: React.FC<CurrencyContextProps> = ({
  children,
}) => {
  const showToastError = useToastError();

  const [selectedCurrency, setSelectedCurrencyState] =
    useLocalStorage<Currency>(SELECTED_CURRENCY_KEY, defaultCurrency);

  const setSelectedCurrency = useCallback(
    (currency: Currency) => {
      setSelectedCurrencyState(currency);
    },
    [setSelectedCurrencyState]
  );

  const [storedConversionRates, setStoredConversionRates] = useLocalStorage<{
    [key: string]: number;
    expires: number;
  } | null>(CONVERSION_RATES_KEY, null);

  const { loading: loadingRates, refetch } = useQuery(GET_RATES, {
    skip: storedConversionRates !== null,
    onCompleted: (dataRates) => {
      const conversionRates = dataRates.getRates;

      const handleCompletion = async (): Promise<void> => {
        if (conversionRates.length === 0) {
          try {
            await updateRates();
            const response = await getRatesRefetch();
            if (response !== null) {
              storeConversionRates(convertToRateObject(response.data.getRates));
            }
          } catch (error) {
            showToastError(RATES_UPDATE_ERROR);
          }
        } else {
          storeConversionRates(convertToRateObject(conversionRates));
        }
      };

      void handleCompletion();
    },
    onError: () => {
      showToastError(RATES_REQUEST_ERROR);
    },
  });

  const [updateRates, { loading: updatingRates }] = useMutation(UPDATE_RATES);

  const getRatesRefetch = useCallback(
    async (variables?: Partial<OperationVariables>) => {
      return await refetch(variables);
    },
    [refetch]
  );

  const storeConversionRates = useCallback(
    (conversionRates: Record<string, number>) => {
      setStoredConversionRates({
        ...conversionRates,
        expires: getExpirationDate(),
      });
    },
    [setStoredConversionRates]
  );

  const convertToRateObject = (
    ratesArray: Array<{ currency: { code: string }; rate: number }>
  ): Record<string, number> => {
    return ratesArray.reduce<Record<string, number>>(
      (acc, { currency, rate }) => {
        acc[currency.code] = rate;
        return acc;
      },
      {}
    );
  };

  const currencyContextValue = useMemo(
    () => ({
      selectedCurrency,
      setSelectedCurrency,
      conversionRate:
        storedConversionRates?.[selectedCurrency?.code] ??
        DEFAULT_CONVERSION_RATE,
    }),
    [selectedCurrency, setSelectedCurrency, storedConversionRates]
  );

  useEffect(() => {
    if (storedConversionRates !== null) {
      const delayUntilNextCheck = storedConversionRates.expires - Date.now();
      const updateTimeout = setTimeout(() => {
        if (!loadingRates && !updatingRates) {
          void (async () => {
            try {
              await updateRates();
              const response = await getRatesRefetch();
              if (response !== undefined) {
                storeConversionRates(
                  convertToRateObject(response.data.getRates)
                );
              }
            } catch (error) {
              showToastError(RATES_UPDATE_ERROR);
            }
          })();
        }
      }, delayUntilNextCheck);
      return () => {
        clearTimeout(updateTimeout);
      };
    }
  }, [
    storedConversionRates,
    loadingRates,
    updatingRates,
    getRatesRefetch,
    storeConversionRates,
    updateRates,
    showToastError,
  ]);

  useEffect(() => {
    if (
      selectedCurrency === null ||
      typeof selectedCurrency.code !== 'string'
    ) {
      setSelectedCurrencyState(defaultCurrency);
    }
  }, [selectedCurrency, setSelectedCurrencyState]);

  return (
    <CurrencyContext.Provider value={currencyContextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
