import React, { useState } from 'react';
import { type ActionsMenuProps, type ButtonAction } from './ActionsMenu.types';
import Icon from 'components/atoms/Icon';
import ellipsisIcon from 'assets/svgs/ellipsis-icon.svg';
import ButtonLink from 'components/molecules/ButtonLink';
import cx from 'classnames';
import styles from './actionsMenu.module.scss';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { MENU_ICON_ARIA_LABEL } from './ActionsMenu.contants';

const ActionsMenu: React.FC<ActionsMenuProps> = ({
  actions,
  className,
  small,
}) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const menuRef = useOutsideClick(() => {
    setDisplayOptions(false);
  });

  const onClick = (): void => {
    setDisplayOptions((prevState) => {
      return !prevState;
    });
  };

  return (
    <div
      className={cx(className, styles['actions-menu'])}
      ref={menuRef}>
      <ButtonLink
        className={cx(styles['actions-menu__icon-container'], {
          [styles['actions-menu__icon-container--small']]: small,
        })}
        ariaLabel={MENU_ICON_ARIA_LABEL}
        onClick={onClick}>
        <Icon
          src={ellipsisIcon}
          color="interactive-black"
          variant="major"
          className={cx(styles['actions-menu__icon'], {
            [styles['actions-menu__icon--small']]: small,
          })}
        />
      </ButtonLink>
      <div
        className={cx(
          styles['actions-menu__actions'],
          {
            [styles['actions-menu__actions--hide']]: !displayOptions,
          },
          {
            [styles['actions-menu__actions--small']]: small,
          }
        )}>
        {actions.map((action: ButtonAction) => {
          return (
            <ButtonLink
              key={action.id}
              as="button"
              className={styles['actions-menu__action']}
              onClick={action.onClick}>
              {action.label}
            </ButtonLink>
          );
        })}
      </div>
    </div>
  );
};

export default ActionsMenu;
