import React, { useCallback, useState } from 'react';
import { type DrawerFormProps } from './DrawerForm.types';
import {
  ACCEPT_BUTTON_VARIANT,
  ARIA_MODAL,
  BUTTONS_SIZE_VARIANT,
  BUTTON_AS,
  DEFAULT_EXIT_ICON_ARIA_LABEL,
  DISMISS_BUTTON_VARIANT,
  FORM_ID,
  MODAL_ACCEPT_TEXT,
  MODAL_CANCEL_TEXT,
  MODAL_DESCRIBED_BY,
  MODAL_DESCRIPTION,
  MODAL_LABELLED_BY,
  DRAWER_ROLE,
  TITLE_ID,
  MODAL_TITLE,
  SUBMIT_TYPE,
  TEXT_AS,
} from './DrawerForm.constants';
import Modal from '../Modal';
import Drawer from 'components/atoms/Drawer';
import Text from 'components/atoms/Text';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Icon from 'components/atoms/Icon';
import ButtonLink from 'components/molecules/ButtonLink';
import { TYPEKIT } from 'utils/constants';
import cx from 'classnames';
import styles from './drawerForm.module.scss';

/**
 * DrawerForm is a React component that renders a slide-out form within a drawer.
 * This form is wrapped within a modal overlay. When the user tries to close the form,
 * a confirmation modal prompts the user to either stay on the form or leave.
 *
 * @param {boolean} isOpen - Indicates whether the drawer form is open or not.
 * @param {function} hide - A callback function to hide/close the drawer form.
 * @param {string} title - The title text displayed at the top of the drawer form.
 * @param {function} onSubmit - A callback function that handles the form submission, passed FormData as its parameter.
 * @param {string} [cancelButtonText] - Text for the cancel/dismiss button.
 * @param {string} [props.acceptButtonText] - Text for the accept/submit button.
 * @param {React.ReactNode} children - Content (form fields) to be rendered inside the drawer form.
 *
 * @returns {React.FC} The rendered DrawerForm component.
 *
 * @example
 * // Example usage:
 * import DrawerForm from './DrawerForm';
 *
 * function MyComponent() {
 *   const [isDrawerFormOpen, setDrawerFormOpen] = React.useState(false);
 *   const closeDrawer = () => setDrawerFormOpen(false);
 *   const handleFormSubmit = (formData) => {
 *     // Handle form submission here
 *   };
 *
 *   return (
 *     <>
 *       <button onClick={() => setDrawerFormOpen(true)}>Open Form in Drawer</button>
 *       <DrawerForm
 *         isOpen={isDrawerFormOpen}
 *         hide={closeDrawer}
 *         title="My Form Title"
 *         onSubmit={handleFormSubmit}
 *       >
 *         <input type="text" name="example" />
 *       </DrawerForm>
 *     </>
 *   );
 * }
 */

const DrawerForm: React.FC<DrawerFormProps<any>> = ({
  isOpen,
  hide,
  title,
  onSubmit,
  cancelButtonText,
  acceptButtonText,
  children,
  submitting,
  disabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hideModal = useCallback((): void => {
    setIsModalOpen(true);
  }, []);

  const stayAction = (): void => {
    setIsModalOpen(false);
  };

  const leaveAction = (): void => {
    setIsModalOpen(false);
    hide();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    onSubmit(formData);
  };

  return (
    <>
      <Modal
        title={MODAL_TITLE}
        ariaLabelledBy={MODAL_LABELLED_BY}
        ariaDescribedBy={MODAL_DESCRIBED_BY}
        cancelButtonText={MODAL_CANCEL_TEXT}
        acceptButtonText={MODAL_ACCEPT_TEXT}
        isOpen={isModalOpen}
        hide={leaveAction}
        action={stayAction}>
        {MODAL_DESCRIPTION}
      </Modal>
      <Drawer
        isOpen={isOpen}
        hide={hideModal}
        className={styles.drawer__overlay}>
        <div
          className={cx(styles.drawer)}
          role={DRAWER_ROLE}
          aria-labelledby={TITLE_ID}
          aria-modal={ARIA_MODAL}>
          <div className={styles.drawer__container}>
            <div className={styles.drawer__header}>
              <Text
                as={TEXT_AS}
                variant={TYPEKIT.D3}
                id={TITLE_ID}>
                {title}
              </Text>
            </div>
          </div>
          <form
            id={FORM_ID}
            onSubmit={handleSubmit}
            className={styles.drawer__form}>
            {children}
          </form>
          <div
            className={cx(
              styles.drawer__container,
              styles['drawer__container--wider'],
              styles.drawer__buttons
            )}>
            <ButtonLink
              as={BUTTON_AS}
              form={FORM_ID}
              type={SUBMIT_TYPE}
              size={BUTTONS_SIZE_VARIANT}
              className={styles['auto-width']}
              disabled={disabled}
              isLoading={submitting}
              variant={ACCEPT_BUTTON_VARIANT}>
              {acceptButtonText}
            </ButtonLink>
            <ButtonLink
              as={BUTTON_AS}
              size={BUTTONS_SIZE_VARIANT}
              className={styles['auto-width']}
              variant={DISMISS_BUTTON_VARIANT}
              disabled={submitting}
              ariaLabel={cancelButtonText}
              onClick={hideModal}>
              {cancelButtonText}
            </ButtonLink>
          </div>
          <Icon
            ariaLabel={DEFAULT_EXIT_ICON_ARIA_LABEL}
            onClick={hideModal}
            className={cx(styles['drawer__exit-icon'])}
            src={faXmark}
          />
        </div>
      </Drawer>
    </>
  );
};

export default DrawerForm;
