export const DEFAULT_EXIT_ICON_ARIA_LABEL = 'Close modal';
export const ESCAPE_KEY = 'Escape';

export const TITLE_TEXT_TYPE = 'h1';
export const DESCRIPTION_TEXT_TYPE = 'span';

export const BUTTONS_SIZE_VARIANT = 'large';

export const BUTTON_AS = 'button';
export const ACCEPT_BUTTON_VARIANT = 'primary';

export const DISMISS_BUTTON_AS_DESKTOP = 'Link';
export const DISMISS_BUTTON_ROLE_DESKTOP = 'button';
export const DISMISS_BUTTON_VARIANT = 'secondary';

export const MOUSEDOWN_EVENT_LISTENER = 'mousedown';
export const KEYDOWN_EVENT_LISTENER = 'keydown';
