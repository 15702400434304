import React, { useState } from 'react';
import { type CroppedTextProps } from './CroppedText.types';
import {
  CROPPED_TEXT_BUTTON_LESS,
  CROPPED_TEXT_BUTTON_MORE,
} from './CroppedText.constants';
import { TYPEKIT } from 'utils/constants';
import styles from './croppedText.module.scss';
import Text from 'components/atoms/Text';
import cx from 'classnames';

const CroppedText: React.FC<CroppedTextProps> = ({ id, children }) => {
  const [displayText, setDisplayText] = useState(false);

  const onClick = (): void => {
    setDisplayText((prevState) => {
      return !prevState;
    });
  };

  return (
    <div className={styles['cropped-text']}>
      <p
        className={cx(styles['cropped-text__text'], {
          [styles['cropped-text__text--hidden']]: displayText,
        })}>
        {children}
      </p>
      <Text
        variant={TYPEKIT.P2}
        className={cx({
          [styles['cropped-text__text--hidden']]: !displayText,
        })}>
        {children}
      </Text>
      <Text
        as="a"
        variant={TYPEKIT.P2}
        onClick={onClick}
        className={styles['cropped-text__button']}>
        {displayText ? CROPPED_TEXT_BUTTON_LESS : CROPPED_TEXT_BUTTON_MORE}
      </Text>
    </div>
  );
};

export default CroppedText;
