import { useEffect, useRef } from 'react';

export const useOutsideClick = (
  handleClick: (target: Node) => void
): React.Ref<any> => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current !== null && !ref.current.contains(event.target as Node)) {
        handleClick(event.target as Node);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClick]);

  return ref;
};
