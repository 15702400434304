import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { type SettingsPageProps } from './SettingsPage.types';
import UserTable from 'components/organisms/UserTable';
import {
  ADMIN_ROLES_TITLE,
  CATEGORIES_TITLE,
  DEFAULT_DESC_SORTING,
  DELETE_CATEGORY_MODAL_ACCEPT,
  DELETE_CATEGORY_MODAL_BODY,
  DELETE_CATEGORY_MODAL_CANCEL,
  DELETE_CATEGORY_MODAL_CONFIRMATION,
  DELETE_CATEGORY_MODAL_DESCRIPTION,
  DELETE_CATEGORY_MODAL_ERROR_ACCEPT,
  DELETE_CATEGORY_MODAL_ERROR_BODY,
  DELETE_CATEGORY_MODAL_ERROR_DESCRIPTION,
  DELETE_CATEGORY_MODAL_ERROR_LABEL,
  DELETE_CATEGORY_MODAL_ERROR_TITLE,
  DELETE_CATEGORY_MODAL_LABEL,
  DELETE_CATEGORY_MODAL_TITLE,
  FILTER_DEFAULT_VALUE,
  FILTER_OFFICE_LABEL,
  FILTER_OFFICE_TEXT,
  FILTER_RESET_OPTION_ID,
  FILTER_ROLE_LABEL,
  FILTER_ROLE_TEXT,
  SETTINGS_SEARCHBAR_PLACEHOLDER,
  SORT_ID,
  SORT_LABEL,
  SORT_LASTNAME_ID,
  SORT_OPTIONS,
  TOAST_ASSIGN_OFFICE,
  TOAST_ASSIGN_ROLE,
  TOAST_ASSIGN_SUCCESS,
  TOAST_CREATE_CATEGORY,
  TOAST_CREATE_SUCCESS,
  TOAST_DELETE_SUCCESS,
} from './SettingsPage.constants';
import { ROLES, TYPEKIT, UNAUTHORIZED_ERROR_PAGE_ROUTE } from 'utils/constants';
import Text from 'components/atoms/Text';
import FilterBar from 'components/molecules/FilterBar';
import { type Filter } from 'components/molecules/FilterBar/FilterBar.types';
import PageContainer from 'components/atoms/PageContainer';
import PageHeader from 'components/molecules/PageHeader';
import styles from './settingsPage.module.scss';
import useBreakpoint from 'hooks/useBreakpoint';
import {
  NAME_HEADER,
  OFFICE_HEADER,
  ROLE_HEADER,
} from 'components/organisms/UserTable/UserTable.constants';
import {
  type SortingState,
  type ColumnFiltersState,
} from '@tanstack/react-table';
import { type DropdownOption } from 'components/molecules/Dropdown/Dropdown.types';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_ROLES, GET_ALL_USERS } from 'graphql/user/queries.gql';
import { type Office, type Role, type User } from 'utils/dataTypes';
import { GET_ALL_OFFICES } from 'graphql/office/queries.gql';
import getUserColumns from 'components/organisms/UserTable/UserTable.columns';
import { CHANGE_OFFICE, CHANGE_ROLE } from 'graphql/user/mutations.gql';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'context/Authentication/AuthContext';
import { formatToFirstUpperCase, showToast } from 'utils/utilities';
import { useNavigate } from 'react-router-dom';
import CategoryGrid from 'components/organisms/CategoryGrid';
import {
  type DeleteCategoryResponse,
  type Category,
} from 'graphql/generated-types/graphql';
import { GET_ALL_CATEGORIES } from 'graphql/category/queries.gql';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
} from 'graphql/category/mutations.gql';
import Modal from 'components/organisms/Modal';
import ContentLoader from 'components/atoms/ContentLoader';

const SettingsPage: React.FC<SettingsPageProps> = ({ users }) => {
  const toast = useToast();
  const [listUsers, setListUsers] = useState<User[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [deletedCategory, setDeletedCategory] = useState<Category>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [showDeleteError, setShowDeleteError] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const { loading: loadingUsers } = useQuery(GET_ALL_USERS, {
    onCompleted: (data) => {
      setListUsers(data.getAllUsers);
    },
    onError: (error) => {
      showToast(error.message, toast, TOAST_TYPE.ERROR);
    },
  });
  const { loading: loadingRoles } = useQuery(GET_ALL_ROLES, {
    onCompleted: (data) => {
      const roles: Role[] = data.getAllRoles;
      setRoles(
        roles.map((role) => {
          return { ...role, name: formatToFirstUpperCase(role.name) };
        })
      );
    },
    onError: (error) => {
      showToast(error.message, toast, TOAST_TYPE.ERROR);
    },
  });

  const { loading: loadingCategories } = useQuery(GET_ALL_CATEGORIES, {
    onCompleted: (data) => {
      const categories: Category[] = data.getAllCategories;
      setCategories(
        categories.map((category) => {
          return { ...category, name: formatToFirstUpperCase(category.name) };
        })
      );
    },
    onError: (error) => {
      showToast(error.message, toast, TOAST_TYPE.ERROR);
    },
  });

  const { loading: loadingOffices } = useQuery(GET_ALL_OFFICES, {
    onCompleted: (data) => {
      setOffices(data.getAllOffices);
    },
    onError: (error) => {
      showToast(error.message, toast, TOAST_TYPE.ERROR);
    },
  });

  const setUserData = useCallback((responseUser: User) => {
    setListUsers((prevUsers) => {
      return prevUsers.map((user) =>
        user.id === responseUser.id ? responseUser : user
      );
    });
  }, []);

  const setCategoryData = useCallback(
    (responseCategory: Category) => {
      setCategories([...categories, responseCategory]);
    },
    [categories]
  );

  const [changeOffice] = useMutation(CHANGE_OFFICE, {
    onCompleted: (data) => {
      const responseUser: User = data.changeOffice;
      const fullName: string = `${responseUser.firstName} ${responseUser.lastName}`;
      showToast(
        `${TOAST_ASSIGN_SUCCESS} ${responseUser.office.name} ${TOAST_ASSIGN_OFFICE} ${fullName}.`,
        toast,
        TOAST_TYPE.SUCCESS
      );
      setUserData(responseUser);
    },
    onError: (error) => {
      showToast(error.message, toast, TOAST_TYPE.ERROR);
    },
  });

  const [addCategory] = useMutation(CREATE_CATEGORY, {
    onCompleted: (data) => {
      const responseCategory: Category = data.createCategory;

      showToast(
        `${TOAST_CREATE_SUCCESS} ${responseCategory.name} ${TOAST_CREATE_CATEGORY}.`,
        toast,
        TOAST_TYPE.SUCCESS
      );
      setCategoryData(responseCategory);
    },
    onError: (error) => {
      showToast(error.message, toast, TOAST_TYPE.ERROR);
    },
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    onCompleted: (data) => {
      const response: DeleteCategoryResponse = data.deleteCategory;
      if (response.success) {
        showToast(`${TOAST_DELETE_SUCCESS}`, toast, TOAST_TYPE.SUCCESS);
        setCategories((prevCategories) => {
          return prevCategories.filter((category) => {
            return category.id !== deletedCategory?.id;
          });
        });
      }
    },
    onError: () => {
      setShowDeleteError(true);
    },
  });

  const [changeRole] = useMutation(CHANGE_ROLE, {
    onCompleted: (data) => {
      const responseUser: User = data.changeRole;
      const fullName: string = `${responseUser.firstName} ${responseUser.lastName}`;
      const roleName: string = responseUser.role.name.toLowerCase();
      showToast(
        `${TOAST_ASSIGN_SUCCESS} ${fullName} ${TOAST_ASSIGN_ROLE} ${roleName}.`,
        toast,
        TOAST_TYPE.SUCCESS
      );
      setUserData(responseUser);
    },
    onError: (error) => {
      showToast(error.message, toast, TOAST_TYPE.ERROR);
    },
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    {
      id: OFFICE_HEADER,
      value: FILTER_DEFAULT_VALUE,
    },
    {
      id: ROLE_HEADER,
      value: FILTER_DEFAULT_VALUE,
    },
  ]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [globalFilter, setGlobalFilter] = useState('');
  const { isDesktop } = useBreakpoint();

  const onChangeFilter = useCallback(
    (selectedOption: DropdownOption): void => {
      setColumnFilters((prevArray) =>
        prevArray.map((filter) =>
          filter.id === selectedOption.id
            ? { ...filter, value: selectedOption.name }
            : filter
        )
      );
    },
    [setColumnFilters]
  );

  const onChangeSort = useCallback((selectedOption: DropdownOption): void => {
    const name = selectedOption.name.replace(/\s/g, '');
    setSorting(
      selectedOption.id !== FILTER_RESET_OPTION_ID
        ? [
            {
              id: name === SORT_LASTNAME_ID ? NAME_HEADER : name,
              desc: DEFAULT_DESC_SORTING,
            },
          ]
        : []
    );
  }, []);

  const createFilter = useCallback(
    (
      filterType: string,
      id: string,
      label: string,
      options: DropdownOption[],
      resetName: string,
      onChangeFunction: (selectedOption: DropdownOption) => void,
      className?: string
    ): Filter => {
      return {
        id,
        label,
        options,
        resetOption: { id: FILTER_RESET_OPTION_ID, name: resetName },
        className,
        onChange: (selectedOption: DropdownOption[]) => {
          const value =
            selectedOption[0].id !== FILTER_RESET_OPTION_ID
              ? selectedOption[0].name
              : '';
          onChangeFunction({
            id: filterType === SORT_ID ? selectedOption[0].id : filterType,
            name: value,
          });
        },
      };
    },
    []
  );

  useEffect(() => {
    if (
      !loadingOffices &&
      !loadingRoles &&
      !loadingCategories &&
      offices.length > 0 &&
      roles.length > 0
    ) {
      setFilters([
        createFilter(
          SORT_ID,
          'sort-by',
          SORT_LABEL,
          SORT_OPTIONS,
          SORT_LABEL,
          onChangeSort,
          styles.filters
        ),
        createFilter(
          OFFICE_HEADER,
          'filter-by-office',
          FILTER_OFFICE_LABEL,
          offices,
          FILTER_OFFICE_TEXT,
          onChangeFilter,
          styles.filters
        ),
        createFilter(
          ROLE_HEADER,
          'filter-by-role',
          FILTER_ROLE_LABEL,
          roles,
          FILTER_ROLE_TEXT,
          onChangeFilter,
          styles['filters--roles']
        ),
      ]);
    }
  }, [
    createFilter,
    loadingCategories,
    loadingOffices,
    loadingRoles,
    offices,
    onChangeFilter,
    onChangeSort,
    roles,
  ]);

  const onSearch = useCallback((searchTerm: string): void => {
    const cleanSearchTerm = searchTerm.trim().replace(/\s\s+/g, ' ');
    setGlobalFilter(cleanSearchTerm);
  }, []);

  const spacing = useMemo<string>((): string => {
    return !isDesktop ? '1rem' : '2rem';
  }, [isDesktop]);

  const onChangeOffice = useCallback(
    async (officeId: string, userId: string): Promise<void> => {
      void changeOffice({ variables: { input: { officeId, userId } } });
    },
    [changeOffice]
  );

  const onAddCategory = useCallback(
    async (name: string, description: string): Promise<void> => {
      void addCategory({ variables: { categoryInput: { name, description } } });
    },
    [addCategory]
  );

  const onDeleteCategory = useCallback(
    async (categoryId: string): Promise<void> => {
      void deleteCategory({ variables: { categoryId } });
    },
    [deleteCategory]
  );

  const onChangeRole = useCallback(
    async (roleName: string, userEmail: string): Promise<void> => {
      void changeRole({
        variables: { input: { roleName: roleName.toUpperCase(), userEmail } },
      });
    },
    [changeRole]
  );

  const userColumns = useMemo(
    () =>
      getUserColumns(
        roles,
        offices,
        onChangeRole,
        onChangeOffice,
        currentUser?.id as string
      ),
    [roles, offices, onChangeRole, onChangeOffice, currentUser?.id]
  );

  if (currentUser?.role.name !== ROLES.ADMIN) {
    navigate(UNAUTHORIZED_ERROR_PAGE_ROUTE);
  }

  return (
    <>
      <ContentLoader
        isLoading={
          loadingUsers || loadingOffices || loadingRoles || loadingCategories
        }>
        <PageHeader />
        <PageContainer
          spacingTop={spacing}
          spacingBottom={spacing}>
          <div className={styles.settings}>
            <section className={styles['settings__admin-roles']}>
              <Text variant={TYPEKIT.D4}>{CATEGORIES_TITLE}</Text>
              <CategoryGrid
                categories={categories}
                deleteFunction={(category) => {
                  setDeletedCategory(category);
                  setShowDeleteConfirmation(true);
                }}
                addFunction={(name, description) => {
                  void onAddCategory(name, description);
                }}
              />
            </section>
            <section className={styles['settings__admin-roles']}>
              <Text variant={TYPEKIT.D4}>{ADMIN_ROLES_TITLE}</Text>
              <FilterBar
                filters={filters}
                onSearch={onSearch}
                searchBarPlaceholder={SETTINGS_SEARCHBAR_PLACEHOLDER}
                optionsClassName={styles['settings__filter-options']}
              />
              <div className={styles.settings__table}>
                <UserTable
                  className={styles['settings__user-table']}
                  columnFilters={columnFilters}
                  setColumnFilter={setColumnFilters}
                  data={listUsers}
                  columns={userColumns}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  setSorting={setSorting}
                  sorting={sorting}
                />
              </div>
            </section>
          </div>
        </PageContainer>
        <Modal
          isOpen={showDeleteConfirmation}
          acceptButtonText={DELETE_CATEGORY_MODAL_ACCEPT}
          cancelButtonText={DELETE_CATEGORY_MODAL_CANCEL}
          hide={() => {
            setShowDeleteConfirmation(false);
          }}
          action={() => {
            setShowDeleteConfirmation(false);
            if (deletedCategory !== undefined)
              void onDeleteCategory(deletedCategory?.id);
          }}
          ariaLabelledBy={DELETE_CATEGORY_MODAL_LABEL}
          ariaDescribedBy={DELETE_CATEGORY_MODAL_DESCRIPTION}
          title={DELETE_CATEGORY_MODAL_TITLE}>
          {`${DELETE_CATEGORY_MODAL_BODY} ${deletedCategory?.name}. ${DELETE_CATEGORY_MODAL_CONFIRMATION}`}
        </Modal>

        <Modal
          isOpen={showDeleteError}
          acceptButtonText={DELETE_CATEGORY_MODAL_ERROR_ACCEPT}
          hide={() => {
            setShowDeleteError(false);
          }}
          action={() => {
            setShowDeleteError(false);
          }}
          ariaLabelledBy={DELETE_CATEGORY_MODAL_ERROR_LABEL}
          ariaDescribedBy={DELETE_CATEGORY_MODAL_ERROR_DESCRIPTION}
          title={DELETE_CATEGORY_MODAL_ERROR_TITLE}>
          {DELETE_CATEGORY_MODAL_ERROR_BODY}
        </Modal>
      </ContentLoader>
    </>
  );
};

export default SettingsPage;
