import React, { useContext, useEffect, useMemo, useState } from 'react';
import { type BidFormMobileProps } from './BidFormMobile.types';
import { DOLLAR_SYMBOL, MASKTYPES, TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import Input from 'components/molecules/Input';
import ButtonLink from 'components/molecules/ButtonLink';
import {
  TOP_BID_TITLE,
  STARTING_BID_TITLE,
  BUTTON_TITLE,
  BID_INPUT_PLACEHOLDER,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  FORM_ID,
  SUBMIT_TYPE,
  BID_AMOUNT_ERROR_MESSAGE,
  INPUT_ID,
  INPUT_REGISTER_VALUE,
  BOTTOM_SHEET_TITLE,
  CLOSE_BUTTON_ARIA_LABEL,
  BID_INPUT_TITLE,
} from './BidFormMobile.constants';
import styles from './bidFormMobile.module.scss';
import BottomSheet from 'components/organisms/BottomSheet';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';
import { formatCurrencyInText } from 'utils/utilities';

const BidFormMobile: React.FC<BidFormMobileProps> = ({
  currentBid,
  minimumBiddingAmount,
  isFirstBid = true,
  currency,
  onSubmit,
  handleSubmit,
  register,
  watch,
  errors,
  reset,
  submitting,
}) => {
  const [openBottomSheetForm, setOpenBottomSheetForm] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const amountInput = watch(INPUT_REGISTER_VALUE);
  const { selectedCurrency, conversionRate } = useContext(CurrencyContext);

  const minimumAmountFormated = useMemo(() => {
    return `${DOLLAR_SYMBOL}${minimumBiddingAmount}`;
  }, [minimumBiddingAmount]);

  const currentBidFormated = useMemo(() => {
    return `${DOLLAR_SYMBOL}${currentBid}`;
  }, [currentBid]);

  const handleOpenClick = (): void => {
    setOpenBottomSheetForm(true);
  };

  const handleHideForm = (): void => {
    setOpenBottomSheetForm(false);
    reset({ amount: '' });
  };

  useEffect(() => {
    const sanitizedInput = amountInput.replace(/\D/g, '');
    const isInputValid =
      sanitizedInput.trim() !== '' &&
      parseFloat(sanitizedInput) >= minimumBiddingAmount * conversionRate;
    setIsButtonDisabled(isInputValid);
  }, [amountInput, conversionRate, minimumBiddingAmount]);

  return (
    <div className={styles['body-container']}>
      <div className={styles['info__top-bid']}>
        <Text
          as="span"
          variant={TYPEKIT.D5}
          className={styles['info__title-text']}>
          {isFirstBid ? STARTING_BID_TITLE : TOP_BID_TITLE}
        </Text>
        <Text
          displayCurrency
          as="span"
          variant={TYPEKIT.D2}>
          {currentBidFormated}
        </Text>
      </div>
      <ButtonLink
        className={[styles['form__button--large']]}
        as={BUTTON_TYPE}
        variant={BUTTON_VARIANT}
        onClick={handleOpenClick}
        textClassName={TYPEKIT.D5}>
        {BUTTON_TITLE}
      </ButtonLink>
      <BottomSheet
        isOpen={openBottomSheetForm}
        hide={handleHideForm}
        title={BOTTOM_SHEET_TITLE}
        closeAriaLabel={CLOSE_BUTTON_ARIA_LABEL}>
        <form
          id={FORM_ID}
          onSubmit={(event) => {
            const theReturnedFunction = handleSubmit(onSubmit);
            void theReturnedFunction(event);
          }}>
          <Text
            displayCurrency
            as="span"
            variant={TYPEKIT.P2}
            className={styles.form__title}>
            {BID_INPUT_TITLE + currency + minimumAmountFormated}
          </Text>
          <Input
            id={INPUT_ID}
            placeholder={
              currency +
              formatCurrencyInText(
                minimumAmountFormated,
                selectedCurrency,
                conversionRate
              ) +
              BID_INPUT_PLACEHOLDER
            }
            mask={MASKTYPES.CURRENCY}
            error={errors?.amount?.message}
            className={styles.form__input}
            {...register(INPUT_REGISTER_VALUE, {
              required: BID_AMOUNT_ERROR_MESSAGE,
            })}
          />
          <ButtonLink
            isLoading={submitting}
            className={styles.form__button}
            as={BUTTON_TYPE}
            variant={BUTTON_VARIANT}
            textClassName={TYPEKIT.D5}
            form={FORM_ID}
            type={SUBMIT_TYPE}
            disabled={!isButtonDisabled}>
            {BUTTON_TITLE}
          </ButtonLink>
        </form>
      </BottomSheet>
    </div>
  );
};

export default BidFormMobile;
