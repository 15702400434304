import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { useToast } from './useToast';
import { useCallback } from 'react';

const useToastError = (): ((message: string) => void) => {
  const toast = useToast();

  const showToastError = useCallback(
    (message: string): void => {
      if (toast !== undefined) toast.open(message, TOAST_TYPE.ERROR);
    },
    [toast]
  );
  return showToastError;
};

export default useToastError;
