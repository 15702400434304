import { gql } from '@apollo/client';

export const GET_BIDS_BY_ITEM_ID = gql`
  query getBidsByItemId($itemId: ID!) {
    getBidsByItemId(itemId: $itemId) {
      id
      amount
      updatedAt
      updatedBy
      isDeleted
      user {
        id
        firstName
        lastName
      }
      item {
        id
      }
      createdAt
    }
  }
`;

export const GET_TOP_BID = gql`
  query getTopBidByItemId($itemId: ID!) {
    getTopBidByItemId(itemId: $itemId) {
      id
      amount
      updatedAt
      updatedBy
      isDeleted
      user {
        id
        firstName
        lastName
        imageUrl
      }
      item {
        id
      }
      createdAt
    }
  }
`;
