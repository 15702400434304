import React from 'react';
import { type PageContainerProps } from './PageContainer.types';
import styles from './pageContainer.module.scss';
import { DEFAULT_SPACING } from './PageContainer.constants';
import cx from 'classnames';

const PageContainer: React.FC<PageContainerProps> = ({
  ariaLabel,
  children,
  spacingBottom = DEFAULT_SPACING,
  spacingTop = DEFAULT_SPACING,
  wide = false,
  className,
}) => {
  const containerStyle = {
    paddingTop: spacingTop,
    paddingBottom: spacingBottom,
  };

  return (
    <div className={styles['bg-color']}>
      <main
        aria-label={ariaLabel}
        className={cx(className, styles['page-container'], {
          [styles['page-container--wide']]: wide,
        })}
        style={containerStyle}>
        {children}
      </main>
    </div>
  );
};

export default PageContainer;
