import React, { useRef } from 'react';
import { type ErrorMessageProps } from './ErrorMessage.types';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import styles from './errorMessage.module.scss';
import { v4 as uuidv4 } from 'uuid';
import warningIcon from 'assets/svgs/warningNotification.svg';
import { TYPEKIT } from 'utils/constants';
import {
  HIDDEN_TRUE,
  ROLE_ALERT,
  TAB_INDEX_VALUE,
  TEXT_AS_P,
} from './ErrorMessage.constants';
import cx from 'classnames';

/**
 * ErrorMessage is a React component that renders an error message with a warning icon.
 *
 * @property {string} error - The error message to be displayed.
 * @property {string} [className] - Additional class names to be applied to the root element of the component.
 *
 * @returns {React.FC} The rendered ErrorMessage component.
 *
 * @example
 * // Example usage:
 * import ErrorMessage from './ErrorMessage';
 *
 * function MyComponent() {
 *   return (
 *     <ErrorMessage error="Invalid email address" />
 *   );
 * }
 */

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, className }) => {
  const errorMessageId = useRef(uuidv4());
  return (
    <div
      className={cx(styles['error-message'], className)}
      id={errorMessageId.current}
      role={ROLE_ALERT}>
      <Icon
        src={warningIcon}
        aria-hidden={HIDDEN_TRUE}
        tabIndex={TAB_INDEX_VALUE}
        className={styles['error-message__warning-icon']}
      />
      <Text
        as={TEXT_AS_P}
        variant={TYPEKIT.P3}>
        {error}
      </Text>
    </div>
  );
};

export default ErrorMessage;
