export const HERO_TITLE = 'KG Auction Hub';
export const ACTIVE_CAMPAIGNS_HEADER_TEXT = 'Active Campaigns';
export const PAGE_CONTAINER_LABEL = 'Campaign Landing Page';
export const PAST_CAMPAIGNS_HEADER_TEXT = 'Past Campaigns';
export const BUTTON_TEXT = 'Add Campaign';
export const NO_ACTIVE_CAMPAIGNS_TEXT = 'No Campaigns Yet';
export const NO_PAST_CAMPAIGNS_TEXT = 'No past campaigns in this location.';
export const REGULAR_SUBTEXT = 'There are no active campaigns at the moment.';
export const REGULAR_SUBTEXT_FILTER =
  'There are no active campaigns in this location.';
export const ADMIN_SUBTEXT =
  'Click on the button below to add a campaign and start fundraising!';
export const BUTTON_SMALL = 'small';
export const BUTTON_LARGE = 'large';
export const BUTTON_VARIANT = 'primary';
export const BUTTON_AS = 'button';
export const TOTAL_CARDS_PER_PAGE = 8;
export const CAMPAIGN_FORM_TITLE = 'Create a campaign';
export const CAMPAIGN_FORM_CANCEL_TEXT = 'Cancel';
export const CAMPAIGN_FORM_ACCEPT_TEXT = 'Create';
export const BUTTON_ICON_INDEX = -1;
export const ICON_HIDDEN_TRUE = 'true';
export const TEXT_AS_P = 'p';
export const ALL_OFFICES = 'All Offices';
export const OFFICE_REQUEST_FAILURE_ERROR =
  'An error occured while fetching offices';
export const CAMPAIGN_REQUEST_FAILURE_ERROR =
  'An error occured while fetching campaigns';
export const FILTER_OFFICE_RESET_OPTION = 'All Offices';
export const FILTER_OFFICE_LABEL = 'Offices';
export const FILTER_OFFICE_ID = '1';
export const FALLBACK_OFFICES_DROPDOWN_OPTION = {
  id: FILTER_OFFICE_ID,
  name: FILTER_OFFICE_RESET_OPTION,
};

/**
 * Predefined array of office options for dropdown selection.
 * This array is hardcoded to prevent potential issues related to data retrieval and ensure application stability.
 * @type {Array<{id: string, name: string}>}
 */
export const OFFICES_DROPDOWN_OPTIONS = [
  { id: '0d920d64-78f9-11ee-b962-0242ac120002', name: 'San Jose' },
  { id: '4dc53b3e-78fb-11ee-b962-0242ac120002', name: 'Toronto' },
  { id: '7e1ff8c0-78f9-11ee-b962-0242ac120002', name: 'Miami' },
  { id: 'e37422dc-78f9-11ee-b962-0242ac120002', name: 'Vancouver' },
  { id: 'a1d63e28-78f9-11ee-b962-0242ac120002', name: 'London' },
  { id: 'b3af5a26-78f9-11ee-b962-0242ac120002', name: 'New York' },
];
