export const TOP_BID_TITLE = 'Top bid';
export const STARTING_BID_TITLE = 'Starting bid';
export const BUTTON_TITLE = 'Place bid';
export const BID_INPUT_PLACEHOLDER = ' or more';
export const BUTTON_TYPE = 'button';
export const BUTTON_VARIANT = 'primary';
export const FORM_ID = 'bid_form';
export const SUBMIT_TYPE = 'submit';
export const BID_AMOUNT_ERROR_MESSAGE = 'Please enter a bidding amount';
export const INPUT_ID = 'placed_bid';
export const INPUT_REGISTER_VALUE = 'amount';
export const BOTTOM_SHEET_TITLE = 'Place a bid';
export const CLOSE_BUTTON_ARIA_LABEL = 'Close place a bid form';
export const BID_INPUT_TITLE = 'Minimum bidding amount ';
