import PageContainer from 'components/atoms/PageContainer';
import Hero from 'components/organisms/Hero';
import Text from 'components/atoms/Text';
import {
  TYPEKIT,
  GRIDTYPES,
  TITLE_TAG,
  SEARCH_BAR_PLACEHOLDER,
} from 'utils/constants';
import ButtonLink from 'components/molecules/ButtonLink';
import plusIcon from 'assets/svgs/plus.svg';
import Icon from 'components/atoms/Icon';
import cx from 'classnames';
import useBreakpoint from 'hooks/useBreakpoint';
import heroBackground from 'assets/images/graphical-header.jpeg';
import CardGrid from 'components/molecules/CardGrid/CardGrid';
import CampaignCard from 'components/organisms/CampaignCard';
import FilterBar from 'components/molecules/FilterBar';
import styles from './campaignLandingPage.module.scss';
import {
  ACTIVE_CAMPAIGNS_HEADER_TEXT,
  ADMIN_SUBTEXT,
  ALL_OFFICES,
  BUTTON_AS,
  BUTTON_ICON_INDEX,
  BUTTON_LARGE,
  BUTTON_SMALL,
  BUTTON_TEXT,
  BUTTON_VARIANT,
  CAMPAIGN_FORM_ACCEPT_TEXT,
  CAMPAIGN_FORM_CANCEL_TEXT,
  CAMPAIGN_FORM_TITLE,
  CAMPAIGN_REQUEST_FAILURE_ERROR,
  FALLBACK_OFFICES_DROPDOWN_OPTION,
  OFFICES_DROPDOWN_OPTIONS,
  FILTER_OFFICE_RESET_OPTION,
  HERO_TITLE,
  ICON_HIDDEN_TRUE,
  NO_ACTIVE_CAMPAIGNS_TEXT,
  NO_PAST_CAMPAIGNS_TEXT,
  // OFFICE_REQUEST_FAILURE_ERROR,
  PAGE_CONTAINER_LABEL,
  PAST_CAMPAIGNS_HEADER_TEXT,
  REGULAR_SUBTEXT,
  REGULAR_SUBTEXT_FILTER,
  TEXT_AS_P,
  TOTAL_CARDS_PER_PAGE,
} from './CampaignLandingPage.constants';
import { useEffect, useState } from 'react';
import { type DropdownOption } from 'components/molecules/Dropdown/Dropdown.types';
import CampaignForm from 'components/organisms/CampaignForm';
import { GET_ALL_CAMPAIGNS } from 'graphql/campaign/queries.gql';
import { useQuery } from '@apollo/client';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'context/Authentication/AuthContext';
import {
  checkIsAdmin,
  extractCampaignLocations,
  showToast,
} from 'utils/utilities';
import { getCampaignFilters } from './CampaignLandingPage.filters';
import { type Campaign } from 'utils/dataTypes';
import ContentLoader from 'components/atoms/ContentLoader';
import { type Filter } from 'components/molecules/FilterBar/FilterBar.types';

const CampaignLandingPage = (): JSX.Element => {
  const { isDesktop } = useBreakpoint();
  const isMobileBreakpoint = !isDesktop;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const toast = useToast();
  const [allCampaigns, setAllCampaigns] = useState<Campaign[]>([]);
  const { currentUser } = useAuth();
  const [activeSearchTerm, setActiveSearchTerm] = useState('');
  const [pastSearchTerm, setPastSearchTerm] = useState('');

  const isAdmin: boolean = checkIsAdmin(
    currentUser !== undefined ? currentUser?.role.name : undefined
  );

  const [currentActiveCampaigns, setCurrentActiveCampaigns] =
    useState<Campaign[]>(allCampaigns);

  const [currentPastCampaigns, setCurrentPastCampaigns] =
    useState<Campaign[]>(allCampaigns);

  const [appliedActiveCampaignFilters, setAppliedActiveCampaignFilters] =
    useState({
      office: [
        currentUser?.office !== undefined
          ? currentUser?.office.name
          : ALL_OFFICES,
      ],
    });

  const [appliedPastCampaignFilters, setAppliedPastCampaignFilters] = useState({
    office: [
      currentUser?.office !== undefined ? currentUser.office.name : ALL_OFFICES,
    ],
  });

  const [activeCampaignFilters, setActiveCampaignFilters] = useState<Filter[]>(
    getCampaignFilters(
      setAppliedActiveCampaignFilters,
      currentUser !== undefined
        ? (currentUser?.office as DropdownOption)
        : (FALLBACK_OFFICES_DROPDOWN_OPTION as DropdownOption)
    )
  );

  const [pastCampaignFilters, setPastCampaignFilters] = useState<Filter[]>(
    getCampaignFilters(
      setAppliedPastCampaignFilters,
      currentUser !== undefined
        ? (currentUser?.office as DropdownOption)
        : (FALLBACK_OFFICES_DROPDOWN_OPTION as DropdownOption)
    )
  );

  useEffect(() => {
    setActiveCampaigns(allCampaigns.filter((campaign) => campaign.isActive));
    setPastCampaigns(allCampaigns.filter((campaign) => !campaign.isActive));
  }, [allCampaigns]);

  const { loading: loadingCampaigns } = useQuery(GET_ALL_CAMPAIGNS, {
    onCompleted: (dataCampaigns) => {
      setAllCampaigns(dataCampaigns.getAllCampaigns);
    },
    onError: () => {
      showToast(CAMPAIGN_REQUEST_FAILURE_ERROR, toast, TOAST_TYPE.ERROR);
    },
  });

  const [activeCampaigns, setActiveCampaigns] = useState<Campaign[]>(
    allCampaigns.filter((campaign) => campaign.isActive)
  );

  const [pastCampaigns, setPastCampaigns] = useState<Campaign[]>(
    allCampaigns.filter((campaign) => !campaign.isActive)
  );

  const handleSearch = (searchTerm: string, isPastCampaign = false): void => {
    if (isPastCampaign) {
      setPastSearchTerm(searchTerm);
    } else {
      setActiveSearchTerm(searchTerm);
    }
  };

  const hideCampaignForm = (): void => {
    setIsFormOpen(false);
  };

  const showCampaignForm = (): void => {
    setIsFormOpen(true);
  };

  const pastCampaignsContainerClasses = cx({
    [styles['past-campaigns-container']]: pastCampaigns.length > 0,
    [styles['past-campaigns-container--empty']]: pastCampaigns.length === 0,
  });

  useEffect(() => {
    setActiveCampaignFilters((oldFilters) => {
      const newFilters = [...oldFilters];
      newFilters[0].options = OFFICES_DROPDOWN_OPTIONS;
      return newFilters;
    });
    setPastCampaignFilters((oldFilters) => {
      const newFilters = [...oldFilters];
      newFilters[0].options = OFFICES_DROPDOWN_OPTIONS;
      return newFilters;
    });
  }, []);

  useEffect(() => {
    setActiveCampaigns(allCampaigns.filter((campaign) => campaign.isActive));
    setPastCampaigns(allCampaigns.filter((campaign) => !campaign.isActive));
  }, [allCampaigns]);

  useEffect(() => {
    let filteredCampaigns = activeCampaigns.filter((campaign) =>
      campaign.name.toLowerCase().includes(activeSearchTerm.toLowerCase())
    );

    filteredCampaigns =
      appliedActiveCampaignFilters.office[0] !== FILTER_OFFICE_RESET_OPTION
        ? filteredCampaigns.filter((campaign) => {
            return campaign.offices
              .map((office) => office.name)
              .some((office) =>
                appliedActiveCampaignFilters.office.includes(office)
              );
          })
        : filteredCampaigns;
    setCurrentActiveCampaigns(filteredCampaigns);
  }, [appliedActiveCampaignFilters, activeCampaigns, activeSearchTerm]);

  useEffect(() => {
    let filteredCampaigns = pastCampaigns.filter((campaign) =>
      campaign.name.toLowerCase().includes(pastSearchTerm.toLowerCase())
    );

    filteredCampaigns =
      appliedPastCampaignFilters.office[0] !== FILTER_OFFICE_RESET_OPTION
        ? filteredCampaigns.filter((campaign) => {
            return campaign.offices
              .map((office) => office.name)
              .some((office) =>
                appliedPastCampaignFilters.office.includes(office)
              );
          })
        : filteredCampaigns;
    setCurrentPastCampaigns(filteredCampaigns);
  }, [appliedPastCampaignFilters, pastCampaigns, pastSearchTerm]);

  return (
    <ContentLoader isLoading={loadingCampaigns}>
      <CampaignForm
        title={CAMPAIGN_FORM_TITLE}
        cancelButtonText={CAMPAIGN_FORM_CANCEL_TEXT}
        acceptButtonText={CAMPAIGN_FORM_ACCEPT_TEXT}
        hide={hideCampaignForm}
        isOpen={isFormOpen}
        onSubmit={(data) => {
          setAllCampaigns([...allCampaigns, data]);
        }}
      />
      <header aria-label={HERO_TITLE}>
        <Hero
          title={HERO_TITLE}
          background={heroBackground}
          isAdmin={isAdmin}
        />
      </header>
      <PageContainer ariaLabel={PAGE_CONTAINER_LABEL}>
        <section className={styles['active-campaings-container']}>
          <header
            tabIndex={0}
            className={styles['section-heading']}>
            <Text
              as={TITLE_TAG.H2}
              variant={TYPEKIT.D3}
              className={styles['section-heading__text']}>
              {ACTIVE_CAMPAIGNS_HEADER_TEXT}
            </Text>
            {activeCampaigns.length > 0 &&
              !isMobileBreakpoint &&
              activeCampaignFilters.length > 0 && (
                <FilterBar
                  className={styles['filter-bar']}
                  searchboxClassNames={{
                    searchBox: styles['filter-bar__searchbox'],
                    container: styles['filter-bar__searchbox-container'],
                  }}
                  filters={activeCampaignFilters}
                  searchBarPlaceholder={SEARCH_BAR_PLACEHOLDER}
                  onSearch={handleSearch}
                />
              )}
            {isAdmin && activeCampaigns.length > 0 && (
              <div className={styles['btn-container--header']}>
                <ButtonLink
                  size={BUTTON_SMALL}
                  variant={BUTTON_VARIANT}
                  as={BUTTON_AS}
                  onClick={showCampaignForm}>
                  <div
                    className={cx(
                      styles['btn-content'],
                      styles['btn-content--header']
                    )}>
                    <Text
                      as={TEXT_AS_P}
                      variant={TYPEKIT.D6}>
                      {BUTTON_TEXT}
                    </Text>
                    <Icon
                      tabIndex={BUTTON_ICON_INDEX}
                      aria-hidden={ICON_HIDDEN_TRUE}
                      src={plusIcon}
                      className={cx(
                        styles['btn-content--header-icon-size'],
                        styles['btn-content--icon-color']
                      )}
                    />
                  </div>
                </ButtonLink>
              </div>
            )}
          </header>

          {isMobileBreakpoint && activeCampaigns.length > 0 && (
            <FilterBar
              className={styles['filter-bar']}
              searchboxClassNames={{
                searchBox: styles['filter-bar__searchbox'],
                container: styles['filter-bar__searchbox-container'],
              }}
              filters={activeCampaignFilters}
              searchBarPlaceholder={SEARCH_BAR_PLACEHOLDER}
              onSearch={handleSearch}
              smallerIcon
            />
          )}

          <div
            className={cx(styles.body, {
              [styles['body--regular']]: activeCampaigns.length === 0,
              [styles['body--card']]: activeCampaigns.length > 0,
            })}>
            {activeCampaigns.length === 0 ? (
              <div className={styles['message-frame']}>
                <Text
                  as={TITLE_TAG.H3}
                  variant={TYPEKIT.D3}>
                  {NO_ACTIVE_CAMPAIGNS_TEXT}
                </Text>
                {isAdmin ? (
                  <Text
                    as={TEXT_AS_P}
                    className={styles['subtext-color']}
                    variant={isMobileBreakpoint ? TYPEKIT.P2 : TYPEKIT.D5}>
                    {ADMIN_SUBTEXT}
                  </Text>
                ) : (
                  <Text
                    as={TEXT_AS_P}
                    className={styles['subtext-color']}
                    variant={isMobileBreakpoint ? TYPEKIT.P2 : TYPEKIT.D5}>
                    {REGULAR_SUBTEXT}
                  </Text>
                )}
              </div>
            ) : (
              <>
                {currentActiveCampaigns.length > 0 ? (
                  <CardGrid
                    type={GRIDTYPES.CAMPAIGN}
                    totalPerPage={TOTAL_CARDS_PER_PAGE}>
                    {currentActiveCampaigns.map((campaign) => (
                      <CampaignCard
                        key={campaign.id}
                        title={campaign.name}
                        raisedAmount={campaign.raisedAmount}
                        locations={extractCampaignLocations(campaign)}
                        endDate={campaign.endDate}
                        imgUrl={campaign.imageKey}
                        donationGoal={campaign.goal}
                        redirectTo={campaign.id}
                        isFinished={!campaign.isActive}
                      />
                    ))}
                  </CardGrid>
                ) : (
                  <div className={styles['body--regular']}>
                    <div className={styles['message-frame']}>
                      <Text
                        as={TITLE_TAG.H3}
                        variant={TYPEKIT.D3}>
                        {NO_ACTIVE_CAMPAIGNS_TEXT}
                      </Text>
                      <Text
                        as={TEXT_AS_P}
                        className={styles['subtext-color']}
                        variant={isMobileBreakpoint ? TYPEKIT.P2 : TYPEKIT.D5}>
                        {REGULAR_SUBTEXT_FILTER}
                      </Text>
                    </div>
                  </div>
                )}
              </>
            )}

            {isAdmin && activeCampaigns.length === 0 && (
              <div className={styles['btn-container']}>
                <ButtonLink
                  size={isMobileBreakpoint ? BUTTON_SMALL : BUTTON_LARGE}
                  variant={BUTTON_VARIANT}
                  as={BUTTON_AS}
                  onClick={showCampaignForm}>
                  <div className={styles['btn-content']}>
                    <Text
                      as={TEXT_AS_P}
                      variant={TYPEKIT.D6}>
                      {BUTTON_TEXT}
                    </Text>
                    <Icon
                      tabIndex={BUTTON_ICON_INDEX}
                      aria-hidden={ICON_HIDDEN_TRUE}
                      src={plusIcon}
                      className={cx(
                        styles['btn-content--icon-size'],
                        styles['btn-content--icon-color']
                      )}
                    />
                  </div>
                </ButtonLink>
              </div>
            )}
          </div>
        </section>

        <section className={pastCampaignsContainerClasses}>
          <header
            tabIndex={0}
            className={styles['section-heading']}>
            <Text
              as={TITLE_TAG.H2}
              variant={TYPEKIT.D3}
              className={styles['section-heading__text']}>
              {PAST_CAMPAIGNS_HEADER_TEXT}
            </Text>

            {pastCampaigns.length > 0 && !isMobileBreakpoint && (
              <FilterBar
                className={styles['filter-bar']}
                searchboxClassNames={{
                  searchBox: styles['filter-bar__searchbox'],
                  container: styles['filter-bar__searchbox-container'],
                }}
                filters={pastCampaignFilters}
                searchBarPlaceholder={SEARCH_BAR_PLACEHOLDER}
                onSearch={(searchTerm) => {
                  handleSearch(searchTerm, true);
                }}
              />
            )}
          </header>

          {isMobileBreakpoint &&
            pastCampaigns.length > 0 &&
            pastCampaignFilters.length > 0 && (
              <FilterBar
                className={styles['filter-bar']}
                searchboxClassNames={{
                  searchBox: styles['filter-bar__searchbox'],
                  container: styles['filter-bar__searchbox-container'],
                }}
                filters={pastCampaignFilters}
                searchBarPlaceholder={SEARCH_BAR_PLACEHOLDER}
                onSearch={(searchTerm) => {
                  handleSearch(searchTerm, true);
                }}
                smallerIcon
              />
            )}

          {pastCampaigns.length === 0 ? (
            <Text
              as={TEXT_AS_P}
              className={styles['subtext-color']}
              variant={isMobileBreakpoint ? TYPEKIT.P3 : TYPEKIT.D5}>
              {NO_PAST_CAMPAIGNS_TEXT}
            </Text>
          ) : (
            <>
              {currentPastCampaigns.length > 0 ? (
                <CardGrid
                  type={GRIDTYPES.CAMPAIGN}
                  totalPerPage={TOTAL_CARDS_PER_PAGE}>
                  {currentPastCampaigns.map((campaign) => (
                    <CampaignCard
                      key={campaign.id}
                      title={campaign.name}
                      raisedAmount={campaign.raisedAmount}
                      locations={extractCampaignLocations(campaign)}
                      endDate={campaign.endDate}
                      imgUrl={campaign.imageKey}
                      donationGoal={campaign.goal}
                      redirectTo={campaign.id}
                      isFinished={!campaign.isActive}
                    />
                  ))}
                </CardGrid>
              ) : (
                <Text
                  as={TEXT_AS_P}
                  className={styles['subtext-color']}
                  variant={isMobileBreakpoint ? TYPEKIT.P3 : TYPEKIT.D5}>
                  {NO_PAST_CAMPAIGNS_TEXT}
                </Text>
              )}
            </>
          )}
        </section>
      </PageContainer>
    </ContentLoader>
  );
};

export default CampaignLandingPage;
