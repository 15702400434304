import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  type FileData,
  type ErrorType,
  type FileUploadProps,
} from './FileUpload.types';
import fileUploadIcon from 'assets/svgs/fileUpload.svg';
import removeIcon from 'assets/svgs/closedCircle.svg';
import confirmIcon from 'assets/svgs/confirmNotification.svg';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import ProgressBar from 'components/atoms/ProgressBar';
import ButtonLink from 'components/molecules/ButtonLink';
import Image from 'components/atoms/Image';
import {
  TYPEKIT,
  PROGRESS_BAR_VARIANTS,
  BREAKPOINT,
  EMPTY_STRING,
} from 'utils/constants';
import styles from './fileUpload.module.scss';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import {
  BUTTON_AS,
  BUTTON_SIZE,
  BUTTON_TEXT,
  BUTTON_VARIANT,
  INTERNAL_SIZE_ERROR_MESSAGE,
  FILE_SIZE,
  ACCEPT_DEFAULT_VALUE,
  MULTIPLE_DEFAULT_VALUE,
  INITIAL_DISPLAY_TEXT,
  INPUT_TYPE,
  UPLOAD_COMPLETE,
  VALIDATION_TEXT,
  LABEL_FILE_PREVIEW_WRAPPER,
  HIDDEN_TRUE,
  TEXT_AS,
  POLITE,
  GROUP_ROLE,
  ASSERTIVE,
  UPLOAD_COMPLETE_TEXT,
  FILE_TEXT,
  FILES_TEXT,
  UPLOADING_TEXT,
  REMOVE_TEXT,
  PREVIEW_TEXT,
  ERROR_REACHED_LIMIT_MESSAGE,
  NUMBER_FILES_1,
  INTERNAL_FORMAT_ERROR_MESSAGE,
  FORMAT,
  SIZE,
  BOTH,
  INTERNAL_FORMAT_SIZE_ERROR_MESSAGE,
  SUBSTRING_LOWER_LIMIT,
  MAX_NAME_SIZE_MOBILE,
  MAX_NAME_SIZE_DESKTOP,
  ELLIPSIS,
  OFFSET,
} from './FileUpload.constants';
import ErrorMessage from 'components/molecules/ErrorMessage';
import useBreakpoint from 'hooks/useBreakpoint';

/**
 * FileUpload is a React component that allows users to upload files, display upload progress,
 * and preview selected files.
 *
 * @param {number} maxNumberFiles - The maximum number of files that can be selected.
 * @param {string} [accept] - A string representing accepted file types (e.g., 'image/*').
 * @param {boolean} [multiple] - Indicates whether multiple files can be selected.
 * @param {boolean} [errorState] - Error state to render the appropriate style.
 * @param {string} [label] - Label for the FileUpload component.
 * @param {RemoteFile[]} [defaultValue] - Array of files to be set as default values.
 * @param {string} [errorMessage] - Prop to customize the error message thrown.
 * @param {function} onSelectedFilesChange - A callback function invoked when selected files change and the remote files.
 * @returns {React.FC} The rendered FileUpload component.
 *
 * @example
 * // Example usage:
 * import FileUpload from './FileUpload';
 *
 * function MyComponent() {
 *   const handleSelectedFilesChange = (selectedFiles) => {
 *     // Handle the selected files here
 *   };
 *
 *   return (
 *     <FileUpload
 *       maxNumberFiles={5}
 *       accept="image/*"
 *       multiple={true}
 *       onSelectedFilesChange={handleSelectedFilesChange}
 *     />
 *   );
 * }
 */

const FileUpload: React.FC<FileUploadProps> = forwardRef<
  HTMLDivElement,
  FileUploadProps
>(
  (
    {
      maxNumberFiles,
      accept = ACCEPT_DEFAULT_VALUE,
      multiple = MULTIPLE_DEFAULT_VALUE,
      errorMessage,
      label,
      defaultValue,
      onSelectedFilesChange,
    },
    ref
  ) => {
    const [displayText, setDisplayText] = useState(INITIAL_DISPLAY_TEXT);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploadComplete, setIsUploadComplete] = useState(false);
    const [remoteFiles, setRemoteFiles] = useState(defaultValue ?? []);
    const [selectedFiles, setSelectedFiles] = useState<FileData[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const filePreviewContainerRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState(EMPTY_STRING);
    const maxFileSizeId = useRef(uuidv4());
    const { isDesktop, currentBreakpoint } = useBreakpoint();

    const checkForInvalidFiles = (files: File[]): ErrorType => {
      for (const file of files) {
        if (file.type !== accept && file.size > FILE_SIZE) return BOTH;
        if (file.type !== accept) return FORMAT;
        if (file.size > FILE_SIZE) return SIZE;
      }
      return null;
    };

    const setErrorBasedOnFileType = (files: File[]): boolean => {
      const errorType = checkForInvalidFiles(files);
      switch (errorType) {
        case FORMAT:
          setError(INTERNAL_FORMAT_ERROR_MESSAGE);
          return true;
        case SIZE:
          setError(INTERNAL_SIZE_ERROR_MESSAGE);
          return true;
        case BOTH:
          setError(INTERNAL_FORMAT_SIZE_ERROR_MESSAGE);
          return true;
        default:
          return false;
      }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newFiles: File[] = Array.from(e.target.files as FileList);

      if (newFiles.length === 0) return;

      if (setErrorBasedOnFileType(newFiles)) return;

      setIsUploadComplete(false);
      processFiles(newFiles);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
      e.preventDefault();
      const newFiles: File[] = Array.from(e.dataTransfer.files);

      const remainingSpace =
        remoteFiles === undefined
          ? maxNumberFiles - selectedFiles.length
          : maxNumberFiles - selectedFiles.length - remoteFiles.length;

      if (remainingSpace <= 0 && maxNumberFiles !== NUMBER_FILES_1) {
        setError(ERROR_REACHED_LIMIT_MESSAGE);
        return;
      }

      if (setErrorBasedOnFileType(newFiles)) return;

      processFiles(newFiles);
    };

    const handleClick = (): void => {
      const remainingSpace =
        remoteFiles === undefined
          ? maxNumberFiles - selectedFiles.length
          : maxNumberFiles - selectedFiles.length - remoteFiles.length;

      if (remainingSpace <= 0 && maxNumberFiles !== NUMBER_FILES_1) {
        setError(ERROR_REACHED_LIMIT_MESSAGE);
      } else if (fileInputRef.current !== null) {
        fileInputRef.current.click();
      }
    };

    const processFiles = (newFiles: File[]): void => {
      setError(EMPTY_STRING);

      const remainingSpace =
        remoteFiles === undefined
          ? maxNumberFiles - selectedFiles.length
          : maxNumberFiles - selectedFiles.length - remoteFiles.length;
      const numberFilesAvailable = Math.min(remainingSpace, newFiles.length);
      const newSelectedFiles = newFiles.slice(0, numberFilesAvailable);

      setIsUploading(true);
      setUploadProgress(0);

      const numberFiles =
        newFiles.length > maxNumberFiles ? maxNumberFiles : newFiles.length;

      setDisplayText(
        `${UPLOADING_TEXT} ${numberFiles} ${
          newFiles.length === 1 ? FILE_TEXT : FILES_TEXT
        }`
      );

      if (maxNumberFiles === NUMBER_FILES_1) {
        uploadProgressInterval(newFiles.slice(0, 1));
      } else {
        uploadProgressInterval(newSelectedFiles);
      }

      if (filePreviewContainerRef.current != null) {
        filePreviewContainerRef.current.focus();
      }
    };

    const uploadProgressInterval = (newSelectedFiles: File[]): void => {
      const uploadInterval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          const newProgress = prevProgress + 10;
          if (newProgress >= 100) {
            clearInterval(uploadInterval);
            setIsUploading(false);

            const newFilesData = newSelectedFiles.map((file) => {
              return { file, key: EMPTY_STRING };
            });

            if (maxNumberFiles === NUMBER_FILES_1) {
              if (remoteFiles.length > 0) {
                setRemoteFiles([]);
              }
              setSelectedFiles(newFilesData);
            } else {
              setSelectedFiles([...selectedFiles, ...newFilesData]);
            }

            const fileName = newSelectedFiles[0].name;
            const [name, extension] = fileName.split('.');

            const maxNameLength =
              isDesktop || currentBreakpoint === BREAKPOINT.LARGE_MOBILE
                ? MAX_NAME_SIZE_DESKTOP
                : MAX_NAME_SIZE_MOBILE;

            const finalName =
              name.length > maxNameLength
                ? `${name.substring(
                    SUBSTRING_LOWER_LIMIT,
                    name.length - (name.length - maxNameLength) - OFFSET
                  )}${ELLIPSIS}${extension}`
                : fileName;

            setDisplayText(
              newSelectedFiles.length === 1
                ? `"${finalName}" ${UPLOAD_COMPLETE_TEXT}`
                : UPLOAD_COMPLETE
            );
            setIsUploadComplete(true);

            return 100;
          }
          return newProgress;
        });
      }, 200);
    };

    const handleRemoveUploaded = (indexToRemove: number): void => {
      setError(EMPTY_STRING);
      const newRemoteFiles = [...remoteFiles];
      newRemoteFiles.splice(indexToRemove, 1);
      setRemoteFiles([...newRemoteFiles]);

      if (newRemoteFiles.length === 0 && selectedFiles.length === 0) {
        setDisplayText(INITIAL_DISPLAY_TEXT);
        setIsUploadComplete(false);
      }

      if (fileInputRef.current !== null) {
        fileInputRef.current.value = '';
      }
    };

    const handleRemove = (indexToRemove: number): void => {
      setError(EMPTY_STRING);
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles.splice(indexToRemove, 1);
      setSelectedFiles([...newSelectedFiles]);

      if (newSelectedFiles.length === 0 && remoteFiles.length === 0) {
        setDisplayText(INITIAL_DISPLAY_TEXT);
        setIsUploadComplete(false);
      }

      if (fileInputRef.current != null) {
        fileInputRef.current.value = '';
      }
    };

    useEffect(() => {
      setError(errorMessage as string);
    }, [errorMessage]);

    useEffect(() => {
      const file = { localFiles: selectedFiles, remoteFiles };
      onSelectedFilesChange(file);
    }, [selectedFiles, onSelectedFilesChange, remoteFiles]);

    return (
      <div className={styles['file-upload']}>
        {label !== null && (
          <Text
            as={TEXT_AS}
            variant={TYPEKIT.D5}
            className={styles['file-upload__label']}>
            {label}
          </Text>
        )}
        <div className={styles['file-upload-container']}>
          <div
            className={cx(styles['file-selection-container'], {
              [styles['file-selection-container--error']]: error,
            })}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={handleDrop}
            ref={dropZoneRef}>
            <Icon
              src={fileUploadIcon}
              aria-hidden={HIDDEN_TRUE}
              tabIndex={-1}
              className={styles['file-selection-container__icon']}
            />
            {isUploading ? (
              <>
                <div
                  className={styles['display-text-container']}
                  aria-live={ASSERTIVE}>
                  <Text variant={TYPEKIT.D4}>{displayText}</Text>
                </div>
                <div className={styles['progress-bar-container']}>
                  <ProgressBar
                    variant={PROGRESS_BAR_VARIANTS.ROUNDED}
                    progress={uploadProgress}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles['text-container']}>
                  <div
                    className={styles['display-text-container']}
                    aria-live={POLITE}>
                    <Text variant={TYPEKIT.D4}>{displayText}</Text>
                    {isUploadComplete && (
                      <Icon
                        src={confirmIcon}
                        aria-hidden={HIDDEN_TRUE}
                        tabIndex={-1}
                        className={styles['confirmation-icon']}
                      />
                    )}
                  </div>
                  <Text
                    id={maxFileSizeId.current}
                    className={styles['text-container__validation-text']}
                    variant={TYPEKIT.P3}>
                    {VALIDATION_TEXT}
                  </Text>
                </div>
                <ButtonLink
                  className={styles['file-selection-container__upload-btn']}
                  as={BUTTON_AS}
                  aria-describedby={maxFileSizeId.current}
                  variant={BUTTON_VARIANT}
                  size={BUTTON_SIZE}
                  onClick={handleClick}>
                  {BUTTON_TEXT}
                </ButtonLink>
              </>
            )}
            <input
              aria-hidden={HIDDEN_TRUE}
              tabIndex={-1}
              ref={fileInputRef}
              type={INPUT_TYPE}
              className={styles['input-style']}
              onChange={handleFileChange}
              multiple={multiple}
              accept={accept}
              max={FILE_SIZE}
            />
          </div>
          {Boolean(error) && error !== EMPTY_STRING && (
            <>
              <ErrorMessage error={error} />
            </>
          )}
          <div className={styles['file-preview-wrapper']}>
            <div
              className={styles['file-preview-container']}
              role={GROUP_ROLE}
              aria-label={LABEL_FILE_PREVIEW_WRAPPER}
              tabIndex={0}
              ref={filePreviewContainerRef}>
              {remoteFiles.map((fileData, index) => (
                <div
                  key={index}
                  className={styles['img-container']}>
                  <Image
                    className={styles['img-container__img-style']}
                    alt={`${PREVIEW_TEXT} ${remoteFiles[index].key}`}
                    src={fileData.url}
                  />
                  <Text
                    variant={TYPEKIT.P3}
                    className={styles['img-container__text-style']}
                    aria-hidden={HIDDEN_TRUE}>
                    {fileData.key ?? `uploaded-picture-${index}`}
                  </Text>
                  <Icon
                    src={removeIcon}
                    ariaLabel={`${REMOVE_TEXT} ${remoteFiles[index].key}`}
                    className={styles['img-container__remove-btn']}
                    onClick={() => {
                      handleRemoveUploaded(index);
                    }}
                  />
                </div>
              ))}
              {selectedFiles.map((fileData, index) => (
                <div
                  key={index}
                  className={styles['img-container']}>
                  <Image
                    className={styles['img-container__img-style']}
                    alt={`${PREVIEW_TEXT} ${selectedFiles[index].file.name}`}
                    src={URL.createObjectURL(fileData.file)}
                  />
                  <Text
                    variant={TYPEKIT.P3}
                    className={styles['img-container__text-style']}
                    aria-hidden={HIDDEN_TRUE}>
                    {selectedFiles[index].file.name}
                  </Text>
                  <Icon
                    src={removeIcon}
                    ariaLabel={`${REMOVE_TEXT} ${selectedFiles[index].file.name}`}
                    className={styles['img-container__remove-btn']}
                    onClick={() => {
                      handleRemove(index);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

FileUpload.displayName = 'FileUpload';
export default FileUpload;
