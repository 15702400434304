import ModalOverlay from 'components/atoms/ModalOverlay';
import { Swiper, SwiperSlide } from 'swiper/react';
import type SwiperCore from 'swiper';
import { Pagination, Controller, A11y, Keyboard, Navigation } from 'swiper';
import Image from 'components/atoms/Image';
import 'swiper/scss';
import 'swiper/scss/a11y';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './swiper.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { type CarouselProps } from './Carousel.types';
import styles from './carousel.module.scss';
import useBreakpoint from 'hooks/useBreakpoint';
import {
  CAROUSEL_NEXT_BUTTON_LABEL,
  CAROUSEL_PREVIOUS_BUTTON_LABEL,
  DEFAULT_CAROUSEL_LABEL,
  FULL_IMAGE_ASPECT_RATIO,
  MAX_SLIDES_MOBILE,
  PAGINATION_CONTAINER_CLASSNAME,
  PAGINATION_CONTAINER_CLASSNAME_DOT,
  PAGINATION_DIVIDER,
  PAGINATION_TYPE,
  PREVIEW_ALT_PREFIX,
  PREVIEW_ASPECT_RATIO,
  PREVIEW_KEY_PREFIX,
  PREVIEW_SLIDE_CLASS,
  SLIDES_PER_VIEW_MOBILE,
  SLIDE_KEY_PREFIX,
  SPACE_BETWEEN_CAROUSEL_IMAGES,
  SPACE_BETWEEN_CAROUSEL_PREVIEWS,
} from './Carousel.constants';
import { CLICK_EVENT_LISTENER } from 'utils/constants';
import useHidePageOverflow from 'hooks/useHidePageOverflow';
import cx from 'classnames';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';

/**
 * The Carousel Component renders a modal slider that displays a series of images passed by prop.
 *
 * @param {string[]} images - Array of image sources to be displayed.
 * @param {boolean} isOpen - Toggles the visibility of the carousel
 * @param hide - Function that hides the modal.
 * @param elementRef - Ref to the trigger element for the modal. It focuses that element after it closes.
 *
 */

const Carousel: React.FC<CarouselProps> = ({
  images,
  isOpen,
  hide,
  elementRef,
}) => {
  const [swiper, updateSwiper] = useState<SwiperCore>();
  const [swiperThumbs, updateSwiperThumbs] = useState<SwiperCore>();
  const modalRef = useRef<HTMLDivElement>(null);
  const { isVisible, setIsVisible } = useHandleExitAnimation(
    styles.scaleDown30PercentFromOrigin,
    () => {
      hideModal();
      setIsVisible(true);
    },
    modalRef.current as HTMLElement,
    true
  );

  const { isDesktop } = useBreakpoint();

  const getSlidesPerView = (): number => {
    if (!isDesktop && images.length > MAX_SLIDES_MOBILE) {
      return SLIDES_PER_VIEW_MOBILE;
    }
    return images.length;
  };

  const hideModal = useCallback((): void => {
    elementRef?.focus();
    hide();
  }, [elementRef, hide]);

  useHidePageOverflow(isOpen, elementRef);

  useEffect(() => {
    const slideOverlay = Array.from(
      document.querySelectorAll(`.${styles.carousel__slide}`)
    );
    const paginationOverlay = document.querySelector(
      PAGINATION_CONTAINER_CLASSNAME_DOT
    );
    const handleClick = (e: Event): void => {
      if (
        slideOverlay.includes(e.target as Element) ||
        e.target === paginationOverlay
      ) {
        setIsVisible(false);
      }
    };

    if (slideOverlay != null) {
      document.addEventListener(CLICK_EVENT_LISTENER, handleClick);
    }

    return () => {
      document.removeEventListener(CLICK_EVENT_LISTENER, handleClick);
    };
  }, [setIsVisible, swiper]);

  const updateIndexThumbs = (): void => {
    if (swiper !== undefined && swiperThumbs !== undefined) {
      swiperThumbs.activeIndex = swiper.activeIndex;
      swiperThumbs.updateSlidesClasses();
    }
  };

  const handlePreviewClick = (index: number): void => {
    if (swiper !== undefined && swiperThumbs !== undefined) {
      swiper.slideTo(index);
    }
  };

  return isOpen ? (
    <ModalOverlay
      className={cx({ [styles['carousel__container--closing']]: !isVisible })}
      isOpen={isOpen}
      hideModal={() => {
        setIsVisible(false);
      }}>
      <div
        className={cx(styles.carousel__container)}
        aria-modal="true"
        role="dialog">
        <Swiper
          modules={[Controller, Pagination, Navigation, Keyboard, A11y]}
          pagination={{
            el: PAGINATION_CONTAINER_CLASSNAME_DOT,
            type: PAGINATION_TYPE,
          }}
          keyboard
          spaceBetween={SPACE_BETWEEN_CAROUSEL_IMAGES}
          navigation={isDesktop}
          mousewheel
          a11y={{
            enabled: true,
            itemRoleDescriptionMessage: DEFAULT_CAROUSEL_LABEL,
            nextSlideMessage: CAROUSEL_NEXT_BUTTON_LABEL,
            prevSlideMessage: CAROUSEL_PREVIOUS_BUTTON_LABEL,
          }}
          onSwiper={updateSwiper}
          onSlideChange={updateIndexThumbs}
          className={styles['modal-carousel']}>
          {images.map((image, idx) => (
            <SwiperSlide
              key={`${SLIDE_KEY_PREFIX}${idx}`}
              className={styles.carousel__slide}>
              <div
                className={cx(styles['image-container'], {
                  [styles['image-container--closing']]: !isVisible,
                })}
                ref={modalRef}>
                <Image
                  src={image}
                  alt={`${idx} ${PAGINATION_DIVIDER} ${images.length}`}
                  aspectRatio={FULL_IMAGE_ASPECT_RATIO}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={PAGINATION_CONTAINER_CLASSNAME}></div>
      </div>

      <Swiper
        modules={[Controller]}
        slidesPerView={getSlidesPerView()}
        slideToClickedSlide
        onSwiper={updateSwiperThumbs}
        onSlideChange={updateIndexThumbs}
        spaceBetween={SPACE_BETWEEN_CAROUSEL_PREVIEWS}
        className={cx(
          styles.carousel__previews,
          styles[`carousel__previews--${images.length}-previews`],
          { [styles['carousel__previews--closing']]: !isVisible }
        )}>
        {images.map((image, idx) => (
          <SwiperSlide
            key={`${PREVIEW_KEY_PREFIX}${idx}`}
            className={PREVIEW_SLIDE_CLASS}
            onClick={() => {
              handlePreviewClick(idx);
            }}>
            <Image
              aspectRatio={PREVIEW_ASPECT_RATIO}
              src={image}
              alt={`${PREVIEW_ALT_PREFIX} ${idx} ${PAGINATION_DIVIDER} ${images.length}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </ModalOverlay>
  ) : null;
};

export default Carousel;
