import { gql } from '@apollo/client';

export const DELETE_ITEM = gql`
  mutation DeleteItem($id: ID!) {
    flagItemAsDeleted(itemId: $id) {
      success
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation UpdateItem(
    $id: ID!
    $name: String
    $startPrice: Float
    $description: String
    $isReviewed: Boolean
    $isApproved: Boolean
    $images: [String!]
    $category: String
    $user: String
    $campaign: String
    $updatedBy: String
    $isDeleted: Boolean
    $offices: [OfficeInput!]!
  ) {
    updateItem(
      itemId: $id
      itemInput: {
        name: $name
        startPrice: $startPrice
        description: $description
        isReviewed: $isReviewed
        isApproved: $isApproved
        images: $images
        category: $category
        updatedBy: $updatedBy
        campaign: $campaign
        user: $user
        isDeleted: $isDeleted
        offices: $offices
      }
    ) {
      id
      name
    }
  }
`;

export const REVIEW_ITEM = gql`
  mutation ReviewItem($id: ID!, $approved: Boolean!) {
    reviewItem(itemId: $id, approved: $approved) {
      id
      name
    }
  }
`;

export const CREATE_ITEM = gql`
  mutation CreateItem(
    $name: String!
    $startPrice: Float!
    $description: String!
    $isReviewed: Boolean!
    $isApproved: Boolean!
    $images: [String!]!
    $category: String!
    $user: String!
    $campaign: String!
    $updatedBy: String!
    $isDeleted: Boolean!
    $offices: [OfficeInput!]!
  ) {
    createItem(
      itemInput: {
        name: $name
        startPrice: $startPrice
        description: $description
        isReviewed: $isReviewed
        isApproved: $isApproved
        images: $images
        category: $category
        updatedBy: $updatedBy
        campaign: $campaign
        user: $user
        isDeleted: $isDeleted
        offices: $offices
      }
    ) {
      id
      name
    }
  }
`;
