import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign(
    $name: String!
    $imageKey: String!
    $startDate: String!
    $endDate: String!
    $goal: Int!
    $isActive: Boolean!
    $isDeleted: Boolean!
    $raisedAmount: Int!
    $updatedBy: String!
    $offices: [OfficeInput!]!
  ) {
    createCampaign(
      campaignInput: {
        name: $name
        imageKey: $imageKey
        startDate: $startDate
        endDate: $endDate
        goal: $goal
        isActive: $isActive
        isDeleted: $isDeleted
        raisedAmount: $raisedAmount
        updatedBy: $updatedBy
        offices: $offices
      }
    ) {
      id
      name
      imageKey
      startDate
      endDate
      goal
      raisedAmount
      isActive
      createdAt
      isDeleted
      updatedBy {
        id
        email
        imageUrl
        lastName
        firstName
        dateJoined
        role {
          id
          name
          description
        }
      }
      offices {
        id
        name
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $id: ID!
    $name: String
    $imageKey: String
    $startDate: String
    $endDate: String
    $goal: Int
    $raisedAmount: Int
    $isActive: Boolean
    $isDeleted: Boolean
    $updatedBy: String
    $offices: [OfficeInput!]!
  ) {
    updateCampaign(
      campaignId: $id
      campaignInput: {
        name: $name
        imageKey: $imageKey
        startDate: $startDate
        endDate: $endDate
        goal: $goal
        raisedAmount: $raisedAmount
        isActive: $isActive
        isDeleted: $isDeleted
        updatedBy: $updatedBy
        offices: $offices
      }
    ) {
      id
      name
    }
  }
`;
