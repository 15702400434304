import { useEffect, useState } from 'react';
import dayjs, { type Dayjs } from 'dayjs';

interface Countdown {
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  counterOff: boolean;
}

const calculateRemainingTime = (targetDateTime: Dayjs): Countdown => {
  let now = dayjs();
  const months = targetDateTime.diff(now, 'month');
  now = now.add(months, 'month');
  const days = targetDateTime.diff(now, 'day');
  now = now.add(days, 'day');
  const hours = targetDateTime.diff(now, 'hour');
  now = now.add(hours, 'hour');
  const minutes = targetDateTime.diff(now, 'minute');
  now = now.add(minutes, 'minute');
  const seconds = targetDateTime.diff(now, 'second');

  const counterOff = days + hours + minutes + seconds <= 0;

  return { months, days, hours, minutes, seconds, counterOff };
};

const useCountdown = (targetDate: string): Countdown => {
  const targetDateTime = dayjs(targetDate);
  const { months, days, hours, minutes, seconds, counterOff } =
    calculateRemainingTime(targetDateTime);

  const [countdown, setCountdown] = useState({
    months,
    days,
    hours,
    minutes,
    seconds,
    counterOff,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const { months, days, hours, minutes, seconds, counterOff } =
        calculateRemainingTime(targetDateTime);

      setCountdown({
        months,
        days,
        hours,
        minutes,
        seconds,
        counterOff,
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [targetDateTime]);

  return countdown;
};

export { useCountdown };
