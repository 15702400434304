import React, {
  createElement,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import cx from 'classnames';
import {
  type RangeDatePickerProps,
  type RangePickerDate,
} from 'components/organisms/DatePicker/DatePicker.types';
import Text from 'components/atoms/Text';
import useBreakpoint from 'hooks/useBreakpoint';
import 'react-datepicker/dist/react-datepicker.css';
import 'components/organisms/DatePicker/datePicker.scss';

import { TYPEKIT, BREAKPOINT, TITLE_TAG } from 'utils/constants';
import {
  DEFAULT_BLOCK_PAST_DATES,
  DEFAULT_DISABLED_VALUE,
  DEFAULT_REQUIRED_VALUE,
  DEFAULT_SELECTS_RANGE_VALUE,
  REQUIRED_MARK,
  REQUIRED_MARK_ARIA_LABEL,
  SINGLE_MONTH,
} from 'components/organisms/DatePicker/DatePicker.constants';
import DatePickerInput from 'components/molecules/DatePickerInput';
import ErrorMessage from 'components/molecules/ErrorMessage';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';

/**
 * A date picker component that allows the user to select a single date or a range of dates from startDate to endDate
 *
 * @component
 * @example
 * <DatePicker
 *   onDateChange={(dates) => console.log('Selected Dates:', dates)}
 * />
 * @param {Date} id - The HTML ID attribute of the label component if specified. Should match the 'for'
 * property in the associated label.
 * @param {function} onChange - Callback function invoked when the selected date changes.
 * @param {boolean} selectsRange - Specifies whether the date picker should allow selecting a range of dates.
 * @param {string} label - Text to be displayed as the label. If empty, no label will be created.
 * @param {string} errorMessage - Text to be displayed as error in case one occurs.
 * @param {boolean} disabled - Indicates whether the input is disabled.
 * @param {boolean} required - Indicates whether the field is required or not.
 * @param {string} classNames - Additional CSS class name(s) to be applied to the component.
 * @param {boolean} blockPastDates - Determines if past dates should be blocked in the calendar.
 * @param {props} props - Additional props for the react-date-picker library component
 * @returns {React.FunctionComponent} A React component rendering the custom date picker input.
 */

const RangeDatePicker: React.FC<RangeDatePickerProps> = forwardRef<
  HTMLDivElement,
  RangeDatePickerProps
>(
  (
    {
      id,
      onChange,
      label,
      errorMessage,
      className,
      required = DEFAULT_REQUIRED_VALUE,
      blockPastDates = DEFAULT_BLOCK_PAST_DATES,
      disabled = DEFAULT_DISABLED_VALUE,
      selectsRange = DEFAULT_SELECTS_RANGE_VALUE,
      defaultValue = [null, null],
      ...props
    },
    ref
  ) => {
    const [startDate, setStartDate] = useState<RangePickerDate>(
      defaultValue[0]
    );
    const [endDate, setEndDate] = useState<RangePickerDate>(defaultValue[1]);
    const [isOpen, setIsOpen] = useState(false);
    const breakpoint = useBreakpoint().currentBreakpoint;
    const datePickerRef = useRef<HTMLDivElement>(null);
    const { isVisible, setIsVisible } = useHandleExitAnimation(
      'shrinkTopToBottomDatePicker',
      () => {
        setIsOpen(false);
      },
      datePickerRef.current as HTMLElement
    );
    /**
     * Handle date selection.
     * @param {Array<Date | null>} dates - Array of selected start and end dates.
     */
    const onRangeDateChange = (dates: [Date, Date]): void => {
      const [start, end] = dates;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      if (end !== null && end >= currentDate) {
        setEndDate(end);
      } else {
        setEndDate(null);
      }

      setStartDate(start);
      setIsVisible(true);
      setIsOpen(true);
      onChange?.([start, end]);
    };

    /**
     * Updates the selected start date and closes the date picker.
     *
     * @param {Date} date - The new start date to set.
     * @returns {void}
     */

    const onDateChange = (date: Date): void => {
      setStartDate(date);
      setIsVisible(false);
      onChange?.(date);
    };

    /**
     * Handles date changes based on whether the component allows selecting a range of dates or a single date.
     *
     * If `selectsRange` is `true`, it calls `onRangeDateChange` with an array of two dates [startDate, endDate].
     * If `selectsRange` is `false`, it calls `onDateChange` with a single date.
     *
     * @param {Date | [Date | null, Date | null]} dates - The selected date(s) or date range.
     * @returns {void}
     */

    const handleChange = selectsRange
      ? (onRangeDateChange as (dates: [Date | null, Date | null]) => void)
      : (onDateChange as (date: Date) => void);

    /**
     * Event listener to handle Escape key press and close the date picker.
     * @param {KeyboardEvent} event - The keyboard event.
     */
    useEffect(() => {
      const handleEscape = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
          setIsVisible(false);
        }
      };

      if (isOpen) {
        document.addEventListener('keydown', handleEscape);
      }

      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }, [isOpen]);

    /**
     * Determine how many months should be shown depending to the user screen size
     * @returns {number} - '2' is the screen size is a small desktop, `1` otherwise
     */
    const monthsShown = (breakpoint: string): number => {
      return breakpoint === BREAKPOINT.SMALL_DESKTOP ||
        breakpoint === BREAKPOINT.MEDIUM_DESKTOP ||
        breakpoint === BREAKPOINT.LARGE_DESKTOP
        ? 2
        : 1;
    };

    return (
      <div
        ref={datePickerRef}
        className={cx(
          'date-picker',
          { 'date-picker--closing': !isVisible },
          className
        )}>
        {label !== null && (
          <label
            className="date-picker__label"
            htmlFor={id}>
            {
              <Text
                as={TITLE_TAG.H2}
                variant={TYPEKIT.D5}>
                {label}
                {required && (
                  <span
                    className="required-mark"
                    aria-label={REQUIRED_MARK_ARIA_LABEL}>
                    {REQUIRED_MARK}
                  </span>
                )}
              </Text>
            }
          </label>
        )}
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          monthsShown={selectsRange ? monthsShown(breakpoint) : SINGLE_MONTH}
          customInput={createElement(DatePickerInput, {
            onClick: props.onClick,
            selectsRange,
            disabled,
            startDate,
            endDate,
          })}
          selectsRange={selectsRange}
          showPopperArrow={false}
          onInputClick={() => {
            setIsVisible(true);
            setIsOpen(true);
          }}
          onClickOutside={() => {
            setIsVisible(false);
          }}
          disabled={disabled}
          minDate={blockPastDates ? new Date() : undefined}
          {...props}
        />
        {Boolean(errorMessage) && <ErrorMessage error={errorMessage} />}
      </div>
    );
  }
);

RangeDatePicker.displayName = 'RangeDatePicker';
export default RangeDatePicker;
