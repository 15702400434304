import React, { useEffect, useRef, useState } from 'react';
import { type CategoryGridProps } from './CategoryGrid.types';
import Chip from 'components/molecules/Chip';
import ButtonLink from 'components/molecules/ButtonLink';
import plusIcon from 'assets/svgs/plus-icon.svg';
import styles from './categoryGrid.module.scss';
import {
  CHIP_VARIANT,
  ENTER_KEY,
  ESCAPE_KEY,
  KEYDOWN_EVENT_LISTENER,
} from 'utils/constants';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';
import cx from 'classnames';
import Modal from '../Modal';
import {
  CHIP_INPUT_LABEL,
  CHIP_INPUT_PLACEHOLDER_TEXT,
  CHIP_LABEL,
  CONFIRMATION_MODAL_ACCEPT_TEXT,
  CONFIRMATION_MODAL_ADD_BODY,
  CONFIRMATION_MODAL_ADD_QUESTION,
  CONFIRMATION_MODAL_DECLINE_TEXT,
  CONFIRMATION_MODAL_DESCRIPTION,
  CONFIRMATION_MODAL_LABEL,
  CONFIRMATION_MODAL_TITLE,
} from './categoryGrid.constants';

/**
 * A grid of chips that displays all the categories in the system. Allows to delete and create new categories with an input chip.
 *
 *
 * @param {Category[]} categories - Array of categories to display in the Category Grid
 * @param deleteFunction - Function to execute on click for each category.
 * @param addFunction - Function to execute once the input chip loses focus.
 */

const CategoryGrid: React.FC<CategoryGridProps> = ({
  categories,
  deleteFunction,
  addFunction,
}) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newCategory, setNewCategory] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const { isVisible, setIsVisible } = useHandleExitAnimation(
    styles.shrinkCategoryInput,
    () => {
      setShowInput(false);
      setIsVisible(true);
    },
    inputContainerRef.current as HTMLElement
  );

  useEffect(() => {
    const handleEscapePress = (e: KeyboardEvent): void => {
      if (e.key === ESCAPE_KEY && !showModal) {
        setIsVisible(false);
      } else if (e.key === ENTER_KEY && inputRef.current !== null) {
        inputRef.current.blur();
      }
    };
    if (showInput) {
      document.addEventListener(KEYDOWN_EVENT_LISTENER, handleEscapePress);
    } else {
      document.removeEventListener(KEYDOWN_EVENT_LISTENER, handleEscapePress);
    }
    return () => {
      document.removeEventListener(KEYDOWN_EVENT_LISTENER, handleEscapePress);
    };
  }, [setIsVisible, showInput, showModal]);

  useEffect(() => {
    if (showInput) {
      if (inputRef.current !== null) inputRef.current.focus();
    }
  }, [showInput]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    if (newValue === '') {
      setIsVisible(false);
    } else {
      setNewCategory(newValue);
      setShowModal(true);
    }
  };

  return (
    <>
      <div className={styles.container}>
        {categories.map((category) => (
          <Chip
            size={CHIP_VARIANT.LARGE}
            key={category.id}
            ariaLabel={CHIP_LABEL}
            text={category.name}
            onClick={() => {
              deleteFunction(category);
            }}
          />
        ))}
        {showInput && (
          <div
            ref={inputContainerRef}
            className={cx(styles.input__container, {
              [styles['input__container--closing']]: !isVisible,
            })}>
            <Chip
              ariaLabel={CHIP_INPUT_LABEL}
              size={CHIP_VARIANT.LARGE}
              placeholder={CHIP_INPUT_PLACEHOLDER_TEXT}
              onBlur={handleBlur}
              ref={inputRef}
              className={styles.input}
            />
          </div>
        )}

        <ButtonLink
          as="button"
          variant="secondary"
          size="small"
          icon={plusIcon}
          onClick={() => {
            setIsVisible(true);
            setShowInput(true);
          }}
          className={styles.button}></ButtonLink>
      </div>
      <Modal
        isOpen={showModal}
        acceptButtonText={CONFIRMATION_MODAL_ACCEPT_TEXT}
        cancelButtonText={CONFIRMATION_MODAL_DECLINE_TEXT}
        hide={() => {
          setShowModal(false);
        }}
        action={() => {
          setShowModal(false);
          setIsVisible(false);
          addFunction(newCategory, newCategory);
        }}
        ariaLabelledBy={CONFIRMATION_MODAL_LABEL}
        ariaDescribedBy={CONFIRMATION_MODAL_DESCRIPTION}
        title={CONFIRMATION_MODAL_TITLE}
        elementRef={inputRef.current as HTMLElement}>
        {`${CONFIRMATION_MODAL_ADD_BODY} ${newCategory}. ${CONFIRMATION_MODAL_ADD_QUESTION}`}
      </Modal>
    </>
  );
};

export default CategoryGrid;
