import cx from 'classnames';
import React from 'react';
import { type TableProps } from './Table.types';
import styles from './table.module.scss';

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  type FilterFn,
  type ColumnDef,
  getSortedRowModel,
  filterFns,
} from '@tanstack/react-table';

import { type RankingInfo } from '@tanstack/match-sorter-utils';
import TableCell from 'components/atoms/TableCell';
import TableHeader from 'components/atoms/TableHeader';
import { fuzzyFilter, getCellHeader, getCellModifier } from 'utils/utilities';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

/**
 * A flexible generic table component for displaying tabular data.
 *
 * @template TData - The type of data for the table.
 * @param {TData[]} props.data - An array of data to display as rows in the table.
 * @param {ColumnDef<TData, any>[]} - An array containing the definition of each table column.
 * @param {string} [props.className] - Custom class name for the table component.
 * @param {string} [props.globalFilter] - The global filter value applied to the table.
 * @param {React.Dispatch<React.SetStateAction<string>>} [props.setGlobalFilter] - A function to set the global filter value.
 * @param {React.Dispatch<React.SetStateAction<ColumnFiltersState>>} [props.setColumnFilter] - A function to set the column filter value.
 * @param {React.Dispatch<React.SetStateAction<SortingState>>} [props.setSorting] - A function to set the sorting filter value.
 * @param {string} [props.globalFilter] - Global filter value for the table.
 * @param {ColumnFiltersState} [props.columnFilters] - Filter values for the table.
 * @param {SortingState} [props.sorting] - Sorting values for the table.
 * @returns {JSX.Element} - A JSX element representing the Table component.
 */

const Table = <TData extends object>({
  data,
  columns,
  className,
  ariaDescribedBy,
  globalFilter,
  columnFilters,
  columnVisibility,
  sorting,
  setGlobalFilter,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
}: TableProps<TData>): JSX.Element => {
  const tableData = React.useMemo<TData[]>(() => [...data], [data]);
  const tableColumns = React.useMemo<Array<ColumnDef<TData, string>>>(
    () => columns,
    [columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,

    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
      columnFilters,
      columnVisibility,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: filterFns.includesString,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={styles.table__wrapper}>
      <table
        aria-describedby={ariaDescribedBy}
        className={cx(className, styles.table)}>
        <thead className={styles.table__header}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              className={cx(
                styles['table__header-row'],
                styles[
                  `table__header-row--${getCellModifier(
                    headerGroup.headers[0].id,
                    headerGroup.headers[0].column.columnDef.header as string
                  )}`
                ]
              )}
              key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader
                  key={header.id}
                  header={header}
                  type={getCellModifier(
                    header.id,
                    header.column.columnDef.header as string
                  )}
                />
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              className={styles.table__row}
              key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  type={getCellHeader(cell)}
                  cell={cell}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

/**
 * The display name for the Table component.
 * @type {string}
 */

Table.displayName = 'UserTable';

export default Table;
