import React, { useMemo } from 'react';
import { type PaginationProps } from './pagination.types';
import cx from 'classnames';
import Icon from 'components/atoms/Icon';
import ButtonLink from 'components/molecules/ButtonLink';
import Text from 'components/atoms/Text';
import chevronLeft from 'assets/svgs/chevron-left.svg';
import chevronRight from 'assets/svgs/chevron-right.svg';
import styles from './pagination.module.scss';
import { COLORS, TYPEKIT } from 'utils/constants';
import {
  PREV_PAGE,
  NEXT_PAGE,
  NAVIGATION_INSTRUCTION,
  ELLIPSIS,
  DEFAULT_MAX_PAGES_VISIBLE,
  MINIMUM_RANGE_START,
  MINIMUM_RANGE_END_OFFSET,
  FIRST_PAGE,
  AS_SPAN,
  AS_BUTTON,
} from './Pagination.constants';

const calculateRangeStart = (
  currentPage: number,
  halfPageVisible: number,
  totalPages: number,
  maxPagesVisible: number
): number => {
  return Math.max(
    MINIMUM_RANGE_START,
    Math.min(
      currentPage - halfPageVisible,
      totalPages - maxPagesVisible + halfPageVisible
    )
  );
};

const calculateRangeEnd = (
  currentPage: number,
  halfPageVisible: number,
  totalPages: number,
  maxPagesVisible: number
): number => {
  return Math.min(
    totalPages - MINIMUM_RANGE_END_OFFSET,
    Math.max(
      currentPage + halfPageVisible,
      maxPagesVisible - halfPageVisible + MINIMUM_RANGE_END_OFFSET
    )
  );
};

const getPageNumbers = (
  totalPages: number,
  currentPage: number,
  maxPagesVisible: number = DEFAULT_MAX_PAGES_VISIBLE
): Array<number | string> => {
  const halfPageVisible = Math.floor(maxPagesVisible / 2);

  const rangeStart = calculateRangeStart(
    currentPage,
    halfPageVisible,
    totalPages,
    maxPagesVisible
  );
  const rangeEnd = calculateRangeEnd(
    currentPage,
    halfPageVisible,
    totalPages,
    maxPagesVisible
  );

  const pages: Array<number | string> = [FIRST_PAGE];

  if (rangeStart > FIRST_PAGE + 1) pages.push(ELLIPSIS);

  for (let i = rangeStart; i <= rangeEnd; i++) {
    pages.push(i);
  }

  if (rangeEnd < totalPages - MINIMUM_RANGE_END_OFFSET) pages.push(ELLIPSIS);

  if (totalPages > FIRST_PAGE) pages.push(totalPages);

  return pages;
};

export const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const pageNumbers = useMemo(
    () => getPageNumbers(totalPages, currentPage),
    [totalPages, currentPage]
  );

  const nextPage = (): void => {
    if (currentPage !== totalPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = (): void => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const showRightChevron = currentPage !== totalPages;
  const showLeftChevron = currentPage !== 1;

  return (
    <div className={styles['pagination-container']}>
      <nav>
        <ul className={styles.pagination}>
          <li className={styles['pagination__chevron-container']}>
            {showLeftChevron && (
              <Icon
                onClick={prevPage}
                aria-label={PREV_PAGE}
                src={chevronLeft}
                color={COLORS.INTERACTIVE_BLACK}
                className={cx(
                  styles['pagination__chevron-icon'],
                  styles['pagination__chevron-icon--left-side']
                )}
              />
            )}
          </li>
          {pageNumbers.map((pgNumber, index) =>
            pgNumber === ELLIPSIS ? (
              <li key={pgNumber + index}>
                <span className={styles.pagination__ellipsis}>{ELLIPSIS}</span>
              </li>
            ) : (
              <li key={pgNumber}>
                <ButtonLink
                  as={AS_BUTTON}
                  ariaLabel={NAVIGATION_INSTRUCTION + pgNumber.toString()}
                  className={cx(styles['pagination__page-number'], {
                    [styles['pagination__page-number--selected']]:
                      pgNumber === currentPage,
                  })}
                  onClick={() => {
                    setCurrentPage(pgNumber as number);
                  }}>
                  <Text
                    className={styles['pagination__number-text']}
                    as={AS_SPAN}
                    variant={TYPEKIT.P2}>
                    {pgNumber}
                  </Text>
                </ButtonLink>
              </li>
            )
          )}
          <li className={styles['pagination__chevron-container']}>
            {showRightChevron && (
              <Icon
                onClick={nextPage}
                aria-label={NEXT_PAGE}
                src={chevronRight}
                color={COLORS.INTERACTIVE_BLACK}
                className={cx(
                  styles['pagination__chevron-icon'],
                  styles['pagination__chevron-icon--right-side']
                )}
              />
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
