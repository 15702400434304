import telescope_icon from 'assets/svgs/telescope-icon.svg';
import sadface_icon from 'assets/svgs/sadface-icon.svg';
import lock_icon from 'assets/svgs/lock-icon.svg';

const NOT_FOUND_TITLE = 'We couldn’t find this page';
const SERVER_ERROR_TITLE = 'It looks like something went wrong';
const UNAUTHORIZED_TITLE = 'Permission Needed';

const NOT_FOUND_DESCRIPTION =
  'We can’t seem to find the page you’re looking for. Try going back to the previous page or return to the dashboard.';
const SERVER_ERROR_DESCRIPTION =
  'Please try again in a few minutes or refresh the page.';
const UNAUTHORIZED_DESCRIPTION =
  "This page is controlled with elevated permissions. Please contact your manager if this doesn't seem right.";

export const errorTypeMap = {
  'not-found': {
    icon: telescope_icon,
    title: NOT_FOUND_TITLE,
    description: NOT_FOUND_DESCRIPTION,
    iconSize: 'default',
    descriptionSize: 'default',
  },
  'server-error': {
    icon: sadface_icon,
    title: SERVER_ERROR_TITLE,
    description: SERVER_ERROR_DESCRIPTION,
    iconSize: 'small',
    descriptionSize: 'default',
  },
  unauthorized: {
    icon: lock_icon,
    title: UNAUTHORIZED_TITLE,
    description: UNAUTHORIZED_DESCRIPTION,
    iconSize: 'small',
    descriptionSize: 'small',
  },
};

export const BUTTON_DEFAFULT_TITLE = 'Go to Homepage';
export const BUTTON_DEFAULT_LINK = '/';
