import {
  DELETE_ITEM_IMAGES_URL,
  DELETE_ITEM_IMAGE_URL,
  FILE_MANAGER_DOMAIN,
  FIND_FILE_BY_KEY_URL,
  UPLOAD_ITEM_IMAGE_URL,
  URL_OPERATOR,
  URL_VARIABLE_OPERATOR,
} from 'utils/constants';
import { getAuthorizationHeader } from 'utils/utilities';

const fileManager = {
  getKeyFromURL: (url: string): string => {
    const key = url
      .split(FILE_MANAGER_DOMAIN)
      .pop()
      ?.split(URL_VARIABLE_OPERATOR)[0];
    return key ?? '';
  },

  /**
   * Returns the key from a url.
   *
   * @param url - URL of the image that contains the ID
   * @example
   * // returns dev/campaigns/23bs05a9-23b7-4938-9998-re8f8d458rr0.jpg
   * getIdFromURL(url: https://sj-web-interns.s3.amazonaws.com/dev/campaigns/23bs05a9-64b7-4938-9998-re8f8d458a40.jpg?X-...)
   */
  getIdFromURL: (url: string): string => {
    const key = url
      .split(FILE_MANAGER_DOMAIN)
      .pop()
      ?.split(URL_OPERATOR)
      .pop()
      ?.split(URL_VARIABLE_OPERATOR)[0];
    return key ?? '';
  },

  saveImage: async (
    file: File,
    endpoint: string = UPLOAD_ITEM_IMAGE_URL,
    serverURI: string | undefined = process.env.REACT_APP_SERVER_URI
  ): Promise<string> => {
    const data = new FormData();
    data.append('file', file);
    const res = await fetch(`${serverURI}${endpoint}`, {
      method: 'POST',
      body: data,
      headers: {
        authorization: getAuthorizationHeader(),
      },
    });
    return await res.text();
  },

  deleteImage: async (
    key: string,
    serverURI: string | undefined = process.env.REACT_APP_SERVER_URI,
    endpoint: string = DELETE_ITEM_IMAGE_URL
  ): Promise<string> => {
    const res = await fetch(`${serverURI}${endpoint}?key=${key}`, {
      method: 'DELETE',
      headers: {
        authorization: getAuthorizationHeader(),
      },
    });
    return await res.text();
  },

  deleteImages: async (
    keys: string[],
    serverURI: string | undefined = process.env.REACT_APP_SERVER_URI,
    endpoint: string = DELETE_ITEM_IMAGES_URL
  ): Promise<string> => {
    const data = new FormData();
    keys.forEach((key) => {
      data.append('keys', key);
    });
    const res = await fetch(`${serverURI}${endpoint}`, {
      method: 'DELETE',
      body: data,
      headers: {
        authorization: getAuthorizationHeader(),
      },
    });
    return await res.text();
  },

  findFileByKey: async (
    key: string,
    serverURI: string | undefined = process.env.REACT_APP_SERVER_URI,
    endpoint: string = FIND_FILE_BY_KEY_URL
  ): Promise<any> => {
    const res = await fetch(`${serverURI}${endpoint}?key=${key}`, {
      method: 'GET',
      headers: {
        authorization: getAuthorizationHeader(),
      },
    });
    return await res.text();
  },
};

const fileManagerClient = Object.freeze(fileManager);

export default fileManagerClient;
