export const NAME_FIELD = 'name';
export const CAMPAIGN_NAME_LABEL = 'Campaign name';
export const CAMPAIGN_NAME_PLACEHOLDER = 'Enter name';
export const CAMPAIGN_NAME_ERROR = 'Please enter the campaign name';

export const GOAL_FIELD = 'goal';
export const CAMPAIGN_GOAL_LABEL = 'Fundraising goal';
export const CAMPAIGN_GOAL_PLACEHOLDER = 'Enter goal';
export const CAMPAIGN_GOAL_ERROR = 'Please enter the fundraising goal';

export const RAISED_AMOUNT_FIELD = 'raisedAmount';
export const CAMPAIGN_AMOUNT_LABEL = 'Current donation amount';
export const CAMPAIGN_AMOUNT_PLACEHOLDER = '0.00';
export const CAMPAIGN_AMOUNT_ERROR = 'Please enter the current donation amount';

export const DATES_FIELD = 'dates';
export const CAMPAIGN_DATES_LABEL = 'Campaign start and end date';
export const CAMPAIGN_DATES_ERROR = 'Please select a start and end date';

export const LOCATIONS_FIELD = 'locations';
export const CAMPAIGN_LOCATIONS_LABEL = 'Location';
export const CAMPAIGN_LOCATIONS_ARIA_LABEL = 'Location checkboxes';
export const CAMPAIGN_LOCATIONS_ERROR = 'Please select a location';
export const ALL_OFFICES = 'All Offices';

export const IMAGE_FIELD = 'image';
export const CAMPAIGN_IMAGE_LABEL = 'Campaign Photo';
export const CAMPAIGN_IMAGE_ERROR = 'Please upload a photo for the campaign';
export const MULTIPLE_FILES = false;
export const MAX_NUMBER_FILES = 1;
export const CAMPAIGN_CREATION_ERROR = 'Could not add campaign';
export const LOADING_IMAGE_ERROR = 'Error loading image';

export const SHOULD_FOCUS = false;
export const EDIT_FALSE = false;

export const TOAST_ADDED = 'was added.';
export const TOAST_UPDATED_SUCCESS = 'Campaign updated successfully';
export const TOAST_UPDATED_ERROR = 'Error while updating campaign';

export const DEFAULT_IS_ACTIVE = true;
export const DEFAULT_IS_DELETED = false;
export const DEFAULT_INITIAL_DONATIONS = 0;
