export const SELECTED_CURRENCY_KEY = 'selectedCurrency';
export const CONVERSION_RATES_KEY = 'conversionRates';
export const CURRENCY_DEFAULT_NAME = 'United States dollar';
export const CURRENCY_DEFAULT_CODE = 'USD';
export const CURRENCY_DEFAULT_SYMBOL = '$';
export const DEFAULT_CONVERSION_RATE = 1;
export const RATES_REQUEST_ERROR = 'An error occured while fetching rates';
export const RATES_UPDATE_ERROR = 'An error occured while updating rates';
export const NOON_HOUR = 12;
export const START_OF_HOUR = 0;
