import { flexRender } from '@tanstack/react-table';
import cx from 'classnames';
import { type TableCellProps } from './TableCell.types';
import styles from './tableCell.module.scss';

/**
 * Renders a table cell component with flexible content based on the column definition.
 *
 * @template TData - The type of data for the table.
 * @param {TableCellProps<TData>} props - The props for the TableCell component.
 * @param {string} [props.className] - Custom css class name for the table cell.
 * @param {Cell<TData, unknown>} [props.cell] - The cell data for the table cell.
 * @param {string} [props.type] - The type of the table cell.
 * @returns {JSX.Element} - A JSX element representing the TableCell component.
 */

const TableCell = <TData,>({
  className,
  cell,
  type,
}: TableCellProps<TData>): JSX.Element => {
  return (
    <td
      className={cx(
        className,
        styles.table__cell,
        styles[`table__cell--${type}`]
      )}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

export default TableCell;
