import { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import styles from './auctionItemDetailsPage.module.scss';
import PageHeader from 'components/molecules/PageHeader';
import PageContainer from 'components/atoms/PageContainer';
import AuctionItemDetailsCard from 'components/organisms/AuctionItemDetailsCard';
import Text from 'components/atoms/Text';
import dayjs from 'dayjs';
import PlaceBidForm from 'components/organisms/PlaceBidForm';
import BiddingTable from 'components/organisms/BiddingTable';
import {
  AUTH_HEADER_BEARER,
  DESTINATION_ADD,
  DESTINATION_PREFIX,
  INFO_MESSAGE_TYPES,
  DEFAULT_AUTH_TOKEN_KEY,
  NOT_FOUND_ERROR_PAGE_ROUTE,
  SLASH_SYMBOL,
  SOCKET_BROKER_URL,
  SPACE_CHAR,
  TITLE_TAG,
  TOPIC_PREFIX,
  TYPEKIT,
  POLICY,
  TIME_SECOND,
  TOPIC_DELETE,
} from 'utils/constants';
import WinnerBanner from 'components/molecules/WinnerBanner';
import InfoMessage from 'components/molecules/InfoMessage';
import { useStomp } from 'hooks/useStomp';
import { type IMessage, StompHeaders } from '@stomp/stompjs';
import { useQuery } from '@apollo/client';
import { GET_ITEM_BY_ID } from 'graphql/item/queries.gql';
import { useNavigate, useParams } from 'react-router-dom';
import {
  type User,
  type Bid,
  type Item,
} from 'graphql/generated-types/graphql';
import { useAuth } from 'context/Authentication/AuthContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { GET_BIDS_BY_ITEM_ID, GET_TOP_BID } from 'graphql/bid/queries.gql';
import { type BidTable } from 'components/organisms/BiddingTable/BiddingTable.columns';
import {
  BIDDING_HISTORY_TITLE,
  ITEM_DELETED_MESSAGE,
  PAGE_TOP_CONTAINER_SPACING,
  RESET_COUNTDOWN_DATE,
  TOP_BID_INDEX,
  TOP_BID_INITIAL_VALUE,
} from './AuctionItemDetailsPage.constants';
import useBreakpoint from 'hooks/useBreakpoint';
import { useCountdownFinished } from 'hooks/useCountdownFinished';
import cx from 'classnames';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';
import ContentLoader from 'components/atoms/ContentLoader';

/**
 * Page to create and delete bids in real time to win the respective Item.
 */
const AuctionItemDetailsPage = (): JSX.Element => {
  const [token] = useLocalStorage(DEFAULT_AUTH_TOKEN_KEY, null);
  const [bids, setBids] = useState<Bid[]>([]);
  const [item, setItem] = useState<Item>();
  const { itemId } = useParams<string>();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();
  const isFinished = useCountdownFinished(item?.campaign.endDate as string);
  const [topBid, setTopBid] = useState<Bid>();
  const { selectedCurrency } = useContext(CurrencyContext);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { loading: loadingItem, data: dataItem } = useQuery(GET_ITEM_BY_ID, {
    variables: {
      itemId,
    },
    onCompleted: (data) => {
      setItem(data.getItemById);
    },
    onError: () => {
      navigate(NOT_FOUND_ERROR_PAGE_ROUTE, {});
    },
  });

  const { loading: loadingTopBid, refetch: refetchTopBid } = useQuery(
    GET_TOP_BID,
    {
      variables: {
        itemId,
      },
      onCompleted: (data) => {
        setTopBid(data.getTopBidByItemId);
      },
      onError: () => {}, // Not neccessary to take some action, but error must be catched
      fetchPolicy: POLICY.NETWORK_ONLY,
    }
  );

  const { loading: loadingBids } = useQuery(GET_BIDS_BY_ITEM_ID, {
    variables: {
      itemId,
    },
    onCompleted: (data) => {
      setBids(data.getBidsByItemId);
    },
    onError: () => {
      navigate(NOT_FOUND_ERROR_PAGE_ROUTE, {});
    },
  });

  const { client, connect, disconnect, subscribe, unsubscribeAll, publish } =
    useStomp({
      brokerURL: SOCKET_BROKER_URL,
    });

  const onCreatedBid = useCallback(
    (response: IMessage): void => {
      const newBid = JSON.parse(response.body).data;
      if (newBid === null) {
        return;
      }
      setBids((prevBids: Bid[]) => {
        if (prevBids.length <= 0) return [newBid];
        return [newBid, ...prevBids];
      });
    },
    [setBids]
  );

  const onFlagDeleteBid = useCallback(
    (response: IMessage): void => {
      const deletedBid = JSON.parse(response.body).data;
      setBids((currentBids) =>
        currentBids.filter((bid) => bid.id !== deletedBid.id)
      );
    },
    [setBids]
  );

  useEffect(() => {
    const headers = Object.assign(new StompHeaders(), {
      Authorization: `${AUTH_HEADER_BEARER} ${token}`,
    });
    connect({
      onConnect: () => {
        subscribe(TOPIC_PREFIX + itemId, onCreatedBid);
        subscribe(TOPIC_PREFIX + itemId + TOPIC_DELETE, onFlagDeleteBid);
      },
      connectHeaders: headers,
      onDisconnect: () => {
        unsubscribeAll();
      },
    });
  }, [
    connect,
    subscribe,
    disconnect,
    unsubscribeAll,
    itemId,
    token,
    onCreatedBid,
    onFlagDeleteBid,
  ]);

  const createBid = useCallback(
    (bidAmount: number): void => {
      publish(
        DESTINATION_PREFIX + itemId + DESTINATION_ADD,
        JSON.stringify({
          amount: bidAmount,
          item: itemId,
          user: currentUser?.id,
          updatedBy: currentUser?.id,
        })
      );
    },
    [currentUser?.id, itemId, publish]
  );

  const deleteBid = useCallback(
    (bidId: string): void => {
      publish(
        DESTINATION_PREFIX +
          itemId +
          TOPIC_DELETE +
          SLASH_SYMBOL +
          bidId +
          SLASH_SYMBOL +
          currentUser?.id,
        JSON.stringify({
          bidId,
        })
      );
    },
    [publish, itemId, currentUser?.id]
  );

  const topBidAmount: number = useMemo<number>(() => {
    const bid = bids[TOP_BID_INDEX];
    setSubmitting(false);
    return bid !== undefined ? (bid.amount as number) : TOP_BID_INITIAL_VALUE;
  }, [bids]);

  const isFinishedAuction = useMemo<boolean>(() => {
    return (
      !(item?.campaign.isActive ?? false) ||
      (bids[TOP_BID_INDEX] !== undefined && isFinished)
    );
  }, [item, bids, isFinished]);

  useEffect(() => {
    void refetchTopBid({ itemId })
      .then((response) => {
        setTopBid(response.data.getTopBidByItemId);
      })
      .catch(() => {}); // Not neccessary to take some action, but error must be catched
  }, [isFinished]);

  return (
    <>
      {item !== undefined && !loadingItem && !loadingBids && !loadingTopBid ? (
        <>
          <PageHeader
            campaignName={item.campaign.name}
            itemName={item.name}
          />
          <PageContainer
            className={styles['auction-item-details__page-container']}
            spacingTop={PAGE_TOP_CONTAINER_SPACING}
            wide={isDesktop}>
            {item?.isDeleted && (
              <div className={styles['auction-item-details__info-message']}>
                <InfoMessage
                  message={ITEM_DELETED_MESSAGE}
                  variant={INFO_MESSAGE_TYPES.INFO}
                />
              </div>
            )}
            <section className={styles['auction-item-details__content']}>
              <div className={styles['auction-item-details__card']}>
                <AuctionItemDetailsCard
                  name={item?.name}
                  description={item?.description}
                  images={item?.images}
                  locations={item?.offices.map((office) => office.name)}
                  donor={
                    item?.user.firstName + SPACE_CHAR + item?.user.lastName
                  }
                  donorPicture={item?.user.imageUrl as string}
                  targetDate={
                    !item.isDeleted
                      ? dayjs(item?.campaign.endDate).toString()
                      : RESET_COUNTDOWN_DATE
                  }
                />
              </div>
              <div className={styles['auction-item-details__bidding']}>
                {!item?.isDeleted && item.isApproved && (
                  <div className={styles['auction-item-details__bidding-info']}>
                    {isFinishedAuction ? (
                      <WinnerBanner
                        winnerName={
                          topBid?.user?.firstName +
                          SPACE_CHAR +
                          topBid?.user?.lastName
                        }
                        winningBid={topBid?.amount as number}
                        winnerPicture={topBid?.user?.imageUrl as string}
                      />
                    ) : (
                      <PlaceBidForm
                        currency={`${selectedCurrency.code} `}
                        expirationDate={item?.campaign.endDate}
                        itemID={dataItem.getItemById?.id}
                        userID={currentUser?.id as string}
                        currentBid={
                          bids.length !== 0 ? topBidAmount : item.startPrice
                        }
                        isFirstBid={bids.length === 0}
                        minimumBiddingAmount={
                          bids.length !== 0 ? topBidAmount + 1 : item.startPrice
                        }
                        createBid={(bidAmount: number) => {
                          setSubmitting(true);
                          createBid(bidAmount);
                        }}
                        submitting={submitting}
                      />
                    )}
                  </div>
                )}
                <div
                  className={cx(
                    styles['auction-item-details__bidding-history'],
                    {
                      [styles[
                        'auction-item-details__bidding-history--winner-spacing'
                      ]]: isFinishedAuction,
                      [styles[
                        'auction-item-details__bidding-history--active-bid-spacing'
                      ]]: !isFinishedAuction,
                      [styles[
                        'auction-item-details__bidding-history--deleted-spacing'
                      ]]: item.isDeleted,
                    }
                  )}>
                  <Text
                    as={TITLE_TAG.H3}
                    variant={TYPEKIT.D4}>
                    {BIDDING_HISTORY_TITLE}
                  </Text>
                  <BiddingTable
                    itemId={item?.id}
                    client={client}
                    data={bids.map((bid): BidTable => {
                      const user = bid.user as User;
                      const now = dayjs();
                      const createAtTime = dayjs.utc(bid.createdAt).local();
                      return {
                        BidId: bid.id,
                        UserId: user.id,
                        FirstName: user.firstName,
                        LastName: user.lastName,
                        Bid: bid.amount?.toString() as string,
                        ElapsedTime:
                          createAtTime <= now
                            ? createAtTime.toString()
                            : now.subtract(1, TIME_SECOND).toString(),
                      };
                    })}
                    deleteFunction={deleteBid}
                    isActive={
                      !item?.isDeleted &&
                      item?.campaign.isActive &&
                      item?.isApproved &&
                      !isFinished
                    }
                  />
                </div>
              </div>
            </section>
          </PageContainer>
        </>
      ) : (
        <ContentLoader />
      )}
    </>
  );
};
export default AuctionItemDetailsPage;
