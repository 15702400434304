import React from 'react';
import Text from 'components/atoms/Text';
import Breadcrumbs from '../Breadcrumbs';
import { TYPEKIT } from 'utils/constants';
import { HEADER_TITLE } from './PageHeader.contants';
import { type PageHeaderProps } from './PageHeader.types';
import styles from './pageHeader.module.scss';

const PageHeader: React.FC<PageHeaderProps> = ({ campaignName, itemName }) => {
  return (
    <>
      <header className={styles.header}>
        <Text variant={TYPEKIT.D3}>{HEADER_TITLE}</Text>
      </header>
      <nav className={styles.header__nav}>
        <Breadcrumbs
          campaignName={campaignName}
          itemName={itemName}
        />
      </nav>
    </>
  );
};

export default PageHeader;
