import CampaignLandingPage from 'pages/CampaignLandingPage/CampaignLandingPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import SettingsPage from 'pages/SettingsPage';
import { Navigate, Outlet, Route, Routes as Router } from 'react-router-dom';
import {
  CAMPAIGN_DETAILS_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  ITEM_DETAILS_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  NOT_FOUND_ERROR_PAGE_ROUTE,
  SERVER_ERROR_PAGE_ROUTE,
  SETTINGS_PAGE_ROUTE,
  UNAUTHORIZED_ERROR_PAGE_ROUTE,
} from 'utils/constants';
import { mockUsers } from 'utils/mocks';
import { useQuery } from '@apollo/client';
import { VERIFY_TOKEN } from 'graphql/authentication/queries.gql';
import { isValidToken } from 'utils/utilities';
import CurrencyProvider from 'context/CurrencyContext/CurrencyContext';
import CampaignDetailsPage from 'pages/CampaignDetailsPage';
import AuctionItemDetailsPage from 'pages/AuctionItemDetailsPage';
import NotificationPageWrapper from 'components/organisms/NotificationPageWrapper';
import ContentLoader from 'components/atoms/ContentLoader';

/**
 * Component for rendering private routes after token verification.
 *
 * This component checks the user's token for validity and displays private routes if the token is valid.
 * If the token is not valid or loading, it redirects the user to the login page.
 *
 * @returns {JSX.Element} - The private routes component.
 */

const PrivateRoutes = (): JSX.Element => {
  const { data, loading } = useQuery(VERIFY_TOKEN);

  if (loading) return <ContentLoader />;

  if (data !== undefined) {
    if (isValidToken(data)) {
      return (
        <CurrencyProvider>
          <Outlet />
        </CurrencyProvider>
      );
    }
  }
  return <Navigate to={LOGIN_PAGE_ROUTE} />;
};

/**
 * Component for defining application routes.
 *
 * This component sets up the application's routes, including error pages, login page,
 * and private routes that require token validation.
 *
 * @returns {JSX.Element} - The main routes component.
 */
const Routes = (): JSX.Element => {
  return (
    <Router>
      <Route
        path={NOT_FOUND_ERROR_PAGE_ROUTE}
        element={<ErrorPage type="not-found" />}></Route>
      <Route
        path={SERVER_ERROR_PAGE_ROUTE}
        element={<ErrorPage type="server-error" />}></Route>
      <Route
        path={UNAUTHORIZED_ERROR_PAGE_ROUTE}
        element={<ErrorPage type="unauthorized" />}></Route>
      <Route
        path={LOGIN_PAGE_ROUTE}
        element={<LoginPage />}
      />
      <Route element={<PrivateRoutes />}>
        <Route element={<NotificationPageWrapper />}>
          <Route
            path={HOME_PAGE_ROUTE}
            element={<CampaignLandingPage />}></Route>
          <Route
            path={SETTINGS_PAGE_ROUTE}
            element={<SettingsPage users={mockUsers} />}></Route>
          <Route
            path={ITEM_DETAILS_PAGE_ROUTE}
            element={<AuctionItemDetailsPage />}></Route>
          <Route
            path={CAMPAIGN_DETAILS_PAGE_ROUTE}
            element={<CampaignDetailsPage />}
          />
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
