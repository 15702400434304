import React, { useContext } from 'react';
import { type BidFormData, type PlaceBidFormProps } from './PlaceBidForm.types';
import {
  TOAST_SUCCESS_MESSAGE,
  FORM_SHOULD_FOCUS,
  MINIMUM_BIDDING_AMOUNT_ERROR_MESSAGE,
  SET_ERROR_TYPE,
  INPUT_REGISTER_VALUE,
} from './PlaceBidForm.constants';
import useBreakpoint from 'hooks/useBreakpoint';
import { type SubmitHandler } from 'react-hook-form';
import { useToast } from 'hooks/useToast';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import BidFormDesktop from './BidFormDesktop/BidFormDesktop';
import BidFormMobile from './BidFormMobile/BidFormMobile';
import { useForm } from 'react-hook-form';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';

/**
 * Form used in the auction item details page for place bids and view current end date timer of the current campaign.
 *
 * @param {number} currentBid - Required. The top bid number of the current item.
 * @param {string} expirationDate - Required. It is used to start the end date timer of the campaign. If the campaing ends, the auction item will be considered as ended.
 * @param {number} minimumBiddingAmount - Required. Used to validate the minimum bidding amount when placing a bid.
 * @param {boolean} isFirstBid - Required. To set the title of the form.
 * @param {CURRENCY_TYPES(USD, CAD, CRC)} currency - Required. Type of currency (USD, CAD, CRC)
 * @param {string} itemID - Required. The id of the auction item.
 * @param {string} userID - Required. The id of the user that placed the bid.
 * @param {(bidAmount: number) void} createBid - Optional. Method to create a bid.
 */
const PlaceBidForm: React.FC<PlaceBidFormProps> = ({
  currentBid,
  expirationDate,
  minimumBiddingAmount,
  isFirstBid = true,
  currency,
  userID,
  itemID,
  createBid,
}) => {
  const { isDesktop } = useBreakpoint();
  const toast = useToast();
  const { conversionRate } = useContext(CurrencyContext);

  const showToastSuccess = (message: string): void => {
    if (toast !== undefined) toast.open(message, TOAST_TYPE.SUCCESS);
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm<BidFormData>({
    defaultValues: { amount: '' },
    shouldFocusError: FORM_SHOULD_FOCUS,
  });

  const checkMinimumAmountError = (
    currentAmount: string
  ): { isInputValid: boolean; parsedAmount: number } => {
    const sanitizedInput = currentAmount.replace(/\D/g, '');

    const parsedAmount = parseFloat(sanitizedInput);
    const isInputValid =
      sanitizedInput.trim() !== '' &&
      parsedAmount < minimumBiddingAmount * conversionRate;
    return { isInputValid, parsedAmount: parsedAmount / conversionRate };
  };

  const onSubmitAddBid: SubmitHandler<BidFormData> = async (data) => {
    const { isInputValid, parsedAmount } = checkMinimumAmountError(data.amount);
    if (isInputValid) {
      setError(INPUT_REGISTER_VALUE, {
        type: SET_ERROR_TYPE,
        message: MINIMUM_BIDDING_AMOUNT_ERROR_MESSAGE,
      });
    } else {
      data.itemID = itemID;
      data.userID = userID;
      try {
        if (createBid !== undefined) {
          createBid(parsedAmount);
        }
        reset({ amount: '' });
        showToastSuccess(TOAST_SUCCESS_MESSAGE);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {isDesktop ? (
        <BidFormDesktop
          currentBid={currentBid}
          expirationDate={expirationDate}
          minimumBiddingAmount={minimumBiddingAmount}
          isFirstBid={isFirstBid}
          currency={currency}
          onSubmit={onSubmitAddBid}
          handleSubmit={handleSubmit}
          register={register}
          setError={setError}
          errors={errors}></BidFormDesktop>
      ) : (
        <BidFormMobile
          currentBid={currentBid}
          minimumBiddingAmount={minimumBiddingAmount}
          isFirstBid={isFirstBid}
          currency={currency}
          onSubmit={onSubmitAddBid}
          handleSubmit={handleSubmit}
          register={register}
          watch={watch}
          errors={errors}
          reset={reset}></BidFormMobile>
      )}
    </>
  );
};

export default PlaceBidForm;
