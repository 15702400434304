import Text from 'components/atoms/Text';
import DonationProgress from 'components/molecules/DonationProgress/DonationProgress';
import styles from './donationSection.module.scss';
import { TYPEKIT } from 'utils/constants';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import useBreakpoint from 'hooks/useBreakpoint';
import { type DonationSectionProps } from './DonationSection.types';
import { type FC } from 'react';
import { LAST_UPDATE_TEXT } from './DonationSection.constants';
dayjs.extend(LocalizedFormat);

export const DonationSection: FC<DonationSectionProps> = ({
  raisedAmount,
  goal,
  lastUpdate,
}) => {
  const { isDesktop } = useBreakpoint();
  const updateDateString = dayjs(lastUpdate).format('ll');

  return (
    <>
      <div className={styles['donation-section__progress']}>
        <DonationProgress
          raisedAmount={raisedAmount}
          donationGoal={goal}
          typekit={isDesktop ? TYPEKIT.D2 : TYPEKIT.D4}
        />
        {isDesktop && (
          <Text
            as="h1"
            className={styles['donation-section__text']}
            variant={TYPEKIT.P3}>
            {`${LAST_UPDATE_TEXT} ${updateDateString}`}
          </Text>
        )}
      </div>
    </>
  );
};
