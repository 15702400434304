import React, {
  type MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type CurrencyToggleProps } from './CurrencyToggle.types';
import { COLORS, TYPEKIT } from 'utils/constants';
import { useOutsideClick } from 'hooks/useOutsideClick';
import cx from 'classnames';
import Icon from 'components/atoms/Icon';
import styles from './currencyToggle.module.scss';
import {
  CURRENCIES_REQUEST_ERROR,
  ICON_LABEL,
  LISTBOX_ROLE,
  OPTION_ROLE,
  TAB_INDEX_0,
  TEXT_AS_P,
} from './CurrencyToggle.constants';
import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import { CODE_DOWN, CODE_UP, CODE_ENTER, VALUE_DASH } from 'keycode-js';
import { useQuery } from '@apollo/client';
import { GET_ALL_CURRENCIES } from 'graphql/currency/queries';
import { type Currency } from 'graphql/generated-types/graphql';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';
import useToastError from 'hooks/useToastError';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';

const CurrencyToggle: React.FC<CurrencyToggleProps> = ({
  icon,
  iconColor = COLORS.INTERACTIVE_WHITE,
  classNames,
}) => {
  const showToastError = useToastError();

  const toggleRef = useRef<HTMLDivElement>(null);

  const [displayCurrencies, setDisplayCurrencies] = useState(false);
  const { selectedCurrency, setSelectedCurrency } = useContext(CurrencyContext);
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  useQuery(GET_ALL_CURRENCIES, {
    onCompleted: (dataCurrencies) => {
      setCurrencies(dataCurrencies.getAllCurrencies);
    },
    onError: () => {
      showToastError(CURRENCIES_REQUEST_ERROR);
    },
  });

  const currenciesRef = useOutsideClick((target: Node) => {
    if (toggleRef.current !== null && !toggleRef.current.contains(target)) {
      setIsVisible(false);
    }
  });

  const { isVisible, setIsVisible } = useHandleExitAnimation(
    styles.shrinkTopToBottom,
    () => {
      setDisplayCurrencies(false);
    },
    (currenciesRef as MutableRefObject<HTMLElement>).current
  );

  const showCurrencies = (): void => {
    setIsVisible(!displayCurrencies);
    if (!displayCurrencies) {
      setDisplayCurrencies(true);
    }
  };

  const selectCurrency = (currency: Currency): void => {
    setSelectedCurrency(currency);
    setIsVisible(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    switch (event.key) {
      case CODE_DOWN: {
        event.preventDefault();
        const nextSibling = (event.target as HTMLElement)
          .nextElementSibling as HTMLElement;
        nextSibling?.focus();
        break;
      }

      case CODE_UP: {
        event.preventDefault();
        const prevSibling = (event.target as HTMLElement)
          .previousElementSibling as HTMLElement;
        prevSibling?.focus();
        break;
      }

      case CODE_ENTER:
        event.preventDefault();
        (event.target as HTMLElement)?.click();
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (displayCurrencies && currenciesRef !== null) {
      (
        (currenciesRef as React.RefObject<HTMLElement>).current
          ?.firstChild as HTMLElement
      )?.focus();
    } else {
      toggleRef.current?.focus();
    }
  }, [currenciesRef, displayCurrencies]);

  return (
    <div
      className={styles.toggle}
      ref={toggleRef}>
      <Icon
        src={icon}
        color={iconColor}
        ariaLabel={ICON_LABEL}
        aria-expanded={displayCurrencies}
        className={cx(styles.toggle__icon, classNames?.icon)}
        onClick={showCurrencies}
      />
      {displayCurrencies && (
        <ul
          ref={currenciesRef}
          role={LISTBOX_ROLE}
          className={cx(styles.toggle__currencies, classNames?.currencies, {
            [styles['toggle__currencies--closing']]: !isVisible,
          })}
          onKeyDown={handleKeyDown}>
          {currencies.map((currency) => {
            return (
              <li
                tabIndex={TAB_INDEX_0}
                key={currency.id}
                value={currency.id}
                role={OPTION_ROLE}
                aria-selected={currency.code === selectedCurrency?.code}
                className={cx(styles['toggle__currency-container'], {
                  [styles['toggle__currency-container--selected']]:
                    currency.code === selectedCurrency?.code,
                })}
                onClick={() => {
                  selectCurrency(currency);
                }}>
                <div className={styles['toggle__currency-image-container']}>
                  <Image
                    className={styles['toggle__currency-image']}
                    src={require(`assets/images/${currency.code}.png`)}
                    alt=""
                  />
                </div>
                <div className={styles['toggle__currency-info']}>
                  <Text
                    className={styles['toggle__currency-name']}
                    variant={TYPEKIT.P2}
                    as={TEXT_AS_P}>
                    {currency.name}
                  </Text>
                  <Text
                    className={styles['toggle__currency-code']}
                    variant={TYPEKIT.P2}
                    as={TEXT_AS_P}>
                    {currency.code} {VALUE_DASH} {currency.symbol}
                  </Text>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CurrencyToggle;
