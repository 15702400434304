import { createContext } from 'react';
import { type TOAST_TYPE } from './Toast/toast.constants';

export interface ToastContextType {
  open: (message: string, type: TOAST_TYPE) => void;
}

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined
);
