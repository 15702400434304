export const NAME_FIELD = 'name';
export const ITEM_NAME_LABEL = 'Item name';
export const ITEM_NAME_PLACEHOLDER = 'Enter name';
export const ITEM_NAME_ERROR = 'Please enter the item name';

export const BID_FIELD = 'startPrice';
export const ITEM_BID_LABEL = 'Start bid price';
export const ITEM_BID_PLACEHOLDER = 'Enter price';
export const ITEM_BID_ERROR = 'Please enter the start bid price';

export const DESCRIPTION_FIELD = 'description';
export const ITEM_DESCRIPTION_LABEL = 'Description';
export const ITEM_DESCRIPTION_PLACEHOLDER = 'Enter Description';
export const ITEM_DESCRIPTION_ERROR = 'Please enter the description';
export const ITEM_DESCRIPTION_LENGTH_ERROR =
  'Please reduce the size of the description';
export const ITEM_DESCRIPTION_MAX_LENGTH = 500;
export const ITEM_DESCRIPTION_ROWS = 7;

export const CATEGORY_FIELD = 'category';
export const ITEM_CATEGORY_LABEL = 'Category';
export const ITEM_CATEGORY_PLACEHOLDER = 'Select a category';
export const ITEM_CATEGORY_ERROR =
  'Please select a category for your auction item';

export const LOCATIONS_FIELD = 'locations';
export const ITEM_LOCATIONS_LABEL = 'Location';
export const ITEM_LOCATIONS_ARIA_LABEL = 'Location checkboxes';
export const ITEM_LOCATIONS_ERROR = 'Please select a location';

export const IMAGE_FIELD = 'images';
export const ITEM_IMAGE_LABEL = 'Photos';
export const ITEM_IMAGE_ERROR = 'Please upload a photo for your auction item';
export const MULTIPLE_FILES = true;
export const MAX_NUMBER_FILES = 4;
export const TITLE_MAX_CHARACTER_ERROR_MESSAGE =
  'Name length should be 40 characters or less';
export const SET_ERROR_TYPE = 'manual';
export const NAME_REGISTER_VALUE = 'name';

export const SHOULD_FOCUS = false;
export const EDIT_FALSE = false;
