import React, { type ChangeEvent, useState } from 'react';
import { type SearchboxProps } from './Searchbox.types';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import exitIcon from 'assets/svgs/exit-icon.svg';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import cx from 'classnames';
import { TYPEKIT } from 'utils/constants';
import styles from './searchbox.module.scss';

const Searchbox: React.FC<SearchboxProps> = ({
  onSearch,
  disabled,
  label,
  id,
  placeholder,
  classNames,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [iconVisible, setIconVisible] = useState(true);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIconVisible(true);
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    onSearch(newSearchTerm);
  };

  const handleBlur = (): void => {
    if (searchTerm === '') {
      setIconVisible(true);
    } else {
      setIconVisible(false);
    }
  };

  const onClick = (): void => {
    setSearchTerm('');
    onSearch('');
  };

  return (
    <div className={cx(classNames?.container, styles['searchbox-container'])}>
      <div className={styles['searchbox-body']}>
        {iconVisible && (
          <Icon
            src={faSearch}
            aria-hidden="true"
            className={styles['searchbox__search-icon']}
            variant="minor"
            color="text-medium-grey"
          />
        )}
        <input
          id={id}
          className={cx(
            styles.searchbox,
            {
              [styles['non-icon-searchbox']]: !iconVisible,
            },
            classNames?.searchBox
          )}
          type="search"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleInputChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {searchTerm.length > 0 && (
          <Icon
            src={exitIcon}
            className={styles['searchbox__ex-icon']}
            variant="minor"
            color="interactive-black"
            ariaLabel="Clean input icon"
            onClick={onClick}
          />
        )}
      </div>
      {label !== null && label !== undefined && (
        <Text
          as="label"
          htmlFor={id}
          variant={TYPEKIT.D5}
          className={styles.label}>
          {label}
        </Text>
      )}
    </div>
  );
};

export default Searchbox;
