import React, {
  type FC,
  useState,
  forwardRef,
  useRef,
  useEffect,
  type MutableRefObject,
  type ChangeEvent,
} from 'react';
import { type DropdownOption, type DropdownProps } from './Dropdown.types';
import Checkbox from '../Checkbox';
import Icon from 'components/atoms/Icon';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import { useOutsideClick } from 'hooks/useOutsideClick';
import cx from 'classnames';
import ErrorMessage from '../ErrorMessage';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';
import styles from './dropdown.module.scss';
import {
  DROPDOWN_MASTER_CHECKBOX_INDEX,
  MAX_INPUT_LENGTH,
  MAX_SHORTENED_VALUE_LENGTH,
  MULTIPLE_OPTION_SEPARATOR,
  SCROLLABLE_MIN_OPTIONS,
  SHORTENED_VALUE_START_INDEX,
  SHORTENED_VALUE_SUFFIX,
} from './Dropdown.constants';

const getMappedOptions = (
  options: DropdownOption[],
  defaultOption: DropdownOption,
  masterCheckboxOption: string | undefined
): DropdownOption[] => {
  const mappedOptions = options.map((option) => ({
    ...option,
    isDisabled: false,
    isIndeterminate: false,
    isChecked:
      defaultOption.id === option.id ||
      defaultOption.name === masterCheckboxOption,
  }));

  if (masterCheckboxOption !== undefined) {
    mappedOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isChecked =
      mappedOptions.every((option) => option.isChecked);

    mappedOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isIndeterminate =
      !mappedOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isChecked &&
      mappedOptions.some((option) => option.isChecked);
  }

  return mappedOptions;
};

const Dropdown: FC<DropdownProps> = forwardRef<HTMLInputElement, DropdownProps>(
  (
    {
      label = '',
      errorMessage,
      options,
      disabled = false,
      floating = false,
      placeholder,
      defaultOption = { id: '-1', name: '' },
      className,
      multiple,
      masterCheckboxOption,
      onChange,
      onBlur,
      name,
      scrollable = false,
      filter = false,
    },
    ref
  ) => {
    const [displayOptions, setDisplayOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState([defaultOption]);
    const [inputPlaceholder, setinputPlaceholder] = useState(placeholder);
    const [inputValue, setInputValue] = useState(defaultOption.name);
    const [currentOptions, setCurrentOptions] = useState(
      multiple === true
        ? getMappedOptions(options, defaultOption, masterCheckboxOption)
        : options
    );
    const [displayedOptions, setDisplayedOptions] = useState(currentOptions);
    const masterCheckboxRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const optionsRef = useOutsideClick((target: Node) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(target)
      ) {
        setIsVisible(false);
      }
    });
    const { isVisible, setIsVisible } = useHandleExitAnimation(
      styles.shrinkTopToBottom,
      () => {
        setDisplayOptions(false);
      },
      (optionsRef as MutableRefObject<HTMLElement>).current
    );

    const checkForDefault = (changedOptions: DropdownOption[]): void => {
      if (
        defaultOption.name !== '' &&
        !changedOptions.some((option) => {
          return option.isChecked === true;
        })
      ) {
        changedOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isIndeterminate = true;
        setCurrentOptions(
          changedOptions.map((option) => {
            if (
              option.name === defaultOption.name ||
              defaultOption.name === masterCheckboxOption
            ) {
              return {
                ...option,
                isChecked: true,
                isIndeterminate:
                  option.name === defaultOption.name
                    ? false
                    : option.isIndeterminate,
              };
            } else {
              return { ...option, isChecked: false };
            }
          })
        );
      } else {
        setCurrentOptions(changedOptions);
      }
    };

    const handleMasterChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const isChecked = event.target.checked;

      if (masterCheckboxOption !== defaultOption.name || isChecked) {
        const updatedOptions: DropdownOption[] = currentOptions.map(
          (option) => {
            return { ...option, isChecked };
          }
        );

        updatedOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isIndeterminate = false;

        checkForDefault(updatedOptions);
      }
    };

    const handleCheckboxChange = (
      index: number,
      event: ChangeEvent<HTMLInputElement>
    ): void => {
      const isChecked = event.target.checked;
      const referenceIndex = currentOptions.findIndex(
        (option) => option.name === displayedOptions[index].name
      );
      const updatedOptions = [...currentOptions];
      updatedOptions[referenceIndex].isChecked = isChecked;

      if (masterCheckboxOption !== undefined) {
        const allChecked = updatedOptions
          .slice(1)
          .every((option) => option.isChecked);
        const someChecked = updatedOptions
          .slice(1)
          .some((option) => option.isChecked);
        updatedOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isChecked = allChecked;
        updatedOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isIndeterminate =
          !allChecked && someChecked;
      }

      checkForDefault(updatedOptions);
    };

    const toggleOptionsVisibility = (): void => {
      if (displayOptions) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
        setDisplayOptions(true);
      }
    };

    const selectOption = (option: DropdownOption): void => {
      setSelectedOption([option]);
      setIsVisible(false);
      setInputValue(option.name);
      setCurrentOptions(options);

      onChange !== undefined && onChange([option]);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const searchValue = e.target.value;
      setInputValue(searchValue);
      if (searchValue === '') {
        setDisplayedOptions(currentOptions);
      } else {
        const newOptions = currentOptions.filter((option) => {
          return option.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        newOptions.length > 0
          ? setDisplayedOptions(newOptions)
          : setDisplayedOptions(currentOptions);
      }
    };

    useEffect(() => {
      setDisplayedOptions(currentOptions);
      if (multiple === true) {
        const checkedOptions = currentOptions.filter(
          (option) => option.isChecked
        );
        if (
          masterCheckboxOption !== undefined &&
          checkedOptions.length === currentOptions.length
        ) {
          setSelectedOption([currentOptions[DROPDOWN_MASTER_CHECKBOX_INDEX]]);
          onChange([currentOptions[DROPDOWN_MASTER_CHECKBOX_INDEX]]);
        } else {
          setSelectedOption(checkedOptions);
          onChange(checkedOptions);
        }
      }
    }, [currentOptions, masterCheckboxOption, multiple]);

    useEffect(() => {
      if (masterCheckboxOption !== undefined) {
        const masterCheckbox = masterCheckboxRef.current;
        if (masterCheckbox !== null) {
          masterCheckbox.indeterminate =
            currentOptions[DROPDOWN_MASTER_CHECKBOX_INDEX].isIndeterminate ??
            false;
        }
      }
    }, [currentOptions, masterCheckboxOption, displayOptions]);

    useEffect(() => {
      if (selectedOption[DROPDOWN_MASTER_CHECKBOX_INDEX] !== undefined) {
        if (
          multiple === true &&
          selectedOption[DROPDOWN_MASTER_CHECKBOX_INDEX].name !==
            masterCheckboxOption
        ) {
          const newInputValue = selectedOption
            .map((option) => option.name)
            .sort((option1: string, option2: string): number => {
              return option1 < option2 ? -1 : 1;
            })
            .join(MULTIPLE_OPTION_SEPARATOR);

          if (newInputValue.length >= MAX_INPUT_LENGTH) {
            const shortInputValue =
              newInputValue.slice(
                SHORTENED_VALUE_START_INDEX,
                MAX_SHORTENED_VALUE_LENGTH
              ) + SHORTENED_VALUE_SUFFIX;
            setInputValue(shortInputValue);
            setinputPlaceholder(shortInputValue);
          } else {
            setInputValue(newInputValue);
            setinputPlaceholder(newInputValue);
          }
        } else {
          setInputValue(selectedOption[DROPDOWN_MASTER_CHECKBOX_INDEX].name);
          setinputPlaceholder(
            selectedOption[DROPDOWN_MASTER_CHECKBOX_INDEX].name
          );
        }
      }
    }, [masterCheckboxOption, multiple, selectedOption]);

    return (
      <div
        className={cx(
          styles['dropdown-container'],
          {
            [styles['dropdown-container--floating']]: floating,
          },
          className
        )}>
        {!floating && label !== '' && (
          <Text
            variant={TYPEKIT.D5}
            className={cx(styles.dropdown__label, {
              [styles['dropdown__label--disabled']]: disabled,
            })}
            as="label">
            {label}
          </Text>
        )}
        <div
          ref={dropdownRef}
          className={cx(styles['dropdown__input-container'], {
            [styles['dropdown__input-container--floating']]: floating,
          })}>
          <input
            className={cx(styles.dropdown, {
              [styles['dropdown--floating']]: floating,
              [styles['dropdown--error']]: errorMessage,
            })}
            type="text"
            onClick={toggleOptionsVisibility}
            placeholder={inputPlaceholder}
            value={inputValue}
            onChange={handleChange}
            disabled={disabled}
            onBlur={onBlur}
            name={name}
            ref={ref}
          />
          <Icon
            color={disabled ? 'text-disabled-text' : 'interactive-black'}
            className={cx(styles.dropdown__icon, {
              [styles['dropdown__icon--floating']]: floating,
              [styles['dropdown__icon--spinning']]: isVisible,
              [styles['dropdown__icon--spinning-close']]:
                !isVisible && displayOptions,
            })}
            variant="minor"
            src={isVisible ? faChevronUp : faChevronDown}
            ariaLabel="Dropdown icon"
          />
        </div>
        {displayOptions && displayedOptions.length > 0 && (
          <ul
            ref={optionsRef}
            className={cx(styles.dropdown__options, {
              [styles['dropdown__options--floating']]: floating,
              [styles['dropdown__options--scrollable']]:
                scrollable && currentOptions.length >= SCROLLABLE_MIN_OPTIONS,
              [styles['dropdown__options--filter']]: filter,
              [styles['dropdown__options--closing']]: !isVisible,
            })}>
            {displayedOptions.map((option, index) => {
              return (
                <li
                  role="option"
                  className={cx(styles.dropdown__option, {
                    [styles['dropdown__option--selected']]:
                      multiple !== true &&
                      option.id ===
                        selectedOption[DROPDOWN_MASTER_CHECKBOX_INDEX].id,
                    [styles['dropdown__option--multiple']]: multiple,
                  })}
                  key={option.id}
                  value={option.id}
                  onClick={
                    multiple === true
                      ? undefined
                      : () => {
                          selectOption(option);
                        }
                  }>
                  {multiple === true ? (
                    <Checkbox
                      ref={
                        index === 0 && masterCheckboxOption === option.name
                          ? masterCheckboxRef
                          : undefined
                      }
                      key={option.id}
                      name={option.name}
                      onChange={
                        index === 0 && masterCheckboxOption === option.name
                          ? (event) => {
                              handleMasterChange(event);
                            }
                          : (event) => {
                              handleCheckboxChange(index, event);
                            }
                      }
                      id={option.id}
                      checked={option.isChecked}
                      value={option.id}
                      className={styles.dropdown__checkbox}
                    />
                  ) : (
                    option.name
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {Boolean(errorMessage) && <ErrorMessage error={errorMessage} />}
      </div>
    );
  }
);

Dropdown.displayName = 'DropdownComponent';

export default Dropdown;
