import { useGoogleLogin, type CodeResponse } from '@react-oauth/google';
import ButtonLink from 'components/molecules/ButtonLink';
import { useAuth } from 'context/Authentication/AuthContext';
import { type LoginPageProps } from './LoginPage.types';
import styles from './loginPage.module.scss';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { useToast } from 'hooks/useToast';
import { useEffect } from 'react';
import kg_logo from 'assets/svgs/konrad-logo.svg';

import {
  DEFAULT_AUTH_FLOW,
  DEFAULT_SCOPE,
  DEFAULT_UX_MODE,
  ICON_COLOR,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  BUTTON_SIZE,
  BUTTON_MESSAGE,
} from './LoginPage.constants';
import { HOME_PAGE_ROUTE } from 'utils/constants';
import { showToast } from 'utils/utilities';
import Icon from 'components/atoms/Icon';

/**
 * Page for login.
 *
 * @component
 * @returns {JSX.Element} The login page component.
 */

const LoginPage: React.FC<LoginPageProps> = () => {
  const { login, authError } = useAuth();

  const toast = useToast();

  /**
   * Handles successful login.
   *
   * @param {Object} credentialResponse - The response containing credentials.
   * @param {string} credentialResponse.code - The access token code.
   * @returns {void}
   */

  const handleLogin = (
    credentialResponse: Omit<
      CodeResponse,
      'error' | 'error_description' | 'error_uri'
    >
  ): void => {
    const accessToken = credentialResponse.code;
    void login(accessToken).then(() => {
      // Instead of using the standard react-router navigation, we're using window.location.replace
      // to handle the post-login redirection. This approach is chosen due to the specific nature
      // of the login page and its requirements.
      //
      // The reason behind this exception include:
      // - Ensuring a reliable and clean redirection after login.
      // - Avoiding potential issues or conflicts with the React Router history stack.
      // - Meeting specific security or authentication requirements.
      // - Achieving a seamless user experience during login.
      if (authError === undefined) {
        window.location.replace(HOME_PAGE_ROUTE);
      }
    });
  };

  /**
   * Handles login errors.
   *
   * @param {Object} errorResponse - The response containing error details.
   * @returns {void}
   */
  const handleError = (
    errorResponse: Pick<
      CodeResponse,
      'error' | 'error_description' | 'error_uri'
    >
  ): void => {};

  const googleLoginCall = useGoogleLogin({
    onSuccess: handleLogin,
    onError: handleError,
    flow: DEFAULT_AUTH_FLOW,
    ux_mode: DEFAULT_UX_MODE,
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    scope: DEFAULT_SCOPE,
  });

  /**
   * Displays an error toast message if an authentication error happens.
   *
   * @param {string} message - The error message to display.
   * @returns {void}
   */

  useEffect(() => {
    if (authError !== undefined) {
      showToast(authError, toast, TOAST_TYPE.ERROR);
    }
  }, [authError, toast]);

  return (
    <div className={styles.login__container}>
      <Icon
        className={styles.login__container__icon}
        src={kg_logo}
        aria-hidden={true}
        disabled
        color={ICON_COLOR}
      />
      <ButtonLink
        as={BUTTON_TYPE}
        variant={BUTTON_VARIANT}
        size={BUTTON_SIZE}
        onClick={() => {
          googleLoginCall();
        }}>
        {BUTTON_MESSAGE}
      </ButtonLink>
    </div>
  );
};

export default LoginPage;
