import React, { type MutableRefObject, useEffect, useRef } from 'react';
import { type ModalOverlayProps } from './ModalOverlay.types';
import styles from './modalOverlay.module.scss';
import cx from 'classnames';
import useTrapFocus from 'hooks/useTrapFocus';
import useElementClickListener from 'hooks/useElementClickListener';
import { ESCAPE_KEY, KEYDOWN_EVENT_LISTENER } from 'utils/constants';
import useHidePageOverflow from 'hooks/useHidePageOverflow';

const ModalOverlay: React.FC<ModalOverlayProps> = ({
  children,
  className,
  isOpen,
  hideModal,
  elementRef,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleEscapePress = (e: KeyboardEvent): void => {
      if (e.key === ESCAPE_KEY) {
        hideModal();
      }
    };
    if (isOpen) {
      document.addEventListener(KEYDOWN_EVENT_LISTENER, handleEscapePress);
    } else {
      document.removeEventListener(KEYDOWN_EVENT_LISTENER, handleEscapePress);
    }
    return () => {
      document.removeEventListener(KEYDOWN_EVENT_LISTENER, handleEscapePress);
    };
  }, [isOpen, hideModal]);

  useElementClickListener(
    overlayRef as MutableRefObject<HTMLElement>,
    hideModal
  );

  useTrapFocus(overlayRef as MutableRefObject<HTMLElement>, elementRef);
  useHidePageOverflow(isOpen);

  return (
    <div
      ref={overlayRef}
      className={cx(styles.overlay, className)}>
      {children}
    </div>
  );
};
ModalOverlay.displayName = 'ModalOverlay';

export default ModalOverlay;
