import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import useBreakpoint from 'hooks/useBreakpoint';
import Icon from 'components/atoms/Icon';
import filterIcon from 'assets/svgs/filter-icon.svg';
import filterClosedIcon from 'assets/svgs/filter-closed-icon.svg';
import { type FilterBarProps } from './FilterBar.types';
import Searchbox from '../Searchbox';
import Dropdown from '../Dropdown';
import { FILTER_ICON_ARIA_LABEL } from './FilterBar.constants';
import styles from './filterBar.module.scss';

const FilterBar: React.FC<FilterBarProps> = ({
  filters,
  searchBarPlaceholder,
  hideSearchBar,
  onSearch,
  className,
  optionsClassName,
  searchboxClassNames,
  smallerIcon = false,
}) => {
  const [displayMobileFilters, setDisplayMobileFilters] = useState(false);
  const { isDesktop } = useBreakpoint();

  const toggleOptionsVisibility = (): void => {
    setDisplayMobileFilters((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    setDisplayMobileFilters(isDesktop);
  }, [isDesktop]);

  return (
    <div className={cx(styles['filters-bar'], className)}>
      {!(hideSearchBar ?? false) && onSearch !== undefined && (
        <div className={styles['filters__search-bar']}>
          <Searchbox
            id="search-box-filters"
            onSearch={onSearch}
            placeholder={searchBarPlaceholder}
            classNames={searchboxClassNames}
          />
        </div>
      )}

      <div
        className={cx(
          styles.filters,
          {
            [styles['filters--open']]: !isDesktop && displayMobileFilters,
            [styles['filters--close']]: !isDesktop && !displayMobileFilters,
          },
          optionsClassName
        )}>
        {filters.map((filter) => {
          return (
            <div
              className={cx(styles.filter, filter.className)}
              key={filter.id}>
              <Dropdown
                scrollable
                filter
                multiple={filter.multiple}
                key={filter.id}
                className={styles.filter__background}
                label={!isDesktop ? filter.label : ''}
                options={[filter.resetOption, ...filter.options]}
                onChange={filter.onChange}
                defaultOption={filter.defaultOption ?? filter.resetOption}
                floating={isDesktop}
                masterCheckboxOption={filter.masterCheckboxOption}
              />
            </div>
          );
        })}
      </div>
      {!isDesktop && (
        <Icon
          src={displayMobileFilters ? filterClosedIcon : filterIcon}
          onClick={toggleOptionsVisibility}
          aria-label={FILTER_ICON_ARIA_LABEL}
          color="interactive-black"
          variant="major"
          className={cx({
            [styles['filters-bar__icon']]: smallerIcon,
          })}
        />
      )}
    </div>
  );
};

export default FilterBar;
