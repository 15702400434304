import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Routes from 'Routes';
import { ToastProvider } from 'components/molecules/Toast/Toast';
import { NotificationProvider } from 'components/organisms/Notification/Notification';
import AuthProvider from 'context/Authentication/AuthContext';
import client from 'api/connection';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.module.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ToastProvider>
            <NotificationProvider>
              <BrowserRouter>
                <Routes></Routes>
              </BrowserRouter>
            </NotificationProvider>
          </ToastProvider>
        </AuthProvider>
      </ApolloProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
