import { type FC } from 'react';
import { type ProgressBarProps } from './ProgressBar.types';
import styles from './progressBar.module.scss';
import cx from 'classnames';

const ProgressBar: FC<ProgressBarProps> = ({
  className,
  variant,
  progress,
}) => {
  const MAX_PROGRESS_VALUE = 100;

  // When progress is 0 the progress bar should display some progress
  const clampedProgress = Math.min(Math.max(progress, 1), MAX_PROGRESS_VALUE);

  return (
    <div
      className={cx(className, styles['progress-bar'], styles[variant])}
      role="progressbar"
      aria-label="Progress Bar"
      aria-valuemin={0}
      aria-valuemax={MAX_PROGRESS_VALUE}
      aria-valuetext={`${clampedProgress}% completed`}>
      <div
        className={styles['progress-bar__filler']}
        style={{
          width: `${clampedProgress}%`,
        }}></div>
    </div>
  );
};

export default ProgressBar;
