import { type CellContext } from '@tanstack/table-core';
import {
  type ColumnSettings,
  type AuctionItemForTable,
} from './AuctionItemTable.types';
import Text from 'components/atoms/Text';
import { STATUS, TYPEKIT } from 'utils/constants';
import styles from './auctionItemTable.module.scss';
import ButtonLink from 'components/molecules/ButtonLink';
import Image from 'components/atoms/Image';
import * as constants from './AuctionItemTable.constants';
import ApprovalActions from './ApprovalActions/ApprovalActions';
import StatusTag from 'components/atoms/StatusTag';
import ActionsMenu from './ActionsMenu/ActionsMenu';
import { type ButtonAction } from './ActionsMenu/ActionsMenu.types';
import CroppedText from './CroppedText/CroppedText';

const textCell = (
  cell: CellContext<AuctionItemForTable, string>
): JSX.Element => {
  return (
    <Text
      displayCurrency
      variant={TYPEKIT.P2}
      className={styles['item-table__text-cell']}>
      {cell.getValue()}
    </Text>
  );
};

const descriptionCell = (
  cell: CellContext<AuctionItemForTable, string>
): JSX.Element => {
  return <CroppedText id={cell.row.id}>{cell.getValue()}</CroppedText>;
};

export const getImageArray = (images: string): string[] => {
  return images.split(',');
};

const getImageCell = (
  handleClickCarousel: (images: string) => void
): ((cell: CellContext<AuctionItemForTable, string>) => JSX.Element) => {
  const imageCell = (
    cell: CellContext<AuctionItemForTable, string>
  ): JSX.Element => {
    return (
      <ButtonLink
        onClick={() => {
          handleClickCarousel(cell.getValue());
        }}
        className={styles['item-table__image-cell']}>
        <Image
          src={getImageArray(cell.getValue())[0]}
          alt={constants.ITEM_IMAGE_ALT_TEXT}
          className={styles['item-table__image-cell']}
        />
      </ButtonLink>
    );
  };
  return imageCell;
};

const getApprovalCell = (
  onAdminAction?: (approved: boolean, itemId: string) => void
): ((cell: CellContext<AuctionItemForTable, string>) => JSX.Element) => {
  const approvalCell = (
    cell: CellContext<AuctionItemForTable, string>
  ): JSX.Element => {
    return onAdminAction !== undefined ? (
      <ApprovalActions
        handleApprovalAction={(approved: boolean) => {
          onAdminAction(approved, cell.getValue());
        }}
      />
    ) : (
      <></>
    );
  };
  return approvalCell;
};

const getStatus = (itemStatus: string, isActive: boolean): string => {
  return itemStatus === STATUS.DECLINED ||
    itemStatus === STATUS.DELETED ||
    isActive
    ? itemStatus
    : STATUS.COMPLETED;
};

const getStatusCell = (
  isActive: boolean
): ((cell: CellContext<AuctionItemForTable, string>) => JSX.Element) => {
  const statusCell = (
    cell: CellContext<AuctionItemForTable, string>
  ): JSX.Element => {
    return <StatusTag status={getStatus(cell.getValue(), isActive)} />;
  };
  return statusCell;
};

const editItemAction = (
  itemId: string,
  onEdit: (itemId: string) => void
): ButtonAction => {
  return {
    id: constants.ACTION_EDIT_ID,
    label: constants.ACTION_EDIT_LABEL,
    onClick: () => {
      onEdit(itemId);
    },
  };
};

const deleteItemAction = (
  itemId: string,
  onDelete: (itemId: string) => void
): ButtonAction => {
  return {
    id: constants.ACTION_DELETE_ID,
    label: constants.ACTION_DELETE_LABEL,
    onClick: () => {
      onDelete(itemId);
    },
  };
};

const getActionCell = (
  isAdmin: boolean,
  onDelete: (itemId: string) => void,
  onEdit: (itemId: string) => void
): ((cell: CellContext<AuctionItemForTable, string>) => JSX.Element) => {
  const actionCell = (
    cell: CellContext<AuctionItemForTable, string>
  ): JSX.Element => {
    return (
      <ActionsMenu
        actions={
          isAdmin
            ? [
                editItemAction(cell.getValue(), onEdit),
                deleteItemAction(cell.getValue(), onDelete),
              ]
            : [editItemAction(cell.getValue(), onEdit)]
        }
      />
    );
  };
  return actionCell;
};

export const getColumnSettings = (
  handleClickCarousel: (images: string) => void,
  approval: boolean,
  isActive: boolean,
  isAdmin: boolean,
  onDelete: (itemId: string) => void,
  onEdit: (itemId: string) => void,
  onAdminAction?: (approved: boolean, itemId: string) => void
): ColumnSettings[] => {
  const columnSettings = [
    {
      key: constants.COLUMN_KEY_IMAGES,
      header: constants.COLUMN_HEADER_IMAGES,
      cell: getImageCell(handleClickCarousel),
      enableGlobalFilter: false,
    },
    {
      key: constants.COLUMN_KEY_NAME,
      header: constants.COLUMN_HEADER_NAME,
      cell: textCell,
    },
    {
      key: constants.COLUMN_KEY_DESCRIPTION,
      header: constants.COLUMN_HEADER_DESCRIPTION,
      cell: descriptionCell,
      enableGlobalFilter: false,
    },
    {
      key: constants.COLUMN_KEY_STARTING_BID,
      header: constants.COLUMN_HEADER_STARTING_BID,
      cell: textCell,
    },
    {
      key: constants.COLUMN_KEY_DATE_CREATED,
      header: constants.COLUMN_HEADER_DATE_CREATED,
      cell: textCell,
    },
    {
      key: constants.COLUMN_KEY_CATEGORY,
      header: constants.COLUMN_HEADER_CATEGORY,
      cell: textCell,
    },
    approval && onAdminAction !== undefined
      ? {
          key: constants.COLUMN_KEY_ITEM_ID,
          header: constants.COLUMN_HEADER_APPROVAL,
          cell: getApprovalCell(onAdminAction),
          enableGlobalFilter: false,
        }
      : {
          key: constants.COLUMN_KEY_STATUS,
          header: constants.COLUMN_HEADER_STATUS,
          cell: getStatusCell(isActive),
          enableGlobalFilter: false,
        },
  ];

  !approval &&
    isActive &&
    columnSettings.push({
      key: constants.COLUMN_KEY_ITEM_ID,
      header: '',
      cell: getActionCell(isAdmin, onDelete, onEdit),
      enableGlobalFilter: false,
    });

  isAdmin &&
    columnSettings.splice(3, 0, {
      key: constants.COLUMN_KEY_OWNER,
      header: constants.COLUMN_HEADER_OWNER,
      cell: textCell,
    });

  return columnSettings;
};
