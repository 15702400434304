import { useEffect, type MutableRefObject } from 'react';
import { MOUSEDOWN_EVENT_LISTENER } from 'utils/constants';

/**
 *
 * Adds an event listener for a MouseDownEvent that executes an action, that is passed as a parameter.
 *
 * @param ref - Reference to the HTML element that the hook should monitor. Can be Casted as a MutableRef.
 * @param action - Function to be executed with each succesful click.
 */

const useElementClickListener = (
  ref: MutableRefObject<HTMLElement | undefined>,
  action: () => void
): void => {
  useEffect(() => {
    const elementCurrent = ref.current;

    const handleClick = (e: Event): void => {
      if (e.target === elementCurrent) {
        e.preventDefault();
        action();
      }
    };

    if (elementCurrent != null) {
      document.addEventListener(MOUSEDOWN_EVENT_LISTENER, handleClick);
    } else {
      document.removeEventListener(MOUSEDOWN_EVENT_LISTENER, handleClick);
    }
    return () => {
      document.removeEventListener(MOUSEDOWN_EVENT_LISTENER, handleClick);
    };
  }, [action, ref]);
};

export default useElementClickListener;
