import React, { useEffect, useState } from 'react';
import { type BreadcrumbsProps, type Breadcrumb } from './Breadcrumbs.types';
import ButtonLink from '../ButtonLink';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import cx from 'classnames';
import styles from './breadcrumbs.module.scss';
import { useLocation, useParams } from 'react-router-dom';
import {
  HOME_BREADCRUMB_NAME,
  HOME_BREADCRUMB_URL,
  URL_SEPARATOR,
} from './breadcrumbs.constants';

/**
 * Renders breadcrumbs component which allows the user to navigate to previous visited pages.
 *
 * @example
 * // Renders the breadcrumbs based on current location using react-router-dom
 * <Breadcrumbs />
 *
 */
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  campaignName,
  itemName,
}) => {
  const location = useLocation();
  const { campaignId, itemId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([] as Breadcrumb[]);

  useEffect(() => {
    const crumbs = location.pathname.split(URL_SEPARATOR).filter((str) => str);
    const homeCrumb = { name: HOME_BREADCRUMB_NAME, url: HOME_BREADCRUMB_URL };
    const newCrumbs = crumbs.map((crumb, i) => {
      const url = crumbs.slice(0, i + 1).join(URL_SEPARATOR);
      return { name: crumb, url };
    });
    setBreadcrumbs([homeCrumb].concat(newCrumbs));
  }, [location]);

  const isLast = (i: number): boolean => {
    return i === breadcrumbs.length - 1;
  };

  const getBreadcrumbName = (crumb: string): string => {
    if (crumb === campaignId && campaignName !== undefined) return campaignName;
    if (crumb === itemId && itemName !== undefined) return itemName;
    return crumb;
  };

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, i) => {
        return (
          <div
            className={styles.breadcrumbs}
            key={`crumb-${i}`}>
            <ButtonLink
              key={`crumb-${i}`}
              as="Link"
              to={
                breadcrumb.url === campaignId
                  ? `/${breadcrumb.url}`
                  : breadcrumb.url
              }
              className={styles.breadcrumbs__link}
              disabled={isLast(i)}>
              {getBreadcrumbName(breadcrumb.name)}
            </ButtonLink>
            {!isLast(i) && (
              <Text
                variant={TYPEKIT.P2}
                className={cx(styles.breadcrumbs__separator, {
                  [styles['breadcrumbs__last-separator']]: isLast(i + 1),
                })}
                key={'crumb-separator-' + i}>
                /
              </Text>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
