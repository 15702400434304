export const ITEM_IMAGE_ALT_TEXT = 'Auction item image';

export const ACTION_EDIT_ID = '1';
export const ACTION_EDIT_LABEL = 'edit';
export const ACTION_DELETE_ID = '2';
export const ACTION_DELETE_LABEL = 'delete';

export const COLUMN_KEY_IMAGES = 'itemImages';
export const COLUMN_HEADER_IMAGES = 'Images';
export const COLUMN_KEY_NAME = 'itemName';
export const COLUMN_HEADER_NAME = 'Item Name';
export const COLUMN_KEY_DESCRIPTION = 'itemDescription';
export const COLUMN_HEADER_DESCRIPTION = 'Description';
export const COLUMN_KEY_STARTING_BID = 'itemStartingBid';
export const COLUMN_HEADER_STARTING_BID = 'Starting Bid';
export const COLUMN_KEY_DATE_CREATED = 'itemDateCreated';
export const COLUMN_HEADER_DATE_CREATED = 'Date Created';
export const COLUMN_KEY_CATEGORY = 'itemCategory';
export const COLUMN_HEADER_CATEGORY = 'Category';
export const COLUMN_KEY_ITEM_ID = 'itemId';
export const COLUMN_HEADER_APPROVAL = 'Approval';
export const COLUMN_KEY_STATUS = 'itemStatus';
export const COLUMN_HEADER_STATUS = 'Status';
export const COLUMN_KEY_OWNER = 'itemOwner';
export const COLUMN_HEADER_OWNER = 'Owner';
