import { gql } from '@apollo/client';

export const GET_ALL_ITEMS_FOR_CAMPAIGN = gql`
  query GetAllItemsForCampaign($campaignId: ID) {
    getAllItemsForCampaign(campaignId: $campaignId) {
      id
      name
      startPrice
      description
      isReviewed
      isApproved
      updatedAt
      createdAt
      isDeleted
      images {
        imageKey
        id
      }
      offices {
        name
        id
      }
      campaign {
        id
        isActive
        name
      }
      user {
        id
        firstName
        lastName
        imageUrl
      }
      category {
        id
        name
      }
      latestBid
      earliestBid
      lastBids
      topBid
      topBidder
    }
  }
`;

export const GET_ITEM_BY_ID = gql`
  query getItemById($itemId: ID) {
    getItemById(itemId: $itemId) {
      id
      name
      startPrice
      description
      isReviewed
      isApproved
      updatedAt
      createdAt
      isDeleted
      images
      offices {
        name
        id
      }
      campaign {
        id
        isActive
        endDate
        name
      }
      user {
        id
        firstName
        lastName
        imageUrl
      }
      category {
        id
        name
      }
    }
  }
`;
