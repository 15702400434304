import { forwardRef } from 'react';
import { type CheckboxProps } from './Checkbox.types';
import styles from './checkbox.module.scss';
import cx from 'classnames';
import { CHECKBOX_INPUT } from './Checkbox.constants';

/**
 * Checkbox is a React component that represents a checkbox element with a label passed as name.
 * It can receive whether it's checked or not. It can also be disabled.
 *
 * @param {string} [checked] - Conditional to determine the state of the checkbox.
 * @param {string} id - The unique identifier of the entity represented by the checkbox.
 * @param {string} name - The label text associated with the checkbox.
 * @param {boolean} [disabled] - Indicates whether the checkbox is disabled.
 * @param {function} [onChange] - A callback function invoked when the checkbox is clicked.
 * @returns {React.FC} The rendered Checkbox Component.
 *
 * @example
 * // Example usage:
 * import Checkbox from './Checkbox';
 *
 * function MyComponent() {
 *   const handleCheckboxClick = (state, id) => {
 *     // Handle checkbox click and state changes here
 *   };
 *
 *   return (
 *     <Checkbox
 *       isChecked={true}
 *       id="myCheckbox"
 *       name="Enable Feature"
 *       isDisabled={false}
 *       onChange={handleCheckboxClick}
 *     />
 *   );
 * }
 */

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, name, disabled, className, checked, onChange, ...props }, ref) => {
    return (
      <label className={cx(styles['checkbox-container'], className)}>
        <input
          type={CHECKBOX_INPUT}
          ref={ref}
          className={cx(styles['hidden-checkbox'])}
          name={name}
          id={id}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          {...props}
        />
        <div className={cx(styles.checkbox)}></div>
        <span
          className={cx(styles['checkbox-label'], {
            [styles['checkbox-label--disabled']]: disabled,
          })}>
          {name}
        </span>
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
