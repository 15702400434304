import { gql } from '@apollo/client';

export const LOGIN = gql`
  query login($accessCode: String!) {
    login(input: { accessCode: $accessCode }) {
      token {
        id
        expiresIn
      }
      user {
        id
        email
        firstName
        lastName
        dateJoined
        imageUrl
        role {
          name
          description
        }
        office {
          id
          name
        }
      }
    }
  }
`;

export const VERIFY_TOKEN = gql`
  query verifyToken {
    verifyToken {
      verified
    }
  }
`;

export const REFRESH_TOKEN = gql`
  query getRefreshTokens {
    getRefreshTokens {
      token {
        id
        expiresIn
      }
      user {
        id
        firstName
        lastName
        email
        imageUrl
        role {
          id
          name
          description
        }
        office {
          id
          name
        }
      }
    }
  }
`;
