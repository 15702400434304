import React, { useContext, useMemo, useState } from 'react';
import {
  type CampaignFormProps,
  type CampaignFormData,
} from './CampaignForm.types';
import FormModal from '../FormModal';
import Input from 'components/molecules/Input';
import {
  CAMPAIGN_IMAGES_ENDPOINT,
  MASKTYPES,
  TOAST_OFFICES_FETCH_ERROR,
  TOAST_UPDATED,
  EMPTY_STRING,
  FILE_MANAGER_ERROR,
} from 'utils/constants';
import DatePicker from '../DatePicker';
import CheckboxGroup from 'components/molecules/CheckboxGroup';
import FileUpload from '../FileUpload';
import { type SubmitHandler, useForm, Controller } from 'react-hook-form';
import {
  ALL_OFFICES,
  CAMPAIGN_AMOUNT_ERROR,
  CAMPAIGN_AMOUNT_LABEL,
  CAMPAIGN_AMOUNT_PLACEHOLDER,
  CAMPAIGN_CREATION_ERROR,
  CAMPAIGN_DATES_ERROR,
  CAMPAIGN_DATES_LABEL,
  CAMPAIGN_GOAL_ERROR,
  CAMPAIGN_GOAL_LABEL,
  CAMPAIGN_GOAL_PLACEHOLDER,
  CAMPAIGN_IMAGE_ERROR,
  CAMPAIGN_IMAGE_LABEL,
  CAMPAIGN_LOCATIONS_ARIA_LABEL,
  CAMPAIGN_LOCATIONS_ERROR,
  CAMPAIGN_LOCATIONS_LABEL,
  CAMPAIGN_NAME_ERROR,
  CAMPAIGN_NAME_LABEL,
  CAMPAIGN_NAME_PLACEHOLDER,
  DATES_FIELD,
  DEFAULT_INITIAL_DONATIONS,
  DEFAULT_IS_ACTIVE,
  DEFAULT_IS_DELETED,
  EDIT_FALSE,
  GOAL_FIELD,
  IMAGE_FIELD,
  LOCATIONS_FIELD,
  MAX_NUMBER_FILES,
  MULTIPLE_FILES,
  NAME_FIELD,
  RAISED_AMOUNT_FIELD,
  SHOULD_FOCUS,
  TOAST_ADDED,
} from './CampaignForm.constants';

import { useToast } from 'hooks/useToast';
import {
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from 'graphql/campaign/mutations.gql';
import { type UpdateCampaignMutation } from 'graphql/generated-types/graphql';
import { useAuth } from 'context/Authentication/AuthContext';
import { useParams } from 'react-router';
import { useMutation, useQuery, type ApolloError } from '@apollo/client';
import { parseCurrencyToFloat, showToast } from 'utils/utilities';
import { GET_ALL_OFFICES } from 'graphql/office/queries.gql';
import { type Office, type Campaign } from 'utils/dataTypes';
import {
  GET_ALL_CAMPAIGNS,
  GET_CAMPAIGN_BY_ID,
} from 'graphql/campaign/queries.gql';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import dayjs from 'dayjs';
import { maskCurrency } from 'utils/masks';
import fileManagerClient from 'api/fileManagerClient';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';
/**
 * CampaignForm is a React component that presents a modal form allowing users to create or update campaigns.
 *
 * @param {string} title - Text for the form's title.
 * @param {string} cancelButtonText - Text for the cancel button.
 * @param {string} acceptButtonText - Text for the accept button.
 * @param {boolean} isOpen - Determines if the form modal is visible.
 * @param {boolean} edit - Indicates whether the CampaignForm is going to be used for editing or not.
 * @param {function} hide - Handler function to hide the form modal.
 * @param {function} onSubmit - Function to handle the form's submission.
 *
 * @component
 * @returns {React.FC} The `CampaignForm` component rendered within a `FormModal`.
 *
 * @example
 * const handleHide = () => {
 *   // Handle modal hide logic here
 * };
 *
 * const handleFormSubmit = (data) => {
 *   // Handle form data submission here
 * };
 *
 * <CampaignForm
 *   title="Create Campaign"
 *   cancelButtonText="Cancel"
 *   acceptButtonText="Submit"
 *   isOpen={true}
 *   hide={handleHide}
 *   onSubmit={handleFormSubmit}
 * />
 */

const CampaignForm: React.FC<CampaignFormProps> = ({
  title,
  cancelButtonText,
  acceptButtonText,
  defaultValues,
  isOpen,
  edit = EDIT_FALSE,
  hide,
  campaign,
  onSubmit,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<CampaignFormData>({
    shouldFocusError: SHOULD_FOCUS,
    defaultValues,
  });

  const [offices, setOffices] = useState<Office[]>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const { campaignId } = useParams();
  const toast = useToast();
  const userId = useMemo(() => currentUser?.id ?? '', [currentUser]);
  const { conversionRate, selectedCurrency } = useContext(CurrencyContext);

  const [updateCampaignMutation] = useMutation<UpdateCampaignMutation>(
    UPDATE_CAMPAIGN,
    {
      onCompleted: (response) => {
        showToast(
          `${response.updateCampaign.name} ${TOAST_UPDATED}`,
          toast,
          TOAST_TYPE.SUCCESS
        );
        hideForm();
        setSubmitting(false);
      },
      onError: (error: ApolloError) => {
        showToast(error.message, toast, TOAST_TYPE.ERROR);
        setSubmitting(false);
      },
      refetchQueries: [
        { query: GET_CAMPAIGN_BY_ID, variables: { campaignId } },
      ],
    }
  );

  const [createCampaign] = useMutation(CREATE_CAMPAIGN);

  useQuery(GET_ALL_OFFICES, {
    onCompleted: (dataOffices) => {
      setOffices(dataOffices.getAllOffices);
    },
    onError: () => {
      showToast(TOAST_OFFICES_FETCH_ERROR, toast, TOAST_TYPE.WARNING);
    },
  });

  const onSubmitAddCampaign: SubmitHandler<CampaignFormData> = async (data) => {
    try {
      setSubmitting(true);
      let imageKey;
      if (data.image.remoteFiles.length === 0) {
        imageKey = data.image.localFiles[0].key;
        try {
          if (imageKey === EMPTY_STRING) {
            imageKey = await fileManagerClient.saveImage(
              data.image.localFiles[0].file,
              CAMPAIGN_IMAGES_ENDPOINT
            );
          }
        } catch {
          setSubmitting(false);
          showToast(FILE_MANAGER_ERROR, toast, TOAST_TYPE.ERROR);
          return;
        }
      } else {
        imageKey = fileManagerClient.getKeyFromURL(
          data.image.remoteFiles[0].url
        );
      }
      void createCampaign({
        variables: {
          name: data.name,
          imageKey,
          startDate: data.dates[0].toISOString().split('T')[0],
          endDate: data.dates[1].toISOString().split('T')[0],
          donations: DEFAULT_INITIAL_DONATIONS,
          goal: parseCurrencyToFloat(
            data.goal,
            conversionRate,
            selectedCurrency.symbol
          ),
          isActive: DEFAULT_IS_ACTIVE,
          isDeleted: DEFAULT_IS_DELETED,
          raisedAmount: parseCurrencyToFloat(
            data.raisedAmount,
            conversionRate,
            selectedCurrency.symbol
          ),
          updatedBy: userId,
          offices: data.locations,
        },
        onCompleted: (data) => {
          showToast(
            `${data.createCampaign.name} ${TOAST_ADDED}`,
            toast,
            TOAST_TYPE.SUCCESS
          );
          hide();
          setSubmitting(false);
          reset();
          onSubmit?.(data.createCampaign);
        },
        update: (cache, { data: { createCampaign } }) => {
          const cachedData: { getAllCampaigns: Campaign[] } | null =
            cache.readQuery({ query: GET_ALL_CAMPAIGNS });
          if (cachedData !== null && createCampaign !== null) {
            const updatedCache = {
              getAllCampaigns: [...cachedData.getAllCampaigns, createCampaign],
            };
            cache.writeQuery({
              data: updatedCache,
              query: GET_ALL_CAMPAIGNS,
            });
          }
        },
        onError: () => {
          setSubmitting(false);
          showToast(CAMPAIGN_CREATION_ERROR, toast, TOAST_TYPE.WARNING);
        },
      });
    } catch (error) {
      setSubmitting(false);
      showToast(CAMPAIGN_CREATION_ERROR, toast, TOAST_TYPE.WARNING);
    }
  };

  const onSubmitEditCampaign: SubmitHandler<CampaignFormData> = async (
    updatedCampaign
  ) => {
    setSubmitting(true);
    let newImageFlag = false;
    let imageKey;
    if (updatedCampaign.image.remoteFiles.length === 0) {
      imageKey = updatedCampaign.image.localFiles[0].key;

      try {
        if (imageKey === EMPTY_STRING) {
          imageKey = await fileManagerClient.saveImage(
            updatedCampaign.image.localFiles[0].file,
            CAMPAIGN_IMAGES_ENDPOINT
          );
          newImageFlag = true;
        }
      } catch {
        setSubmitting(false);
        showToast(FILE_MANAGER_ERROR, toast, TOAST_TYPE.ERROR);
        return;
      }
    } else {
      imageKey = fileManagerClient.getKeyFromURL(
        updatedCampaign.image.remoteFiles[0].url
      );
    }

    if (campaign !== undefined) {
      const response = await updateCampaignMutation({
        variables: {
          id: campaign.id,
          name: updatedCampaign.name,
          imageKey: fileManagerClient.getKeyFromURL(imageKey),
          startDate: dayjs(updatedCampaign.dates[0]).format('YYYY-MM-DD'),
          endDate: dayjs(updatedCampaign.dates[1]).format('YYYY-MM-DD'),
          goal: parseCurrencyToFloat(
            updatedCampaign.goal,
            conversionRate,
            selectedCurrency.symbol
          ),
          donations: campaign.donations,
          raisedAmount: parseCurrencyToFloat(
            updatedCampaign.raisedAmount,
            conversionRate,
            selectedCurrency.symbol
          ),
          isActive: campaign.isActive,
          isDeleted: campaign.isDeleted,
          updatedBy: currentUser?.id,
          offices: updatedCampaign.locations,
        },
      });

      if (response.errors !== undefined && newImageFlag) {
        void fileManagerClient.deleteImage(imageKey);
      }
      setSubmitting(false);
    }
  };

  const hideForm = (): void => {
    hide();
    reset();
  };

  return (
    <FormModal
      isOpen={isOpen}
      hide={hideForm}
      title={title}
      disabled={offices === undefined}
      onSubmit={(event) => {
        const theReturnedFunction = handleSubmit(
          !edit ? onSubmitAddCampaign : onSubmitEditCampaign
        );
        void theReturnedFunction(event);
      }}
      submitting={submitting}
      cancelButtonText={cancelButtonText}
      acceptButtonText={acceptButtonText}>
      <Input
        id={NAME_FIELD}
        label={CAMPAIGN_NAME_LABEL}
        placeholder={CAMPAIGN_NAME_PLACEHOLDER}
        value={defaultValues?.name}
        error={errors?.name?.message}
        {...register(NAME_FIELD, {
          required: CAMPAIGN_NAME_ERROR,
        })}
      />
      <Input
        id={GOAL_FIELD}
        label={CAMPAIGN_GOAL_LABEL}
        placeholder={CAMPAIGN_GOAL_PLACEHOLDER}
        value={
          defaultValues?.goal !== undefined
            ? maskCurrency(defaultValues.goal)
            : undefined
        }
        mask={MASKTYPES.CURRENCY}
        error={errors?.goal?.message}
        {...register(GOAL_FIELD, {
          required: CAMPAIGN_GOAL_ERROR,
        })}
      />
      <Input
        id={RAISED_AMOUNT_FIELD}
        label={CAMPAIGN_AMOUNT_LABEL}
        placeholder={`${selectedCurrency.symbol}${CAMPAIGN_AMOUNT_PLACEHOLDER}`}
        value={
          defaultValues?.raisedAmount !== undefined
            ? maskCurrency(defaultValues.raisedAmount)
            : undefined
        }
        mask={MASKTYPES.CURRENCY}
        error={errors?.raisedAmount?.message}
        {...register(RAISED_AMOUNT_FIELD, {
          required: CAMPAIGN_AMOUNT_ERROR,
        })}
      />
      <Controller
        control={control}
        name={DATES_FIELD}
        rules={{
          required: CAMPAIGN_DATES_ERROR,
          validate: (value) =>
            (Array.isArray(value) &&
              value.length === 2 &&
              value[0] !== null &&
              value[1] !== null) ||
            CAMPAIGN_DATES_ERROR,
        }}
        defaultValue={defaultValues?.dates}
        render={({ field }) => (
          <DatePicker
            id={DATES_FIELD}
            label={CAMPAIGN_DATES_LABEL}
            selectsRange={true}
            disabled={false}
            onClick={() => {}}
            defaultValue={field.value}
            onChange={field.onChange}
            errorMessage={errors?.dates?.message}
          />
        )}
      />
      {offices !== undefined && (
        <Controller
          control={control}
          name={LOCATIONS_FIELD}
          rules={{
            required: CAMPAIGN_LOCATIONS_ERROR,
          }}
          defaultValue={defaultValues?.locations}
          render={({ field }) => (
            <CheckboxGroup
              ariaLabel={CAMPAIGN_LOCATIONS_ARIA_LABEL}
              label={CAMPAIGN_LOCATIONS_LABEL}
              options={offices.map((office) => ({
                ...office,
                isChecked: true,
              }))}
              defaultValues={field.value}
              onCheckChange={field.onChange}
              masterCheckboxOption={ALL_OFFICES}
              errorMessage={errors?.locations?.message}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={IMAGE_FIELD}
        rules={{
          validate: ({ localFiles, remoteFiles }) => {
            if (localFiles.length === 0 && remoteFiles.length === 0)
              return CAMPAIGN_IMAGE_ERROR;
          },
        }}
        render={({ field }) => (
          <FileUpload
            label={CAMPAIGN_IMAGE_LABEL}
            multiple={MULTIPLE_FILES}
            maxNumberFiles={MAX_NUMBER_FILES}
            defaultValue={defaultValues?.image?.remoteFiles}
            onSelectedFilesChange={field.onChange}
            errorMessage={errors?.image?.message}
          />
        )}
      />
    </FormModal>
  );
};

export default CampaignForm;
