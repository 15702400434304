import React from 'react';
import Icon from 'components/atoms/Icon';
import { type WinnerBannerProps } from './WinnerBanner.types';
import { TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import auction_winner_medal from 'assets/svgs/auction-winner-medal.svg';
import styles from './winnerbanner.module.scss';
import {
  KG_BLUE_COLOR,
  MAJOR,
  WINNER_TITLE,
  TOP_BID_TITLE,
} from './WinnerBanner.constants';

const WinnerBanner: React.FC<WinnerBannerProps> = ({ winnerName, topBid }) => {
  return (
    <div className={styles['banner-content']}>
      <div className={styles['banner-content__winner']}>
        <Icon
          src={auction_winner_medal}
          color={KG_BLUE_COLOR}
          variant={MAJOR}
          disabled
          aria-hidden="true"
          className={styles['banner-content__medal-icon']}></Icon>
        <div className={styles['banner-content__column-display']}>
          <Text
            as="span"
            variant={TYPEKIT.P3}
            className={styles['banner-content__text']}>
            {WINNER_TITLE}
          </Text>
          <Text
            as="span"
            variant={TYPEKIT.D5}>
            {winnerName}
          </Text>
        </div>
      </div>
      <div className={styles['banner-content__column-display']}>
        <Text
          as="span"
          variant={TYPEKIT.P3}
          className={styles['banner-content__text']}>
          {TOP_BID_TITLE}
        </Text>
        <Text
          as="span"
          variant={TYPEKIT.D5}>
          ${topBid.toFixed(2)}
        </Text>
      </div>
    </div>
  );
};

export default WinnerBanner;
