import dayjs from 'dayjs';
import { STATUS } from 'utils/constants';
import { type AuctionItem } from 'utils/dataTypes';
import { maskCurrency } from 'utils/masks';
import {
  BIDS_IN_THE_PAST,
  IMAGE_DEFAULT_EXTENSION,
  IMAGE_DEFAULT_TYPE,
  ITEM_IMAGE_DEFAULT_NAME,
  ITEM_TRENDING_MARK,
  MINUTES,
} from './CampaignDetailsPage.constants';
import { type AuctionItemForTable } from 'components/organisms/AuctionItemTable/AuctionItemTable.types';
import { getAuctionItemForTable } from 'utils/utilities';
import { type ItemTrend } from 'graphql/generated-types/graphql';

/**
 * Get the current status of a item as string.
 * @param isReviewed Indicates weather the item was already review or not by an admin.
 * @param isApproved In case the admin already reviewed it, indicates if it was approved or not.
 * @param isDeleted Indicates if the item was soft deleted or not.
 * @param isCampaignActive Indicates if the campaign is still active.
 * @returns A string indicating the state of the item.
 */
const getStatus = (
  isReviewed: boolean,
  isApproved: boolean,
  isDeleted: boolean,
  isCampaignActive: boolean
): string => {
  let status = '';
  if (isReviewed) {
    status = STATUS.ACTIVE;
    if (!isApproved) {
      status = STATUS.DECLINED;
    } else if (!isCampaignActive) {
      status = STATUS.COMPLETED;
    }
  } else {
    status = STATUS.PENDING;
  }

  if (isDeleted) status = STATUS.DELETED;

  return status;
};

/**
 * Calculates the difference in minutes between two dates.
 * @param earliestBid Datetime of the earliest bid.
 * @param latestBid Datetime of the latest bid.
 * @returns A number that represents the difference in minutes between the two dates.
 */
const diffInMin = (earliestBid: string, latestBid: string): number => {
  return dayjs(latestBid).diff(dayjs(earliestBid), 'minute');
};

/**
 * Creates a message to show that the item is trending.
 * @param lastBids Number of bids the item received in a specific lapse of time.
 * @param earliestBid Datetime of the earliest bid in that specific lapse of time.
 * @param latestBid Datetime of the latest bid in that specific lapse of time.
 * @returns A string that tells how many bids the item received in a specific lapse of time.
 */
const getTrendingMessage = (
  lastBids?: number,
  earliestBid?: string,
  latestBid?: string
): string => {
  let message = '';
  if (
    earliestBid !== undefined &&
    latestBid !== undefined &&
    lastBids !== undefined
  ) {
    message = `${lastBids} ${BIDS_IN_THE_PAST} ${diffInMin(
      earliestBid,
      latestBid
    )} ${MINUTES}`;
  }
  return message;
};

/**
 * Maps an item received from database to the an AuctionItem object.
 * @param item An item with the raw format of the backend.
 * @returns An item with a format that can be used in the frontend.
 */
export const mapItemDBToAuctionItem = (item: ItemTrend): AuctionItem => {
  const mappedItem: AuctionItem = {
    itemId: item.id,
    itemImages: item.images.map((image) => image.imageKey),
    itemName: item.name,
    itemDescription: item.description,
    itemOwner: {
      id: item.user.id,
      name: `${item.user.firstName} ${item.user.lastName}`,
    },
    itemCampaignId: item.campaign.id,
    itemStartingBid: maskCurrency(item.startPrice.toString()),
    itemStartPrice: item.startPrice,
    itemDateCreated: dayjs(item.createdAt).format('MMM DD, YYYY'),
    itemCategory: item.category,
    itemStatus: getStatus(
      item.isReviewed,
      item.isApproved,
      item.isDeleted,
      item.campaign.isActive
    ),
    itemIsReviewed: item.isReviewed,
    itemIsApproved: item.isApproved,
    itemIsDeleted: item.isDeleted,
    itemOffices: item.offices,
    itemOwnerImage: item.user.imageUrl ?? '',
    itemTopBid: item.topBid ?? 0,
    itemTopBidderName: item.topBidder ?? '',
    itemIsTrending:
      item.lastBids !== undefined && item.lastBids !== null
        ? item.lastBids >= ITEM_TRENDING_MARK
        : false,
    itemTrendMessage:
      item.lastBids !== null &&
      item.earliestBid !== null &&
      item.latestBid !== null
        ? getTrendingMessage(item.lastBids, item.earliestBid, item.latestBid)
        : '',
  };
  return mappedItem;
};

/**
 * Maps an array of items from database to a an array of AuctionItem objects.
 * This mapping needs to be done for two reasons:
 *  1. Some fields need to be transformed into a user-friendly format.
 *  2. The fields need to have more specific names to match the modifiers used in the tables.
 * @param items An array of items with the raw format of the backend.
 * @returns An array of items with a format that can be used in the frontend.
 */
export const mapItemDBsToAuctionItems = (items: ItemTrend[]): AuctionItem[] => {
  return items.map((item) => {
    return mapItemDBToAuctionItem(item);
  });
};

/**
 * Finds item by id.
 * @param itemId An id to find.
 * @param allItems An array of items to search.
 * @returns An AuctionItem object if found, undefined otherwise.
 */
export const findItemById = (
  itemId: string,
  allItems: AuctionItem[]
): AuctionItem | undefined => {
  return allItems.find((item) => {
    return item.itemId === itemId;
  });
};

/**
 * Gets an image from a URL and transforms it to BLOB
 * @param url URL to fetch the image from.
 * @returns A Promise of a BLOB object.
 */
export const fetchImageBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  return await response.blob();
};

/**
 * Transforms an image URL into a File object
 * @param url URL to fetch the image from.
 * @param imageName The name of retrieved image.
 * @returns A Promise of a File object.
 */
export const fetchImageFile = async (
  url: string,
  imageName: string
): Promise<File> => {
  const shortenedURL = url.split('?')[0];
  const blob = await fetchImageBlob(shortenedURL);
  return new File([blob], imageName, {
    type: IMAGE_DEFAULT_TYPE,
  });
};

/**
 * Transforms many image URLs into an array of File objects.
 * @param item The item whose images will be retrieved.
 * @returns A Promise of an array of File objects.
 */
export const fetchImageFiles = async (item: AuctionItem): Promise<File[]> => {
  return await Promise.all(
    item.itemImages.map(async (url, i) => {
      return await fetchImageFile(
        url,
        `${ITEM_IMAGE_DEFAULT_NAME}${i}${IMAGE_DEFAULT_EXTENSION}`
      );
    })
  );
};

/**
 * Separates items that are pending of review from an array of items.
 * @param currentItems List of items to separate from.
 * @returns Array of AuctionItemForTable objects.
 */
export const getPendingItems = (
  currentItems: AuctionItem[]
): AuctionItemForTable[] => {
  const items = getAuctionItemForTable(currentItems).filter((item) => {
    return item.itemStatus === STATUS.PENDING;
  });
  return items;
};

/**
 * Gets an array of all auction items formatted for table usage
 * @param currentItems List of items to separate from.
 * @returns Array of AuctionItemForTable objects.
 */
export const getAllItems = (
  currentItems: AuctionItem[]
): AuctionItemForTable[] => {
  const items = getAuctionItemForTable(currentItems);
  return items;
};
