import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type BiddingTableProps } from './BiddingTable.types';
import Table from '../Table';
import { type BidTable, updateBidColumns } from './BiddingTable.columns';
import { type ColumnDef } from '@tanstack/react-table';
import Modal from '../Modal';
import Text from 'components/atoms/Text';
import { DESTINATION_ABORT, TOPIC_PREFIX, TYPEKIT } from 'utils/constants';
import styles from './biddingTable.module.scss';
import {
  DELETE_MODAL_CANCEL,
  DELETE_MODAL_DELETE,
  DELETE_MODAL_ARIA_DESCRIPTION,
  DELETE_MODAL_DESCRIPTION_CONFIRMATION,
  DELETE_MODAL_DESCRIPTION_WARNING,
  DELETE_MODAL_TITLE,
  EMPTY_BIDS_TEXT,
  BIDDING_TABLE_INTERVAL_REFRESH_RATE,
  DELETE_MODAL_DESCRIPTION_TEXT_TYPE,
  BID_CANNOT_BE_DELETED_BUTTON_TEXT,
  BID_CANNOT_BE_DELETED_DESCRIPTION,
  BID_CANNOT_BE_DELETED_MODAL_TEXT_VARIANT,
  BID_CANNOT_BE_DELETED_TITLE,
  BID_CANNOT_BE_DELETE_ARIA_DESCRIPTION,
} from './BiddingTable.constants';
import { useAuth } from 'context/Authentication/AuthContext';
import { type StompSubscription } from '@stomp/stompjs';

/**
 * Bidding table component that displays all the bids for an auction item from newest to latest.
 * Also allows to delete a bid if the auction is still active.
 *
 * @param {BidTable[]} data - Required. The bid array to display. The order doesn't matter since it is ordered by date when rendered.
 * @param {(id:number) void} deleteFunction - Required. Function to delete the bid, it must receive the id of the bid to delete.
 * @param {boolean} isActive - Required. Boolean that indicates if the current auction is open for bid. Disables delete button.
 * @param {string} itemId - Optional. String that is needed to set the destination url when using websockets.
 * @param {MutableRefObject<Client>} client - Optional. Stomp client to handle when a user is trying to delete an a bid is placed before deleting a bid.
 */

const BiddingTable: React.FC<BiddingTableProps> = ({
  data,
  deleteFunction,
  isActive,
  itemId,
  client,
}) => {
  const { currentUser } = useAuth();
  const [bidIdDelete, setBidIdDelete] = useState('');
  const [showModal, setShowModal] = useState(false);
  const destinationAbortRef = useRef<StompSubscription>();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const userId = useMemo(
    () =>
      currentUser !== undefined && currentUser !== null ? currentUser.id : '',
    [currentUser]
  );

  const onAbortedDeleteBid = useCallback((): void => {
    setShowModal(false);
    setShowWarningModal(true);
    destinationAbortRef.current?.unsubscribe();
  }, []);

  const deletePressed = useCallback(
    (deleteId: string): void => {
      setBidIdDelete(deleteId);
      setShowModal(true);
      destinationAbortRef.current = client?.current.subscribe(
        `${TOPIC_PREFIX}${itemId}${DESTINATION_ABORT}`,
        onAbortedDeleteBid
      );
    },
    [itemId, onAbortedDeleteBid, client]
  );

  const [bidColumns, setBidColumns] = useState<
    Array<ColumnDef<BidTable, string>>
  >(updateBidColumns(deletePressed, isActive, userId));

  const deleteConfirmation = (): void => {
    setShowModal(false);
    deleteFunction(bidIdDelete);
    destinationAbortRef.current?.unsubscribe();
  };

  useEffect(() => {
    setBidColumns(updateBidColumns(deletePressed, isActive, userId));
    const interval = setInterval(() => {
      setBidColumns(updateBidColumns(deletePressed, isActive, userId));
    }, BIDDING_TABLE_INTERVAL_REFRESH_RATE);
    return () => {
      clearInterval(interval);
    };
  }, [deletePressed, isActive, userId]);

  return (
    <>
      {data.length === 0 ? (
        <div className={styles['empty-text__container']}>
          <Text variant={TYPEKIT.D5}>{EMPTY_BIDS_TEXT}</Text>
        </div>
      ) : (
        <>
          <Table
            className={styles['bidding-table']}
            data={data.sort((a: BidTable, b: BidTable) => {
              return (
                new Date(b.ElapsedTime).getTime() -
                new Date(a.ElapsedTime).getTime()
              );
            })}
            columns={bidColumns}
          />
          <Modal
            isOpen={showWarningModal}
            hide={() => {
              setShowWarningModal(false);
            }}
            action={() => {
              setShowWarningModal(false);
            }}
            title={BID_CANNOT_BE_DELETED_TITLE}
            acceptButtonText={BID_CANNOT_BE_DELETED_BUTTON_TEXT}
            ariaDescribedBy={BID_CANNOT_BE_DELETE_ARIA_DESCRIPTION}
            ariaLabelledBy={BID_CANNOT_BE_DELETED_TITLE}>
            <Text
              as={BID_CANNOT_BE_DELETED_MODAL_TEXT_VARIANT}
              variant={TYPEKIT.P2}>
              {BID_CANNOT_BE_DELETED_DESCRIPTION}
            </Text>
          </Modal>
          <Modal
            isOpen={showModal}
            hide={() => {
              setShowModal(false);
            }}
            title={DELETE_MODAL_TITLE}
            cancelButtonText={DELETE_MODAL_CANCEL}
            acceptButtonText={DELETE_MODAL_DELETE}
            action={deleteConfirmation}
            ariaDescribedBy={DELETE_MODAL_ARIA_DESCRIPTION}
            ariaLabelledBy={DELETE_MODAL_TITLE}>
            <Text
              as={DELETE_MODAL_DESCRIPTION_TEXT_TYPE}
              variant={TYPEKIT.P2}>
              {DELETE_MODAL_DESCRIPTION_CONFIRMATION}
            </Text>
            <br></br>
            <Text
              as={DELETE_MODAL_DESCRIPTION_TEXT_TYPE}
              variant={TYPEKIT.P2}>
              {DELETE_MODAL_DESCRIPTION_WARNING}
            </Text>
          </Modal>
        </>
      )}
    </>
  );
};

export default BiddingTable;
