import React from 'react';
import { type ItemCardProps } from './ItemCard.types';
import Card from 'components/atoms/Card';
import { CardType, TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import Image from 'components/atoms/Image';
import Icon from 'components/atoms/Icon';
import arrow_trend_up from 'assets/svgs/arrow-trend-up.svg';
import useBreakpoint from 'hooks/useBreakpoint';
import WinnerBanner from './WinnerBanner/WinnerBanner';
import Locations from 'components/molecules/Locations/Locations';
import {
  TOP_BIDDER,
  NO_BIDS_YET,
  ITEM,
  KG_BLUE_COLOR,
  TOOLTIP_POSITION,
  ITEM_LINK_ARIA_LABEL,
  TOOL_TIP_ARIA_HIDDEN,
} from './ItemCard.constants';
import cx from 'classnames';
import DonorBanner from './DonorBanner/DonorBanner';
import ImageSlider from 'components/molecules/ImageSlider';
import Tooltip from 'components/molecules/Tooltip';
import styles from './itemCard.module.scss';

const ItemCard: React.FC<ItemCardProps> = ({
  redirectTo,
  itemTitle,
  topBidderName,
  topBidAmount,
  trend,
  isCompleted,
  category,
  donorName,
  donorPictureUrl,
  itemPhotos,
  locations,
}) => {
  const { isDesktop } = useBreakpoint();
  const columnDisplay = !isDesktop && isCompleted;

  return (
    <>
      <Card
        type={CardType.AUCTION_ITEM}
        className={styles.card}>
        <a
          href={redirectTo}
          aria-label={ITEM_LINK_ARIA_LABEL}>
          {isDesktop ? (
            <div
              className={cx(styles['card__carousel-container'], {
                [styles['card__carousel-container--completed']]: isCompleted,
              })}>
              <ImageSlider
                images={itemPhotos}
                className={cx(styles['card__img-container'], {
                  [styles['card__img-container--completed']]: isCompleted,
                })}></ImageSlider>
            </div>
          ) : (
            <div className={styles['card__img-container']}>
              <div className={styles['card__img-cover']}>
                <Image
                  src={itemPhotos[0]}
                  alt={`${itemTitle} ${ITEM}`}
                  className={styles.card__img}></Image>
              </div>
            </div>
          )}
        </a>
        {trend !== undefined && trend.trim().length > 0 && (
          <Tooltip
            className={styles['card__trend-circle']}
            content={trend}
            position={TOOLTIP_POSITION}>
            <Icon
              src={arrow_trend_up}
              color={KG_BLUE_COLOR}
              disabled
              aria-hidden={TOOL_TIP_ARIA_HIDDEN}
              className={styles['card__trend-arrow']}></Icon>
          </Tooltip>
        )}
        <a
          href={redirectTo}
          className={styles.card__link}
          aria-label={ITEM_LINK_ARIA_LABEL}>
          <div
            className={cx(
              styles.card__content,
              {
                [styles['card__content--column']]: columnDisplay,
              },
              {
                [styles['card__content--completed']]: isCompleted,
              }
            )}>
            {isCompleted &&
              topBidderName !== undefined &&
              topBidderName.trim().length > 0 && (
                <div className={styles['card__winner-banner']}>
                  <WinnerBanner
                    winnerName={topBidderName}
                    topBid={topBidAmount}></WinnerBanner>
                </div>
              )}
            <div className={styles.card__info}>
              {isDesktop && (
                <div className={styles.card__category}>
                  <Text
                    as="span"
                    variant={TYPEKIT.D6}>
                    {category}
                  </Text>
                </div>
              )}

              <Text
                as="h1"
                variant={TYPEKIT.D3}
                className={cx(styles.card__title, {
                  [styles['card__title--spacing']]: columnDisplay,
                })}>
                {itemTitle}
              </Text>
              {!isCompleted && (
                <>
                  <Text
                    as="span"
                    variant={TYPEKIT.P2}>
                    {topBidderName !== undefined &&
                    topBidderName.trim().length > 0 ? (
                      <>
                        <Text
                          as="span"
                          variant={TYPEKIT.P2}
                          className={styles['card__info--text']}>
                          {TOP_BIDDER}
                        </Text>
                        <Text
                          as="span"
                          variant={TYPEKIT.P2}>
                          {topBidderName}
                        </Text>
                      </>
                    ) : (
                      <Text
                        as="span"
                        variant={TYPEKIT.P2}
                        className={styles['card__info--text']}>
                        {NO_BIDS_YET}
                      </Text>
                    )}
                  </Text>
                  {!isDesktop && (
                    <Locations
                      showLocationIcon={false}
                      locations={locations}
                    />
                  )}
                </>
              )}
            </div>
            {!isCompleted && (
              <div className={styles['card__secondary-info']}>
                <div className={styles['card__price-container']}>
                  <div className={styles.card__price}>
                    <Text
                      displayCurrency
                      as="span"
                      variant={TYPEKIT.D4}
                      className={styles['card__price-amount']}>
                      {`$${topBidAmount}`}
                    </Text>
                  </div>
                </div>
              </div>
            )}
          </div>
          {isDesktop && (
            <>
              <DonorBanner
                donorName={donorName}
                donorPictureUrl={donorPictureUrl}
                className={styles['card__donor-banner']}
              />
              <Locations
                className={styles.card__locations}
                showLocationIcon={true}
                locations={locations}
              />
            </>
          )}
        </a>
      </Card>
    </>
  );
};

export default ItemCard;
