import React, { Children } from 'react';
import { type CardGridProps } from './CardGrid.types';
import styles from './cardgrid.module.scss';
import { usePagination } from 'hooks/usePagination';
import Pagination from '../Pagination/Pagination';
import cx from 'classnames';

const CardGrid: React.FC<CardGridProps> = ({
  type,
  children,
  totalPerPage,
}) => {
  const { totalPages, currentPage, setCurrentPage, currentRecords } =
    usePagination(children, totalPerPage);

  const showPagination = Children.count(children) > totalPerPage;

  return (
    <div
      className={cx(styles['cards-container'], {
        [styles[`cards-container--fit-width`]]: type === 'item',
      })}>
      <div
        className={cx(
          styles[`cards-container__content`],
          styles[`cards-container__content--${type}`]
        )}>
        {currentRecords}
      </div>
      <div className={styles['cards-container__pagination']}>
        {showPagination && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default CardGrid;
