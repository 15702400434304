export const TITLE = 'Place a Bid';
export const TOP_BID_TITLE = 'Top bid';
export const STARTING_BID_TITLE = 'Starting bid';
export const COUNTDOWN_TITLE = 'Ending in';
export const BUTTON_TITLE = 'Place bid';
export const BID_INPUT_TITLE = 'Minimum bidding amount ';
export const BID_INPUT_PLACEHOLDER = ' or more';
export const BUTTON_TYPE = 'button';
export const MINIMUM_BIDDING_AMOUNT_ERROR_MESSAGE =
  'Please enter an amount higher than the top bid';

export const SUBMIT_MESSAGE = 'Submitted data: ';
export const TOAST_SUCCESS_MESSAGE = 'Success! Bid placed.';

export const FORM_SHOULD_FOCUS = false;
export const SET_ERROR_TYPE = 'manual';
export const INPUT_REGISTER_VALUE = 'amount';
