import { Children, type ReactNode, useEffect, useState } from 'react';

interface Pagination {
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentRecords: ReactNode[];
}

export const usePagination = (
  children: ReactNode,
  totalPerPage: number
): Pagination => {
  const [recordsPerPage, setRecordsPerPage] = useState(totalPerPage);

  useEffect(() => {
    setRecordsPerPage(totalPerPage);
  }, [totalPerPage]);

  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const childrenArray = Children.toArray(children);

  const currentRecords = childrenArray.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const totalPages = Math.ceil(Children.count(children) / recordsPerPage);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1);
    }
  }, [children, totalPages, currentPage]);

  return { totalPages, currentPage, setCurrentPage, currentRecords };
};
