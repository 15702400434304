import { gql } from '@apollo/client';

export const CHANGE_ROLE = gql`
  mutation changeRole($input: ChangeRoleInput!) {
    changeRole(input: $input) {
      id
      firstName
      lastName
      email
      imageUrl
      dateJoined
      role {
        description
        name
        id
      }
      office {
        id
        name
      }
    }
  }
`;

export const CHANGE_OFFICE = gql`
  mutation changeOffice($input: ChangeOfficeInput!) {
    changeOffice(input: $input) {
      id
      firstName
      lastName
      email
      imageUrl
      dateJoined
      role {
        description
        name
        id
      }
      office {
        id
        name
      }
    }
  }
`;
