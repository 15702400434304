import React from 'react';
import CardGrid from 'components/molecules/CardGrid/CardGrid';
import styles from './auctionItems.module.scss';
import { GRIDTYPES } from 'utils/constants';
import ItemCard from 'components/organisms/ItemCard';
import { type AuctionItemsProps } from './AuctionItem.types';
import { NoItemsMessage } from '../NoItemsMessage/NoItemsMessage';
import { useLocation } from 'react-router-dom';
import { CARDS_PER_PAGE } from './AuctionItems.constants';

const AuctionItems: React.FC<AuctionItemsProps> = ({
  currentItems,
  isActive,
  onAddItem,
  isListEmpty,
}) => {
  const location = useLocation();

  return (
    <div className={styles['auction-items']}>
      <CardGrid
        type={GRIDTYPES.ITEM}
        totalPerPage={CARDS_PER_PAGE}>
        {currentItems
          .filter((item) => {
            return (
              item.itemIsApproved && item.itemIsReviewed && !item.itemIsDeleted
            );
          })
          .map((item) => {
            const trendMessage = item.itemIsTrending
              ? item.itemTrendMessage
              : undefined;
            const price =
              item.itemTopBid > 0 ? item.itemTopBid : item.itemStartPrice;
            return (
              <ItemCard
                key={item.itemId}
                itemTitle={item.itemName}
                category={item.itemCategory.name}
                donorName={item.itemOwner.name}
                itemPhotos={item.itemImages}
                donorPictureUrl={item.itemOwnerImage}
                topBidAmount={price}
                topBidderName={item.itemTopBidderName}
                redirectTo={`${location.pathname}/${item.itemId}`}
                isCompleted={!isActive}
                trend={trendMessage}
                locations={item.itemOffices.map((office) => office.name)}
              />
            );
          })}
      </CardGrid>
      {currentItems.length === 0 && (
        <NoItemsMessage
          isListEmpty={isListEmpty}
          onAddItem={onAddItem}
        />
      )}
    </div>
  );
};

export default AuctionItems;
