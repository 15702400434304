import React from 'react';
import Icon from 'components/atoms/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ButtonLinkChildrenProps } from './ButtonLinkChildren.types';
import Text from 'components/atoms/Text';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { EXTERNAL_SR_ONLY_TEXT } from '../ButtonLink.constants';
import styles from './buttonlinkchildren.module.scss';
import cx from 'classnames';
import { TYPEKIT } from 'utils/constants';
import loadingSpinner from 'assets/svgs/spinner.svg';

const ButtonLinkChildren: React.FC<ButtonLinkChildrenProps> = ({
  showChevron,
  icon,
  isLoading,
  size,
  children,
  type,
  isExternal,
  externalNode,
  textClassName,
}) => {
  const renderChevron = (buttonVariant: boolean): JSX.Element | null => {
    if (showChevron === true) {
      if (buttonVariant && type === 'button') {
        return (
          <FontAwesomeIcon
            icon={faChevronRight}
            className={styles['chevron--small']}
          />
        );
      } else if (!buttonVariant && type !== 'button') {
        return (
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={styles.chevron}
          />
        );
      }
    }

    return null;
  };

  const renderIcon = (buttonVariant: boolean): JSX.Element | null => {
    if (icon !== undefined) {
      if (typeof icon === 'string') {
        if (type === 'button' && buttonVariant) {
          return (
            <Icon
              src={icon}
              className={cx(styles.icon, styles[`icon--${size}`], {
                [styles.loading]: isLoading,
              })}
            />
          );
        } else if (type !== 'button' && !buttonVariant) {
          return (
            <Icon
              src={icon}
              className={cx(styles.icon, styles['icon--link'], {
                [styles.loading]: isLoading,
              })}
            />
          );
        }
      } else if ((type === 'button') === buttonVariant) {
        return (
          <FontAwesomeIcon
            icon={icon}
            className={cx({ [styles.loading]: isLoading })}
          />
        );
      }
    }
    return null;
  };

  const renderIcons = (buttonVariant: boolean): JSX.Element | null => {
    return (
      <>
        {renderChevron(buttonVariant)}
        {renderIcon(buttonVariant)}
      </>
    );
  };

  return (
    <>
      {renderIcons(false)}
      {isLoading === true && (
        <Icon
          src={loadingSpinner}
          className={cx(
            styles.icon,
            styles[`icon--${size}`],
            styles.icon__spinner
          )}
        />
      )}

      <Text
        variant={
          type === 'button' && size === 'small' ? TYPEKIT.D6 : TYPEKIT.D5
        }
        className={cx(textClassName, {
          [styles.loading]: isLoading,
        })}
        as="span">
        {' '}
        {children}
      </Text>
      {isLoading === false && isExternal && externalNode}
      {isLoading === false && isExternal && externalNode === undefined && (
        <Text
          className="sr-only"
          variant={TYPEKIT.D5}
          as="span">
          {EXTERNAL_SR_ONLY_TEXT}
        </Text>
      )}
      {renderIcons(true)}
    </>
  );
};

export default ButtonLinkChildren;
