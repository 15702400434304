import React from 'react';
import ButtonLink from 'components/molecules/ButtonLink';
import { type ApprovalActionsProps } from './ApprovalActions.types';
import cx from 'classnames';
import Icon from 'components/atoms/Icon';
import checkmarkIcon from 'assets/svgs/checkmark-icon.svg';
import exitIcon from 'assets/svgs/exit-icon.svg';
import styles from './approvalActions.module.scss';
import {
  APPROVE_BUTTON_ARIA_LABEL,
  DECLINE_BUTTON_ARIA_LABEL,
} from './ApprovalActions.constants';

const ApprovalActions: React.FC<ApprovalActionsProps> = ({
  handleApprovalAction,
}) => {
  return (
    <div className={styles['approval-actions']}>
      <ButtonLink
        as="button"
        ariaLabel={DECLINE_BUTTON_ARIA_LABEL}
        className={cx(
          styles['approval-actions__button'],
          styles['approval-actions__button--decline']
        )}
        textClassName={styles['approval-actions__button-text']}
        onClick={() => {
          handleApprovalAction(false);
        }}>
        <Icon
          src={exitIcon}
          color="interactive-white"
          variant="minor"
          className={styles['approval-actions__icon']}
        />
      </ButtonLink>
      <ButtonLink
        as="button"
        ariaLabel={APPROVE_BUTTON_ARIA_LABEL}
        className={cx(
          styles['approval-actions__button'],
          styles['approval-actions__button--approve']
        )}
        textClassName={styles['approval-actions__button-text']}
        onClick={() => {
          handleApprovalAction(true);
        }}>
        <Icon
          src={checkmarkIcon}
          color="interactive-white"
          variant="minor"
        />
      </ButtonLink>
    </div>
  );
};

export default ApprovalActions;
