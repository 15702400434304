import { useCallback, useEffect, useState } from 'react';

export type Breakpoint =
  | 'smallMobile'
  | 'largeMobile'
  | 'smallTablet'
  | 'smallDesktop'
  | 'mediumDesktop'
  | 'largeDesktop';

export type BreakpointMinMax = {
  [key in Breakpoint]: {
    min: number;
    max: number;
  };
};

const breakpoints: BreakpointMinMax = {
  smallMobile: { min: 0, max: 600 },
  largeMobile: { min: 600, max: 768 },
  smallTablet: { min: 768, max: 905 },
  smallDesktop: { min: 905, max: 1280 },
  mediumDesktop: { min: 1280, max: 1440 },
  largeDesktop: { min: 1440, max: Infinity },
};

interface UseBreakpoint {
  currentBreakpoint: Breakpoint;
  isDesktop: boolean;
  isBreakpoint: (targetBreakpoint: Breakpoint) => boolean;
}

const isDesktop = (currentBreakpoint: string): boolean => {
  return ['smallDesktop', 'mediumDesktop', 'largeDesktop'].includes(
    currentBreakpoint
  );
};

const useBreakpoint = (): UseBreakpoint => {
  const [currentBreakpoint, setCurrentBreakpoint] =
    useState<Breakpoint>('smallMobile');

  /**
   * Checks if targetBreakpoint encompasses currentBreakpoint.
   */
  const isBreakpoint = (targetBreakpoint: Breakpoint): boolean => {
    const breakpointArray: Breakpoint[] = [
      'smallMobile',
      'largeMobile',
      'smallTablet',
      'smallDesktop',
      'mediumDesktop',
      'largeDesktop',
    ];
    const targetIndex = breakpointArray.indexOf(targetBreakpoint);
    const currentIndex = breakpointArray.indexOf(currentBreakpoint);
    return currentIndex >= targetIndex;
  };

  const handleWindowResize = useCallback((): void => {
    const windowWidth = window.innerWidth;
    let breakpoint: Breakpoint = 'smallMobile';
    for (const breakpointKey in breakpoints) {
      const { min, max } = breakpoints[breakpointKey as Breakpoint];
      if (windowWidth >= min && windowWidth < max) {
        breakpoint = breakpointKey as Breakpoint;
      }
    }
    setCurrentBreakpoint(breakpoint);
  }, []);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return {
    currentBreakpoint,
    isDesktop: isDesktop(currentBreakpoint),
    isBreakpoint,
  };
};

export default useBreakpoint;
