import React, { type Ref, forwardRef, useContext } from 'react';
import { type InputProps } from './Input.types';
import maskText from './MasksHandler';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import cx from 'classnames';
import ErrorMessage from '../ErrorMessage';
import styles from './input.module.scss';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';

/**
 * A generic input component that allows the user to register required information
 *
 * @component
 * @example
 * <Input id="adress"
 * label="Adress"
 * placeholder="Type your adress here"
 * name="adress"/>
 *
 * @example
 * <Input id="phone"
 * label="Phone Number"
 * value={phoneNumber}
 * name="phoneNumber"
 * disabled/>
 *
 * @example
 * <Input id="price"
 * label="Price"
 * placeholder="Type final price here"
 * name="price"
 * mask=MASKTYPES.CURRENCY/>
 *
 *
 * @param {string} id - HTML attribute for the input component, it matches with the htmlFor label attribute
 * @param {string} label - Text to be displayed as the input component label is specified, otherwise, no label will be displayed
 * @param {string} placeholder - Text used to stand in for the actual value of the input component in case is not specified by the value prop
 * @param {boolean} disabled - If included, disables the current input
 * @param {string} name - Input's name being registered
 * @param {MASKTYPES} mask - If included, applies the specified mask to the input text
 * @param {string} value - Text displayed on the input, if not provided, input will display placeholder
 * @param {string} type - Type of the input element
 * @param {function} onChange - Callback function invoked when the input text changes
 * @param {function} onBlur - Callback function invoked when the input element loses focus
 * @param {string} error - Error message to  be displayed, if provided input will render with an error styling
 */

const Input: React.FC<InputProps> = forwardRef(
  (props: InputProps, ref: Ref<HTMLInputElement>) => {
    const {
      id,
      label,
      placeholder,
      disabled,
      name,
      mask,
      value,
      type = 'text',
      onChange,
      onBlur,
      error,
      className,
    } = props;

    const { selectedCurrency } = useContext(CurrencyContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const originalText = event.target.value;

      const maskedText =
        mask !== undefined && mask !== null
          ? maskText(originalText, mask, selectedCurrency.symbol)
          : originalText;

      event.target.value = maskedText;

      void onChange?.(event);
    };

    return (
      <div className={cx(className, styles.input__container)}>
        <input
          id={id}
          className={cx(styles.input, {
            [styles['input--error']]: error,
          })}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
          ref={ref}
          defaultValue={value}
          name={name}
          {...(disabled ?? false ? { contenteditable: 'true' } : {})}
        />
        {label !== null && (
          <Text
            as="label"
            htmlFor={id}
            variant={TYPEKIT.D5}
            className={styles.input__label}>
            {label}
          </Text>
        )}
        {Boolean(error) && (
          <ErrorMessage
            error={error}
            className={styles['error-order']}
          />
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';
export default Input;
