import React from 'react';
import { type DonorBannerProps } from './DonorBanner.types';
import { TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import styles from './donorbanner.module.scss';
import Image from 'components/atoms/Image';
import { DONATED_BY, PICTURE } from './DonorBanner.constants';
import cx from 'classnames';

const DonorBanner: React.FC<DonorBannerProps> = ({
  donorName,
  donorPictureUrl,
  className,
}) => {
  return (
    <div className={cx(styles['banner-container'], className)}>
      <div className={styles['banner-container__donor-picture']}>
        <Image
          src={donorPictureUrl}
          alt={`${donorName} ${PICTURE}`}
          className={styles['banner-container__profile-picture']}></Image>
      </div>
      <div className={styles['banner-container__donor-info']}>
        <Text
          as="span"
          variant={TYPEKIT.P3}
          className={styles['banner-container__text']}>
          {DONATED_BY}
        </Text>
        <Text
          as="span"
          variant={TYPEKIT.D5}>
          {donorName}
        </Text>
      </div>
    </div>
  );
};

export default DonorBanner;
