import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  type AuctionItemFormData,
  type AuctionItemDrawerProps,
} from './AuctionItemDrawer.types';
import { type SubmitHandler, useForm, Controller } from 'react-hook-form';
import {
  BID_FIELD,
  CATEGORY_FIELD,
  DESCRIPTION_FIELD,
  EDIT_FALSE,
  IMAGE_FIELD,
  ITEM_BID_ERROR,
  ITEM_BID_LABEL,
  ITEM_BID_PLACEHOLDER,
  ITEM_CATEGORY_ERROR,
  ITEM_CATEGORY_LABEL,
  ITEM_CATEGORY_PLACEHOLDER,
  ITEM_DESCRIPTION_ERROR,
  ITEM_DESCRIPTION_LABEL,
  ITEM_DESCRIPTION_LENGTH_ERROR,
  ITEM_DESCRIPTION_MAX_LENGTH,
  ITEM_DESCRIPTION_PLACEHOLDER,
  ITEM_DESCRIPTION_ROWS,
  ITEM_IMAGE_ERROR,
  ITEM_IMAGE_LABEL,
  ITEM_LOCATIONS_ARIA_LABEL,
  ITEM_LOCATIONS_ERROR,
  ITEM_LOCATIONS_LABEL,
  ITEM_NAME_ERROR,
  ITEM_NAME_LABEL,
  ITEM_NAME_PLACEHOLDER,
  LOCATIONS_FIELD,
  MAX_NUMBER_FILES,
  MULTIPLE_FILES,
  NAME_FIELD,
  SHOULD_FOCUS,
  TITLE_MAX_CHARACTER_ERROR_MESSAGE,
  SET_ERROR_TYPE,
  NAME_REGISTER_VALUE,
} from './AuctionItemDrawer.constants';
import Input from 'components/molecules/Input';
import Dropdown from 'components/molecules/Dropdown';
import FileUpload from '../FileUpload';
import {
  CATEGORIES_ERROR,
  FILE_MANAGER_ERROR,
  MASKTYPES,
  OFFICES_ERROR,
  TOAST_ADDED,
  TOAST_UPDATED,
} from 'utils/constants';
import CheckboxGroup from 'components/molecules/CheckboxGroup';
import DrawerForm from '../DrawerForm/DrawerForm';
import LongInput from 'components/molecules/LongInput';
import { type ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_ALL_OFFICES } from 'graphql/office/queries.gql';
import { GET_ALL_CATEGORIES } from 'graphql/category/queries.gql';
import { CREATE_ITEM, UPDATE_ITEM } from 'graphql/item/mutations.gql';
import { useToast } from 'hooks/useToast';
import { TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { parseCurrencyToFloat } from 'utils/utilities';
import { useParams } from 'react-router-dom';
import {
  type UpdateItemMutation,
  type CreateItemMutation,
  type GetAllCategoriesQuery,
  type GetAllOfficesQuery,
} from 'graphql/generated-types/graphql';
import { useAuth } from 'context/Authentication/AuthContext';
import { type DropdownOption } from 'components/molecules/Dropdown/Dropdown.types';
import { GET_ALL_ITEMS_FOR_CAMPAIGN } from 'graphql/item/queries.gql';
import fileManagerClient from 'api/fileManagerClient';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';
import { maskCurrency } from 'utils/masks';

/**
 * AuctionItemDrawer is a React component that renders a detailed form specifically designed for
 * adding or editing auction items. The form includes fields for item name, start bid price,
 * item description, category, associated locations, and images.
 *
 * @param {string} title - The title text displayed at the top of the drawer form.
 * @param {string} cancelButtonText - Text for the cancel/dismiss button in the drawer form.
 * @param {string} acceptButtonText - Text for the accept/submit button in the drawer form.
 * @param {Object} defaultValues - Default values for the form fields.
 * @param {boolean} isOpen - Indicates whether the auction item drawer is open or not.
 * @param {boolean} edit - Indicates whether the AuctionItemDrawer is going to be used for editing or not.
 * @param {function} hide - A callback function to hide/close the auction item form.
 *
 * @returns {React.FC} The rendered AuctionItemForm component.
 *
 * @example
 * // Example usage:
 * import AuctionItemForm from './AuctionItemForm';
 *
 * function MyComponent() {
 *   const [isAuctionFormOpen, setAuctionFormOpen] = React.useState(false);
 *   const closeForm = () => setAuctionFormOpen(false);
 *
 *   return (
 *     <>
 *       <button onClick={() => setAuctionFormOpen(true)}>Add Auction Item</button>
 *       <AuctionItemForm
 *         isOpen={isAuctionFormOpen}
 *         hide={closeForm}
 *         title="Add New Auction Item"
 *         cancelButtonText="Cancel"
 *         acceptButtonText="Add Item"
 *         defaultValues={{ name: '', startPrice: '', description: '', category: '', locations: [], images: [] }}
 *       />
 *     </>
 *   );
 * }
 */

const AuctionItemDrawer: React.FC<AuctionItemDrawerProps> = ({
  title,
  cancelButtonText,
  acceptButtonText,
  defaultValues,
  isOpen,
  availableOffices,
  edit = EDIT_FALSE,
  hide,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm<AuctionItemFormData>({
    shouldFocusError: SHOULD_FOCUS,
    defaultValues,
  });

  const [offices, setOffices] = useState<DropdownOption[]>();
  const [categories, setCategories] = useState<DropdownOption[]>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { campaignId } = useParams();
  const { currentUser } = useAuth();
  const { conversionRate, selectedCurrency } = useContext(CurrencyContext);

  const [createItemMutation] = useMutation<CreateItemMutation>(CREATE_ITEM, {
    onCompleted: (response) => {
      showToastSuccess(`${response.createItem.name} ${TOAST_ADDED}`);
      hideForm();
    },
    onError: (error: ApolloError) => {
      showToastError(error.message);
    },
    refetchQueries: [
      { query: GET_ALL_ITEMS_FOR_CAMPAIGN, variables: { campaignId } },
    ],
  });

  const [updateItemMutation] = useMutation<UpdateItemMutation>(UPDATE_ITEM, {
    onCompleted: (response) => {
      showToastSuccess(`${response.updateItem?.name} ${TOAST_UPDATED}`);
      hideForm();
    },
    onError: (error: ApolloError) => {
      setSubmitting(false);
      showToastError(error.message);
    },
    refetchQueries: [
      { query: GET_ALL_ITEMS_FOR_CAMPAIGN, variables: { campaignId } },
    ],
  });

  const {
    loading: loadingOffices,
    error: errorOffices,
    data: dataOffices,
  } = useQuery<GetAllOfficesQuery>(GET_ALL_OFFICES);

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
  } = useQuery<GetAllCategoriesQuery>(GET_ALL_CATEGORIES);

  const toast = useToast();

  const showToastSuccess = useCallback(
    (message: string): void => {
      if (toast !== undefined) toast.open(message, TOAST_TYPE.SUCCESS);
    },
    [toast]
  );

  const showToastError = useCallback(
    (message: string): void => {
      if (toast !== undefined) toast.open(message, TOAST_TYPE.ERROR);
    },
    [toast]
  );

  useEffect(() => {
    if (availableOffices !== null && availableOffices !== undefined) {
      setOffices(availableOffices);
    } else {
      if (errorOffices === undefined) {
        if (!loadingOffices && dataOffices !== undefined) {
          setOffices(dataOffices.getAllOffices);
        }
      } else {
        showToastError(OFFICES_ERROR);
      }
    }
  }, [
    loadingOffices,
    errorOffices,
    dataOffices,
    showToastSuccess,
    showToastError,
    availableOffices,
  ]);

  useEffect(() => {
    if (errorCategories === undefined) {
      if (!loadingCategories && dataCategories !== undefined) {
        setCategories(dataCategories.getAllCategories);
      }
    } else {
      showToastError(CATEGORIES_ERROR);
    }
  }, [
    loadingCategories,
    errorCategories,
    dataCategories,
    showToastSuccess,
    showToastError,
  ]);

  useEffect(() => {
    if (isOpen && !edit) {
      reset({ name: '', startPrice: '', description: '' });
    } else {
      reset({
        name: defaultValues?.name,
        startPrice: defaultValues?.startPrice,
        description: defaultValues?.description,
      });
    }
  }, [defaultValues, isOpen, edit, reset]);

  useEffect(() => {
    if (defaultValues !== undefined || !edit) reset();
  }, [defaultValues, edit, reset]);

  const onSubmitAddItem: SubmitHandler<AuctionItemFormData> = async (item) => {
    let newImageKeys: string[] = [];
    setSubmitting(true);
    if (item.name.length > 40) {
      setError(NAME_REGISTER_VALUE, {
        type: SET_ERROR_TYPE,
        message: TITLE_MAX_CHARACTER_ERROR_MESSAGE,
      });
      setSubmitting(false);
      showToastError(TITLE_MAX_CHARACTER_ERROR_MESSAGE);
    } else {
      try {
        newImageKeys = await Promise.all(
          item.images.localFiles.map(async (image) => {
            return await fileManagerClient.saveImage(image.file);
          })
        );
      } catch {
        setSubmitting(false);
        showToastError(FILE_MANAGER_ERROR);
        return;
      }

      const response = await createItemMutation({
        variables: {
          name: item.name,
          startPrice: parseCurrencyToFloat(
            item.startPrice,
            conversionRate,
            selectedCurrency.symbol
          ),
          description: item.description,
          isReviewed: false,
          isApproved: false,
          images: newImageKeys,
          category: item.category[0].id,
          user: currentUser?.id,
          campaign: campaignId,
          updatedBy: currentUser?.id,
          isDeleted: false,
          offices: item.locations,
        },
      });

      if (response.errors !== undefined) {
        setSubmitting(false);
        void fileManagerClient.deleteImages(newImageKeys);
      }
    }
  };

  const onSubmitEditItem: SubmitHandler<AuctionItemFormData> = async (
    updatedItem
  ) => {
    setSubmitting(true);
    const item = { ...updatedItem, id: defaultValues?.id };
    const existingImageKeys = item.images.remoteFiles.map(
      // Doesn't need to be async since it's a local operation
      (image) => fileManagerClient.getKeyFromURL(image.url)
    );

    const newImages = item.images.localFiles;

    let newImageKeys: string[] = [];

    try {
      const newImageURLs = await Promise.all(
        newImages.map(async (image) => {
          return await fileManagerClient.saveImage(image.file);
        })
      );

      newImageKeys = newImageURLs.map((url) =>
        fileManagerClient.getKeyFromURL(url)
      );
    } catch {
      setSubmitting(false);
      showToastError(FILE_MANAGER_ERROR);
      return;
    }

    const response = await updateItemMutation({
      variables: {
        id: item.id,
        name: item.name,
        startPrice: parseCurrencyToFloat(
          item.startPrice,
          conversionRate,
          selectedCurrency.symbol
        ),
        description: item.description,
        images: [...existingImageKeys, ...newImageKeys],
        category: item.category[0].id,
        campaign: campaignId,
        user: currentUser?.id,
        updatedBy: currentUser?.id,
        offices: item.locations,
      },
    });

    if (response.errors !== undefined && newImageKeys.length > 0) {
      setSubmitting(false);
      void fileManagerClient.deleteImages(newImageKeys);
    }
  };

  const hideForm = (): void => {
    hide();
    setSubmitting(false);
  };

  return (
    <DrawerForm
      isOpen={isOpen}
      hide={hideForm}
      title={title}
      disabled={offices === undefined || categories === undefined}
      onSubmit={(event) => {
        const theReturnedFunction = handleSubmit(
          !edit ? onSubmitAddItem : onSubmitEditItem
        );
        void theReturnedFunction(event);
      }}
      submitting={submitting}
      cancelButtonText={cancelButtonText}
      acceptButtonText={acceptButtonText}>
      <Input
        id={NAME_FIELD}
        label={ITEM_NAME_LABEL}
        placeholder={ITEM_NAME_PLACEHOLDER}
        error={errors?.name?.message}
        value={defaultValues?.name}
        {...register(NAME_FIELD, {
          required: ITEM_NAME_ERROR,
        })}
      />
      <Input
        id={BID_FIELD}
        label={ITEM_BID_LABEL}
        placeholder={ITEM_BID_PLACEHOLDER}
        value={
          defaultValues?.startPrice !== undefined
            ? maskCurrency(defaultValues.startPrice)
            : undefined
        }
        mask={MASKTYPES.CURRENCY}
        error={errors?.startPrice?.message}
        {...register(BID_FIELD, {
          required: ITEM_BID_ERROR,
        })}
      />
      <LongInput
        id={DESCRIPTION_FIELD}
        label={ITEM_DESCRIPTION_LABEL}
        placeholder={ITEM_DESCRIPTION_PLACEHOLDER}
        maxLength={ITEM_DESCRIPTION_MAX_LENGTH}
        rows={ITEM_DESCRIPTION_ROWS}
        value={defaultValues?.description}
        error={errors?.description?.message}
        {...register(DESCRIPTION_FIELD, {
          required: ITEM_DESCRIPTION_ERROR,
          validate: (value) =>
            value.length <= ITEM_DESCRIPTION_MAX_LENGTH ||
            ITEM_DESCRIPTION_LENGTH_ERROR,
        })}
      />
      {categories !== undefined && (
        <Controller
          control={control}
          name={CATEGORY_FIELD}
          rules={{ required: ITEM_CATEGORY_ERROR }}
          defaultValue={defaultValues?.category}
          render={({ field }) => (
            <Dropdown
              label={ITEM_CATEGORY_LABEL}
              placeholder={ITEM_CATEGORY_PLACEHOLDER}
              options={categories}
              defaultOption={edit ? field.value[0] : undefined}
              errorMessage={errors?.category?.message}
              onChange={field.onChange}
            />
          )}
        />
      )}
      {offices !== undefined && (
        <Controller
          control={control}
          name={LOCATIONS_FIELD}
          rules={{ required: ITEM_LOCATIONS_ERROR }}
          defaultValue={defaultValues?.locations}
          render={({ field }) => (
            <CheckboxGroup
              ariaLabel={ITEM_LOCATIONS_ARIA_LABEL}
              label={ITEM_LOCATIONS_LABEL}
              options={offices}
              defaultValues={field.value}
              onCheckChange={field.onChange}
              errorMessage={errors?.locations?.message}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={IMAGE_FIELD}
        rules={{
          validate: ({ localFiles, remoteFiles }) => {
            if (localFiles.length === 0 && remoteFiles.length === 0)
              return ITEM_IMAGE_ERROR;
          },
        }}
        render={({ field }) => (
          <FileUpload
            label={ITEM_IMAGE_LABEL}
            multiple={MULTIPLE_FILES}
            maxNumberFiles={MAX_NUMBER_FILES}
            defaultValue={defaultValues?.images?.remoteFiles}
            onSelectedFilesChange={field.onChange}
            errorMessage={errors?.images?.message}
          />
        )}
      />
    </DrawerForm>
  );
};

export default AuctionItemDrawer;
