import React from 'react';
import { useTimeout } from 'hooks/useTimeout';
import styles from './toast.module.scss';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import warning_circle_logo from 'assets/svgs/warning-circle-logo.svg';
import close_icon from 'assets/svgs/close-icon.svg';
import { TYPEKIT } from 'utils/constants';
import { type ToastProps } from './Toast.types';
import { TIMEOUT } from './toast.constants';
import cx from 'classnames';

const iconMap = {
  success: { icon: faCircleCheck, color: 'system-success-green' },
  warning: { icon: warning_circle_logo, color: 'system-caution-yellow' },
  error: { icon: close_icon, color: 'system-error-red' },
};

export const Toast: React.FC<ToastProps> = ({ close, children, type }) => {
  useTimeout(() => {
    close?.();
  }, TIMEOUT);

  const { icon, color } = iconMap[type];

  return (
    <div
      onClick={() => {
        close();
      }}
      className={cx(styles['open-animation'], styles.toast)}
      role="status"
      aria-live="polite">
      <Icon
        aria-hidden="true"
        disabled
        src={icon}
        variant="minor"
        color={color}
        className={styles.toast__icon}
      />
      <Text
        as="span"
        variant={TYPEKIT.D5}>
        {children}
      </Text>
    </div>
  );
};

export default Toast;
