import React from 'react';
import { type UserTableProps } from './UserTable.types';
import styles from './userTable.module.scss';

import { type RankingInfo } from '@tanstack/match-sorter-utils';
import { type VisibilityState, type FilterFn } from '@tanstack/react-table';

import Table from 'components/organisms/Table';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}
/**
 * Represents a table component that displays user data.
 *
 * @component
 * @param {User[]} [props.data] - An array of user data to display as rows in the table.
 * @param {User[]} [props.columns] - An array containing the definition of each table column.
 * @param {string} [props.className] - Custom classname for the table component.
 * @param {React.Dispatch<React.SetStateAction<string>>} [props.setGlobalFilter] - A function to set the global filter value.
 * @param {React.Dispatch<React.SetStateAction<ColumnFiltersState>>} [props.setColumnFilter] - A function to set the column filter value.
 * @param {React.Dispatch<React.SetStateAction<SortingState>>} [props.setSorting] - A function to set the sorting filter value.
 * @param {string} [props.globalFilter] - Global filter value for the table.
 * @param {ColumnFiltersState} [props.columnFilters] - Filter values for the table.
 * @param {SortingState} [props.sorting] - Sorting values for the table.
 * @returns {React.FunctionComponent} The UserTable component.
 */
const UserTable: React.FC<UserTableProps> = ({
  data,
  className,
  setGlobalFilter,
  setColumnFilter,
  setSorting,
  columnFilters,
  globalFilter,
  sorting,
  columns,
}) => {
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      DateJoined: false,
    });

  return (
    <div className={styles.table__container}>
      <Table
        className={className}
        data={data}
        columns={columns}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        setColumnFilters={setColumnFilter}
        columnFilters={columnFilters}
        sorting={sorting}
        setSorting={setSorting}
        setColumnVisibility={setColumnVisibility}
        columnVisibility={columnVisibility}
      />
    </div>
  );
};

/**
 * The display name for the UserTable component.
 * @type {string}
 */
UserTable.displayName = 'UserTable';

export default UserTable;
