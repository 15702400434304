export const ADMIN_ROLES_TITLE = 'Users';
export const CATEGORIES_TITLE = 'Categories';
export const SETTINGS_SEARCHBAR_PLACEHOLDER = 'Search for...';
export const FILTER_RESET_OPTION_ID = '0';
export const DEFAULT_DESC_SORTING = false;
export const SORT_LABEL = 'Sort by';
export const SORT_ID = 'sort';
export const FILTER_OFFICE_TEXT = 'All Offices';
export const FILTER_OFFICE_LABEL = 'Offices';
export const FILTER_ROLE_TEXT = 'All Roles';
export const FILTER_ROLE_LABEL = 'Roles';
export const FILTER_DEFAULT_VALUE = '';
export const SORT_LASTNAME_ID = 'LastName';
export const TOAST_ASSIGN_SUCCESS = 'You assigned';
export const TOAST_ASSIGN_OFFICE = 'office to';
export const TOAST_ASSIGN_ROLE = 'as an';
export const TOAST_CREATE_SUCCESS = 'You created';
export const TOAST_DELETE_SUCCESS = 'Deleted category successfully.';
export const TOAST_CREATE_CATEGORY = 'as a new auction item category';
export const DELETE_CATEGORY_MODAL_ACCEPT = 'Delete';
export const DELETE_CATEGORY_MODAL_CANCEL = 'Cancel';
export const DELETE_CATEGORY_MODAL_LABEL = 'DeleteCategoryConfirmationLabel';
export const DELETE_CATEGORY_MODAL_DESCRIPTION =
  'DeleteCategoryConfirmationDescription';
export const DELETE_CATEGORY_MODAL_TITLE = 'Delete category';
export const DELETE_CATEGORY_MODAL_BODY =
  'You are about to delete a category named';
export const DELETE_CATEGORY_MODAL_CONFIRMATION =
  'Are you sure you want to proceed?';
export const DELETE_CATEGORY_MODAL_ERROR_ACCEPT = 'OK';
export const DELETE_CATEGORY_MODAL_ERROR_LABEL = 'DeleteCategoryErrorLabel';
export const DELETE_CATEGORY_MODAL_ERROR_DESCRIPTION =
  'DeleteCategoryErrorDescription';
export const DELETE_CATEGORY_MODAL_ERROR_TITLE = 'Unable to delete';
export const DELETE_CATEGORY_MODAL_ERROR_BODY =
  'You cannot delete this category because there are existing auction items that are associated with this category.';

export const SORT_OPTIONS = [
  { id: '1', name: 'Last Name' },
  { id: '2', name: 'Date Joined' },
];
