export const PREV_PAGE = 'Previous Page';
export const NEXT_PAGE = 'Next Page';
export const NAVIGATION_INSTRUCTION = 'Go to page ';
export const ELLIPSIS = '...';
export const DEFAULT_MAX_PAGES_VISIBLE = 3;
export const FIRST_PAGE = 1;
export const MINIMUM_RANGE_START = 2;
export const MINIMUM_RANGE_END_OFFSET = 1;
export const AS_SPAN = 'span';
export const AS_BUTTON = 'button';
