import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  type AuctionItemForTable,
  type AuctionItemTableProps,
} from './AuctionItemTable.types';
import Table from '../Table';
import { createColumnHelper } from '@tanstack/table-core';
import Carousel from 'components/molecules/Carousel';
import { getColumnSettings, getImageArray } from './AuctionItemTable.cells';
import styles from './auctionItemTable.module.scss';
import { type ColumnDef } from '@tanstack/react-table';

const AuctionItemTable: React.FC<AuctionItemTableProps> = ({
  items,
  onAdminAction,
  approval = false,
  isActive,
  isAdmin,
  onEdit,
  onDelete,
  globalFilter,
  setGlobalFilter,
  className,
}) => {
  const handleClickCarousel = useCallback((images: string): void => {
    setCarouselImages(getImageArray(images));
  }, []);

  const getAccessorColumns = useCallback((): Array<
    ColumnDef<AuctionItemForTable, any>
  > => {
    const columnSettings = getColumnSettings(
      handleClickCarousel,
      approval,
      isActive,
      isAdmin,
      onDelete,
      onEdit,
      onAdminAction
    );

    return columnSettings.map((column) => {
      return columnHelper.current.accessor((row) => row[column.key], {
        id: column.key,
        header: column.header,
        cell: column.cell,
      });
    });
  }, [
    handleClickCarousel,
    approval,
    isActive,
    isAdmin,
    onDelete,
    onEdit,
    onAdminAction,
  ]);

  const [displayCarousel, setDisplayCarousel] = useState(false);
  const [carouselImages, setCarouselImages] = useState([] as string[]);
  const columnHelper = useRef(createColumnHelper<AuctionItemForTable>());
  const [accessorColumns, setAccessorColumns] = useState(getAccessorColumns());

  useEffect(() => {
    setAccessorColumns(getAccessorColumns());
  }, [
    handleClickCarousel,
    approval,
    isActive,
    isAdmin,
    onDelete,
    onEdit,
    onAdminAction,
    getAccessorColumns,
  ]);

  useEffect(() => {
    carouselImages.length > 0 && toggleCarousel();
  }, [carouselImages]);

  const toggleCarousel = (): void => {
    setDisplayCarousel((prevState) => {
      return !prevState;
    });
  };

  return (
    <>
      <div className={styles['item-table__container']}>
        <Table<AuctionItemForTable>
          className={className}
          data={items}
          columns={accessorColumns}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <Carousel
        isOpen={displayCarousel}
        images={carouselImages}
        hide={toggleCarousel}
      />
    </>
  );
};

export default AuctionItemTable;
