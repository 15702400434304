import React, { useRef, useState } from 'react';
import Image from 'components/atoms/Image';
import ButtonLink from 'components/molecules/ButtonLink';
import Countdown from 'components/molecules/Countdown/Countdown';
import Carousel from 'components/molecules/Carousel';
import Locations from 'components/molecules/Locations/Locations';
import { type AuctionItemDetailsCardProps } from './AuctionItemDetailsCard.types';
import styles from './auctionItemDetailsCard.module.scss';
import Text from 'components/atoms/Text';
import { CardType, TYPEKIT } from 'utils/constants';
import Card from 'components/atoms/Card';
import useBreakpoint from 'hooks/useBreakpoint';
import {
  AUCTION_ITEM_DETAIL_GALLERY_ALT as GALLERY_ALT,
  AUCTION_ITEM_DETAIL_INFORMATION_TYPE as INFORMATION_TYPE,
  AUCTION_ITEM_DETAIL_TITLE_TYPE as TITLE_TYPE,
  AUCTION_ITEM_DETAIL_COUNTDOWN_SUBTITLE as COUNTDOWN_SUBTITLE,
  AUCTION_ITEM_DETAIL_DONOR_NAME_SUBTITLE as DONOR_NAME_SUBTITLE,
  AUCTION_ITEM_DETAIL_GALLERY_BUTTON_PREFIX as GALLERY_BUTTON_PREFIX,
  AUCTION_ITEM_DETAIL_GALLERY_BUTTON_SUFIX as GALLERY_BUTTON_SUFIX,
  AUCTION_ITEM_DETAIL_GALLERY_ASPECT_RATIO,
  AUCTION_ITEM_DETAIL_DONOR_PICTURE_ALT,
  AUCTION_ITEM_DETAIL_SUBTITLE_TYPE,
} from './AuctionItemDetailsCard.constants';
import { useCountdown } from 'hooks/useCountdown';

/**
 * Card component that displays the information of an Auction Item.
 *
 * @param {string} name - The name of the auction item
 * @param {string} description - Description of the auction item
 * @param {string[]} locations - Array of locations where the auction item is available
 * @param {string} donorPicture - Url string for the donor picture
 * @param {string[]} images - Array of strings with urls for the auction item's images
 * @param {string} targetDate - String containing the target date when the Auction will end.
 */

const AuctionItemDetailsCard: React.FC<AuctionItemDetailsCardProps> = ({
  name,
  description,
  locations,
  donor,
  donorPicture,
  images,
  targetDate,
}) => {
  const galleryButton = useRef<HTMLButtonElement>(null);
  const { isDesktop } = useBreakpoint();
  const { counterOff } = useCountdown(targetDate);

  const [showCarousel, setShowCarousel] = useState(false);

  return (
    <>
      <Card
        type={CardType.AUCTION_DETAILS}
        className={styles.container}>
        <div className={styles.gallery}>
          <Image
            alt={GALLERY_ALT}
            src={images[0]}
            aspectRatio={AUCTION_ITEM_DETAIL_GALLERY_ASPECT_RATIO}
            className={styles.gallery__image}
          />
          <ButtonLink
            as="button"
            variant="secondary"
            size="small"
            onClick={() => {
              setShowCarousel(true);
            }}
            className={styles.gallery__button}
            ref={galleryButton}>
            {GALLERY_BUTTON_PREFIX + images.length + GALLERY_BUTTON_SUFIX}
          </ButtonLink>
        </div>
        <div className={styles.container__details}>
          <div className={styles.information}>
            <div className={styles.donor}>
              <Locations locations={locations} />
            </div>
            <Text
              as={TITLE_TYPE}
              variant={TYPEKIT.D2}>
              {name}
            </Text>
            <Text
              as={INFORMATION_TYPE}
              variant={TYPEKIT.P2}>
              {description}
            </Text>
          </div>
          <div className={styles['container__auction-info']}>
            <div className={styles.donor}>
              <Image
                className={styles.donor__image}
                alt={AUCTION_ITEM_DETAIL_DONOR_PICTURE_ALT}
                src={donorPicture}
              />

              <div className={styles.donor__name}>
                <Text
                  as={AUCTION_ITEM_DETAIL_SUBTITLE_TYPE}
                  variant={TYPEKIT.P3}
                  className={styles.subtitle}>
                  {DONOR_NAME_SUBTITLE}
                </Text>
                <Text
                  as={INFORMATION_TYPE}
                  variant={TYPEKIT.D5}>
                  {donor}
                </Text>
              </div>
            </div>
            {!isDesktop && !counterOff && (
              <div className={styles.countdown}>
                <Text
                  as={AUCTION_ITEM_DETAIL_SUBTITLE_TYPE}
                  variant={TYPEKIT.P3}
                  className={styles.subtitle}>
                  {COUNTDOWN_SUBTITLE}
                </Text>
                <div className={styles.countdown__container}>
                  <Countdown
                    targetDate={targetDate}
                    variant={TYPEKIT.D5}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
      <Carousel
        elementRef={galleryButton.current as HTMLElement}
        hide={() => {
          setShowCarousel(false);
        }}
        isOpen={showCarousel}
        images={images}
      />
    </>
  );
};

export default AuctionItemDetailsCard;
