import React from 'react';
import DisplayTime from '../DisplayTime/DisplayTime';
import { type ShowCountdownProps } from './ShowCountdown.types';
import styles from './showcountdown.module.scss';
import cx from 'classnames';
import {
  DAYS,
  HOURS,
  MINUTES,
  MONTHS,
  SECONDS,
  COLON_KEY,
  TIMER_ROLE,
  COUNTDOWN_ARIA_LABEL,
} from './ShowCountdown.constants';
import Text from 'components/atoms/Text';

export const ShowCountdown: React.FC<ShowCountdownProps> = ({
  months,
  days,
  hours,
  minutes,
  seconds,
  descriptive,
  spaced,
  variant,
}) => {
  const showMonth = months > 0;

  return (
    <div
      role={TIMER_ROLE}
      aria-labelledby={COUNTDOWN_ARIA_LABEL}
      className={styles.showcountdown}>
      {showMonth && [
        <DisplayTime
          key={MONTHS}
          value={months.toString()}
          type={MONTHS}
          descriptive={descriptive}
          spaced={spaced}
          variant={variant}
        />,
        <Text
          as="p"
          key={COLON_KEY}
          className={cx(styles.showcountdown__colon, {
            [styles['showcountdown__colon--spaced']]: spaced,
          })}
          variant={variant}>
          :
        </Text>,
      ]}

      <DisplayTime
        value={days.toString()}
        type={DAYS}
        descriptive={descriptive}
        spaced={spaced}
        variant={variant}
      />

      <Text
        as="p"
        className={cx(styles.showcountdown__colon, {
          [styles['showcountdown__colon--spaced']]: spaced,
        })}
        variant={variant}>
        :
      </Text>

      <DisplayTime
        value={hours.toString()}
        type={HOURS}
        descriptive={descriptive}
        spaced={spaced}
        variant={variant}
      />
      <Text
        as="p"
        className={cx(styles.showcountdown__colon, {
          [styles['showcountdown__colon--spaced']]: spaced,
        })}
        variant={variant}>
        :
      </Text>

      <DisplayTime
        value={minutes.toString()}
        type={MINUTES}
        descriptive={descriptive}
        spaced={spaced}
        variant={variant}
      />

      {!showMonth && [
        <Text
          as="p"
          key={COLON_KEY}
          className={cx(styles.showcountdown__colon, {
            [styles['showcountdown__colon--spaced']]: spaced,
          })}
          variant={variant}>
          :
        </Text>,
        <DisplayTime
          value={seconds.toString()}
          type={SECONDS}
          key={SECONDS}
          descriptive={descriptive}
          spaced={spaced}
          variant={variant}
        />,
      ]}
    </div>
  );
};
