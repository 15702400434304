import React from 'react';
import { type ErrorPageInfoProps } from './ErrorPageInfo.types';
import Icon from 'components/atoms/Icon';
import { TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import ButtonLink from 'components/molecules/ButtonLink';
import styles from './errorPageInfo.module.scss';
import { useNavigate } from 'react-router-dom';
import {
  ICON_COLOR,
  SMALL_ICON,
  SMALL_DESCRIPTION,
  ARIA_HIDDEN_ICON_PROPERTY,
  BUTTON_VARIANT,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from './ErrorPageInfo.constants';
import cx from 'classnames';

const ErrorPageInfo: React.FC<ErrorPageInfoProps> = ({
  icon,
  title,
  description,
  buttonTitle,
  iconSize = 'default',
  descriptionSize = 'default',
  redirectTo,
}) => {
  const navigate = useNavigate();
  const handleClick = (): void => {
    navigate(redirectTo);
  };

  return (
    <div className={styles['error-page']}>
      <div className={styles['message-container']}>
        <Icon
          className={cx(styles['message-container__icon'], {
            [styles['message-container__icon--small']]: iconSize === SMALL_ICON,
          })}
          src={icon}
          aria-hidden={ARIA_HIDDEN_ICON_PROPERTY}
          color={ICON_COLOR}
          disabled
        />
        <Text
          as="h1"
          variant={TYPEKIT.D1}
          className={styles['message-container__title']}>
          {title}
        </Text>
        <Text
          as="p"
          variant={TYPEKIT.P1}
          className={cx(styles['message-container__description'], {
            [styles['message-container__description--small']]:
              descriptionSize === SMALL_DESCRIPTION,
          })}>
          {description}
        </Text>
      </div>
      <ButtonLink
        variant={BUTTON_VARIANT}
        size={BUTTON_SIZE}
        as={BUTTON_TYPE}
        onClick={handleClick}>
        {buttonTitle}
      </ButtonLink>
    </div>
  );
};

export default ErrorPageInfo;
