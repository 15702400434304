import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      email
      imageUrl
      dateJoined
      role {
        description
        name
        id
      }
      office {
        id
        name
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      firstName
      lastName
      email
      imageUrl
      dateJoined
      role {
        id
        name
        description
      }
      office {
        id
        name
      }
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query getAllRoles {
    getAllRoles {
      id
      name
      description
    }
  }
`;
