import React, { useEffect, useState } from 'react';
import ModalOverlay from 'components/atoms/ModalOverlay';
import { type DrawerProps } from './Drawer.types';
import cx from 'classnames';
import styles from './drawer.module.scss';
import useHidePageOverflow from 'hooks/useHidePageOverflow';
import {
  OVERLAY_OPEN_TIMEOUT,
  OVERLAY_CLOSE_TIMEOUT,
} from './Drawer.constants';

/**
 * Drawer is a React component that provides a slide-out drawer wrapped within a modal overlay.
 *
 * @param {boolean} isOpen - Indicates whether the drawer is open or not.
 * @param {function} hide - A callback function to hide/close the drawer.
 * @param {string} [className] - Additional class names to be applied to the root element of the drawer.
 * @param {React.ReactNode} children - Content to be rendered inside the drawer.
 *
 * @returns {React.FC} The rendered Drawer component.
 *
 * @example
 * // Example usage:
 * import Drawer from './Drawer';
 *
 * function MyComponent() {
 *   const [isDrawerOpen, setDrawerOpen] = React.useState(false);
 *   const handleClose = () => setDrawerOpen(false);
 *
 *   return (
 *     <>
 *       <button onClick={() => setDrawerOpen(true)}>Open Drawer</button>
 *       <Drawer isOpen={isDrawerOpen} hide={handleClose}>
 *         <div>Drawer Content Here</div>
 *       </Drawer>
 *     </>
 *   );
 * }
 */

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  hide,
  className,
  children,
}) => {
  useHidePageOverflow(isOpen);
  const [showOverlay, setShowOverlay] = useState(isOpen);

  useEffect(() => {
    if (isOpen !== showOverlay) {
      setTimeout(
        () => {
          setShowOverlay(!showOverlay);
        },
        isOpen ? OVERLAY_OPEN_TIMEOUT : OVERLAY_CLOSE_TIMEOUT
      );
    }
  }, [isOpen]);

  return showOverlay ? (
    <ModalOverlay
      isOpen={isOpen}
      hideModal={hide}
      className={cx(
        styles.drawer,
        className,
        {
          [styles['drawer--open']]: isOpen,
        },
        {
          [styles['drawer--close']]: !isOpen,
        }
      )}>
      <div
        className={cx(
          styles.drawer__container,
          {
            [styles['drawer__container--open']]: isOpen,
          },
          {
            [styles['drawer__container--close']]: !isOpen,
          }
        )}>
        {children}
      </div>
    </ModalOverlay>
  ) : null;
};

export default Drawer;
