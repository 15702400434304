import React from 'react';
import { type ImageProps } from './Image.types';
import styles from './image.module.scss';
import cx from 'classnames';

/**
 * The Image component renders an image with an alt text.
 * @param {string} src - Required. Source of the image, can be a locally loaded image or an external link.
 * @param {string} alt - Required. Alt Text for screen readers.
 * @param {string} className - Classname to style the image.
 * @param {string} role - Role of the image for screenreaders.
 * @param {'original' | '16-9' | '9-16' | '4-3' | '3-4' | '1-1'} aspectRatio - Controls the aspect ratio of the image, can be repositioned using the property background-position
 *
 */

const Image: React.FC<ImageProps> = ({
  src,
  role,
  alt,
  className,
  aspectRatio = 'original',
  ...restProps
}) => {
  if (aspectRatio !== 'original') {
    const imageClasses = cx(
      styles[`image--with-ratio--${aspectRatio}`],
      styles['image--with-ratio'],
      className
    );
    return (
      <div
        className={imageClasses}
        style={{ backgroundImage: `url(${src})` }}
        aria-label={alt}
        role="img"
        {...restProps}></div>
    );
  }
  const imageClasses = cx(styles.image, className);
  return (
    <img
      className={imageClasses}
      src={src}
      alt={alt}
      role={role}
      {...restProps}
    />
  );
};

export default Image;
