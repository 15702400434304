import React from 'react';
import cx from 'classnames';
import styles from './statusTag.module.scss';
import { type StatusTagProps } from './StatusTag.types';

const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  return (
    <div className={cx(styles.tag, styles[`tag--${status}`])}>{status}</div>
  );
};

export default StatusTag;
