import { flexRender } from '@tanstack/react-table';
import cx from 'classnames';
import { type TableHeaderProps } from './TableHeader.types';
import styles from './tableHeader.module.scss';

/**
 * Renders a table header cell component with flexible content based on the column definition.
 *
 * @template TData - The type of data for the table.
 * @param {TableHeaderProps<TData>} props - The props for the TableHeader component.
 * @param {string} [props.className] - Custom css class name for the table header cell.
 * @param {Header<TData, unknown>} props.header - The header object provided by tanstack library.
 * @param {string} [props.type] - The type of the table header cell.
 * @returns {JSX.Element} - A JSX element representing the TableHeader component.
 */
const TableHeader = <TData,>({
  className,
  header,
  type,
}: TableHeaderProps<TData>): JSX.Element => {
  return (
    <th
      className={cx(
        className,
        styles['table__header-cell'],
        styles[`table__header-cell--${type}`]
      )}
      key={header.id}>
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
};

export default TableHeader;
