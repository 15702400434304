import React, { useEffect, useState } from 'react';
import { type BottomSheetProps } from './BottomSheet.types';
import ModalOverlay from 'components/atoms/ModalOverlay';
import cx from 'classnames';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import useHidePageOverflow from 'hooks/useHidePageOverflow';
import ex_icon from 'assets/svgs/ex-icon.svg';
import {
  OVERLAY_OPEN_TIMEOUT,
  OVERLAY_CLOSE_TIMEOUT,
} from './BottomSheet.constants';
import Icon from 'components/atoms/Icon';
import styles from './bottomSheet.module.scss';

/**
 * The Bottom Sheet is a (Mobile Only) React component that acts like an overlay that slides up from the bottom of the screen.
 *
 *
 * @param {boolean} isOpen - indicates wheter the bottom sheet should be shown or not
 * @param {function} hide - Function to perform when the close button is clicked
 * @param {ReactNode} children - react node that act like the bottom sheet content
 * @param {string} Title - Title to be displayed
 * @param {string} buttonText - Text that the button will show to let know the user the button functionality
 * @param {function} onClick -  Function to perform when the button is clicked
 * @param {string} className -  Styles that can be applied to the bottom sheet
 */

const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  hide,
  children,
  title,
  closeAriaLabel,
  className,
}) => {
  useHidePageOverflow(isOpen);

  const [showOverlay, setShowOverlay] = useState(isOpen);

  useEffect(() => {
    if (isOpen !== showOverlay) {
      setTimeout(
        () => {
          setShowOverlay(!showOverlay);
        },
        isOpen ? OVERLAY_OPEN_TIMEOUT : OVERLAY_CLOSE_TIMEOUT
      );
    }
  }, [isOpen]);

  return showOverlay ? (
    <ModalOverlay
      isOpen={isOpen}
      hideModal={hide}
      className={cx(
        styles.sheet,
        className,
        {
          [styles['sheet--open']]: isOpen,
        },
        {
          [styles['sheet--close']]: !isOpen,
        }
      )}>
      <div
        className={cx(
          styles.sheet__container,
          {
            [styles['sheet__container--open']]: isOpen,
          },
          {
            [styles['sheet__container--close']]: !isOpen,
          }
        )}>
        <div className={styles.sheet__title}>
          <Text
            as="span"
            variant={TYPEKIT.D3}
            className={styles.sheet__title__text}>
            {title}
          </Text>
          <Icon
            src={ex_icon}
            className={styles.sheet__title__icon}
            aria-label={closeAriaLabel}
            onClick={hide}></Icon>
        </div>
        <div className={styles.sheet__content}>{children}</div>
      </div>
    </ModalOverlay>
  ) : null;
};

export default BottomSheet;
