import { type MutableRefObject, useEffect } from 'react';

/**
 * Manages the tab focus of only one component, without chanign the focus to others.
 *
 * @param ref - Reference for the selected component that the trap focus will be applied
 */

const useTrapFocus = (
  ref: MutableRefObject<HTMLElement | undefined>,
  triggerRef?: HTMLElement
): void => {
  useEffect(() => {
    triggerRef?.blur();

    const handleTab = (event: KeyboardEvent): void => {
      if (ref.current != null && event.key === 'Tab') {
        const focusableElements = ref.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1])'
        );
        const firstElement = focusableElements[0] as HTMLElement;
        if (firstElement !== null) {
          const lastElement = focusableElements[
            focusableElements.length - 1
          ] as HTMLElement;
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    document.addEventListener('keydown', handleTab, true);
    return () => {
      document.removeEventListener('keydown', handleTab, true);
    };
  }, [ref, triggerRef]);
};

export default useTrapFocus;
