import React from 'react';
import ButtonLink from 'components/molecules/ButtonLink';
import styles from '../campaignDetailsPage.module.scss';
import { TYPEKIT } from 'utils/constants';
import Icon from 'components/atoms/Icon';
import plusIcon from 'assets/svgs/plus-icon.svg';
import Text from 'components/atoms/Text';
import { type ButtonAddItemProps } from './ButtonAddItem.types';
import { ADD_ITEM_BUTTON_TEXT } from './ButtonAddItem.constants';

const ButtonAddItem: React.FC<ButtonAddItemProps> = ({ onAddItem }) => {
  return (
    <ButtonLink
      as="button"
      size="small"
      variant="secondary"
      onClick={onAddItem}
      className={styles['campaign-details__button-container']}>
      <div className={styles['campaign-details__button']}>
        <Text variant={TYPEKIT.D6}>{ADD_ITEM_BUTTON_TEXT}</Text>
        <Icon
          aria-hidden
          src={plusIcon}
          className={styles['campaign-details__button-icon']}
        />
      </div>
    </ButtonLink>
  );
};

export default ButtonAddItem;
