import React, { useState, useMemo, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { NotificationContext } from '../NotificationContext';
import { type NotificationProviderProps } from './NotificationProvider.types';
import styles from './notificationprovider.module.scss';
import Notification from '../Notification/Notification';
import { v4 as uuidv4 } from 'uuid';
import {
  NOTIFICATION_ROLE,
  NOTIFICATION_ARIA_LIVE_LABEL,
} from './NotificationProvider.constants';
import { useMutation } from '@apollo/client';
import { DELETE_NOTIFICATION } from 'graphql/notification/mutations';

interface notificationType {
  id: string;
  title: string;
  relatedURL: string;
  urlTitle: string;
  notificationId: string;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = (
  props
) => {
  const [notifications, setNotifications] = useState<notificationType[]>([]);

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    errorPolicy: 'ignore',
  });

  const handleOpen = useCallback(
    (
      title: string,
      relatedURL: string,
      urlTitle: string,
      notificationId: string
    ) => {
      setNotifications((currentNotifications) => [
        { id: uuidv4(), title, relatedURL, urlTitle, notificationId },
        ...currentNotifications,
      ]);
    },
    []
  );

  const close = (id: string, notificationId: string): void => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((notification) => notification.id !== id)
    );

    void deleteNotification({
      variables: { id: notificationId },
    });
  };

  const contextValue = useMemo(() => ({ open: handleOpen }), [handleOpen]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}

      {createPortal(
        <div
          className={styles['notifications-container']}
          role={NOTIFICATION_ROLE}
          aria-live={NOTIFICATION_ARIA_LIVE_LABEL}>
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              title={notification.title}
              relatedURL={notification.relatedURL}
              urlTitle={notification.urlTitle}
              close={() => {
                close(notification.id, notification.notificationId);
              }}></Notification>
          ))}
        </div>,
        document.body
      )}
    </NotificationContext.Provider>
  );
};
