import { type ReactNode, type FC } from 'react';
import styles from './endDate.module.scss';
import { type EndDateProps } from './EndDate.types';
import dayjs, { type Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Text from '../Text/Text';
import { EndDateType, TYPEKIT } from 'utils/constants';
import cx from 'classnames';
import {
  CAMPAIGN_CLOSED_MESSAGE,
  DAYS_LEFT,
  FEW_DAYS_LEFT_MARK,
  MONTHS_LEFT,
} from './EndDate.constants';

dayjs.extend(LocalizedFormat);

const EndDate: FC<EndDateProps> = ({
  startDatetime,
  endDatetime,
  type = EndDateType.CARD,
}) => {
  const currentDate =
    startDatetime !== undefined ? dayjs(startDatetime) : dayjs();
  const endDate = dayjs(endDatetime);

  const getDaysLeft = (currentDate: Dayjs, endDate: Dayjs): number => {
    return endDate.diff(currentDate, 'day');
  };

  const isClosed = (currentDate: Dayjs, endDate: Dayjs): boolean => {
    return endDate.isBefore(currentDate);
  };

  const formatDate = (date: Dayjs): string => {
    return date.format('ll');
  };

  const getTimeLeftString = (currentDate: Dayjs, endDate: Dayjs): string => {
    const monthsLeft = endDate.diff(currentDate, 'month');
    const tempDate = currentDate.add(monthsLeft, 'month');
    const daysLeft = endDate.diff(tempDate, 'day');
    return monthsLeft > 0
      ? `${monthsLeft} ${MONTHS_LEFT} ${daysLeft} ${DAYS_LEFT}`
      : `${daysLeft} ${DAYS_LEFT}`;
  };

  const displayRemainingTime = (): ReactNode => {
    return (
      <>
        <Text
          variant={TYPEKIT.P3}
          aria-hidden>
          •
        </Text>
        <Text
          variant={TYPEKIT.P3}
          className={cx({
            [styles['enddate__days-left']]:
              getDaysLeft(currentDate, endDate) <= FEW_DAYS_LEFT_MARK,
          })}>
          {getTimeLeftString(currentDate, endDate)}
        </Text>
      </>
    );
  };

  return (
    <div className={styles.enddate}>
      <Text variant={TYPEKIT.P3}>{formatDate(endDate)}</Text>
      {type === EndDateType.BANNER ? (
        <div className={styles['enddate-banner']}>
          {isClosed(currentDate, endDate) ? (
            <>
              <Text
                variant={TYPEKIT.P3}
                aria-hidden>
                •
              </Text>
              <Text variant={TYPEKIT.P3}>{CAMPAIGN_CLOSED_MESSAGE}</Text>
            </>
          ) : (
            displayRemainingTime()
          )}
        </div>
      ) : (
        !isClosed(currentDate, endDate) && displayRemainingTime()
      )}
    </div>
  );
};

export default EndDate;
