import React from 'react';
import cx from 'classnames';
import { type HeroProps } from './Hero.types';

import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

import {
  TYPEKIT,
  TITLE_TAG,
  ICON_VARIANT,
  SETTINGS_PAGE_ROUTE,
  COLORS,
} from 'utils/constants';

import kgLogo from 'assets/svgs/konrad-logo.svg';
import settingsIcon from 'assets/svgs/settings-icon.svg';
import globeIcon from 'assets/svgs/globe.svg';

import {
  DEFAULT_TITLE,
  HERO_BACKGROUND_ALT_TEXT,
  KG_LOGO_ARIA_LABEL,
  SETTINGS_ICON_ARIA_LABEL,
  DEFAULT_IMAGE_BACKGROUND,
  LINK_TAB_INDEX,
  LOGOUT_ICON_ARIA_LABEL,
} from 'components/organisms/Hero/Hero.constants';
import Icon from 'components/atoms/Icon';
import CurrencyToggle from 'components/molecules/CurrencyToggle';
import styles from 'components/organisms/Hero/hero.module.scss';
import { Link } from 'react-router-dom';
import { useAuth } from 'context/Authentication/AuthContext';

/**
 * Hero Component
 *
 * The Hero component is designed to display a prominent section at the top of a page
 * which typically includes a background image, a title, and a description.
 *
 * @component
 * @example
 * // Basic usage:
 * <Hero
 *   className="custom-hero"
 *   isAdmin={true}
 *   title="Welcome to KG"
 *   description="Learn more about the company"
 *   background={kg-background}
 * />
 */

const Hero: React.FC<HeroProps> = ({
  className,
  isAdmin = false,
  title = DEFAULT_TITLE,
  background = DEFAULT_IMAGE_BACKGROUND,
}) => {
  const { logout } = useAuth();

  return (
    <div className={cx(className, styles.hero)}>
      <div className={styles.hero__overlay} />
      <Image
        src={background}
        alt={HERO_BACKGROUND_ALT_TEXT}
        className={styles.hero__background}
      />
      <div className={styles.hero__content}>
        <div className={styles['hero__actions-container']}>
          <div className={styles.hero__actions}>
            <CurrencyToggle
              icon={globeIcon}
              iconColor={COLORS.INTERACTIVE_WHITE}
            />
            {isAdmin && (
              <>
                <Link
                  to={SETTINGS_PAGE_ROUTE}
                  tabIndex={LINK_TAB_INDEX}>
                  <Icon
                    src={settingsIcon}
                    variant={ICON_VARIANT.MAJOR}
                    ariaLabel={SETTINGS_ICON_ARIA_LABEL}
                    className={styles['hero__actions--settings']}
                  />
                </Link>
              </>
            )}
            <Icon
              src={faArrowRightFromBracket}
              variant={ICON_VARIANT.MAJOR}
              ariaLabel={LOGOUT_ICON_ARIA_LABEL}
              className={styles['hero__actions-logout']}
              onClick={() => {
                logout();
              }}
            />
          </div>
        </div>
        <div className={styles['hero__info-container']}>
          <Icon
            src={kgLogo}
            variant={ICON_VARIANT.MAJOR}
            aria-label={KG_LOGO_ARIA_LABEL}
            className={styles['hero__kg-icon']}
          />
          <Text
            as={TITLE_TAG.H1}
            variant={TYPEKIT.D1}
            className={styles['hero__info-text']}>
            {title}
          </Text>
        </div>
      </div>
    </div>
  );
};
export default Hero;
