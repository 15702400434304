import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation createCategory($categoryInput: CreateCategoryInput!) {
    createCategory(categoryInput: $categoryInput) {
      id
      name
      description
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($categoryId: ID!) {
    deleteCategory(categoryId: $categoryId) {
      success
    }
  }
`;
