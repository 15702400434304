export const INTERNAL_SIZE_ERROR_MESSAGE =
  'This file exceeds the maximum size of 5 MB and cannot be uploaded. Please reduce the size of the file and try again.';
export const INTERNAL_FORMAT_ERROR_MESSAGE =
  'This file format is not supported. Please select a .JPEG or .JPG file and try again.';
export const INTERNAL_FORMAT_SIZE_ERROR_MESSAGE =
  'This file is too large (5MB max) and its format is not supported (only JPG or JPEG). Please select a valid file.';
export const ERROR_REACHED_LIMIT_MESSAGE =
  'You already reached the maximum number of images. Delete one or more and try again.';
export const ACCEPT_DEFAULT_VALUE = 'image/jpeg';
export const MULTIPLE_DEFAULT_VALUE = true;
export const FILE_SIZE = 5242880;
export const UPLOAD_COMPLETE = 'File Upload Complete';
export const INPUT_TYPE = 'file';
export const BUTTON_AS = 'button';
export const BUTTON_VARIANT = 'primary';
export const BUTTON_SIZE = 'small';
export const BUTTON_TEXT = 'Select Files';
export const INITIAL_DISPLAY_TEXT = 'Drag or Select Files for Upload';
export const VALIDATION_TEXT = 'Maximum file size 5MB (only JPG or JPEG)';
export const LABEL_FILE_PREVIEW_WRAPPER = 'File Preview Container';
export const HIDDEN_TRUE = 'true';
export const TEXT_AS = 'label';
export const ASSERTIVE = 'assertive';
export const POLITE = 'polite';
export const GROUP_ROLE = 'group';
export const UPLOAD_COMPLETE_TEXT = 'Upload Complete';
export const UPLOADING_TEXT = 'Uploading';
export const FILE_TEXT = 'file';
export const FILES_TEXT = 'files';
export const REMOVE_TEXT = 'Remove';
export const PREVIEW_TEXT = 'Preview of';
export const NUMBER_FILES_1 = 1;
export const FORMAT = 'format';
export const SIZE = 'size';
export const BOTH = 'both';
export const MAX_NAME_SIZE_DESKTOP = 17;
export const MAX_NAME_SIZE_MOBILE = 10;
export const SUBSTRING_LOWER_LIMIT = 0;
export const ELLIPSIS = '...';
export const OFFSET = 3;
export const DELETE_IMAGE_FAIL_MESSAGE = 'Image could not be deleted.';
