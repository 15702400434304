export const DEFAULT_EXIT_ICON_ARIA_LABEL = 'Close modal';
export const BUTTONS_SIZE_VARIANT = 'large';
export const BUTTON_AS = 'button';
export const ACCEPT_BUTTON_VARIANT = 'primary';
export const DISMISS_BUTTON_VARIANT = 'secondary';
export const MODAL_TITLE = 'Are You Sure?';
export const MODAL_DESCRIPTION =
  'Are you sure you want to leave? You will lose any edits or changes that you made.';
export const MODAL_LABELLED_BY = 'Confirmation Modal Title';
export const MODAL_DESCRIBED_BY = 'Confirmation Modal Description';
export const MODAL_CANCEL_TEXT = 'Leave';
export const MODAL_ACCEPT_TEXT = 'Stay';
export const MODAL_OVERLAY_ROLE = 'dialog';
export const ARIA_MODAL = 'true';
export const SUBMIT_TYPE = 'submit';
export const FORM_ID = 'campaignForm';
export const TITLE_ID = 'campaignFormModal';
export const TEXT_AS = 'h1';
