export const MOCK_CURRENT_USER_ID = '';
export const DEFAULT_CURRENCY_SIGN = '$';
export const BID_HEADER = 'Bid';
export const DECIMAL_PLACES_FOR_BID = 2;
export const DELETE_BUTTON_TEXT = 'Delete your bid';
export const OWNER_NAME_IDENTIFIER = ' (You)';
export const EMPTY_BIDS_TEXT = 'There are no bids yet.';
export const DELETE_MODAL_TITLE = 'Delete Bid?';
export const DELETE_MODAL_DESCRIPTION_CONFIRMATION =
  'Are you sure you want to delete your bid?';
export const DELETE_MODAL_DESCRIPTION_WARNING =
  'Your bid will be deleted immediately. You cannot undo this action';
export const DELETE_MODAL_DESCRIPTION_TEXT_TYPE = 'p';
export const DELETE_MODAL_CANCEL = 'Cancel';
export const DELETE_MODAL_DELETE = 'Delete bid';
export const DELETE_MODAL_ARIA_DESCRIPTION =
  'deleteBidConfirmationModalDescription';
export const DELETE_MODAL_ARIA_TITLE = 'deleteBidConfirmationModalTitle';
export const BIDDING_TABLE_INTERVAL_REFRESH_RATE = 40000;
export const RELATIVE_TIME_PAST_SUFFIX = '%s ago';
export const RELATIVE_TIME_SECONDS = 'a few seconds';
export const RELATIVE_TIME_MINUTE = '1 min';
export const RELATIVE_TIME_MINUTES = '%d mins';
export const RELATIVE_TIME_HOUR = '1 hr';
export const RELATIVE_TIME_HOURS = '%d hrs';
export const RELATIVE_TIME_DAY = '1 day';
export const RELATIVE_TIME_DAYS = '%d days';
export const RELATIVE_TIME_MONTH = '1 month';
export const RELATIVE_TIME_MONTHS = '%d months';
export const RELATIVE_TIME_YEAR = '1 year';
export const RELATIVE_TIME_YEARS = '%d years';
export const UPDATE_LOCALE_LANG = 'en';
export const BID_CANNOT_BE_DELETED_TITLE = 'Bid Cannot Be Deleted';
export const BID_CANNOT_BE_DELETED_DESCRIPTION =
  'Unfortunately, a new bid has been placed. You are unable to delete your old bid.';
export const BID_CANNOT_BE_DELETED_BUTTON_TEXT = 'View bids';
export const BID_CANNOT_BE_DELETED_MODAL_TEXT_VARIANT = 'p';
export const BID_CANNOT_BE_DELETE_ARIA_DESCRIPTION =
  'warningBidCannotBeDeleted';
