import { forwardRef } from 'react';
import { type ChipProps } from './Chip.types';
import Text from 'components/atoms/Text';
import Icon from 'components/atoms/Icon';
import styles from './chip.module.scss';
import cx from 'classnames';
import { CHIP_VARIANT, TYPEKIT } from 'utils/constants';
import closeIcon from 'assets/svgs/close-icon.svg';

/**
 * Chips are compact components that represent input, attribute, or action. Often used for filters, tags, or small amounts of information.
 *
 * @param {string} text - Text to display inside the chip. If left undefined it will return an input.
 * @param {string} placeholder - Placeholder for the input if text is left undefined.
 * @param {string} onBlur - Function for every time the input loses focus.
 * @param {string} onClick - OnClick function for.
 * @param {'small' | 'large'} size - Size variant for the chip.
 * @param {string} ariaLabel - ARIA Label for the input or chip. Depending on the current variation.
 *
 */

const Chip = forwardRef<HTMLInputElement, ChipProps>(
  (
    {
      text,
      placeholder,
      onBlur,
      onClick,
      size = CHIP_VARIANT.SMALL,
      className,
      ariaLabel,
    },
    ref
  ) => {
    return (
      <div
        className={cx(
          styles.container,
          {
            [styles[`container--edit-small`]]:
              onClick !== undefined &&
              size === CHIP_VARIANT.SMALL &&
              (text ?? false),
            [styles[`container--no-edit-small}`]]:
              onClick === undefined &&
              size === CHIP_VARIANT.SMALL &&
              (text ?? false),
            [styles[`container--large`]]:
              size === CHIP_VARIANT.LARGE && (text ?? false),
            [styles[`container--input`]]: text === undefined,
          },
          className
        )}
        tabIndex={onClick !== undefined ? 0 : undefined}
        aria-label={text !== undefined ? ariaLabel : undefined}
        onClick={onClick}
        role={onClick !== undefined ? 'button' : undefined}>
        {text === undefined ? (
          <input
            aria-label={ariaLabel}
            ref={ref}
            placeholder={placeholder}
            className={styles[`input--${size}`]}
            onBlur={onBlur}
          />
        ) : (
          <>
            <Text variant={TYPEKIT.D6}>{text}</Text>
            {onClick !== undefined && (
              <Icon
                src={closeIcon}
                className={cx(styles.icon)}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

Chip.displayName = 'Chip';
export default Chip;
