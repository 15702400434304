import { rankItem } from '@tanstack/match-sorter-utils';
import { type Cell } from '@tanstack/react-table';
import { type FilterFn } from '@tanstack/table-core';
import {
  type ValidTokenResponse,
  type AuctionItem,
  type Campaign,
} from './dataTypes';
import { type AuctionItemForTable } from 'components/organisms/AuctionItemTable/AuctionItemTable.types';
import {
  APPROVAL_HEADER_EXCEPTION,
  DATE_LOCALE,
  DEFAULT_AUTH_TOKEN_KEY,
  EMPTY_COLUMN_HEADER,
  FRACTION_DIGITS,
  ITEM_ID_MODIFIER,
  STRING_STYLE,
  NOTIFICATION_TYPE,
} from './constants';
import { type ToastContextType } from 'components/molecules/Toast/ToastContext';
import { type TOAST_TYPE } from 'components/molecules/Toast/Toast/toast.constants';
import { type DropdownOption } from 'components/molecules/Dropdown/Dropdown.types';
import dayjs from 'dayjs';
import { type Currency } from 'graphql/generated-types/graphql';

/**
 * Formats a Date object into "MMM DD" format.
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date string (e.g., "Aug 31").
 */

export const dateFormatMonthDay = (date: Date): string => {
  return dayjs(date).format('MMM D, YYYY');
};

/**
 * Get the header text for a cell.
 *
 * @param {Cell<any, unknown>} cell - The cell object.
 * @returns {string | undefined} - The header text in lowercase, or undefined if not found.
 */
export const getCellHeader = (cell: Cell<any, unknown>): string | undefined => {
  return cell.column.columnDef.id !== undefined
    ? getCellModifier(
        cell.column.columnDef.id.toString(),
        cell.getValue() as string
      )
    : undefined;
};

/**
 * Transforms id of a cell into a proper modifier name
 *
 * @param {string} id - Id of table cell.
 * @returns {string | undefined} - The modifier name in lowercase, joined by '-' if necessary.
 */
export const getCellModifier = (id: string, content?: string): string => {
  const idParts = id.split(/(?=[A-Z])/).map((part) => part.toLowerCase());
  let modifier = idParts.join('-');
  if (modifier === ITEM_ID_MODIFIER) {
    if (content === EMPTY_COLUMN_HEADER) modifier = 'item-id__empty-header';
    else if (content === APPROVAL_HEADER_EXCEPTION)
      modifier = 'item-id__approval-header';
  }
  return modifier;
};

/**
 * A custom fuzzy filter function for table column filtering.
 *
 * @function
 * @param {Row<TData>} row - The data row being filtered.
 * @param {string} columnId - The ID of the column being filtered.
 * @param {string} value - The filter value to match against.
 * @param {function} addMeta - A function to add metadata to the filtering process.
 * @returns {boolean} - `true` if the row should be included in the filter results, `false` otherwise.
 */
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

/**
 * Converts an array of AuctionItem objects to an array of AuctionItemForTable objects.
 *
 * @param {AuctionItem[]} items - An array of AuctionItem objects.
 * @returns {AuctionItemForTable[]} - An array of AuctionItemForTable objects.
 */

export const getAuctionItemForTable = (
  items: AuctionItem[]
): AuctionItemForTable[] => {
  return items.map((item) => {
    return {
      ...item,
      itemOwner: item.itemOwner.name,
      itemCategory: item.itemCategory.name,
      itemOffices: item.itemOffices.map((office) => office.name),
      itemImages: item.itemImages.join(','),
    };
  });
};

/**
 * Checks if a token response is valid by verifying the 'verified' property.
 *
 * @param {ValidTokenResponse} data - The token response data.
 * @returns {boolean} - Returns true if the token is valid, otherwise false.
 */

export const isValidToken = (data: ValidTokenResponse): boolean => {
  return data.verifyToken.verified;
};

/**
 * Checks if a user has an 'ADMIN' role.
 *
 * @param {string | undefined} userRole - The user's role.
 * @returns {boolean} - Returns true if the user has an 'ADMIN' role, otherwise false.
 */
export const checkIsAdmin = (userRole: string | undefined): boolean => {
  return userRole === 'ADMIN';
};

/**
 * Parses a currency string to a floating-point number, removing symbols like '$' and ','.
 *
 * @param {string} amount - The currency string to parse.
 * @returns {number} - The parsed floating-point number.
 */
export const parseCurrencyToFloat = (
  amount: string,
  conversionRate: number,
  currencySymbol: string
): number => {
  const parsingRegex = new RegExp('[' + currencySymbol + ',' + ']', 'g');
  return Math.ceil(
    parseFloat(amount.toString().replace(parsingRegex, '')) / conversionRate
  );
};

/**
 * Extracts office names from a Campaign object.
 *
 * @param {Campaign} campaign - The Campaign object.
 * @returns {string[]} - An array of office names from the Campaign.
 */
export const extractCampaignLocations = (campaign: Campaign): string[] => {
  return campaign.offices.map((office) => office.name);
};

/**
 * Displays a toast message using the provided ToastContext.
 *
 * @param {string} message - The message to display in the toast.
 * @param {ToastContextType | undefined} toast - The ToastContext instance.
 * @param {TOAST_TYPE} type - The type of toast message (e.g., success, error).
 * @returns {void}
 */
export const showToast = (
  message: string,
  toast: ToastContextType | undefined,
  type: TOAST_TYPE
): void => {
  if (toast !== undefined) toast.open(message, type);
};

/**
 * Retrieves the authorization header for making authenticated API requests.
 *
 * @returns {string} - The authorization header (e.g., 'Bearer <authenticationToken>').
 */
export const getAuthorizationHeader = (): string => {
  let authenticationToken = localStorage.getItem(DEFAULT_AUTH_TOKEN_KEY);
  if (authenticationToken !== null) {
    authenticationToken = JSON.parse(authenticationToken);
  }
  return authenticationToken != null ? `Bearer ${authenticationToken}` : '';
};

/**
 * Format any string to have the first letter to upper case and the rest lower case. (Example: ADMIN -> Admin)
 *
 * @param {string} text - Required the string to be formatted.
 * @returns {string} Formatted text.
 */
export const formatToFirstUpperCase = (text: string): string => {
  if (text.length === 0) {
    return text;
  }
  const firstLetter = text[0].toUpperCase();
  const restOfTheString = text.slice(1).toLowerCase();
  return firstLetter + restOfTheString;
};

/**
 * Format any string to have the correct currency format and value based on the selectedCurrency and conversionRate received
 *
 * @param {string} text - The string to be formatted.
 * @param {Currency} selectedCurrency - Currency selected by the user used for formatting.
 * @param {number} conversionRate - Rate of conversion related to the currency, used to convert the value properly.
 * @param {boolean} [useKFormat] - Boolean to determine wheter or not to parse 1000s as k.
 * @returns {string} Formatted text.
 */
export const formatCurrencyInText = (
  text: string,
  selectedCurrency: Currency,
  conversionRate: number,
  useKFormat: boolean = false
): string => {
  const currencyRegex = /\$\d+(?:\.\d{0,2})?/g;
  return text.replace(currencyRegex, (match) => {
    const numericValue = Math.ceil(parseFloat(match.slice(1)) * conversionRate);

    let formattedValue;
    if (useKFormat && numericValue >= 1000) {
      const thousands = Math.round(numericValue / 1000);
      formattedValue = `${thousands}k`;
    } else {
      formattedValue = numericValue.toLocaleString(DATE_LOCALE, {
        style: STRING_STYLE,
        currency: selectedCurrency.code,
        minimumFractionDigits: FRACTION_DIGITS,
        maximumFractionDigits: FRACTION_DIGITS,
      });
    }

    const valueWithoutSymbol = formattedValue.replace(/[^\d.,k]/g, '');
    return `${selectedCurrency.symbol}${valueWithoutSymbol}`;
  });
};

export const getNotificationMessage = (
  itemName: string,
  type: NOTIFICATION_TYPE
): string => {
  let message = `Your item ${itemName} has been `;
  switch (type) {
    case NOTIFICATION_TYPE.ITEM_APPROVE:
      message += 'approved.';
      break;
    case NOTIFICATION_TYPE.ITEM_DECLINE:
      message += 'declined.';
      break;
    case NOTIFICATION_TYPE.ITEM_UPDATE:
      message += 'updated.';
      break;
    case NOTIFICATION_TYPE.ITEM_DELETE:
      message += 'deleted.';
      break;
    case NOTIFICATION_TYPE.ITEM_BID_DELETE:
      message = `${itemName} that you placed a bid on has been removed.`;
      break;
  }
  return message;
};

/**
 * Creates a custom filter function to filter by different keys of a given filter type.
 * @param filter Key to filter items by
 * @param setAppliedFilters Function to set applied filters state
 * @returns A custom filter function
 */
export const createCustomFilterHandler = <T extends Record<string, any>>(
  filter: keyof T,
  setAppliedFilters: React.Dispatch<React.SetStateAction<T>>
): ((selectedOption: DropdownOption[]) => void) => {
  const filterFunction = (selectedOption: DropdownOption[]): void => {
    setAppliedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      newFilters[filter] = selectedOption.map(
        (option) => option.name
      ) as T[keyof T];
      return newFilters;
    });
  };
  return filterFunction;
};
