import { type IconProps } from './Icon.types';
import cx from 'classnames';
import styles from './icon.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVG from 'react-inlinesvg';
import React from 'react';
import {
  BUTTON_TYPE,
  COLOR_DEFAULT_VALUE,
  IS_FONT_AWESOME,
  IS_SVG,
  VARIANT_DEFAULT_VALUE,
} from './Icon.constants';

/**
 * Icon component with button-like behavior for rendering icons with different size variants and colors.
 * @param {string|IconDefinition} src - The source of the icon. Can be an SVG or a FontAwesome icon definition.
 * @param {function} [onClick] - A callback function to handle click events.
 * @param {string} [variant] - The size variant of the icon (e.g., 'minor', 'major', 'spot').
 * @param {string} [color] - The color of the icon.
 * @param {string} [ariaLabel] - A label for the icon for accessibility.
 * @param {string} [className] - Additional SCSS classes to apply to the icon.
 * @returns {React.FC} The rendered Icon component.
 * @example
 * // Rendering an SVG icon
 * import icon from 'assets/svgs/icon.svg';
 * <Icon
 *   src={icon}
 *   onClick={() => handleIconClick()}
 *   ariaLabel="Example Icon"
 *   className={styles["custom-icon"]}
 * />
 *
 * // Rendering a FontAwesome icon
 * import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
 * <Icon
 *   src={faTrashAlt}
 *   variant="major"
 *   color="interactive-kg-blue"
 *   ariaLabel="Example FontAwesome Icon"
 * />
 *
 * // Note: For some SVGs, using a specific selector like "circle" may be necessary
 * // to target and style specific parts of the SVG for desired results.
 *
 * // Note: To override the size of FontAwesome icons, use "font-size" property,
 * // not "width" and "height" properties. To override the color of icons, use the "color" property.
 */

const Icon: React.FC<IconProps> = ({
  src,
  onClick,
  variant = VARIANT_DEFAULT_VALUE,
  color = COLOR_DEFAULT_VALUE,
  ariaLabel,
  className,
  ...props
}) => {
  const isFontAwesome = src instanceof Object && 'iconName' in src;

  return (
    <button
      type={BUTTON_TYPE}
      aria-label={ariaLabel}
      onClick={onClick}
      className={cx(
        styles.icon,
        styles[
          `icon--variant-${variant}-${isFontAwesome ? IS_FONT_AWESOME : IS_SVG}`
        ],
        styles[`icon--${color}`],
        className
      )}
      {...props}>
      {isFontAwesome ? <FontAwesomeIcon icon={src} /> : <SVG src={src} />}
    </button>
  );
};

export default Icon;
