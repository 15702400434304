export const CONFIRMATION_MODAL_TITLE = 'Add Category';
export const CONFIRMATION_MODAL_ADD_BODY =
  'You are about to save a new category named';
export const CONFIRMATION_MODAL_ADD_QUESTION =
  'Are you sure you want to proceed?';
export const CONFIRMATION_MODAL_ACCEPT_TEXT = 'Save';
export const CONFIRMATION_MODAL_DECLINE_TEXT = 'Cancel';
export const CONFIRMATION_MODAL_LABEL = 'AddCategoryConfirmationLabel';
export const CONFIRMATION_MODAL_DESCRIPTION =
  'AddCategoryConfirmationDescription';
export const CHIP_INPUT_PLACEHOLDER_TEXT = 'New Category';
export const CHIP_INPUT_LABEL =
  'Add a new category by typing its name and pressing enter.';
export const CHIP_LABEL = 'Click this chip to delete the category.';
