import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS_BY_USER_ID = gql`
  query GetNotificationsByUserId($id: ID!) {
    getNotificationsByUserId(userId: $id) {
      id
      itemId
      itemName
      notificationType
      itemCampaignId
    }
  }
`;
