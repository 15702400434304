import React, { useCallback, useRef } from 'react';
import ButtonLink from 'components/molecules/ButtonLink';
import Icon from 'components/atoms/Icon';
import ModalOverlay from 'components/atoms/ModalOverlay';
import { type ModalProps } from './Modal.types';
import styles from './modal.module.scss';
import cx from 'classnames';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import useBreakpoint from 'hooks/useBreakpoint';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import {
  BUTTON_AS,
  ACCEPT_BUTTON_VARIANT,
  BUTTONS_SIZE_VARIANT,
  DEFAULT_EXIT_ICON_ARIA_LABEL,
  DESCRIPTION_TEXT_TYPE,
  DISMISS_BUTTON_AS_DESKTOP,
  DISMISS_BUTTON_VARIANT,
  TITLE_TEXT_TYPE,
  DISMISS_BUTTON_ROLE_DESKTOP,
} from './Modal.constants';
import useHandleExitAnimation from 'hooks/useHandleExitAnimation';

/**
 * The modal component provides a modal which has different props to style it. Including it's title, description and text of each button (cancel and accept).
 * - If the escape key is pressed or the modal overlay is clicked, the modal uses the hide function.
 * - Using Tab for the components only cycles through the focusable elementes inside the modal.
 *
 *
 * @param {boolean} isOpen - Provides the context to show or hide the Modal.
 * @param {string} title - Sets the for the modal.
 * @param {string} description - Sets the description parragraph for the modal.
 * @param {string} cancelTextButton - Sets the for the modal.
 * @param {string} acceptButtonText - Sets the description parragraph for the modal.
 * @param {ModalClasses} classNames - Defines each of the classes for the modal. Check the types document for each component
 * @param {string} exitLabel - Defines the text for the aria label of the exit icon.
 * @param  hide - Function provided by the parent component that executes everytime the modal needs to be dismissed.
 * @param  action - Function provided by the parent component that executes when the "Accept Button" is pressed. It should also dismiss the modal.
 *
 */

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  cancelButtonText,
  acceptButtonText,
  isOpen,
  hide,
  action,
  classNames,
  exitLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  elementRef,
}) => {
  const { isDesktop } = useBreakpoint();
  const modalRef = useRef<HTMLDivElement>(null);
  const { isVisible, setIsVisible } = useHandleExitAnimation(
    styles.slideOutFromBottomFade,
    () => {
      hideModal();
      setIsVisible(true);
    },
    modalRef.current as HTMLElement,
    true
  );

  const cancelButtonRender = (): JSX.Element | null => {
    if (cancelButtonText === undefined) {
      return null;
    }
    if (isDesktop) {
      return (
        <ButtonLink
          as={DISMISS_BUTTON_AS_DESKTOP}
          role={DISMISS_BUTTON_ROLE_DESKTOP}
          className={cx(styles.modal__link, classNames?.cancelLinkClassName)}
          onClick={() => {
            setIsVisible(false);
          }}>
          {cancelButtonText}
        </ButtonLink>
      );
    } else {
      return (
        <ButtonLink
          as={BUTTON_AS}
          size={BUTTONS_SIZE_VARIANT}
          variant={DISMISS_BUTTON_VARIANT}
          ariaLabel={cancelButtonText}
          onClick={() => {
            setIsVisible(false);
          }}
          className={classNames?.cancelButtonClassName}>
          {cancelButtonText}
        </ButtonLink>
      );
    }
  };

  const hideModal = useCallback((): void => {
    hide();
    elementRef?.focus();
  }, [elementRef, hide]);

  return isOpen ? (
    <ModalOverlay
      className={cx(styles.modal__overlay, classNames?.overlayClassName, {
        [styles['modal__overlay--closing']]: !isVisible,
      })}
      isOpen={isOpen}
      hideModal={() => {
        setIsVisible(false);
      }}
      elementRef={elementRef}>
      <div
        ref={modalRef}
        className={cx(
          styles.modal,
          { [styles['modal--closing']]: !isVisible },
          classNames?.modalClassName
        )}
        role="dialog"
        aria-modal="true"
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}>
        <div className={cx(styles.modal__container, styles.modal__information)}>
          <div className={styles.modal__header}>
            <Text
              id={ariaLabelledBy}
              variant={TYPEKIT.D3}
              className={classNames?.titleClassName}
              as={TITLE_TEXT_TYPE}>
              {title}
            </Text>
          </div>
          <Text
            id={ariaDescribedBy}
            variant={TYPEKIT.P2}
            as={DESCRIPTION_TEXT_TYPE}
            className={cx(
              styles.modal__description,
              classNames?.descriptionClassName
            )}>
            {children}
          </Text>
        </div>
        <div className={cx(styles.modal__container, styles.modal__buttons)}>
          <ButtonLink
            as={BUTTON_AS}
            size={BUTTONS_SIZE_VARIANT}
            variant={ACCEPT_BUTTON_VARIANT}
            className={classNames?.acceptButtonClassName}
            onClick={action}>
            {acceptButtonText}
          </ButtonLink>
          {cancelButtonRender()}
        </div>
        <Icon
          onClick={() => {
            setIsVisible(false);
          }}
          ariaLabel={exitLabel ?? DEFAULT_EXIT_ICON_ARIA_LABEL}
          className={cx(
            styles['modal__exit-icon'],
            classNames?.exitIconClassName
          )}
          src={faXmark}
        />
      </div>
    </ModalOverlay>
  ) : null;
};
export default Modal;
