export const AUCTION_ITEM_DETAIL_COUNTDOWN_SUBTITLE = 'Ending in';
export const AUCTION_ITEM_DETAIL_DONOR_NAME_SUBTITLE = 'Donated by';
export const AUCTION_ITEM_DETAIL_GALLERY_BUTTON_PREFIX = 'View all ';
export const AUCTION_ITEM_DETAIL_GALLERY_BUTTON_SUFIX = ' photos';
export const AUCTION_ITEM_DETAIL_LOCATION_ICON_LABEL = 'Office ';
export const AUCTION_ITEM_DETAIL_GALLERY_ALT = 'First image of the item';

export const AUCTION_ITEM_DETAIL_SUBTITLE_TYPE = 'h2';
export const AUCTION_ITEM_DETAIL_TITLE_TYPE = 'h1';
export const AUCTION_ITEM_DETAIL_INFORMATION_TYPE = 'p';

export const AUCTION_ITEM_DETAIL_DONOR_PICTURE_ALT =
  'Item donor profile picture';

export const AUCTION_ITEM_DETAIL_GALLERY_ASPECT_RATIO = '16-9';
