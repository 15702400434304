import { forwardRef } from 'react';
import { type DatePickerInputProps } from './DatePickerInput.types';
import ButtonLink from 'components/molecules/ButtonLink';
import Icon from 'components/atoms/Icon';
import calendarIcon from 'assets/svgs/calendar-icon.svg';
import rightArrowIcon from 'assets/svgs/right-arrow-icon.svg';
import { BUTTON_LINK_TAG, COLORS, ICON_VARIANT } from 'utils/constants';
import {
  CALENDAR_ICON_ARIA_LABEL,
  END_DATE_BUTTON_ARIA_LABEL,
  END_DATE_BUTTON_PLACEHOLDER_TEXT,
  NEXT_ARROW_ICON_ARIA_LABEL,
  SINGLE_DATE_PLACEHOLDER_TEXT,
  START_DATE_BUTTON_ARIA_LABEL,
  START_DATE_BUTTON_PLACEHOLDER_TEXT,
} from 'components/molecules/DatePickerInput/DatePickerInput.constants';
import { dateFormatMonthDay } from 'utils/utilities';
import cx from 'classnames';
import styles from './datePickerInput.module.scss';

/**
 * Custom date picker input component for selecting dates.
 *
 * @component
 * @param {boolean} selectsRange - Indicates whether the date picker is for selecting a date range.
 * @param {Date} startDate - The selected start date when selecting a range or when singleDatePicker is used.
 * @param {Date} endDate - The selected end date when selecting a range.
 * @param {boolean} disabled - Indicates whether the input is disabled.
 * @param {function} onClick - Callback function to handle click events.
 * @param {React.Ref<HTMLDivElement>} ref - A React ref that can be attached to this component.
 * @returns {React.Element} A React component rendering the custom date picker input.
 */

const DatePickerInput = forwardRef<HTMLDivElement, DatePickerInputProps>(
  ({ selectsRange, startDate, endDate, disabled, onClick }, ref) => (
    <div ref={ref}>
      {!!selectsRange && (
        <div
          className={cx(
            {
              [styles['date-picker__custom-input--disabled']]: disabled,
            },
            styles['date-picker__custom-input--range'],
            styles['date-picker__custom-input']
          )}>
          <Icon
            className={cx(styles['date-picker__custom-input-calendar-icon'], {
              [styles['date-picker__custom-input-calendar-icon--disabled']]:
                disabled,
            })}
            variant={ICON_VARIANT.MAJOR}
            color={COLORS.INTERACTIVE_BLACK}
            ariaLabel={CALENDAR_ICON_ARIA_LABEL}
            src={calendarIcon}
          />
          <ButtonLink
            className={styles['date-picker__custom-input-button']}
            as={BUTTON_LINK_TAG.BUTTON}
            ariaLabel={START_DATE_BUTTON_ARIA_LABEL}
            disabled={disabled}
            onClick={onClick}>
            {startDate != null
              ? dateFormatMonthDay(startDate)
              : START_DATE_BUTTON_PLACEHOLDER_TEXT}
          </ButtonLink>
          <Icon
            src={rightArrowIcon}
            className={styles['date-picker__custom-input-next-arrow']}
            variant={ICON_VARIANT.MAJOR}
            ariaLabel={NEXT_ARROW_ICON_ARIA_LABEL}
            color={COLORS.BORDERS_STROKES_BORDER_GREY}
          />
          <ButtonLink
            className={styles['date-picker__custom-input-button']}
            as={BUTTON_LINK_TAG.BUTTON}
            disabled={disabled}
            ariaLabel={END_DATE_BUTTON_ARIA_LABEL}
            onClick={onClick}>
            {endDate != null
              ? dateFormatMonthDay(endDate)
              : END_DATE_BUTTON_PLACEHOLDER_TEXT}
          </ButtonLink>
        </div>
      )}
      {!selectsRange && (
        <div
          className={cx(
            styles['date-picker__custom-input--single'],
            styles['date-picker__custom-input'],
            {
              [styles['date-picker__custom-input--disabled']]: disabled,
            }
          )}>
          <Icon
            className={cx(styles['date-picker__custom-input-calendar-icon'], {
              [styles['date-picker__custom-input-calendar-icon--disabled']]:
                disabled,
            })}
            variant={ICON_VARIANT.MAJOR}
            color={COLORS.INTERACTIVE_BLACK}
            ariaLabel={CALENDAR_ICON_ARIA_LABEL}
            src={calendarIcon}
          />
          <ButtonLink
            className={styles['date-picker__custom-input-button']}
            as={BUTTON_LINK_TAG.BUTTON}
            ariaLabel={START_DATE_BUTTON_ARIA_LABEL}
            disabled={disabled}
            onClick={onClick}>
            {startDate != null
              ? dateFormatMonthDay(startDate)
              : SINGLE_DATE_PLACEHOLDER_TEXT}
          </ButtonLink>
        </div>
      )}
    </div>
  )
);

DatePickerInput.displayName = 'DatePickerInput';
export default DatePickerInput;
