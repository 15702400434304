export const SLIDE_KEY_PREFIX = 'slide_';
export const PREVIEW_KEY_PREFIX = 'preview_';

export const FULL_IMAGE_ASPECT_RATIO = '4-3';

export const PREVIEW_ASPECT_RATIO = '4-3';

export const FULL_IMAGE_ALT_PREFIX = '';
export const PREVIEW_ALT_PREFIX = 'Preview of image';

export const PAGINATION_DIVIDER = '/';
export const PAGINATION_TYPE = 'fraction';

export const DEFAULT_CAROUSEL_LABEL = 'Carousel of images';
export const CAROUSEL_PREVIOUS_BUTTON_LABEL = 'Previous slide button';
export const CAROUSEL_NEXT_BUTTON_LABEL = 'Next slide button';

export const PAGINATION_CONTAINER_CLASSNAME = 'pagination-container';
export const PAGINATION_CONTAINER_CLASSNAME_DOT = '.pagination-container';

export const PREVIEW_SLIDE_CLASS = 'preview-slide';

export const MAX_SLIDES_MOBILE = 3.5;
export const SLIDES_PER_VIEW_MOBILE = 3.5;
export const SPACE_BETWEEN_CAROUSEL_IMAGES = 30;
export const SPACE_BETWEEN_CAROUSEL_PREVIEWS = 16;
