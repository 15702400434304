import { useEffect } from 'react';
import { OVERFLOW } from 'utils/constants';

/**
 *
 *
 * @param {boolean} hideOverflow - Boolean to hide or unset the overflow of the page
 * @param {HTMLElement} elementRef - Element to focus when the UI blocking element is closed
 */

const useHidePageOverflow = (
  hideOverflow: boolean,
  elementRef?: HTMLElement
): void => {
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = hideOverflow
      ? OVERFLOW.HIDDEN
      : OVERFLOW.UNSET;

    return () => {
      document.body.style.overflow = originalOverflow;
      elementRef?.focus();
    };
  }, [elementRef, hideOverflow]);
};
export default useHidePageOverflow;
