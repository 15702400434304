import {
  Client,
  type frameCallbackType,
  type IMessage,
  type StompHeaders,
} from '@stomp/stompjs';
import { useCallback, useMemo, useRef } from 'react';

interface StompProps {
  brokerURL: string;
}

interface ConnectProps {
  onConnect: frameCallbackType;
  onStompError?: frameCallbackType;
  connectHeaders?: StompHeaders;
  disconnectHeaders?: StompHeaders;
  onDisconnect?: frameCallbackType;
}

interface StompReturnType {
  client: React.MutableRefObject<Client>;
  publish: (destination: string, body: string, headers?: StompHeaders) => void;
  connect: (connectProps?: ConnectProps) => void;
  disconnect: () => Promise<void>;
  subscribe: (
    url: string,
    callback: (message: IMessage) => void,
    headers?: StompHeaders
  ) => void;
  unsubscribe: (url: string, headers?: StompHeaders) => void;
  unsubscribeAll: () => void;
}

export const useStomp = ({ brokerURL }: StompProps): StompReturnType => {
  const client = useRef(new Client({ brokerURL }));
  const subscriptions = useRef<string[]>([]);

  const publish = useCallback(
    (destination: string, body: string, headers?: StompHeaders) => {
      if (client.current.connected) {
        client.current.publish({
          destination,
          headers,
          body,
        });
      }
    },
    []
  );

  const subscribe = useCallback(
    (
      url: string,
      callback: (message: IMessage) => void,
      headers?: StompHeaders
    ) => {
      if (client.current.connected) {
        if (!subscriptions.current.includes(url)) {
          subscriptions.current.push(url);
          client.current.subscribe(url, callback, headers);
        }
      }
    },
    []
  );

  const unsubscribe = useCallback((url: string, headers?: StompHeaders) => {
    if (client.current.connected) {
      subscriptions.current = subscriptions.current.filter(
        (sub) => sub !== url
      );
      client.current.unsubscribe(url, headers);
    }
  }, []);

  const unsubscribeAll = useCallback(() => {
    subscriptions.current.forEach((sub) => {
      unsubscribe(sub);
    });
  }, [unsubscribe]);

  const disconnect = useCallback(async () => {
    unsubscribeAll();
    if (client.current.connected) await client.current.deactivate();
  }, [unsubscribeAll]);

  const connect = useCallback((connectProps?: ConnectProps) => {
    if (!client.current.connected) {
      if (connectProps != null) {
        Object.assign(client.current, connectProps);
      }
      client.current.debug = () => {};
      client.current.activate();
    }
  }, []);

  const value = useMemo(
    () => ({
      client,
      publish,
      connect,
      disconnect,
      subscribe,
      unsubscribe,
      unsubscribeAll,
    }),
    [connect, disconnect, publish, subscribe, unsubscribe, unsubscribeAll]
  );

  return value;
};
