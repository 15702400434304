import {
  useState,
  type FC,
  useRef,
  type KeyboardEvent,
  useEffect,
  useCallback,
} from 'react';
import { type TabProps } from './Tab.types';
import { TYPEKIT } from 'utils/constants';
import useBreakpoint from 'hooks/useBreakpoint';
import Text from 'components/atoms/Text';
import cx from 'classnames';
import styles from './tab.module.scss';

/**
 * Accesible tab component
 *
 * @param tabs - List of tabs as {label: "item 1", content: <div>content</div>}
 */
const Tab: FC<TabProps> = ({ tabs, classNames }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { currentBreakpoint, isDesktop } = useBreakpoint();

  const handleClick = (index: number): void => {
    setSelectedIndex(index);
    const selectedTab = tabs[index];
    if (selectedTab?.onTabSelected != null) {
      selectedTab.onTabSelected();
    }
  };

  // For keyboard accesibility of tabs
  const tabRefs = useRef<Record<number, HTMLAnchorElement | null>>({});

  const indicatorRef = useRef<HTMLDivElement>(null);

  // Select the tab panel associated with the tab
  const setIndex = (index: number): void => {
    const tab = tabRefs.current[index];
    if (tab !== null) {
      tab.focus();
    }
  };

  const animateIndicator = useCallback((): void => {
    if (indicatorRef.current != null) {
      indicatorRef.current.style.left = `${tabRefs.current[selectedIndex]?.offsetLeft}px`;
      indicatorRef.current.style.width = `${tabRefs.current[selectedIndex]?.offsetWidth}px`;
    }
  }, [selectedIndex]);

  // Animation for the indicator
  useEffect(() => {
    animateIndicator();
  }, [animateIndicator, selectedIndex, currentBreakpoint]);

  useEffect(() => {
    const hash = window.location.hash;
    const indexMatch = hash.match(/^#([^]+)$/);
    if (indexMatch != null) {
      const tabId = indexMatch[0].substring(1);
      const tabIndex = tabs.findIndex((tab) => tab.id === tabId);
      if (tabIndex !== -1) {
        setSelectedIndex(tabIndex);
        const selectedTab = tabs[tabIndex];
        if (selectedTab?.onTabSelected != null) {
          selectedTab.onTabSelected();
        }
      }
    }
  }, [tabs]);

  const onKeyDown = (event: KeyboardEvent<HTMLAnchorElement>): void => {
    const count = tabs.length;

    const nextTab = (): void => {
      setIndex((selectedIndex + 1) % count);
    };

    const prevTab = (): void => {
      setIndex((selectedIndex - 1 + count) % count);
    };

    const firstTab = (): void => {
      setIndex(0);
    };

    const lastTab = (): void => {
      setIndex(count - 1);
    };

    const keyMap: Record<string, () => void> = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    };

    const action = keyMap[event.key];
    if (action !== undefined) {
      event.preventDefault();
      action();
    }
  };

  return (
    <>
      <div className={cx(styles.tab, classNames)}>
        <div
          role="tablist"
          aria-orientation="horizontal"
          className={styles['tab-list']}>
          {tabs.map((tab, index) => (
            <a
              className={cx(styles['tab-item'], {
                [styles['tab-item--active']]: selectedIndex === index,
              })}
              id={`tab-${tab.id}`}
              key={`tab-${tab.id}`}
              href={`#${tab.id}`}
              onKeyDown={onKeyDown}
              onFocus={() => {
                setSelectedIndex(index);
              }}
              ref={(element) => (tabRefs.current[index] = element)}
              onClick={() => {
                handleClick(index);
              }}
              role="tab"
              tabIndex={selectedIndex === index ? 0 : -1}
              aria-controls={`tabpanel-${tab.id}`}
              aria-selected={selectedIndex === index}>
              <Text
                variant={isDesktop ? TYPEKIT.P1 : TYPEKIT.P2}
                as="p">
                {tab.label}
              </Text>
            </a>
          ))}
          <div
            className={styles['tab-list__indicator']}
            ref={indicatorRef}></div>
        </div>
        <div className={styles['tab-panel-container']}>
          {tabs.map((tab, index) => (
            <section
              key={`tabpanel-${tab.id}`}
              hidden={selectedIndex !== index}
              role="tabpanel"
              aria-labelledby={`tab-${tab.id}`}
              id={`tabpanel-${tab.id}`}>
              {tab.content}
            </section>
          ))}
        </div>
      </div>
    </>
  );
};

export default Tab;
