import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DEFAULT_AUTH_TOKEN_KEY } from 'utils/constants';
import { getAuthorizationHeader } from 'utils/utilities';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URI_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  let authenticationToken = localStorage.getItem(DEFAULT_AUTH_TOKEN_KEY);
  if (authenticationToken !== null) {
    authenticationToken = JSON.parse(authenticationToken);
  }

  return {
    headers: {
      ...headers,
      authorization: getAuthorizationHeader(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
