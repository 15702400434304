import { gql } from '@apollo/client';

export const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    getAllCampaigns {
      id
      name
      imageKey
      startDate
      endDate
      goal
      raisedAmount
      isActive
      createdAt
      isDeleted
      updatedAt
      updatedBy {
        id
        email
        imageUrl
        lastName
        firstName
        dateJoined
      }
      offices {
        id
        name
      }
    }
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
  query GetCampaignById($campaignId: ID!) {
    getCampaignById(id: $campaignId) {
      name
      imageKey
      startDate
      endDate
      goal
      raisedAmount
      isActive
      createdAt
      updatedAt
      isDeleted
      id
      offices {
        id
        name
      }
    }
  }
`;
