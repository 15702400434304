import React from 'react';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import styles from './noItemsMessage.module.scss';
import {
  EMPTY_FILTERED_LIST_DESCRIPTION,
  EMPTY_LIST_DESCRIPTION,
  EMPTY_LIST_TITLE,
} from './NoItemsMessage.constants';
import { type NoItemsMessageProps } from './NoItemsMessage.types';
import ButtonAddItem from '../ButtonAddItem/ButtonAddItem';

export const NoItemsMessage: React.FC<NoItemsMessageProps> = ({
  isListEmpty,
  onAddItem,
}) => {
  return (
    <div className={styles['no-items']}>
      <Text
        variant={TYPEKIT.D3}
        as="h1">
        {EMPTY_LIST_TITLE}
      </Text>
      <Text
        variant={TYPEKIT.P1}
        className={styles['no-items--light']}>
        {!isListEmpty
          ? EMPTY_FILTERED_LIST_DESCRIPTION
          : EMPTY_LIST_DESCRIPTION}
      </Text>
      {isListEmpty && (
        <div className={styles['no-items__button']}>
          <ButtonAddItem onAddItem={onAddItem} />
        </div>
      )}
    </div>
  );
};
