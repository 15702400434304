import React from 'react';
import Text from 'components/atoms/Text';
import { type ExpiredMessageProps } from './ExpiredMessage.types';
import { TYPEKIT } from 'utils/constants';
import styles from './expiredmessage.module.scss';

export const ExpiredMessage: React.FC<ExpiredMessageProps> = ({ message }) => {
  return (
    <div className={styles.expiredmessage}>
      <Text
        as="p"
        variant={TYPEKIT.D3}
        className={styles.expiredmessage__text}>
        {message}
      </Text>
    </div>
  );
};
