import React, {
  type Ref,
  forwardRef,
  useEffect,
  useState,
  useRef,
  type ChangeEvent,
  useContext,
} from 'react';
import styles from './longInput.module.scss';
import Text from 'components/atoms/Text';
import { TYPEKIT } from 'utils/constants';
import cx from 'classnames';
import maskText from '../Input/MasksHandler';
import { type LongInputProps } from './LongInput.types';
import { ERROR_LABEL } from './LongInput.constants';
import ErrorMessage from '../ErrorMessage';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';

/**
 * A generic long input component that works as a free text input and allows the user to register required information
 *
 * @component
 * @example
 * <LongInput id="adress"
 * label="Adress"
 * placeholder="Type your adress here"
 * name="adress"
 * maxLength=500/>
 *
 * @example
 * <LongInput id="description"
 * label="Description"
 * value={description}
 * name="description"
 * disabled/>
 *
 * @example
 * <LongInput id="adress"
 * label="Adress"
 * placeholder="Type your adress here"
 * name="adress"
 * maxLength=500
 * error="Error message"/>
 *
 *
 * @param {string} id - HTML attribute for the long input component, it matches with the htmlFor label attribute
 * @param {string} label - Text to be displayed as the long input component label if specified, otherwise, no label will be displayed
 * @param {string} placeholder - Text used to stand in for the actual value of the long input component in case is not specified by the value prop
 * @param {boolean} disabled - If included, disables the current long input
 * @param {string} name - Long Input's name being registered
 * @param {MASKTYPES} mask - If included, applies the specified mask to the long input text
 * @param {STRING} value - Text displayed on the long input, if not provided, input will display placeholder
 * @param {function} onChange - Callback function invoked when the input text changes
 * @param {function} onBlur - Callback function invoked when the input element loses focus
 * @param {string} error - Error message to  be displayed, if provided input will render with an error styling
 * @param {number} maxLength - Max number of characters that will be indicated to the user even when the commponent doesn't limit the number of characters
 * @param {number} minLength - minLenght of characters that have to be entered
 * @param {number} cols -  Visible component's width
 * @param {number} rows - Visible component's height
 */

const maxCharacterCounter = (
  text: string | undefined,
  maxLength: number
): number => {
  if (text !== undefined) {
    if (text.length > maxLength) {
      return maxLength - text.length;
    } else {
      return text.length;
    }
  } else {
    return 0;
  }
};

const LongInput: React.FC<LongInputProps> = forwardRef(
  (props: LongInputProps, ref: Ref<HTMLTextAreaElement>) => {
    const {
      id,
      label,
      placeholder,
      disabled,
      name,
      mask,
      value,
      onChange,
      onBlur,
      error,
      className,
      autoCapitalize,
      cols,
      rows,
      maxLength,
      minLength,
    } = props;

    const [inputValue, setInputValue] = useState(value ?? '');
    const { selectedCurrency } = useContext(CurrencyContext);
    const [wordCounter, setWordCounter] = useState(
      maxCharacterCounter(value, maxLength)
    );
    const idRef = useRef(crypto.randomUUID());

    useEffect(() => {
      setInputValue(value ?? '');
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
      const originalText = event?.target.value;
      const maskedText =
        mask !== undefined && mask !== null
          ? maskText(originalText, mask, selectedCurrency.symbol)
          : originalText;

      setInputValue(maskedText);
      event.target.value = maskedText;

      setWordCounter(maxCharacterCounter(maskedText, maxLength));

      void onChange?.(event);
    };

    return (
      <div className={cx(className, styles['long-input__container'])}>
        <textarea
          id={id}
          aria-describedby={ERROR_LABEL + idRef.current}
          className={cx(styles.textarea, {
            [styles['textarea--error']]: error,
          })}
          placeholder={placeholder}
          disabled={disabled}
          autoCapitalize={autoCapitalize}
          cols={cols}
          rows={rows}
          minLength={minLength}
          onChange={handleChange}
          onBlur={onBlur}
          ref={ref}
          value={inputValue}
          name={name}
          {...(disabled ?? false ? { contenteditable: 'true' } : {})}
        />
        <div className={styles.header}>
          {label !== null && (
            <Text
              as="label"
              htmlFor={id}
              variant={TYPEKIT.D5}
              className={styles.header__label}>
              {label}
            </Text>
          )}
          <Text
            as="span"
            variant={TYPEKIT.D5}
            className={cx(styles['word-counter'], {
              [styles['word-counter--error']]: inputValue.length > maxLength,
            })}>
            {wordCounter}/{maxLength}
          </Text>
        </div>

        {Boolean(error) && (
          <ErrorMessage
            error={error}
            className={styles['error-order']}
          />
        )}
      </div>
    );
  }
);
LongInput.displayName = 'LongInput';
export default LongInput;
