import { type FC } from 'react';
import { type DonationProgressProps } from './DonationProgress.types';
import { PROGRESS_BAR_VARIANTS, TYPEKIT } from 'utils/constants';
import ProgressBar from 'components/atoms/ProgressBar';
import Text from 'components/atoms/Text';
import styles from './donationProgress.module.scss';
import cx from 'classnames';

const DonationProgress: FC<DonationProgressProps> = ({
  donationGoal,
  raisedAmount,
  typekit,
  isFinished = false,
  useKFormat = false,
}) => {
  const currentDonationUSFormat = raisedAmount.toString();
  const donationGoalUSFormat = donationGoal.toString();
  const donationsPercentage = parseFloat(
    ((raisedAmount / donationGoal) * 100).toFixed(2)
  );

  const currentRaisedText = `$${currentDonationUSFormat} donated`;
  const goalDonationText = `$${donationGoalUSFormat} goal`;

  const donationPercentageText = `${donationsPercentage}%`;
  const goalReachedText = 'of goal reached';

  const currentDonationText = `$${currentDonationUSFormat}`;
  const currentDonationSecondaryText = 'raised';

  return (
    <>
      {isFinished ? (
        <div className={styles['campaign-finished']}>
          <div className={styles['campaign-finished__text']}>
            <Text
              useKFormat={useKFormat}
              displayCurrency
              variant={TYPEKIT.D3}>
              {currentDonationText}
            </Text>
            <Text
              className={styles['campaign-finished__secondary-text']}
              variant={TYPEKIT.P2}>
              {currentDonationSecondaryText}
            </Text>
          </div>
          <div className={styles['campaign-finished__text--goal']}>
            <Text variant={TYPEKIT.D3}>{donationPercentageText}</Text>
            <Text
              className={styles['campaign-finished__secondary-text']}
              variant={TYPEKIT.P2}>
              {goalReachedText}
            </Text>
          </div>
        </div>
      ) : (
        <div className={styles['donation-progress']}>
          <div className={cx(styles['donation-progress__donation-info'])}>
            <Text
              displayCurrency
              useKFormat={useKFormat}
              variant={typekit}
              className={cx(
                styles['donation-progress__donation-info--kg-blue']
              )}>
              {currentRaisedText}
            </Text>
            <Text
              displayCurrency
              useKFormat={useKFormat}
              variant={typekit}
              className={cx(styles['donation-progress__donation-info--grey'])}>
              {goalDonationText}
            </Text>
          </div>
          <ProgressBar
            className={styles['donation-progress__progress-bar']}
            variant={PROGRESS_BAR_VARIANTS.KG_SIMPLE}
            progress={donationsPercentage}
          />
        </div>
      )}
    </>
  );
};

export default DonationProgress;
