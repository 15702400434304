import React, { forwardRef, type ElementType } from 'react';
import type { ButtonLinkProps } from './ButtonLink.types';
import { Link } from 'react-router-dom';
import styles from './buttonlink.module.scss';
import cx from 'classnames';
import {
  DEFAULT_LINK_TAB_INDEX,
  DEFAULT_ROUTE,
  EXTERNAL_TARGET,
} from './ButtonLink.constants';
import ButtonLinkChildren from './ButtonLinkChildren';
import {
  type PolymorphicComponent,
  type PolymorphicRef,
} from 'utils/dataTypes';

/**
 * The Button Link component renders as a button or as a React Router Link.
 *
 * @example
 * //Renders as back link to external page. BrowserRouter must be present in the App for Link to work.
 * <BrowserRouter>
 *    <ButtonLink back href="https://www.google.com/" ariaLabel="Test Link Example" as="Link">
 *       Text Link Example
 *    </ButtonLink>
 * </BrowserRouter>
 *
 *
 *
 * @example
 * //Renders as a disabled secondary button with a function buttonClickTest
 * <ButtonLink
 *    ariaLabel="Button"
 *    variant="secondary"
 *    size="large"
 *    disabled
 *    as="button"
 *    onClickAction={buttonClickTest}
 *  >
 *    Button
 *  </ButtonLink>
 *
 *
 * @param {"button" | "Link"} as - The type of element to render. It can be "button", or "Link".
 * @param {String} className - Overrides the default styles of the Button Link component.
 * @param {ReactNode} children - Defines the text to display. It can include FontAwesome icons.
 * @param {boolean} disabled - If included, it disables the current element.
 * @param {boolean} loading - If true, it disables the current button and replaces the contents of the button with a spinner.
 * @param {"primary" | "secondary"} variant - Required for styling the buttons, modifies the default style of the button.
 * @param {"small" | "large"} size - Required for styling the buttons, modifies the render size. Defaults to small.
 * @param {IconProp | string} icon - Adds an icon to the button or Link. Optional since they can be passed with the children for more customization.
 * @param {boolean} showChevron - It shows a Chevron with a different style for buttons and links.
 * @param {String} ariaLabel - Accesibility text for each component type.
 * @param {String} role - Role for the Button Link. Required for accesibility information in case a Link is being used as a button.
 * @param onClick - Required function for the action a button or Link must perform. It must return null. It can receibe Event handlers for HTML anchors and HTML buttons.
 * @param {String} to - Path that a Link follows. It can be another page route or an external link (must be a full link).
 * @param {String} type - Sets the type of the button, if not received sets it to `"button"`.
 * @param {string} target - Can be _self, for a link that opens in the same tab, or _blank for a link that opens in a new tab.
 * @param {ReactNode} externalNode - React node that specifies that a link will open a new tab. If not specified when target is _blank, sr-only text will be included by default.
 * @param {string} textClassName - ClassName that affects the font style of the text.
 *
 */

const ButtonLink = forwardRef(
  <C extends ElementType = 'button' | 'a'>(
    {
      className,
      as,
      children,
      disabled,
      isLoading,
      variant,
      size,
      icon,
      showChevron,
      ariaLabel,
      onClick,
      to,
      type = 'button',
      target,
      externalNode,
      textClassName,
      ...props
    }: PolymorphicComponent<C, ButtonLinkProps>,
    ref: PolymorphicRef<C>
  ) => {
    const ButtonLinkTag = as === 'button' ? 'button' : Link;
    const classes = cx(
      {
        [styles[`button--${size}`]]: as === 'button' && size !== undefined,
        [styles[`button--${variant}`]]: as === 'button' && variant,
        [styles['link--disabled']]: as === 'Link' && disabled,
        [styles.link]: as === 'Link' && !(disabled ?? false),
      },
      className
    );
    const isExternal = target === EXTERNAL_TARGET;
    return (
      <ButtonLinkTag
        type={as === 'button' ? type : undefined}
        onClick={onClick}
        disabled={disabled === true || isLoading === true}
        aria-label={ariaLabel}
        aria-disabled={disabled ?? false}
        to={to ?? DEFAULT_ROUTE}
        className={classes}
        target={target}
        ref={ref}
        tabIndex={
          as !== 'button' && onClick !== undefined && disabled !== true
            ? DEFAULT_LINK_TAB_INDEX
            : undefined
        }
        {...props}>
        <ButtonLinkChildren
          showChevron={showChevron}
          icon={icon}
          isLoading={isLoading}
          size={size}
          type={as}
          isExternal={isExternal}
          externalNode={externalNode}
          textClassName={textClassName}>
          {children}
        </ButtonLinkChildren>
      </ButtonLinkTag>
    );
  }
);
ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
