import React, { useContext, useMemo } from 'react';
import { type BidFormDesktopProps } from './BidFormDesktop.types';
import { DOLLAR_SYMBOL, MASKTYPES, TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import Countdown from 'components/molecules/Countdown/Countdown';
import Input from 'components/molecules/Input';
import ButtonLink from 'components/molecules/ButtonLink';
import {
  TITLE,
  TOP_BID_TITLE,
  STARTING_BID_TITLE,
  COUNTDOWN_TITLE,
  BUTTON_TITLE,
  BID_INPUT_PLACEHOLDER,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  BID_INPUT_TITLE,
  FORM_ID,
  SUBMIT_TYPE,
  BID_AMOUNT_ERROR_MESSAGE,
  INPUT_ID,
  INPUT_REGISTER_VALUE,
} from './BidFormDesktop.constants';
import styles from './bidFormDesktop.module.scss';
import { CurrencyContext } from 'context/CurrencyContext/CurrencyContext';
import { formatCurrencyInText } from 'utils/utilities';

const BidFormDesktop: React.FC<BidFormDesktopProps> = ({
  currentBid,
  expirationDate,
  minimumBiddingAmount,
  isFirstBid = true,
  currency,
  onSubmit,
  handleSubmit,
  register,
  errors,
  submitting,
}) => {
  const minimumAmountFormated = useMemo(() => {
    return `${DOLLAR_SYMBOL}${minimumBiddingAmount}`;
  }, [minimumBiddingAmount]);

  const currentBidFormated = useMemo(() => {
    return `${DOLLAR_SYMBOL}${currentBid}`;
  }, [currentBid]);

  const { selectedCurrency, conversionRate } = useContext(CurrencyContext);

  return (
    <div className={styles['body-container']}>
      <Text
        as="span"
        variant={TYPEKIT.D4}
        className={styles['body-container__title']}>
        {TITLE}
      </Text>
      <div className={styles.info}>
        <div className={styles['info__top-bid']}>
          <Text
            as="span"
            variant={TYPEKIT.D5}
            className={styles['info__title-text']}>
            {isFirstBid ? STARTING_BID_TITLE : TOP_BID_TITLE}
          </Text>
          <Text
            displayCurrency
            as="span"
            variant={TYPEKIT.D2}>
            {currentBidFormated}
          </Text>
        </div>
        <div className={styles['info__countdown-container']}>
          <Text
            as="span"
            variant={TYPEKIT.D5}
            className={styles['info__title-text']}>
            {COUNTDOWN_TITLE}
          </Text>
          <Countdown
            targetDate={expirationDate}
            variant={TYPEKIT.D2}
            className={styles.info__countdown}
            descriptive></Countdown>
        </div>
      </div>
      <form
        id={FORM_ID}
        onSubmit={(event) => {
          const theReturnedFunction = handleSubmit(onSubmit);
          void theReturnedFunction(event);
        }}>
        <Text
          displayCurrency
          as="span"
          variant={TYPEKIT.P2}
          className={styles.form__title}>
          {BID_INPUT_TITLE + currency + minimumAmountFormated}
        </Text>
        <div className={styles.form}>
          <Input
            id={INPUT_ID}
            placeholder={
              currency +
              formatCurrencyInText(
                minimumAmountFormated,
                selectedCurrency,
                conversionRate
              ) +
              BID_INPUT_PLACEHOLDER
            }
            mask={MASKTYPES.CURRENCY}
            error={errors.amount?.message}
            className={styles.form__input}
            {...register(INPUT_REGISTER_VALUE, {
              required: BID_AMOUNT_ERROR_MESSAGE,
            })}
          />
          <ButtonLink
            isLoading={submitting}
            className={styles.form__button}
            as={BUTTON_TYPE}
            variant={BUTTON_VARIANT}
            textClassName={TYPEKIT.D5}
            form={FORM_ID}
            type={SUBMIT_TYPE}>
            <Text
              as="span"
              variant={TYPEKIT.D5}>
              {BUTTON_TITLE}
            </Text>
          </ButtonLink>
        </div>
      </form>
    </div>
  );
};

export default BidFormDesktop;
