import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import Text from 'components/atoms/Text';
import Dropdown from 'components/molecules/Dropdown';
import Image from 'components/atoms/Image';
import { ASPECT_RATIO, SPAN_TAG, TYPEKIT } from 'utils/constants';
import { formatToFirstUpperCase, getCellHeader } from 'utils/utilities';
import styles from './userTable.module.scss';
import {
  DATE_JOINED_HEADER,
  EMAIL_HEADER,
  NAME_HEADER,
  OFFICE_HEADER,
  PROFILE_PICTURE_ALT,
  PROFILE_PICTURE_HEADER,
  PROFILE_PICTURE_ID,
  ROLE_HEADER,
} from './UserTable.constants';
import {
  type Office,
  type Role,
  type User,
} from 'graphql/generated-types/graphql';

const getUserColumns = (
  roles: Role[],
  offices: Office[],
  onChangeRole: (roleId: string, userId: string) => Promise<void>,
  onChangeOffice: (officeId: string, userId: string) => Promise<void>,
  currentUserId: string
): Array<ColumnDef<User, string>> => {
  const userColumnHelper = createColumnHelper<User>();

  const userColumns = [
    userColumnHelper.accessor((row) => row.imageUrl, {
      id: PROFILE_PICTURE_ID,
      header: PROFILE_PICTURE_HEADER,
      cell: (info) => {
        return (
          <div className={cx(styles[`profile-picture__container`])}>
            <Image
              src={info.getValue() as string}
              alt={`${info.row.original.firstName} ${info.row.original.lastName}${PROFILE_PICTURE_ALT}`}
              className={cx(styles[`profile-picture`])}
              aspectRatio={ASPECT_RATIO.ONE_BY_ONE}
            />
          </div>
        );
      },
    }),
    userColumnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
      id: NAME_HEADER,
      header: NAME_HEADER,
      cell: (info) => {
        return (
          <Text
            className={cx(styles[`table__cell-text--name`])}
            as={SPAN_TAG}
            variant={TYPEKIT.P2}>
            {info.getValue()}
          </Text>
        );
      },
      filterFn: 'fuzzy',
      sortingFn: (rowA, rowB) => {
        const lastNameA = rowA.original.lastName;
        const lastNameB = rowB.original.lastName;

        return lastNameA.localeCompare(lastNameB);
      },
    }),
    userColumnHelper.accessor((row) => row.email, {
      id: EMAIL_HEADER,
      header: EMAIL_HEADER,
      cell: (info) => {
        return (
          <Text
            className={cx(
              styles[`table__cell-text--${getCellHeader(info.cell)}`]
            )}
            as={SPAN_TAG}
            variant={TYPEKIT.P2}>
            {info.getValue()}
          </Text>
        );
      },
    }),
    userColumnHelper.accessor(
      (row) => {
        return JSON.stringify({ id: row.office.id, name: row.office.name });
      },
      {
        id: OFFICE_HEADER,
        header: OFFICE_HEADER,
        cell: (info) => {
          return (
            <Dropdown
              onChange={(office) => {
                void onChangeOffice(office[0].id, info.row.original.id);
              }}
              className={cx(
                styles[`table__cell-input--${getCellHeader(info.cell)}`]
              )}
              options={offices}
              defaultOption={JSON.parse(info.getValue())}
            />
          );
        },
      }
    ),
    userColumnHelper.accessor((row) => row.dateJoined, {
      id: DATE_JOINED_HEADER,
      header: DATE_JOINED_HEADER,
      cell: (info) => {
        return (
          <Text
            className={cx(
              styles[`table__cell-text--${getCellHeader(info.cell)}`]
            )}
            as={SPAN_TAG}
            variant={TYPEKIT.P2}>
            {info.getValue()}
          </Text>
        );
      },
    }),
    userColumnHelper.accessor(
      (row) => {
        return JSON.stringify({
          id: row.role.id,
          name: formatToFirstUpperCase(row.role.name),
        });
      },
      {
        id: ROLE_HEADER,
        header: ROLE_HEADER,
        cell: (info) => {
          return (
            <Dropdown
              onChange={(role) => {
                void onChangeRole(role[0].name, info.row.original.email);
              }}
              className={cx(
                styles[`table__cell-input--${getCellHeader(info.cell)}`]
              )}
              options={roles}
              defaultOption={JSON.parse(info.getValue())}
              disabled={info.row.original.id === currentUserId}
            />
          );
        },
      }
    ),
  ];

  return userColumns;
};

export default getUserColumns;
