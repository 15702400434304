import { type SetStateAction, useEffect, useState } from 'react';
/**
 * Custom hook for managing state in local storage.
 *
 * @param {string} key - The key to store the value in local storage.
 * @param {string} initialValue - The initial value to use if no data is found in local storage.
 * @returns {[string, React.Dispatch<SetStateAction<LocalStorageValue<T>>>] } - A tuple containing the current value and a function to update it.
 */

type LocalStorageValue<T> = T;

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [
  LocalStorageValue<T>,
  React.Dispatch<SetStateAction<LocalStorageValue<T>>>,
] => {
  const storedValue = JSON.parse(localStorage.getItem(key) ?? 'null');

  const [value, setValue] = useState<LocalStorageValue<T>>(
    storedValue !== null ? storedValue : initialValue
  );

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
