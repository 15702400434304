import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ONE_SECOND, TIME_SECOND } from 'utils/constants';

/**
 * Returns the total of seconds based on an end date and the current date.
 *
 * @param {Dayjs} targetDateTime The end date
 * @returns {number} Total of seconds between the date now and the end date.
 */
const calculateSeconds = (targetDateTime: dayjs.Dayjs): number => {
  if (targetDateTime === null || targetDateTime === undefined) return 0;
  const now = dayjs();
  return targetDateTime.diff(now, TIME_SECOND);
};

/**
 * Calculates the timeleft from today's date and the targetDate.
 *
 * @param {string} targetDate - Required. Target date to calculate if the countdown finished.
 * @returns {boolean} True if the countdown ended, false otherwise.
 */
const useCountdownFinished = (targetDate: string): boolean => {
  const targetDateTime = dayjs(targetDate);

  const [isFinished, setIsFinished] = useState(() => {
    const totalSeconds = calculateSeconds(targetDateTime);
    return totalSeconds <= 0;
  });

  useEffect(() => {
    const updateCountdown = (): void => {
      const totalSeconds = calculateSeconds(targetDateTime);
      setIsFinished(totalSeconds <= 0);
      if (totalSeconds > 0) {
        const delay = ONE_SECOND * totalSeconds;
        setTimeout(updateCountdown, delay);
      }
    };
    updateCountdown();
  }, [targetDateTime]);

  return isFinished;
};

export { useCountdownFinished };
