import React, { type FC } from 'react';
import { type BannerProps } from './Banner.types';
import Image from 'components/atoms/Image/Image';
import Text from '../../atoms/Text/Text';
import useBreakpoint from 'hooks/useBreakpoint';
import { EndDateType, TYPEKIT } from 'utils/constants';
import EndDate from 'components/atoms/EndDate';
import Locations from '../../molecules/Locations/Locations';
import Countdown from '../../molecules/Countdown/Countdown';
import { DonationSection } from './DonationSection/DonationSection';
import {
  AUCTION_CLOSED_MESSAGE,
  COUNTDOWN_TITLE,
  BANNER_IMAGE_ALT,
  MEDIUM_DESKTOP_BREAKPOINT,
} from './Banner.constants';
import { useCountdown } from 'hooks/useCountdown';
import cx from 'classnames';
import styles from './banner.module.scss';

const Banner: FC<BannerProps> = ({
  name,
  locations,
  raisedAmount,
  goal,
  endDatetime,
  imageURL,
  lastUpdate,
}) => {
  const { isDesktop, isBreakpoint } = useBreakpoint();
  const { counterOff } = useCountdown(endDatetime);

  return (
    <div className={styles.banner}>
      <div className={styles.banner__main}>
        <div className={styles.banner__content}>
          <div className={styles.banner__header}>
            <Locations locations={locations} />
            <Text
              className={styles.banner__title}
              variant={isDesktop ? TYPEKIT.D2 : TYPEKIT.D3}>
              {name}
            </Text>
            <EndDate
              endDatetime={endDatetime}
              type={EndDateType.BANNER}
            />
          </div>
          {isDesktop && (
            <DonationSection
              raisedAmount={raisedAmount}
              goal={goal}
              lastUpdate={lastUpdate}
            />
          )}
        </div>
        <div className={styles['banner__image-container']}>
          <div className={styles.banner__gradient} />
          <Image
            className={styles.banner__image}
            src={imageURL}
            alt={BANNER_IMAGE_ALT}
          />
          {isBreakpoint(MEDIUM_DESKTOP_BREAKPOINT) && (
            <div className={styles['banner__countdown-container']}>
              {!counterOff && (
                <Text
                  variant={TYPEKIT.D3}
                  className={styles['banner__countdown-title']}>
                  {COUNTDOWN_TITLE}
                </Text>
              )}
              <Countdown
                variant={TYPEKIT.D1}
                descriptive
                expirationMessage={AUCTION_CLOSED_MESSAGE}
                spaced
                targetDate={endDatetime}
                className={cx({
                  [styles.banner__countdown]: !counterOff,
                })}
              />
            </div>
          )}
        </div>
      </div>
      {!isDesktop && (
        <div className={styles.banner__footer}>
          <DonationSection
            raisedAmount={raisedAmount}
            goal={goal}
            lastUpdate={lastUpdate}
          />
        </div>
      )}
    </div>
  );
};

export default Banner;
