import React from 'react';
import { type InfoMessageProps } from './InfoMessage.types';
import { INFO_MESSAGE_TYPES, TYPEKIT } from 'utils/constants';
import Text from 'components/atoms/Text';
import warning_icon from 'assets/svgs/warning-circle-logo.svg';
import Icon from 'components/atoms/Icon';
import styles from './infoMessage.module.scss';
import cx from 'classnames';

const InfoMessage: React.FC<InfoMessageProps> = ({
  message,
  variant = INFO_MESSAGE_TYPES.DEFAULT,
}) => {
  return (
    <div
      className={cx(
        styles[`info-content`],
        styles[`info-content--variant-${variant}`]
      )}>
      <Icon
        className={cx(
          styles[`info-content__icon`],
          styles[`info-content__icon--variant-${variant}`]
        )}
        src={warning_icon}
        disabled
        aria-hidden="true"
      />
      <Text
        as="span"
        className={styles[`info-content__text`]}
        variant={TYPEKIT.P2}>
        {message}
      </Text>
    </div>
  );
};

export default InfoMessage;
