import { gql } from '@apollo/client';

export const GET_ALL_OFFICES = gql`
  query GetAllOffices {
    getAllOffices {
      id
      name
    }
  }
`;

export const GET_OFFICE_BY_ID = gql`
  query getOfficeById($id: ID!) {
    getOfficeById(id: $id) {
      id
      name
    }
  }
`;

export const GET_OFFICE_BY_NAME = gql`
  query getOfficeByName($name: String!) {
    getOfficeByName(name: $name) {
      id
      name
    }
  }
`;
